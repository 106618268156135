import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

/**************************** */
const VchartSta = (props) => {
  /********************* */
  const titlos = props.titlos;
  const data = props.data;
  const options = props.options;

  const options1 = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    height: 500,
    plugins: {
      title: {
        display: true,
        text: titlos,
      },
    },
    scales: {
      x: {
        stacked: true,
        barPercentage: 0.8,
      },
      y: {
        stacked: true,
        barPercentage: 0.8,
      },
    },
  };

  // console.log("---data1---", data1);
  // console.log("===data===", data);

  return (
    <Bar
      options={options}
      data={data}
      style={{
        //width: 750,
        height: 2000,
      }}
    />
  );
};

export default VchartSta;
