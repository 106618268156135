import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Modal.css";
import Button from "react-bootstrap/Button";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import VtextBox from "../VtextBox.component";
import GroupBox from "../GroupBox.component";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { Md10K } from "react-icons/md";

//***************************************************************** */
function Modal_pass({ setModalOpen, mTitle, mData, onSaveSuccess }) {
  //************************************************************* */
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [oldPassOk, setOldPassOk] = useState(null);
  const [msgPass, setMsgPass] = useState("-");
  const [formData3, setFormData3] = useState({
    id: mData.id,
    username: mData.username,
    password: "",
    newPassword: "",
    confirmPassword: "",
  });

  useEffect(() => {
    //const pass1 = Get_userPass();
  }, []);

  async function Get_userPass() {
    //---------------axios-----------------
    await axios
      .post(`${process.env.REACT_APP_URL}/api/zauth/z_user`, formData3)
      .then((response) => {
        console.log(response);
        setOldPassOk(response.data);
      })
      .catch((error) => console.error(error));
  }

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleTextBox = (TextBox_id, newValue) => {
    setFormData3((prevFormData3) => ({
      ...prevFormData3,
      [TextBox_id]: newValue,
    }));
  };

  /******************************** */
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Add your password change logic here
    await axios
      .post(`${process.env.REACT_APP_URL}/api/zauth/z_user`, formData3)
      .then((response) => {
        console.log(response.data);
        //-------------201---"Old-Password not correct"-----
        if (response.status === 201) {
          setMsgPass("(201) Σφάλμα στο Παλιό Password");
          setModalOpen(true);
        }
        //-------------200---"Pass changed"-----------------
        if (response.status === 200) {
          setMsgPass("Η αλλαγή Password έγινε");
          setTimeout(() => {
            setModalOpen(false);
          }, 2500);
        }
        //------------------
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className="modalBackground">
      <div className="modalContainer modalContainerPass">
        {/* <div className="titleCloseBtn22"> */}
        <div className="title">
          <div className="row">
            <div className="col-10">
              <h4>{mTitle}</h4>
            </div>
            <div className="col-2">
              <Button
                id="closeBtn"
                className="btn btn-secondary btn-sm"
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                &nbsp;X&nbsp;
              </Button>
            </div>
          </div>
          <hr />
        </div>
        {/* ----------------------------[ body ]-------------------------------- */}
        <div className="body">
          {/* ---------------[ Form ]---------------- */}
          <form onSubmit={handleSubmit}>
            <div className="text-center">
              <div>
                {/* ----------------[ id --- username ]---------------- */}
                <VtextBox
                  id="id"
                  name="id"
                  label="id:"
                  textAlign="left"
                  value={formData3.id}
                  width={50}
                  disabled
                />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <VtextBox
                  id="username"
                  name="username"
                  label="username:"
                  textAlign="left"
                  value={formData3.username}
                  width={150}
                  disabled
                />
              </div>
              <div style={{ paddingTop: 10 }}></div>
              <div>
                {/* ----------------[ password ]---------------- */}
                <VtextBox
                  id="password"
                  name="password"
                  label="Παλιό Password:"
                  textAlign="left"
                  type={showPassword ? "text" : "password"}
                  value={formData3.password}
                  width={250}
                  onChange={handleTextBox}
                  maxLength={24}
                  style={{ margin: "10px" }}
                />
                <a
                  className="btn btn-outline-secondary btn-sm"
                  style={{ marginTop: 13, marginLeft: -6, lineHeight: 1 }}
                  onClick={toggleShowPassword}
                >
                  {showPassword ? (
                    <FaEyeSlash color="909090" />
                  ) : (
                    <FaEye color="#606060" onClick={toggleShowPassword} />
                  )}
                </a>
              </div>
              <div>
                {/* ----------------[ newPassword ]---------------- */}
                <VtextBox
                  id="newPassword"
                  name="newPassword"
                  label="Νέο Password: (min 8 χαρ.)"
                  textAlign="left"
                  type={showNewPassword ? "text" : "password"}
                  value={formData3.newPassword}
                  width={250}
                  onChange={handleTextBox}
                  maxLength={24}
                  style={{ margin: "10px" }}
                />
                <a
                  className="btn btn-outline-secondary btn-sm"
                  style={{ marginTop: 13, marginLeft: -6, lineHeight: 1 }}
                  onClick={toggleShowNewPassword}
                >
                  {showPassword ? (
                    <FaEyeSlash color="909090" />
                  ) : (
                    <FaEye color="#606060" onClick={toggleShowNewPassword} />
                  )}
                </a>
              </div>
              <div>
                {/* ----------------[ newPassword ]---------------- */}
                <VtextBox
                  id="confirmPassword"
                  name="confirmPassword"
                  label="Επιβεβαίωση Νέου Password:"
                  textAlign="left"
                  type={showConfirmPassword ? "text" : "password"}
                  value={formData3.confirmPassword}
                  width={250}
                  onChange={handleTextBox}
                  maxLength={24}
                  style={{ margin: "10px" }}
                />
                <a
                  className="btn btn-outline-secondary btn-sm"
                  style={{ marginTop: 13, marginLeft: -6, lineHeight: 1 }}
                  onClick={toggleShowConfirmPassword}
                >
                  {showPassword ? (
                    <FaEyeSlash color="909090" />
                  ) : (
                    <FaEye
                      color="#606060"
                      onClick={toggleShowConfirmPassword}
                    />
                  )}
                </a>
              </div>
            </div>
            {msgPass.startsWith("(201)") ? (
              <div className="text-center text-danger">
                <span style={{ fontSize: 16 }}>{msgPass}</span>
              </div>
            ) : (
              <div className="text-center text-success">
                <span style={{ fontSize: 18 }}>
                  <b>{msgPass}</b>
                </span>
              </div>
            )}
            <hr />

            {/* <button type="submit">Change Password</button> */}
          </form>
          {/* ------------[ end Form ]------------------ */}
        </div>
        <div className="footer">
          <button
            onClick={() => {
              setModalOpen(false);
            }}
            id="cancelBtn"
          >
            Ακύρωση
          </button>
          <Button
            onClick={handleSubmit}
            disabled={
              formData3.password === "" ||
              formData3.newPassword === "" ||
              formData3.newPassword !== formData3.confirmPassword ||
              formData3.newPassword.length < 8
            }
          >
            Αποθήκευση
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Modal_pass;
