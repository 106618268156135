import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import VchartHor from "../../components/VchartHor.component";

import { GiFruitTree } from "react-icons/gi";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

/* ************************************ */
export default function Eidos_CO2() {
  const [myData, setMyData] = useState({});
  //----------- Sumarize -----------------
  const [ssCnt, setSsCnt] = useState(0);
  const [ssSum, setSsSum] = useState(0);
  //----------- Graph --------------------------
  const data_labels = [];
  const data_sCO2 = [];
  const [titlos, setTitlos] = useState("");
  const [graphData, setGraphData] = useState(null);
  //-----------------------------------------------
  const [loadData, setLoadData] = useState(null);
  //-----------------------------------------------

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/statist/st_eidos_co2`)
      .then((response) => {
        const formData = response.data;
        setMyData(response.data);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (myData.length > 0) {
      setSsCnt(myData.reduce((sum, obj) => sum + parseInt(obj.count), 0));
      setSsSum(myData.reduce((sum, obj) => sum + parseInt(obj.sum), 0));
      setLoadData(true);
    }
  }, [myData]);

  //-----------------Graph-----------------------
  useEffect(() => {
    if (loadData) {
      myData.forEach((row) => {
        data_labels.push(row.common_name);
        data_sCO2.push(parseInt(row.sum));
      });
      //-------------titlos-----------------------
      setTitlos("Κατανομή Απορρόφησης CO2");
      //--------------graphData-----------------------
      setGraphData({
        labels: data_labels,
        datasets: [
          {
            label: "CO2",
            borderColor: "rgb(255, 26, 26)",
            backgroundColor: "rgba(255, 26, 26, 0.6)",
            data: data_sCO2,
          },
        ],
      });
      //----------------------------------------------
    }
  }, [loadData]);

  return (
    <div className="container2">
      <header className="jumbotron" style={{ padding: "1.0rem" }}>
        <h3 className="text-center">
          <b>GreenTree</b> - Στατιστικά
        </h3>
        <h6 className="text-center">(Επισκέπτες - Εγγεγραμμένοι χρήστες)</h6>
      </header>
      <header className="jumbotron" style={{ padding: "1.0rem" }}>
        <h5 className="text-justify">
          <img src="/img/small-logo-green-tree.png" width="120px" alt="Logo" />
          Απορρόφηση Άνθρακα ... ανά Είδος Δένδρων
        </h5>
      </header>
      <Link to="/statist" className="btn btn-secondary pull-right">
        <GiFruitTree
          color="#AEFDCE"
          style={{ top: "-2px", position: "relative" }}
        />{" "}
        Επιστροφή στα Στατιστικά{" "}
      </Link>
      <div>&nbsp;</div>
      {/*-------------------Graph------------------------------*/}
      <div className="row">
        <div className="col-1"></div>
        <div className="col-10">
          {/*----------------------------------------------------*/}
          {graphData && (
            <VchartHor titlos={titlos} data={graphData} height="250" />
          )}
          {/*----------------------------------------------------*/}
        </div>
        <div className="col-1"></div>
      </div>
      {/* ---------------------------------------------------- */}
      <div>&nbsp;</div>
      {/* -------------------------------------------- */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell
                colSpan={4}
                align="right"
                style={{ color: "black", backgroundColor: "#ffffff" }}
              ></StyledTableCell>
              <StyledTableCell
                colSpan={4}
                align="center"
                style={{ color: "#ffffff", backgroundColor: "#476b6b" }}
              >
                <h4>
                  <b>
                    CO<sub>2</sub>
                  </b>
                </h4>
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>
                <b>κωδ.</b>
              </StyledTableCell>
              <StyledTableCell align="right">
                <b>Κοινή Ονομασία</b>
              </StyledTableCell>
              <StyledTableCell>
                <b>Βοτανική Ονομασία</b>
              </StyledTableCell>
              <StyledTableCell align="right">
                <b>Πλήθος</b>
              </StyledTableCell>
              <StyledTableCell
                align="right"
                style={{ color: "#ffffff", backgroundColor: "#476b6b" }}
              >
                <b>Σύν.CO2 (kg)</b>
              </StyledTableCell>
              <StyledTableCell
                align="right"
                style={{ color: "#ffffff", backgroundColor: "#476b6b" }}
              >
                <b>M.O. CO2</b>
              </StyledTableCell>
              <StyledTableCell
                align="right"
                style={{ color: "#ffffff", backgroundColor: "#476b6b" }}
              >
                <b>min CO2</b>
              </StyledTableCell>
              <StyledTableCell
                align="right"
                style={{ color: "#ffffff", backgroundColor: "#476b6b" }}
              >
                <b>max CO2</b>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {myData.length > 0 && (
            <TableBody>
              {myData.map((row) => (
                <StyledTableRow key={row.eidos_id}>
                  <StyledTableCell component="th" scope="row">
                    {row.eidos_id}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.common_name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.botanic_name}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {parseInt(row.count).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <b>{parseInt(row.sum).toLocaleString("el-GR")}</b>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {parseInt(row.avg).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {parseInt(row.min).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {parseInt(row.max).toLocaleString("el-GR")}
                  </StyledTableCell>
                </StyledTableRow>
              ))}

              <StyledTableRow
                key={"SYNOLA"}
                sx={{ backgroundColor: "#c2c2c2" }}
              >
                <StyledTableCell align="left">
                  <b>{""}</b>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <b>{"Γενικά Σύνολα"}</b>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <b>{""}</b>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <b>{parseInt(ssCnt).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <b>{parseInt(ssSum).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </StyledTableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {/* --------------------[ waiting ]--------------------------- */}
      {!myData?.length && (
        <h6 className="text-center text-info">
          <b>Υπολογισμός . . .</b>{" "}
          <img src="/img/wait-3.gif" height={150} alt="Wait" />
        </h6>
      )}
      {/* ---------------------------------------------------------- */}
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <Link to="/statist" className="btn btn-secondary pull-right">
        <GiFruitTree
          color="#AEFDCE"
          style={{ top: "-2px", position: "relative" }}
        />{" "}
        Επιστροφή στα Στατιστικά{" "}
      </Link>
      {/* -------------------------------------------- */}
      <div>&nbsp;</div>
      <div>&nbsp;</div>
    </div>
  );
}
