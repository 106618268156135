import React, { Component } from "react";
import {
  //Routes,
  //Route,
  Link,
} from "react-router-dom";
import Button from "react-bootstrap/Button";
import UserService from "../services/user.service";
import GetOne from "../pages/getdendro";
import { FaMapMarkedAlt } from "react-icons/fa";
import { FaChartPie } from "react-icons/fa";

import Box, { BoxProps } from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
//import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";

//----------------------
const searcId = "84663";
//----------------------

const openMapPage = () => {
  GetOne();
};

export default class Views extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: "",
    };
  }

  componentDidMount() {
    UserService.getPublicContent().then(
      (response) => {
        this.setState({
          content: response.data,
        });
      },
      (error) => {
        this.setState({
          content:
            (error.response && error.response.data) ||
            error.message ||
            error.toString(),
        });
      }
    );
  }

  render() {
    return (
      <div className="container2">
        <header className="jumbotron" style={{ padding: "1.0rem" }}>
          {/* <h3>{this.state.content}</h3> */}
          <h3 className="text-center">
            <img src="/img/logo-greentree.png" height="5px" alt="greenTree" />
            &nbsp;
            <b>GreenTree</b> - Σχετικά
          </h3>
          <h6 className="text-center">
            ( Επισκέπτες , Εγγεγραμμένοι χρήστες )
          </h6>
        </header>

        <header className="jumbotron" style={{ padding: "5rem" }}>
          <div>
            <h4 className="text-center">
              <img
                src="/img/logo-greentree.png"
                height="45px"
                alt="greenTree"
              />
              &nbsp;
              <b>GreenTree</b>{" "}
              <span style={{ fontSize: "12px" }}>ver.: 3.0</span>
            </h4>
          </div>
          <div className="text-justify">
            <p>
              Η σουίτα εφαρμογών <b>Green Tree</b> δημιουργήθηκε στο πλαίσιο του
              Έργου με τίτλο «
              <b>
                Δημιουργία ολοκληρωμένου προτύπου συστήματος για την κυβερνητική
                στη διαχείριση της αστικής δασοπονίας και την προσαρμογή των
                πόλεων στην κλιματική αλλαγή
              </b>
              », και ενσωματώνει τις εφαρμογές <b>GreenTree Client</b> και
              <b>GreenTree Server</b>, προκειμένου να συλλεχθεί αξιόπιστη και
              ακριβής πληροφορία για κάθε δένδρο που διαχειρίζεται ο Δήμος
              Θεσσαλονίκης και στη συνέχεια αυτή να οργανωθεί σε μια λειτουργική
              γεωχωρική βάση δεδομένων, η οποία να ενημερώνεται και να είναι
              επεξεργάσιμη. Το σύστημα αναπτύχθηκε στο διάστημα των 34 μηνών
              υλοποίησης του Έργου (2012-2015) και περικλείει ένα σύνολο
              διαδικασιών, υπηρεσιών καθώς και προϊόντων λογισμικού που
              θεωρούνται απαραίτητα για την ύπαρξη ολιστικής προσέγγισης στην
              άσκηση της αστικής δασοπονίας από τους φορείς που είναι
              επιφορτισμένοι με αυτό.
            </p>
          </div>

          <div className="text-justify">
            <p>
              Η διαδικτυακή εφαρμογή Green Tree χρησιμοποιείται από τους πολίτες
              και τους υπαλλήλους του Δήμου Θεσσαλονίκης για την ενημέρωση,
              δικτύωση και διαχείριση του αστικού πρασίνου και αποτελεί ένα
              πανευρωπαϊκά καινοτόμο εργαλείο το οποίο μπορεί να χρησιμοποιηθεί
              από κάθε φορέα που σχετίζεται άμεσα ή έμμεσα με τη διαχείριση
              μεγάλων σε μέγεθος χωρικών μονάδων αστικού πρασίνου ή τεχνητών εν
              γένει φυτεύσεων.
            </p>
          </div>
          <div className="text-justify">
            <p>
              Η παρούσα μορφή της διαδικτυακής εφαρμογής Green Tree αποτελεί
              παραδοτέο του Έργου με τίτλο «
              <b>
                Αναβάθμιση της λειτουργίας του συστήματος διαχείρισης αστικού
                πρασίνου και διασύνδεσή του με την ιστοσελίδα του Δήμου για
                ενημέρωση και χρήση από φορείς και πολίτες.
              </b>
              ». Η υλοποίηση του έργου έγινε με συνεργασία της εταιρίας
              περιβαλλοντικής διαχείρισης <b>ΤΕΧΝΟΟΜΟΙΟΣΤΑΣΗ Ο.Ε.</b> και των
              υπηρεσιών του <b>Δήμου Θεσσαλονίκης</b>.
            </p>
          </div>
          <div className="text-justify">
            <p>
              Σκοπός του Έργου υπήρξε η αναβάθμιση της εφαρμογής Green Tree
              καθώς και η διασύνδεση αυτής με το Χαρτογραφικό portal - WebGIS
              του Δήμου Θεσσαλονίκης. Σε αυτό το πλαίσιο, έγινε επικαιροποίηση
              και εμπλουτισμός της Βάσης Δεδομένων ξυλωδών ειδών, προσθήκη
              πεδίων υπολογισμού δυνητικών βιομετρικών διαστάσεων και εκτίμησης
              έτους φύτευσης, βελτιστοποίηση και εμπλουτισμός της παρουσίασης
              των στατιστικών στοιχείων με προσθήκη δυναμικών διαγραμμάτων,
              σύνδεση με την διαδικτυακή εφαρμογή «Βελτιώνω την πόλη μου»,
              δυναμικός και συνεχής υπολογισμός της οικονομικής αξίας των
              αστικών δένδρων και του αποθηκευμένου CO2 με εξειδικευμένες
              εξισώσεις ανά είδος, γένος ή κατηγορία δένδρου.
            </p>
          </div>
          <div className="text-justify">
            <p>
              Οι παραπάνω αναβαθμίσεις ολοκληρώνουν ένα πρότυπο σύστημα, το
              οποίο ανταποκρίνεται στις απαιτήσεις και τις ιδιαιτερότητες των
              αστικών οικοσυστημάτων για την εφαρμογή κυβερνητικής και τη λήψη
              αποφάσεων κατά την άσκηση της δασοκομίας πόλεων με ολιστικό και
              ενιαίο τρόπο, με στόχο την πρόβλεψη της προσαρμογής των πόλεων
              στην κλιματική αλλαγή.{" "}
            </p>
          </div>
          <div className="text-center">
            <p>
              <img
                src="/img/logo-greentree.png"
                height="45px"
                alt="greenTree"
              />
              &nbsp;
              <b>Ομάδα Έργου:</b>
            </p>
          </div>
          <hr />
          <div className="row">
            <div className="col-6 text-right">
              <p>
                Συντονισμός Έργου -<br /> Επιστημονικά Υπεύθυνος
              </p>
            </div>
            <div className="col-6">
              <p>
                <b>Νικόλαος Γούναρης</b> <br />
                Δασολόγος – Περιβαλλοντολόγος
              </p>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-6 text-right">
              <p>
                Υπεύθυνοι Σχεδιασμού, <br />
                Ανάπτυξης Εφαρμογής,
                <br />
                Εγκατάστασης και Παραμετροποίησης <br /> Λογισμικού{" "}
              </p>
            </div>
            <div className="col-6">
              <p>
                <b>Ηλίας Δημητριάδης </b>
                <br />
                Αγρονόμος Τοπογράφος Μηχανικός, <br />
                <b>MSc</b> Information Systems
              </p>
              <p>
                <b>Τζήμας Χρήστος </b>
                <br />
                Μηχανικός Πληροφορικής και Ηλεκτρονικών Συστημάτων
              </p>
              <p>
                <b>Ελευθέριος Μυστακίδης </b>
                <br />
                Δασολόγος – Περιβαλλοντολόγος
              </p>
              <p>
                <b>Λέανδρος Δημητριάδης </b>
                <br />
                Οικονομολόγος - Πληροφορικός
              </p>
            </div>
          </div>
          <hr />
        </header>
        <div className="text-center">
          <Link to={`/home`} className="btn btn-secondary text-center">
            <FaMapMarkedAlt
              color="#AEFDCE"
              style={{ top: "-2px", position: "relative" }}
            />{" "}
            Επιστροφή στην Αρχική
          </Link>{" "}
        </div>
        <div>&nbsp;</div>
      </div>
    );
  }
}
