import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import AuthService from "../services/auth.service";
import axios from "axios";

import { GiFruitTree } from "react-icons/gi";

export default function Statistics() {
  return (
    <div className="container2">
      <header className="jumbotron" style={{ padding: "1.0rem" }}>
        <div className="row">
          <div className="col-1">
            <img
              src="/img/small-logo-green-tree.png"
              width="120px"
              alt="Logo"
            />
          </div>
          <div className="col-11">
            <h3 className="text-center">
              <b>GreenTree</b> - Στατιστικά
            </h3>
            <h6 className="text-center">
              (Επισκέπτες - Εγγεγραμμένοι χρήστες)
            </h6>
          </div>
        </div>
      </header>
      {/* --------------------------------------------------- */}
      <header className="jumbotron" style={{ padding: "1.0rem" }}>
        <div className="p-2 text-center bg-light">
          <h5 className="text-center">Συνοπτική Παρουσίαση ...</h5>
        </div>
        {/* ............................................. */}
        <div className="p-2 text-center bg-light">
          <Link to="/nekra" className="btn btn-warning pull-right">
            <GiFruitTree
              color="#AEFDCE"
              style={{ top: "-2px", position: "relative" }}
            />{" "}
            Ζωντανά, Νεκρά, ... ανά Δημοτικό Διαμέρισμα{" "}
          </Link>
          &nbsp; &nbsp;
          <Link to="/eidosDD" className="btn btn-warning pull-right">
            <GiFruitTree
              color="#AEFDCE"
              style={{ top: "-2px", position: "relative" }}
            />{" "}
            Είδη Δένδρων, ... ανά Δημοτικό Διαμέρισμα{" "}
          </Link>
        </div>
      </header>
      {/* --------------------------------------------------- */}

      <header className="jumbotron" style={{ padding: "1.0rem" }}>
        <div className="p-2 text-center bg-light">
          <h5 className="text-center">Κατάσταση Δένδρων ...</h5>
        </div>
        {/* ............................................. */}
        <div className="p-2 text-center bg-light">
          <Link to="/zotikotita" className="btn btn-success pull-right">
            <GiFruitTree
              color="#AEFDCE"
              style={{ top: "-2px", position: "relative" }}
            />{" "}
            Ζωτικότητα Δένδρων, ... ανά Δημοτικό Διαμέρισμα{" "}
          </Link>
        </div>
      </header>
      {/* --------------------------------------------------- */}
      <header className="jumbotron" style={{ padding: "1.0rem" }}>
        <div className="p-2 text-center bg-light">
          <h5 className="text-center">Δασοκομικά Χαρακτηριστικά ...</h5>
        </div>
        {/* ............................................. */}
        <div className="p-2 text-center bg-light">
          <Link to="/eidos_hde" className="btn btn-primary pull-right">
            <GiFruitTree
              color="#AEFDCE"
              style={{ top: "-2px", position: "relative" }}
            />{" "}
            Ύψος, Διάμετρος, Προβολή ... ανά Είδος{" "}
          </Link>
          &nbsp; &nbsp;
          <Link to="/eidos_ogkos" className="btn btn-primary pull-right">
            <GiFruitTree
              color="#AEFDCE"
              style={{ top: "-2px", position: "relative" }}
            />{" "}
            Όγκος κόμης ... ανά Είδος{" "}
          </Link>
        </div>
      </header>
      {/* --------------------------------------------------- */}
      <header className="jumbotron" style={{ padding: "1.0rem" }}>
        <div className="p-2 text-center bg-light">
          <h5 className="text-center">Αξία Δένδρων ...</h5>
        </div>
        {/* ............................................. */}
        <div className="p-2 text-center bg-light">
          <Link to="/eidos_co2" className="btn btn-info pull-right">
            <GiFruitTree
              color="#AEFDCE"
              style={{ top: "-2px", position: "relative" }}
            />{" "}
            Αποθήκευση Άνθρακα{" "}
          </Link>
          &nbsp; &nbsp;
          <Link to="/eidos_val" className="btn btn-info pull-right">
            <GiFruitTree
              color="#AEFDCE"
              style={{ top: "-2px", position: "relative" }}
            />{" "}
            Νομισματική Αξία{" "}
          </Link>
        </div>
      </header>
      {/* --------------------------------------------------- */}
      <hr />
      <div className="text-center" style={{ marginTop: -20 }}>
        <Link to="/home" className="btn btn-secondary">
          <GiFruitTree
            color="#AEFDCE"
            style={{ top: "-2px", position: "relative" }}
          />{" "}
          Επιστροφή στην Αρχική{" "}
        </Link>
        &nbsp;&nbsp;&nbsp;
        <Link to="/views" className="btn btn-secondary">
          <GiFruitTree
            color="#AEFDCE"
            style={{ top: "-2px", position: "relative" }}
          />{" "}
          Επιστροφή στις Προβολές{" "}
        </Link>
      </div>
    </div>
  );
}
