import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
//import VchartHor from "../../components/VchartHor.component";
import VchartSta from "../../components/VchartSta.component";
import Modal from "react-modal";
import { Chart, Tooltip } from "chart.js";

import { GiFruitTree } from "react-icons/gi";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

/* ************************************ */
export default function Eidos_DD() {
  /******************************* */
  const [myData, setMyData] = useState({});
  //----------- Sumarize -----------------------
  const [ssdd1, setSsdd1] = useState(0);
  const [ssdd2, setSsdd2] = useState(0);
  const [ssdd3, setSsdd3] = useState(0);
  const [ssdd4, setSsdd4] = useState(0);
  const [ssdd5, setSsdd5] = useState(0);
  const [sssum_d, setSssum_d] = useState(0);
  const [ssemb, setSsemb] = useState(0);
  const [ssogk, setSsogk] = useState(0);
  //----------- Graph --------------------------
  const data_labels = [];
  const data_sdd1 = [];
  const data_sdd2 = [];
  const data_sdd3 = [];
  const data_sdd4 = [];
  const data_sdd5 = [];
  const [titlos, setTitlos] = useState("");
  const [graphData, setGraphData] = useState(null);
  //-----------------------------------------------
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState("");
  //------------------
  const chartRef = useRef(null);
  //-----------------------------------------------------

  const options = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    height: 500,
    plugins: {
      title: {
        display: true,
        text: titlos,
      },
    },
    scales: {
      x: {
        stacked: true,
        barPercentage: 0.8,
      },
      y: {
        stacked: true,
        barPercentage: 0.8,
      },
    },
    // onClick: (_, elements) => {
    //   // Handle click events on the chart
    //   if (elements.length > 0) {
    //     // If there's a clicked element (label), open the popup
    //     const label = graphData.labels[elements[0].index];
    //     setSelectedLabel(label);
    //     setModalIsOpen(true);
    //     console.log("---label---", label);
    //     console.log("---elements---", elements);
    //   }
    // },
    onHover: (_, elements) => {
      // Handle hover events on the chart
      if (elements.length > 0) {
        // If there's a hovered element (label), update the selected label (no popup on hover)
        const label = graphData.labels[elements[0].index];
        setSelectedLabel(label);
      } else {
        setSelectedLabel(""); // Reset the selected label when not hovering
      }
    },
  };

  useEffect(() => {
    // Attach a click event listener to the chart's canvas
    if (chartRef.current) {
      const canvas = chartRef.current.chartInstance.canvas;
      canvas.addEventListener("click", handleCanvasClick);
    }

    return () => {
      // Cleanup: remove the click event listener when the component is unmounted
      if (chartRef.current) {
        const canvas = chartRef.current.chartInstance.canvas;
        canvas.removeEventListener("click", handleCanvasClick);
      }
    };
  }, []);

  const handleCanvasClick = (event) => {
    console.log("---------------------");
    // Get the chart instance
    const chartInstance = chartRef.current.chartInstance;

    // Get the nearest label element to the click event
    const activeLabel = chartInstance.getElementsAtEventForMode(
      event,
      "nearest",
      { intersect: true },
      true
    );

    if (activeLabel.length > 0) {
      // If there's a clicked label element, open the popup
      const label = graphData.labels[activeLabel[0].index];
      setSelectedLabel(label);
      setModalIsOpen(true);
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/statist/st_eidos_dd`)
      .then((response) => {
        const formData = response.data;
        setMyData(response.data);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (myData.length > 0) {
      setSsdd1(myData.reduce((sum, obj) => sum + parseInt(obj.sdd1), 0));
      setSsdd2(myData.reduce((sum, obj) => sum + parseInt(obj.sdd2), 0));
      setSsdd3(myData.reduce((sum, obj) => sum + parseInt(obj.sdd3), 0));
      setSsdd4(myData.reduce((sum, obj) => sum + parseInt(obj.sdd4), 0));
      setSsdd5(myData.reduce((sum, obj) => sum + parseInt(obj.sdd5), 0));
      setSssum_d(myData.reduce((sum, obj) => sum + parseInt(obj.ssum_d), 0));
      setSsemb(myData.reduce((sum, obj) => sum + parseInt(obj.semb), 0));
      setSsogk(myData.reduce((sum, obj) => sum + parseInt(obj.sogkos), 0));

      //-----------------Graph-----------------------
      if (myData.length > 0) {
        myData.forEach((row) => {
          data_labels.push(row.common_name);
          data_sdd1.push(parseInt(row.sdd1));
          data_sdd2.push(parseInt(row.sdd2));
          data_sdd3.push(parseInt(row.sdd3));
          data_sdd4.push(parseInt(row.sdd4));
          data_sdd5.push(parseInt(row.sdd5));
        });
        //-------------titlos-----------------------
        setTitlos("Είδη Δένδρων ανά Δημοτικό Διαμέρισμα");
        //--------------graphData-----------------------
        setGraphData({
          labels: data_labels,
          datasets: [
            {
              label: "1ο Δ.Δ.",
              borderColor: "rgb(255, 26, 26)",
              backgroundColor: "rgba(255, 26, 26, 0.6)",
              data: data_sdd1,
              barThickness: 15,
            },
            {
              label: "2ο Δ.Δ.",
              borderColor: "rgb(0, 92, 230)",
              backgroundColor: "rgba(0, 92, 230, 0.75)",
              data: data_sdd2,
              barThickness: 15,
            },
            {
              label: "3ο Δ.Δ.",
              borderColor: "rgb(255, 153, 0)",
              backgroundColor: "rgba(255, 153, 0, 0.85)",
              data: data_sdd3,
              barThickness: 15,
            },
            {
              label: "4ο Δ.Δ.",
              borderColor: "rgb(0, 153, 0)",
              backgroundColor: "rgba(0, 153, 0, 0.85)",
              data: data_sdd4,
              barThickness: 15,
            },
            {
              label: "5ο Δ.Δ.",
              borderColor: "rgb(184, 77, 255)",
              backgroundColor: "rgba(184, 77, 255, 0.85)",
              data: data_sdd5,
              barThickness: 15,
            },
          ],
        });
      }
      //--------------------------------------------------
    }
  }, [myData]);

  //--------------------------------------------------------
  // Custom tooltip to display additional information (hide default tooltip)
  const customTooltip = {
    enabled: false,
    external: (context) => {
      // Do nothing (disable the default tooltip)
    },
  };

  // Register custom tooltip plugin with Chart.js
  Chart.register({
    id: "customTooltip",
    beforeRender: (chart, args, options) => {
      chart.pluginTooltips = [];
      chart.options.plugins.tooltip = customTooltip;
    },
  });

  const customModalStyles = {
    content: {
      width: "50%", // Set the width of the Modal to 50% of the screen
      height: "50%", // Set the height of the Modal to 50% of the screen
      top: "25%", // Position the Modal vertically in the middle
      left: "25%", // Position the Modal horizontally in the middle
    },
  };

  return (
    <div className="container2">
      <header className="jumbotron" style={{ padding: "1.0rem" }}>
        <h3 className="text-center">
          <b>GreenTree</b> - Στατιστικά
        </h3>
        <h6 className="text-center">(Επισκέπτες - Εγγεγραμμένοι χρήστες)</h6>
      </header>
      <header className="jumbotron" style={{ padding: "1.0rem" }}>
        <h5 className="text-justify">
          <img src="/img/small-logo-green-tree.png" width="120px" alt="Logo" />
          Είδη Δένδρων, ... ανά Δημοτικό Διαμέρισμα
        </h5>
      </header>
      {/* ------------------------------------------------------------ */}
      <Link to="/statist" className="btn btn-secondary pull-right">
        <GiFruitTree
          color="#AEFDCE"
          style={{ top: "-2px", position: "relative" }}
        />{" "}
        Επιστροφή στα Στατιστικά{" "}
      </Link>
      <div>&nbsp;</div>
      {/*-------------------Graph------------------------------*/}
      <div className="row">
        <div className="col-12">
          {/*----------------------------------------------------*/}
          {graphData && (
            <VchartSta
              ref={chartRef}
              titlos={titlos}
              data={graphData}
              options={options}
              height="2500"
            />
          )}
          {/*----------------------------------------------------*/}
          {/* Modal for the popup */}
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            contentLabel="Additional Info"
            ariaHideApp={false}
            style={customModalStyles}
          >
            <h2>{selectedLabel}</h2>
            <p>Additional information about {selectedLabel} goes here.</p>
            <button onClick={() => setModalIsOpen(false)}>Close</button>
          </Modal>
        </div>
      </div>
      {/* ---------------------------------------------------- */}
      <div>&nbsp;</div>
      {/* ---------------------------------------------------- */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <b>κωδ.</b>
              </StyledTableCell>
              <StyledTableCell align="right">
                <b>Κοινή Ονομασία</b>
              </StyledTableCell>
              <StyledTableCell>
                <b>Βοτανική Ονομασία</b>
              </StyledTableCell>
              <StyledTableCell align="right">
                <b>1ο Δ.Δ.</b>
              </StyledTableCell>
              <StyledTableCell align="right">
                <b>2ο Δ.Δ.</b>
              </StyledTableCell>
              <StyledTableCell align="right">
                <b>3ο Δ.Δ.</b>
              </StyledTableCell>
              <StyledTableCell align="right">
                <b>4ο Δ.Δ.</b>
              </StyledTableCell>
              <StyledTableCell align="right">
                <b>5ο Δ.Δ.</b>
              </StyledTableCell>
              <StyledTableCell align="right">
                <b>Σύν.Δ.Δ.</b>
              </StyledTableCell>
              <StyledTableCell align="right">
                <b>
                  Σύν.Εμβαδό
                  <br />
                  Επιφ.(m²)
                </b>
              </StyledTableCell>
              <StyledTableCell align="right">
                <b>
                  Σύν.Όγκου
                  <br />
                  Κόμης(m³)
                </b>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {myData.length > 0 && (
            <TableBody>
              {myData.map((row) => (
                <StyledTableRow key={row.eidos_id}>
                  <StyledTableCell component="th" scope="row">
                    {row.eidos_id}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.common_name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.botanic_name}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {parseInt(row.sdd1).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {parseInt(row.sdd2).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {parseInt(row.sdd3).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {parseInt(row.sdd4).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {parseInt(row.sdd5).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <b>{parseInt(row.ssum_d).toLocaleString("el-GR")}</b>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <b>{parseInt(row.semb).toLocaleString("el-GR")}</b>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <b>{parseInt(row.sogkos).toLocaleString("el-GR")}</b>
                  </StyledTableCell>
                </StyledTableRow>
              ))}

              <StyledTableRow
                key={"SYNOLA"}
                sx={{ backgroundColor: "#c2c2c2" }}
              >
                <StyledTableCell align="left">
                  <b>{""}</b>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <b>{"Γενικό Σύνολο (Δένδρων)"}</b>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <b>{""}</b>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <b>{parseInt(ssdd1).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <b>{parseInt(ssdd2).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <b>{parseInt(ssdd3).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <b>{parseInt(ssdd4).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <b>{parseInt(ssdd5).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                {/* <StyledTableCell align="right">
                  <b>{parseInt(sssum_d).toLocaleString("el-GR")}</b>
                </StyledTableCell> */}
                <StyledTableCell align="right">
                  <b>
                    {parseInt(
                      ssdd1 + ssdd2 + ssdd3 + ssdd4 + ssdd5
                    ).toLocaleString("el-GR", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    })}
                  </b>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <b>{parseInt(ssemb).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <b>{parseInt(ssogk).toLocaleString("el-GR")}</b>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {/* --------------------[ waiting ]--------------------------- */}
      {!myData?.length && (
        <h6 className="text-center text-info">
          <b>Υπολογισμός . . .</b>{" "}
          <img src="/img/wait-1.gif" height={200} alt="Wait" />
        </h6>
      )}
      {/* ---------------------------------------------------------- */}
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <Link to="/statist" className="btn btn-secondary pull-right">
        <GiFruitTree
          color="#AEFDCE"
          style={{ top: "-2px", position: "relative" }}
        />{" "}
        Επιστροφή στα Στατιστικά{" "}
      </Link>
      {/* -------------------------------------------- */}
      <div>&nbsp;</div>
      <div>&nbsp;</div>
    </div>
  );
}
