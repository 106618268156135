import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import AuthService from "../services/auth.service";
import axios from "axios";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import { LayersControl } from "react-leaflet/LayersControl";
import { Pane } from "react-leaflet/Pane";
import BingTileLayer from "../components/BingTileLayer.component";
import { Circle } from "react-leaflet/Circle";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { LatLngBounds } from "leaflet";
import CoordinatesControl from "../components/CoordinatesControl.component";
import Button from "react-bootstrap/Button";
//import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { GiFruitTree } from "react-icons/gi";

L.Marker.prototype.options.icon = L.icon({
  //iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  iconUrl: "http://leafletjs.com/examples/custom-icons/leaf-green.png",
  shadowUrl: "http://leafletjs.com/examples/custom-icons/leaf-shadow.png",
  //iconSize: [30, 95],
  iconAnchor: [20, 94],
  shadowSize: [50, 64],
  shadowAnchor: [4, 62],
  popupAnchor: [0, -76],
  iconSize: [26, 46],
  iconAnchor: [13, 46],
});

const position = [40.629901, 22.953772];

const customMarker = new L.icon({
  iconUrl: "/images/icons/location-pointer.svg",
  iconSize: [30, 46],
  iconAnchor: [17, 46],
});

/******************************** */
/******************************** */
export default function MapSel2() {
  const location = useLocation();
  const selDendra = location.state;

  console.log("location.state", location.state);
  console.log("fData===", selDendra);

  const { did } = useParams();
  const { eid } = useParams();
  const { d_d } = useParams();

  //----------- Check Authentication ------------
  const currentUser = AuthService.getCurrentUser();
  const [state, setState] = useState({
    redirect: null,
    userReady: false,
    currentUser: { username: "" },
    roles: {},
  });
  //----------------------------------------------
  const bounds = new LatLngBounds();
  //const [formData, setFormData] = useState({});
  const [mode, setMode] = useState("");

  useEffect(() => {
    //-------------- Auth -------------------
    if (!currentUser) setState({ redirect: "/home" });
    if (!currentUser)
      setState({
        currentUser: currentUser,
        userReady: true,
        roles: currentUser?.roles,
      });
    //--------------------------------------------
    if (
      currentUser?.roles?.includes("ROLE_MODERATOR") ||
      currentUser?.roles?.includes("ROLE_ADMIN")
    )
      setMode("2");
    //--------------------------------------------
    // const fData = { odos_id: did, eidos_id: eid, dim_diam: d_d };
    // axios
    //     .post(`${process.env.REACT_APP_URL}/api/search/sh_anaz2`, fData)
    //     .then((response) => {
    //         const aaaa = response.data;
    //         setFormData(response.data);
    //     })
    //     .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (mapRef.current && selDendra > 0) {
      const Map = mapRef.current;
      selDendra.forEach((marker) => {
        bounds.extend([marker.y, marker.x]);
      });
      Map.fitBounds(bounds);
    }
  }, [selDendra]);

  const mapRef = useRef();

  useEffect(() => {
    if (mapRef.current && bounds.isValid()) {
      mapRef.current.fitBounds(bounds);
    }
  }, [mapRef, bounds]);

  const handleMapCreated = (map) => {
    // Evaluate the 'map' variable here
    // Perform any necessary evaluations or modifications
    if (selDendra?.length > 0) {
      selDendra.forEach((marker) => {
        bounds.extend([marker.y, marker.x]);
      });
      map.fitBounds(bounds);
      //console.log(formData, bounds, map);
    }
  };

  const openform = (id) => {
    console.log("=====================", id);
  };

  let navigate = useNavigate();

  return (
    <>
      <div>
        <h4>
          Επιλέχτηκαν : <b>{selDendra.length}</b> Δένδρα
        </h4>
      </div>
      <MapContainer
        ref={mapRef}
        center={position}
        zoom={15}
        maxZoom={22}
        style={{ height: "70vh" }}
      >
        <CustomMap handleMapCreated={handleMapCreated} />
        {selDendra.length > 0 &&
          selDendra.map(({ y, x }, id) => (
            <>
              <Marker position={[y, x]} key={id}>
                <Popup>
                  <hr style={{ marginTop: "0.2rem", marginBottom: "0.4rem" }} />
                  <span style={{ color: "gray" }}>.o.id.. :</span>
                  <b>{selDendra[id].old_id}</b>{" "}
                  <hr style={{ marginTop: "0.2rem", marginBottom: "0.4rem" }} />
                  <span style={{ color: "gray" }}>Οδός :</span>{" "}
                  <b>{selDendra[id].onoma}</b>
                  <hr style={{ marginTop: "0.2rem", marginBottom: "0.4rem" }} />
                  <span style={{ color: "gray" }}>Είδος :</span>{" "}
                  <b>{selDendra[id].common_name}</b>
                  <hr style={{ marginTop: "0.2rem", marginBottom: "0.8rem" }} />
                  <Link
                    to={`/getdendro${mode}/${selDendra[id].id}`}
                    className="btn btn-secondary btn-sm pull-right"
                  >
                    <GiFruitTree
                      color="#AEFDCE"
                      style={{ top: "-2px", position: "relative" }}
                    />{" "}
                    <span style={{ color: "#F9F9F9" }}>... περισσότερα</span>
                  </Link>
                </Popup>
              </Marker>
              <Circle
                key={id + 100000}
                center={[y, x]}
                pathOptions={{ fillColor: "blue", border: "red" }}
                radius={2.5}
              />
              <Circle
                key={id + 200000}
                center={[y, x]}
                pathOptions={{ fillColor: "blue", border: "red" }}
                radius={0.01}
              />
            </>
          ))}

        {/*-------------------------*/}
        <CoordinatesControl />
        {/*----------------*/}
      </MapContainer>
      <div className="text-center" style={{ marginTop: 10 }}>
        <Button className="btn btn-secondary" onClick={() => navigate(-1)}>
          <GiFruitTree
            color="#AEFDCE"
            style={{ top: "-2px", position: "relative" }}
          />{" "}
          Επιστροφή
        </Button>
      </div>
    </>
  );
}

const CustomMap = ({ handleMapCreated }) => {
  const map = useMap();
  const popupElRef = useRef(null);
  const mapRef = useRef(null);

  const maxZoom = 22;
  const BING_MAPS_KEY =
    "AjG59CvIm2ydzA7QDKMjSc-VtqC4epnaeu-FRYDoKnCWQKcPtScu04zhNrYegYAo";
  //-------------------------------------------------------------------

  useEffect(() => {
    handleMapCreated(map); // Call the function with the 'map' instance
  }, [map, handleMapCreated]);

  return (
    <>
      <LayersControl position="topright">
        <Pane>
          {/* -------------------------[ Open Street Map ]--- */}
          <LayersControl.BaseLayer name="Open Street Map">
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              maxZoom={maxZoom}
              maxNativeZoom={19}
              minZoom={0}
            />
          </LayersControl.BaseLayer>
          {/* -------------------------[ Bing Map Road ]---- */}
          <LayersControl.BaseLayer name="Bing Map Road" checked>
            <BingTileLayer
              bingMapsKey={BING_MAPS_KEY}
              imagerySet="Road"
              culture="el"
              //minZoom="1"
              maxZoom="21"
            />
          </LayersControl.BaseLayer>
          {/* ---------------------[ ESRI Topo Map ]-------- */}
          <LayersControl.BaseLayer name="ESRI Topo Map">
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
              maxZoom={maxZoom}
              maxNativeZoom={19}
            ></TileLayer>
          </LayersControl.BaseLayer>
          {/* -------------------------[ ESRI Street Map ]------ */}
          <LayersControl.BaseLayer name="ESRI Street Map">
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
              maxZoom={maxZoom}
              maxNativeZoom={19}
            ></TileLayer>
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer></LayersControl.BaseLayer>
          {/* -------------------------[ Bing Satellite Map ]--- */}
          <LayersControl.BaseLayer name="Bing Satellite Map">
            <BingTileLayer
              bingMapsKey={BING_MAPS_KEY}
              imagerySet="Aerial"
              culture="el"
              //minZoom="1"
              maxZoom="21"
            />
          </LayersControl.BaseLayer>
          {/* -------------------------[ ESRI Satellite Map ]--- */}
          <LayersControl.BaseLayer name="ESRI Satellite Map">
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              maxZoom={maxZoom}
              maxNativeZoom={19}
            ></TileLayer>
          </LayersControl.BaseLayer>
          {/* -------------------------[ Κενό ]------------ */}
          <LayersControl.BaseLayer name="Κενό">
            <TileLayer
              attribution=""
              url=""
              maxNativeZoom={19}
              minZoom={0}
              maxZoom={maxZoom}
            />
          </LayersControl.BaseLayer>
          {/* ----------------------------------------- */}
        </Pane>
      </LayersControl>
      {/* <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        maxZoom={22}
        maxNativeZoom={19}
        minZoom={0}
      /> */}
      {/* <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        maxZoom={24}
        maxNativeZoom={19}
        minZoom={0}
      /> */}
    </>
  );
};
