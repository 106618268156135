import {
  Button,
  Dialog,
  DialogContent,
  Fade,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { forwardRef } from "react";

const Transition = forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

function ConfirmBox({ open, closeDialog, title, text, deleteFunction }) {
  return (
    <Dialog style={{ whiteSpace: "pre-line" }}
      fullWidth
      open={open}
      maxWidth="sm"
      scroll="body"
      onClose={closeDialog}
      onBackdropClick={closeDialog}
      TransitionComponent={Transition}
      //overlayStyle={{ backgroundColor: "transparent" }}
      PaperProps={{
        style: {
          //backgroundColor: "lightgreay",
          //boxShadow: "none",
          backfaceVisibility: "initial",
          backdropFilter: "revert",
        },
      }}
    >
      <DialogContent sx={{ px: 8, py: 6, position: "relative" }}>
        <Typography component={'div'} variant={'body2'}>
          <IconButton
            size="medium"
            onClick={closeDialog}
            sx={{ position: "absolute", right: "1rem", top: "1rem" }}
            style={{ backgroundColor: "lightgray" }}
          >
            X
          </IconButton>
          {/* ----------------------------------- */}
          {/* <button
          className="btn btn-danger btn-sm"
          onClick={closeDialog}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginLeft: "auto",
          }}
        >
          X
        </button> */}
          {/* ----------------------------------- */}
          {/* <Button
          variant="contained"
          style={{ float: "right", size: "sm" }}
          color="secondary"
          className="float-right"
          onClick={closeDialog}
        >
          X
        </Button> */}

          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Box
                sx={{
                  mb: 3,
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                }}
              >
                <Typography variant="h5">
                  <b>{title}</b>
                </Typography>

                <Typography component={'div'} variant="body1">
                  <div><hr /></div>
                  <br />
                  {/* Είστε σίγουροι για την Διαγραφή του δένδρου με id :{" "}<br /> */}
                  {text}

                  {/* <b>{title}</b> ? */}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "flex-end", gap: "1rem" }}
            >
              <Button
                onClick={closeDialog}
                size="medium"
                variant="contained"
                color="primary"
              >
                Ακύρωση
              </Button>
              <Button
                onClick={deleteFunction}
                size="medium"
                variant="contained"
                color="error"
              >
                Διαγραφή
              </Button>{" "}
            </Grid>
          </Grid>
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

export default ConfirmBox;
