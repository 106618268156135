import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import AuthService from "../services/auth.service";
import axios from "axios";

//import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";

import VtextBox from "../components/VtextBox.component";
import VcomboBox from "../components/VcomboBox.component";
import VcheckBox from "../components/VcheckBox.component";
import VdatePicker from "../components/VdatePicker.component";
import VimgBox from "../components/VimgBox.component";
import GroupBox from "../components/GroupBox.component";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import { FaMapMarkedAlt } from "react-icons/fa";
import MapHalf from "../components/mapHalf.component";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

import { GiFruitTree } from "react-icons/gi";
import QRCode from "qrcode.react";

export default function GetDendro() {
  const { id } = useParams();

  //----------- Check Authentication ------------
  const currentUser = AuthService.getCurrentUser();
  const [state, setState] = useState({
    redirect: null,
    userReady: false,
    currentUser: { username: "" },
    roles: {},
  });
  //----------------------------------------------

  const [formData, setFormData] = useState({});
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);

  const [kat_xorou, setKat_xorou] = useState([]);
  const [drastir, setDrastir] = useState([]);
  const [kat_drastir, setKat_drastir] = useState([]);
  const [odoi, setOdoi] = useState([]);
  const [eidos, setEidos] = useState([]);
  const [sxKomis, setSxKomis] = useState([]);
  const [apograf, setApograf] = useState([]);
  const [dim_diam, setDim_diam] = useState([]);

  const [opt_kat_xorou, setOpt_kat_xorou] = useState([]);
  const [opt_drastir, setOpt_drastir] = useState([]);
  const [opt_kat_drastir, setOpt_kat_drastir] = useState([]);
  const [opt_odoi, setOpt_odoi] = useState([]);
  const [opt_eidos, setOpt_eidos] = useState([]);
  const [opt_sxKomis, setOpt_sxKomis] = useState([]);
  const [opt_apograf, setOpt_apograf] = useState([]);
  const [opt_dim_diam, setOpt_dim_diam] = useState([]);

  const [ektEtosFyt, setEktEtosFyt] = useState(null);
  const [selectedZNDOption, setSelectedZNDOption] = useState("");

  const handleOptionZNDChange = (event) => {
    //setSelectedZNDOption(event.target.value);
  };

  useEffect(() => {
    //-------------- Auth -------------------
    if (!currentUser) setState({ redirect: "/home" });
    if (!currentUser)
      setState({
        currentUser: currentUser,
        userReady: true,
        roles: currentUser?.roles,
      });

    //--------------------
    window.scrollTo(0, 0);
    //--------------------

    //------------------dendro/:id-------------------
    axios
      .get(`${process.env.REACT_APP_URL}/api/dendra/${id}`)
      .then((response) => {
        const formData = response.data[0];
        setFormData(formData);
        setLat(formData.apol_thesi_lat);
        setLng(formData.apol_thesi_lon);
      })
      .catch((error) => console.error(error));
    //---------------------kat_xorou-----------------------
    axios
      .get(`${process.env.REACT_APP_URL}/api/zlookup/z_kat_xorou`)
      .then((response) => {
        setOpt_kat_xorou(response.data);
      })
      .catch((error) => console.error(error));
    //---------------------drastir-----------------------
    axios
      .get(`${process.env.REACT_APP_URL}/api/zlookup/z_drastir`)
      .then((response) => {
        setOpt_drastir(response.data);
      })
      .catch((error) => console.error(error));
    //---------------------kat_drastir-----------------------
    axios
      .get(`${process.env.REACT_APP_URL}/api/zlookup/z_kat_drastir`)
      .then((response) => {
        setOpt_kat_drastir(response.data);
      })
      .catch((error) => console.error(error));
    //----------------------------odos_id-------------------------
    axios
      .get(`${process.env.REACT_APP_URL}/api/zlookup/z_odoi`)
      .then((response) => {
        setOpt_odoi(response.data);
      })
      .catch((error) => console.error(error));
    //----------------------------z_eidos-------------------------
    axios
      .get(`${process.env.REACT_APP_URL}/api/zlookup/z_eidos`)
      .then((response) => {
        setOpt_eidos(response.data);
        //console.log("---------------------------------", response.data);
      })
      .catch((error) => console.error(error));
    //----------------------------z_sx_komis-------------------------
    axios
      .get(`${process.env.REACT_APP_URL}/api/zlookup/z_sx_komis`)
      .then((response) => {
        setOpt_sxKomis(response.data);
      })
      .catch((error) => console.error(error));
    //----------------------------z_apograf-------------------------
    axios
      .get(`${process.env.REACT_APP_URL}/api/zlookup/z_apograf`)
      .then((response) => {
        setOpt_apograf(response.data);
      })
      .catch((error) => console.error(error));

    //----------------------------z_dim_diam-------------------------
    axios
      .get(`${process.env.REACT_APP_URL}/api/zlookup/z_dim_diam`)
      .then((response) => {
        setOpt_dim_diam(response.data);
      })
      .catch((error) => console.error(error));
    //------------------ekt_etos_fyteysis/:id-------------------
    axios
      .get(`${process.env.REACT_APP_URL}/api/search/etosfyt/${id}`)
      .then((response) => {
        const vData = response.data[0];
        setEktEtosFyt(vData.ekt_etos_fyteysis);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (formData) {
      if (formData.zontano === 1) setSelectedZNDOption("1");
      else if (formData.nekro === 1) setSelectedZNDOption("2");
      else if (formData.den_yparxei === 1) setSelectedZNDOption("3");
      else setSelectedZNDOption("0");
    }
  }, [formData]);

  //-------------- For ComboBox ----------------------
  const find_value = (combo_id, mValue) => {
    let selectedOption = null;
    switch (combo_id) {
      case "kat_xorou":
        selectedOption = opt_kat_xorou.find((opt) => opt.id === mValue);
        return selectedOption ? selectedOption.label : "";
      case "drastir_id":
        selectedOption = opt_drastir.find((opt) => opt.id === mValue);
        return selectedOption ? selectedOption.label : "";
      case "kat_drastir_id":
        selectedOption = opt_kat_drastir.find((opt) => opt.id === mValue);
        return selectedOption ? selectedOption.label : "";
      case "odoi":
        selectedOption = opt_odoi.find((opt) => opt.id === mValue);
        return selectedOption ? selectedOption.label : "";
      case "eidos":
        selectedOption = opt_eidos.find((opt) => opt.id === mValue);
        return selectedOption ? selectedOption.label : "";
      case "sxKomis":
        selectedOption = opt_sxKomis.find((opt) => opt.id === mValue);
        return selectedOption ? selectedOption.label : "";
      case "apograf":
        selectedOption = opt_apograf.find((opt) => opt.id === mValue);
        return selectedOption ? selectedOption.label : "";
      case "dim_diam":
        selectedOption = opt_dim_diam.find(
          (opt) => opt.id === parseInt(mValue)
        );
        return selectedOption ? selectedOption.label : "";

      default:
        return "";
    }
  };

  function conv_img(_old_path) {
    if (_old_path) {
      let out_path = "AA" + _old_path;
      var parts = _old_path.split("/IMG_");
      var final = process.env.REACT_APP_FOTO_URL + "IMG_" + parts[1];
      return final;
    }
    return null;
  }

  const handleTextBox = (TextBox_id, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      //[TextBox_id]: newValue,
    }));
  };

  const handleComboBox = (combo_id, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      //[combo_id]: value ? value.id : 0,
    }));
  };

  const handleCheckBoxOnChange = (event) => {
    const { name, value, checked } = event.target;
    setFormData({
      ...formData,
      //[name]: checked === true ? 1 : 0
    });
  };

  const handleDateChange = (name, value) => {
    const isoDate = value.toISOString();
    setFormData((prevFormData) => ({
      ...prevFormData,
      //[name]: isoDate
    }));
  };

  function get_clearFileName(fullName) {
    if (fullName.startsWith("IMG_")) {
      return fullName;
    } else if (fullName.startsWith("gT_")) {
      return fullName;
    } else if (fullName.includes("/IMG_")) {
      var parts = fullName.split("/IMG_");
      return "IMG_" + parts[1];
    } else if (fullName.includes("/gT_")) {
      var parts = fullName.split("/gT_");
      return "gT_" + parts[1];
    }
  }

  //--------- check images [A]-------------
  useEffect(() => {
    if (
      formData.foto_dendrou_path &&
      !formData.foto_dendrou_path.startsWith(process.env.REACT_APP_URL) &&
      !formData.foto_dendrou_path.startsWith(process.env.REACT_APP_FOTO_URL)
    ) {
      let init_path = formData.foto_dendrou_path;
      let filename = get_clearFileName(init_path);
      let url_local = process.env.REACT_APP_URL + "/api/img11/" + filename;
      let url_out = process.env.REACT_APP_FOTO_URL + filename;
      let isLocal = false;
      try {
        const src = require("../../../treePhotos/" + filename);
        isLocal = true;
      } catch (err) {
        isLocal = false;
      }
      let finalUrl = isLocal ? url_local : url_out;
      setFormData((prevFormData) => ({
        ...prevFormData,
        foto_dendrou_path: finalUrl,
      }));
      //--------------------------------------
    }
  }, [formData.foto_dendrou_path]);

  //--------- check images [B]-------------
  useEffect(() => {
    if (
      formData.foto_dendrodox_path &&
      !formData.foto_dendrodox_path.startsWith(process.env.REACT_APP_URL) &&
      !formData.foto_dendrodox_path.startsWith(process.env.REACT_APP_FOTO_URL)
    ) {
      let init_path = formData.foto_dendrodox_path;
      let filename = get_clearFileName(init_path);
      let url_local = process.env.REACT_APP_URL + "/api/img11/" + filename;
      let url_out = process.env.REACT_APP_FOTO_URL + filename;
      let isLocal = false;
      try {
        const src = require("../../../treePhotos/" + filename);
        isLocal = true;
      } catch (err) {
        isLocal = false;
      }
      let finalUrl = isLocal ? url_local : url_out;
      setFormData((prevFormData) => ({
        ...prevFormData,
        foto_dendrodox_path: finalUrl,
      }));
      //--------------------------------------
    }
  }, [formData.foto_dendrodox_path]);

  /************************************************ */
  function handleSubmit(event) {
    event.preventDefault();
  }

  const MyAccordionsumA = styled(AccordionSummary)({
    backgroundColor: "#79a6d2",
    minHeight: "20px",
    maxHeight: "40px",
  });

  const MyAccordionsumB = styled(AccordionSummary)({
    backgroundColor: "#9fbfdf",
    minHeight: "20px",
    maxHeight: "40px",
  });

  let navigate = useNavigate();

  //=================================URL για QR===============
  const currentPageURL = window.location.href;
  //====================================================

  return (
    <>
      <style>{"body { background-color: #e0ebeb; }"}</style>
      <form style={{ backgroundColor: "#e0ebeb" }}>
        <Box>
          {/* ----------- Map ------------------ */}
          <Accordion defaultExpanded="true">
            <MyAccordionsumA
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ margin: "0px" }}>
                <b>Χάρτης</b>
              </Typography>
            </MyAccordionsumA>
            <AccordionDetails>
              {/* ---------- component MapHalf ---------- */}
              {lat && lng && <MapHalf lat={lat} lng={lng} />}
              {/* --------------------------------------- */}
            </AccordionDetails>
          </Accordion>
          <hr />
          {/* ---------Ταυτότητα - Θέση-------- */}
          <Accordion defaultExpanded="true">
            <MyAccordionsumA
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ margin: "0px" }}>
                <b>Ταυτότητα - Θέση</b>
              </Typography>
            </MyAccordionsumA>
            <AccordionDetails
              style={
                {
                  //display: "flex",
                  //flexDirection: "row",
                  //padding: "20px 20px 20px 20px",
                }
              }
            >
              <div className="row" style={{ padding: "10px" }}>
                <VdatePicker
                  name="date_apogr"
                  label="Ημερομ. Απογραφής"
                  value={formData.date_apogr}
                  onChange={handleDateChange}
                />
              </div>
              <div className="row" style={{ padding: "10px" }}>
                <VtextBox
                  id="id"
                  name="id"
                  label="ID Δένδρου:"
                  textAlign="center"
                  value={formData.id}
                  width={120}
                  onChange={handleTextBox}
                />
                &nbsp;&nbsp;
                <VtextBox
                  id="kodikos"
                  name="kodikos"
                  label="Κωδικός μητρώου:"
                  value={formData.kodikos}
                  width={600}
                  onChange={handleTextBox}
                />
              </div>
              <div className="row" style={{ padding: "10px" }}>
                <GroupBox title={""} backgroundColor="#d9e6f2">
                  <VcomboBox
                    id="eidos"
                    options={opt_eidos}
                    value={find_value("eidos", formData.eidos_id)}
                    label="Είδος Δέντρου"
                    //getOptionSelected={getOptionSelected}
                    onChange={handleComboBox}
                    width={480}
                  />
                </GroupBox>
              </div>
              {/* ----------------------------------------------- */}
              <div className="row" style={{ padding: "10px" }}>
                <VcomboBox
                  id="odoi"
                  options={opt_odoi}
                  value={find_value("odoi", formData.odos_id)}
                  label="Οδός"
                  onChange={handleComboBox}
                  width="300px"
                />
                &nbsp;&nbsp;
                <VcomboBox
                  id="dim_diam"
                  options={opt_dim_diam}
                  value={find_value("dim_diam", formData.dim_diam)}
                  label="Δημοτικό Διαμέρισμα"
                  onChange={handleComboBox}
                  width="300px"
                />
                &nbsp;&nbsp;
                <VtextBox
                  id="oik_tetr"
                  name="oik_tetr"
                  label="Οικοδομικό Τετράγωνο"
                  value={formData.oik_tetr}
                  width={150}
                  onChange={handleTextBox}
                />
                &nbsp;&nbsp;
                <VtextBox
                  id="ar"
                  name="ar"
                  label="Αριθμός"
                  value={formData.ar}
                  width={90}
                  onChange={handleTextBox}
                />
                <div style={{ fontSize: "10px" }}>&nbsp;</div>
                <VtextBox
                  id="tk"
                  name="tk"
                  label="Τ.Κ."
                  value={formData.tk}
                  width={150}
                  onChange={handleTextBox}
                />
              </div>
              {/* ----------------------------------------------- */}
              <div className="row" style={{ padding: "10px" }}>
                <VcomboBox
                  id="kat_xorou"
                  options={opt_kat_xorou}
                  value={find_value("kat_xorou", formData.kat_xorou)}
                  label="Κατηγορία Χώρου"
                  onChange={handleComboBox}
                  width="200px"
                />
                &nbsp;&nbsp;
                <VtextBox
                  id="kat_xor_allo"
                  name="kat_xor_allo"
                  label="Άλλο:"
                  value={formData.kat_xor_allo}
                  //width="300px"
                  onChange={handleTextBox}
                  sx={{ width: "300px" }}
                />
                <div>&nbsp;</div>
              </div>
              <div style={{ padding: "2px" }}>&nbsp;</div>
              <GroupBox title="Συντεταγμένες :">
                {/* {console.log(formData)} */}
                {formData.apol_thesi_x && formData.apol_thesi_lat && (
                  <>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (ΕΓΣΑ87)&nbsp;&nbsp;&nbsp; X
                    = {formData.apol_thesi_x.toFixed(2)} ,&nbsp; Y ={" "}
                    {formData.apol_thesi_y.toFixed(2)}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; φ ={" "}
                    {formData.apol_thesi_lat.toFixed(6)} ,&nbsp; λ ={" "}
                    {formData.apol_thesi_lon.toFixed(6)}
                    &nbsp;&nbsp;&nbsp;(wgs84)
                  </>
                )}
              </GroupBox>
              <div style={{ padding: "2px" }}>&nbsp;</div>
              <GroupBox title={"Υιοθεσία :"}>
                <VcheckBox
                  id="iothetimeno"
                  name="iothetimeno"
                  checked={formData.iothetimeno === 1}
                  label="Υιοθετημένο"
                  onChange={handleCheckBoxOnChange}
                  disabled={!currentUser}
                  sx={{ backgroundColor: "red" }}
                />
                <VtextBox
                  id="etos_iothesias"
                  name="etos_iothesias"
                  label="Έτος Υιοθεσίας"
                  textAlign="right"
                  value={formData.etos_iothesias}
                  width={150}
                  onChange={handleTextBox}
                  disabled={!currentUser}
                  style={{ margin: "10px" }}
                />
              </GroupBox>
            </AccordionDetails>
          </Accordion>
          <hr />
          {/* ------------ Γενικά Στοιχεία Δέντρου ---------- */}
          <Accordion defaultExpanded="true">
            <MyAccordionsumB
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ margin: "0px" }}>
                <b>Γενικά Στοιχεία Δέντρου</b>
              </Typography>
            </MyAccordionsumB>
            <AccordionDetails>
              <div>&nbsp;</div>
              <div>
                <FormControl
                  component="fieldset"
                  sx={{
                    border: "1px solid #bfbfbf",
                    padding: "1px 10px 10px 10px",
                  }}
                >
                  <FormLabel component="legend">
                    &nbsp;Κατάσταση στην οποία βρίσκεται :
                  </FormLabel>
                  <RadioGroup
                    aria-label="Options"
                    name="z_n_d"
                    value={selectedZNDOption}
                    onChange={handleOptionZNDChange}
                    row // Add the 'row' attribute for horizontal alignment
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Ζωντανό"
                    />
                    &nbsp;&nbsp;&nbsp;
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="Νεκρό"
                    />
                    &nbsp;&nbsp;&nbsp;
                    <FormControlLabel
                      value="3"
                      control={<Radio />}
                      label="Δεν υπάρχει"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>&nbsp;</div>
              {/* ------------------------------------------------ */}
              <div>
                <VcomboBox
                  id="sxKomis"
                  options={opt_sxKomis}
                  value={find_value("sxKomis", formData.sxima_komis_id)}
                  label="Σχήμα Κόμης"
                  //getOptionSelected={getOptionSelected}
                  onChange={handleComboBox}
                  width="400px"
                />
              </div>
              <div>
                <VcheckBox
                  id="fylloptosi"
                  name="fylloptosi"
                  checked={formData.fylloptosi === 1}
                  label="Φυλλόπτωση"
                  onChange={handleCheckBoxOnChange}
                  className="col-3"
                />
              </div>
              <div>&nbsp;</div>
              <div>
                <VtextBox
                  id="etos_fyteysis"
                  name="etos_fyteysis"
                  label="Έτος φύτευσης"
                  textAlign="right"
                  value={formData.etos_fyteysis}
                  width={150}
                  onChange={handleTextBox}
                  disabled={!currentUser}
                />
                &nbsp;&nbsp;&nbsp;
                <VtextBox
                  id="ektEtosFyt"
                  name="ektEtosFyt"
                  label="Εκτίμηση Έτους Φύτευσης"
                  textAlign="right"
                  value={ektEtosFyt}
                  width={190}
                  onChange={handleTextBox}
                  disabled={!currentUser}
                />
              </div>
              <div>&nbsp;</div>
            </AccordionDetails>
          </Accordion>
          <hr />
          {/* -----------------Φωτογραφική Τεκμηρίωση -----------------------*/}
          <Accordion defaultExpanded="true">
            <MyAccordionsumA
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>
                <b>Φωτογραφική Τεκμηρίωση</b>
              </Typography>
            </MyAccordionsumA>
            <AccordionDetails>
              <Typography>
                <VtextBox
                  id="foto_dendrou_path"
                  name="foto_dendrou_path"
                  label="Φωτογραφία Δέντρου Κωδικός"
                  value={formData.foto_dendrou_path}
                  width={800}
                  onChange={handleTextBox}
                  disabled={!currentUser}
                />
                &nbsp;&nbsp;
                <VimgBox
                  thumbnailUrl={formData.foto_dendrou_path}
                  fullSizeUrl={formData.foto_dendrou_path}
                  alt="Δένδρο"
                />
                <div>
                  <br />
                </div>
                <VtextBox
                  id="foto_dendrodox_path"
                  name="foto_dendrodox_path"
                  label="Φωτογραφία Δενδροδόχου Κωδικός"
                  value={formData.foto_dendrodox_path}
                  width={800}
                  onChange={handleTextBox}
                  disabled={!currentUser}
                />
                &nbsp;&nbsp;
                <VimgBox
                  thumbnailUrl={formData.foto_dendrodox_path}
                  fullSizeUrl={formData.foto_dendrodox_path}
                  alt="Δενδροδόχος"
                />
              </Typography>
              {/* --------------------- QR Code --------------------------- */}
              <hr />
              <div className="row">
                <div className="col-5 text-center">
                  <h6>
                    Σαρώστε το παρακάτω QR Code για ν' ανοίξετε την τρέχουσα
                    σελίδα (δένδρου) από άλλη συσκευή ...
                    <br />
                    &nbsp;
                  </h6>
                  <QRCode value={currentPageURL} />
                  <div>&nbsp;</div>
                </div>
              </div>
              {/* --------------------- QR Code (end)----------------------- */}
            </AccordionDetails>
          </Accordion>
          <hr />
          <div>&nbsp;</div>
          {/* ------------ Επιστροφή ---------------- */}
          <Button className="btn btn-secondary" onClick={() => navigate(-1)}>
            <GiFruitTree
              color="#AEFDCE"
              style={{ top: "-2px", position: "relative" }}
            />{" "}
            Επιστροφή
          </Button>
          {/* &nbsp; &nbsp; &nbsp; &nbsp;
          <Button
            type="submit"
            disabled={!currentUser}
            className="btn btn-primary"
          >
            {currentUser
              ? `Αποθήκευση from : ${currentUser.username}`
              : "Χωρίς Αποθήκευση"}
          </Button> */}
          &nbsp; &nbsp; &nbsp; &nbsp;
          {/* ------------ Χάρτης: Μετάβαση στο σημείο ---------------- */}
          {formData.apol_thesi_lat && (
            <>
              <Link to={`/Vmap/${formData.id}`} className="btn btn-secondary">
                <FaMapMarkedAlt
                  color="#AEFDCE"
                  style={{ top: "-2px", position: "relative" }}
                />{" "}
                Χάρτης
                {` (id=${formData.id})`}
              </Link>{" "}
            </>
          )}
          {/* ------------ Φόρμα Αναφοράς ---------------- */}
          {/* &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          {formData.apol_thesi_lat && (
            <Link to={`/guestform/${formData.id}`} className="btn btn-warning">
              <FaMapMarkedAlt
                color="#AEFDCE"
                style={{ top: "-2px", position: "relative" }}
              />{" "}
              Φόρμα Αναφοράς
            </Link>
          )}
          <div>&nbsp;</div> */}
        </Box>
      </form>
    </>
    //------- ROLE_USER
    //------- ROLE_MODERATOR
    //------- ROLE_ADMIN
  );
}

// onChange={(event, newValue) => {
//  setInit_Kat_Xorou(newValue);
//  setKat_Xorou(newValue.id);
//  }}

//export default GetDendro;

// function aaa() {
//   axios
//     .get(`${process.env.REACT_APP_URL}/api/zlookup/z_kat_xorou/`)
//     .then((response) => {
//       const arr1 = response.data;
//       console.log(arr1);
//       return arr1;
//     });

//   const top100Films = [
//     { label: "The Shawshank Redemption", year: 1994 },
//     { label: "The Godfather", year: 1972 },
//     { label: "The Godfather: Part II", year: 1974 },
//     { label: "The Dark Knight", year: 2008 },
//     { label: "12 Angry Men", year: 1957 },
//   ];
//   //return top100Films;
// }
