import React from "react";
import { useState } from "react";
import TextareaAutosize from "@mui/base/TextareaAutosize";

//----------VtextArea---------------------
const VtextArea = React.memo((props) => {
  const { id, name, value, width, onChange, ...rest } = props;

  //const [value, setValue] = useState("");

  // const handleChange = (event) => {
  //   setValue(event.target.value);
  // };
  const handleChange = (event) => {
    const newValue = event.target.value;
    if (onChange) {
      onChange(id || name, newValue);
    }
  };

  const textareaStyles = {
    width: [width],
    resize: "both",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "8px",
  };

  return (
    <TextareaAutosize
      value={value}
      onChange={handleChange}
      aria-label="textarea"
      minRows={3} // Customize the minimum number of rows
      style={{
        width: [width],
        resize: "both",
        border: "1px solid #ccc",
        borderRadius: "5px",
        padding: "8px",
      }}
      //style={textareaStyles}
      sx={{
        "&:hover": {
          borderColor: "#888",
        },
        "&:focus": {
          borderColor: "#4c8bf5",
          outline: "none",
        },
      }}
    />
  );
});

export default VtextArea;
