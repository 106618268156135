import React from 'react';
import { useNavigate } from 'react-router-dom';
import BoardUser from './board-user.component';

function BoardUserContainer() {
    const navigate = useNavigate();

    return (
        <BoardUser navigate={navigate} />
    );
}

export default BoardUserContainer;
