import React, { Component } from "react";
import {
  //Routes,
  //Route,
  Link,
} from "react-router-dom";
import Button from "react-bootstrap/Button";
import UserService from "../services/user.service";
import GetOne from "../pages/getdendro";
import { FaMapMarkedAlt } from "react-icons/fa";
import { FaChartPie } from "react-icons/fa";

import Box, { BoxProps } from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
//import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";

//----------------------
const searcId = "38166";
//----------------------

const openMapPage = () => {
  console.log("sssss");
  GetOne();
  console.log("zzzzz");
};

export default class Views extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: "",
    };
  }

  componentDidMount() {
    UserService.getPublicContent().then(
      (response) => {
        this.setState({
          content: response.data,
        });
      },
      (error) => {
        this.setState({
          content:
            (error.response && error.response.data) ||
            error.message ||
            error.toString(),
        });
      }
    );
  }

  render() {
    return (
      <div className="container2">
        <header className="jumbotron" style={{ padding: "1.0rem" }}>
          {/* <h3>{this.state.content}</h3> */}
          <h3 className="text-center">
            <img
              src="/img/logo-greentree.png"
              height="55px"
              alt="greenTree"
            />&nbsp;
            <b>GreenTree</b> - Προβολές
          </h3>
          <h6 className="text-center">
            ( Επισκέπτες , Εγγεγραμμένοι χρήστες )
          </h6>
        </header>

        <hr style={{ margin: 20 }} />
        {/* ************************************************************ */}
        {/* <Box sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)" }}> */}
        <Grid container spacing={2}>
          {/* -------------------------(1)--------------------------- */}
          <Grid item xs={12} sm={4}>
            <Card
              sx={{ maxHeight: 350, maxWidth: 300, backgroundColor: "#e1eaea" }}
            >
              <div>&nbsp;</div>
              <CardMedia
                //sx={{ height: 99, width: 147, margin: "auto" }}
                sx={{ height: 100, width: 100, margin: "auto" }}
                image="/img/small_map.png"
                title="green Tree"
                style={{ justifyContent: "center", display: "flex" }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  <b>Χάρτης Δένδρων</b>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Περιλαμβάνει την προβολή των Δένδρων (σημεία) πάνω σε υπόβαθρα
                  ανοικτής Διάθεσης <b>...BaseMaps</b> διανυσματικά αλλά και
                  δορυφορικά.
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "center", p: 1.5 }}>
                <Link
                  to="/Vmap"
                  className="btn btn-primary float-right pull-right"
                >
                  <FaMapMarkedAlt
                    color="#AEFDCE"
                    style={{ top: "-2px", position: "relative" }}
                  />{" "}
                  Χάρτης{" "}
                </Link>
              </CardActions>
            </Card>
          </Grid>
          {/* -------------------------(2)--------------------------- */}
          <Grid item xs={12} sm={4}>
            <Card
              sx={{ maxHeight: 350, maxWidth: 300, backgroundColor: "#e1eaea" }}
            >
              <div>&nbsp;</div>
              <CardMedia
                //sx={{ height: 99, width: 147, margin: "auto" }}
                sx={{ height: 100, width: 100, margin: "auto" }}
                image="/img/small_search.png"
                title="green Tree"
                style={{ justifyContent: "center", display: "flex" }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  <b>Αναζήτηση</b>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Μπορείτε να κάνετε Αναζήτηση - Εντοπισμό Δένδρων, με την Οδό,
                  το Είδος και το Δημοτικό Διαμέρισμα.
                  <br />
                  &nbsp;
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "center", p: 1.5 }}>
                <Link
                  to="/search"
                  className="btn btn-info float-right pull-right"
                >
                  <FaMapMarkedAlt
                    color="#AEFDCE"
                    style={{ top: "-2px", position: "relative" }}
                  />{" "}
                  Αναζήτηση{" "}
                </Link>
              </CardActions>
            </Card>
          </Grid>

          {/* -------------------------(3)--------------------------- */}
          <Grid item xs={12} sm={4}>
            <Card
              sx={{ maxHeight: 350, maxWidth: 300, backgroundColor: "#e1eaea" }}
            >
              <div>&nbsp;</div>
              <CardMedia
                //sx={{ height: 99, width: 147, margin: "auto" }}
                sx={{ height: 100, width: 100, margin: "auto" }}
                image="/img/small_table.png"
                title="green Tree"
                style={{ justifyContent: "center", display: "flex" }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  <b>Στατιστικά</b>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Περιλαμβάνει την προβολή Συγκεντωτικών και Στατιστικών
                  στοιχείων από το σύνολο των Δένδρων.
                  <br />
                  &nbsp;
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "center", p: 1.5 }}>
                <Link
                  to="/statist"
                  className="btn btn-danger float-right pull-right"
                >
                  <FaMapMarkedAlt
                    color="#AEFDCE"
                    style={{ top: "-2px", position: "relative" }}
                  />{" "}
                  Στατιστικά{" "}
                </Link>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
        {/* ************************************************************ */}
        <hr></hr>
        <div>&nbsp;</div>
        <div
          style={{
            backgroundColor: "#a5ff79",
            backgroundImage: 'url("img/bar1c.png")',
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "top",
            padding: "10px",
          }}
        >
          <h6 className="text-center">
            <Link to="/home" className="btn btn-secondary">
              <FaMapMarkedAlt
                color="#AEFDCE"
                style={{ top: "-2px", position: "relative" }}
              />{" "}
              Επιστροφή στην Αρχική {" "}
            </Link>
          </h6>
        </div>
        {/* <div>&nbsp;</div>
        <div>&nbsp;</div>
        <Link to={`/getdendro/${searcId}`} className="btn btn-success">
          <FaMapMarkedAlt
            color="#AEFDCE"
            style={{ top: "-2px", position: "relative" }}
          />{" "}
          Form
          {` (id=${searcId})`}
        </Link>{" "}
        <Link to="/map3" className="btn btn-primary">
          <FaMapMarkedAlt
            color="#AEFDCE"
            style={{ top: "-2px", position: "relative" }}
          />{" "}
          Χάρτης3{" "}
        </Link>{" "} */}

        <div>&nbsp;</div>
      </div>
    );
  }
}
