import React, { useState, useEffect } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { el } from "date-fns/locale";

const VdatePicker = (props) => {
  const { id, name, format, label, value, onChange, ...rest } = props;

  const dateObj = new Date(value);
  const [selectedDate, setSelectedDate] = useState(new Date(value));

  useEffect(() => {
    setSelectedDate(dateObj);
  });

  const handleChange = (date) => {
    console.log("date =", date);
    if (date) {
      setSelectedDate(date);
      onChange(name, date);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={el}>
      <DatePicker
        disableToolbar
        name={name}
        label={label}
        format="dd/MM/yyyy"
        value={selectedDate}
        onChange={handleChange}
        disabled={props.disabled}
        renderInput={(params) => <TextField {...params} />}
        slotProps={{ textField: { size: "small" } }}
        size="small"
        //InputLabelProps={{ shrink: value ? true : false }}
        sx={{
          width: 200,
          //height: 40,
          "& label": {
            top: "-0.50rem",
          },
          "& legend": {
            textAlign: "left",
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default VdatePicker;
