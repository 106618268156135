import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import AuthService from "../services/auth.service";
import axios from "axios";
import VcomboBox from "../components/VcomboBox.component";
import VtextBox from "../components/VtextBox.component";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { TextField } from "@mui/material";

import { GiFruitTree } from "react-icons/gi";
import { FaMapMarkedAlt } from "react-icons/fa";

export default function SearchPage() {
  //----------- Check Authentication ------------
  const currentUser = AuthService.getCurrentUser();
  const [state, setState] = useState({
    redirect: null,
    userReady: false,
    currentUser: { username: "" },
    roles: {},
  });
  //const [roleOk, setRoleOk] = useState(false);
  const [roleOk, setRoleOk] = useState(true);
  //----------------------------------------------
  const [formData, setFormData] = useState({
    odos_id: 0,
    eidos_id: 0,
    dim_diam: 0,
  });
  //------------- ComboBox ---------------------------------
  const [opt_eidos, setOpt_eidos] = useState([]);
  const [opt_odos, setOpt_odos] = useState([]);
  const [opt_dim_diam, setOpt_dim_diam] = useState([]);
  const [allCnt, setAllCnt] = useState(0);
  const [dataCnt, setDataCnt] = useState(0);
  const [reset, setReset] = useState(true);
  const [lastCombo, setLastCombo] = useState("");
  const [searchId, setSearchId] = useState(0);
  const [existId, setExistId] = useState(false);
  const [showWait, setShowWait] = useState(false);

  //------------------[]
  useEffect(() => {
    setShowWait(true);
    axios
      .get(`${process.env.REACT_APP_URL}/api/statist/st_All`)
      .then((response) => {
        setAllCnt(response.data[0].exact_count);
        setShowWait(false);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    //-------------- Auth -------------------
    if (!currentUser) {
      setState({ redirect: "/home" });
      setRoleOk(false);
    } else {
      setState({
        currentUser: currentUser,
        userReady: true,
        roles: currentUser.roles,
      });
      setRoleOk(
        currentUser?.roles.includes("ROLE_MODERATOR") ||
          currentUser?.roles.includes("ROLE_ADMIN")
          ? true
          : false
      );
    }
    //----------------------------------------------------
    if (reset) {
      //----------------------------odos_id-------------------------
      setShowWait(true);
      axios
        .get(`${process.env.REACT_APP_URL}/api/zlookup/z_odoi`)
        .then((response) => {
          setOpt_odos(response.data);
          setShowWait(false);
        })
        .catch((error) => console.error(error));
      //----------------------------z_eidos-------------------------
      setShowWait(true);
      axios
        .get(`${process.env.REACT_APP_URL}/api/zlookup/z_eidos`)
        .then((response) => {
          setOpt_eidos(response.data);
          setShowWait(false);
          //console.log("---------------------------------", response.data);
        })
        .catch((error) => console.error(error));
      //----------------------------z_dim_diam-------------------------
      setShowWait(true);
      axios
        .get(`${process.env.REACT_APP_URL}/api/zlookup/z_dim_diam`)
        .then((response) => {
          setOpt_dim_diam(response.data);
          setShowWait(false);
        })
        .catch((error) => console.error(error));
      //--------------------------------------------------------------
      setReset(false);
    }
  }, [reset, currentUser]);

  //-------------- For ComboBox ----------------------
  const find_value = (combo_id, mValue) => {
    if (mValue) {
      let selectedOption = null;
      switch (combo_id) {
        case "odos_id":
          selectedOption = opt_odos.find((opt) => opt.id === mValue);
          return selectedOption ? selectedOption.label : "";
        case "eidos_id":
          selectedOption = opt_eidos.find((opt) => opt.id === mValue);
          return selectedOption ? selectedOption.label : "";
        case "dim_diam":
          selectedOption = opt_dim_diam.find(
            (opt) => opt.id === parseInt(mValue)
          );
          return selectedOption ? selectedOption.label : "";
        default:
          return "";
      }
    } else {
      return "";
    }
  };

  const handleComboBox = (combo_id, value) => {
    if (value) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [combo_id]: value ? value.id : 0,
      }));
      setLastCombo(combo_id);
    }
  };

  useEffect(() => {

    //-------------------------- recalc_eidos
    const recalc_eidos = () => {
      //------------------------
      axios
        .post(
          `${process.env.REACT_APP_URL}/api/zlookup/get_eidos_filter`,
          formData
        )
        .then((response) => {
          setOpt_eidos(response.data);
        })
        .catch((error) => console.error(error));
    };
    //-------------------------- recalc_odos
    const recalc_odos = () => {
      //-----------------------
      axios
        .post(
          `${process.env.REACT_APP_URL}/api/zlookup/get_odos_filter`,
          formData
        )
        .then((response) => {
          setOpt_odos(response.data);
        })
        .catch((error) => console.error(error));
    };
    //----------------------------- recalc_dim_diam
    const recalc_dim_diam = () => {
      //---------------------------
      axios
        .post(
          `${process.env.REACT_APP_URL}/api/zlookup/get_dim_diam_filter`,
          formData
        )
        .then((response) => {
          setOpt_dim_diam(response.data);
        })
        .catch((error) => console.error(error));
    };
    //================================================
    if (lastCombo === "odos_id") {
      if (formData.eidos_id === 0) recalc_eidos();
      if (formData.dim_diam === 0) recalc_dim_diam();
    } else if (lastCombo === "eidos_id") {
      if (formData.odos_id === 0) recalc_odos();
      if (formData.dim_diam === 0) recalc_dim_diam();
    } else if (lastCombo === "dim_diam") {
      if (formData.odos_id === 0) recalc_odos();
      if (formData.eidos_id === 0) recalc_eidos();
    }

  }, [formData, lastCombo]);


  //------------------- calculate number of Recs----------------------
  useEffect(() => {
    const calcRecs = () => {
      //const oid = formData.odos_id ? formData.odos_id : 0;
      //const eid = formData.eidos_id ? formData.eidos_id : 0;
      //const d_d = formData.dim_diam ? formData.dim_diam : 0;
      //----------------------------------------------------
      const fData = {
        odos_id: formData.odos_id,
        eidos_id: formData.eidos_id,
        dim_diam: formData.dim_diam
      };
      //----------------------------------------------------
      if (formData.odos_id > 0 || formData.eidos_id > 0 || formData.dim_diam > 0) {
        setShowWait(true);
        axios
          .post(`${process.env.REACT_APP_URL}/api/search/sh_anaz_cnt`, fData)
          .then((response) => {
            //const aaaa = response.data;
            setDataCnt(response.data.length);
            setShowWait(false);
          })
          .catch((error) => console.error(error));
      }
    };
    //---------
    calcRecs();
    //---------
  }, [formData]);

  const handleTextBox = (e) => {
    //console.log("e.target====", e.target);
    //if (e.target.value) {
    let newValue = parseInt(e.target.value);
    console.log("newValue===", newValue);
    //setSearchId(newValue);
    if (newValue) {
      setSearchId(newValue);
    } else {
      setSearchId(0);
    }
    //}
  };

  useEffect(() => {
    //------------------dendro/:id-------------------
    if (searchId) {
      axios
        .get(`${process.env.REACT_APP_URL}/api/dendra/${searchId}`)
        .then((response) => {
          const formData = response.data[0];
          if (formData) setExistId(true);
          else setExistId(false);
        })
        .catch((error) => console.error(error));
    }
  }, [searchId]);

  const handleMap0 = () => {
    setReset(true);
    setFormData({ odos_id: 0, eidos_id: 0, dim_diam: 0 });
    setDataCnt(0);
    //calcRecs();
  };

  let navigate = useNavigate();
  let selForm = currentUser && roleOk ? "getdendro2" : "getdendro";

  //console.log("selForm", selForm);

  const handleMap1 = () => {
    const oid = formData.odos_id ? formData.odos_id : 0;
    const eid = formData.eidos_id ? formData.eidos_id : 0;
    const d_d = formData.dim_diam ? formData.dim_diam : 0;
    let path = `/mapsel/${oid}/${eid}/${d_d}`;
    navigate(path);
  };

  return (
    <div className="container2">
      <header className="jumbotron" style={{ padding: "1.0rem" }}>
        {/* <h3>{this.state.content}</h3> */}
        <h3 className="text-center">
          <img src="/img/logo-greentree.png" height="55px" alt="greenTree" />
          &nbsp;
          <b>GreenTree</b> - Αναζήτηση Δένδρων
        </h3>
        <h6 className="text-center">( Επισκέπτες , Εγγεγραμμένοι χρήστες )</h6>
      </header>
      {/* --------------------------------------------------- */}
      <header className="jumbotron" style={{ padding: "1.0rem" }}>
        <div className="p-2 text-center bg-light">
          <h5 className="text-center">
            Κριτήρια αναζήτησης Δένδρων στον Χάρτη ...
          </h5>
          <div>Επιλέξτε ...</div>
        </div>
        {/* ............................................. */}
        <div className="p-2 text-center bg-light">
          &nbsp; &nbsp;
          {/* ................odos_id..................... */}
          {formData && (
            <>
              <VcomboBox
                id="odos_id"
                options={opt_odos}
                value={find_value("odos_id", formData.odos_id)}
                label="Οδός"
                onChange={handleComboBox}
                //disabled={!currentUser}
                width="300px"
              />
              <div style={{ fontSize: "10px" }}>&nbsp;</div>
              {/* ----------------eidos_id------------- */}
              <VcomboBox
                id="eidos_id"
                options={opt_eidos}
                value={find_value("eidos_id", formData.eidos_id)}
                label="Είδος Δέντρου"
                onChange={handleComboBox}
                width={480}
              //disabled={!currentUser}
              />
              <div style={{ fontSize: "10px" }}>&nbsp;</div>
              {/* ---------------dim_diam-------------- */}
              <VcomboBox
                id="dim_diam"
                options={opt_dim_diam}
                value={find_value("dim_diam", formData.dim_diam)}
                label="Δημοτικό Διαμέρισμα"
                onChange={handleComboBox}
                width={300}
              //disabled={!currentUser}
              />
              <div>
                Αριθμός Επιλεγμένων Δένδρων :{" "}
                <span style={{ fontSize: "20px" }}>
                  {" "}
                  <b>{dataCnt}</b>
                </span>{" "}
                από συνολικά{" "}
                <span style={{ fontSize: "20px" }}>
                  {" "}
                  <b>{allCnt}</b>
                </span>
              </div>
            </>
          )}
          <div>&nbsp;</div>
          <div className="row">
            {/* {currentUser && ( */}
            <div className="col-4">
              <Button onClick={handleMap0} className="btn btn-secondary">
                <GiFruitTree
                  color="#AEFDCE"
                  style={{ top: "-2px", position: "relative" }}
                />{" "}
                μηδενισμός
              </Button>
            </div>
            {/* )} */}
            {/* {currentUser && ( */}
            <div className="col-8">
              {showWait && allCnt > 0 && (
                <>
                  <img
                    src="/img/wait-4.gif"
                    height={35}
                    alt="Wait"
                    style={{ marginTop: -5 }}
                  />
                  &nbsp;
                  <span
                    style={{
                      padding: 4,
                      color: "white",
                      backgroundColor: "#79a6d2",
                    }}
                  >
                    &nbsp;... <b>Έ λ ε γ χ ο ς</b> ... Περιμένετε &nbsp;
                  </span>
                </>
              )}
              {!showWait && allCnt > 0 && (
                <Button
                  onClick={handleMap1}
                  className="btn btn-success pull-right"
                  disabled={dataCnt === 0}
                >
                  <GiFruitTree
                    color="#AEFDCE"
                    style={{ top: "-2px", position: "relative" }}
                  />{" "}
                  Εμφάνιση στον χάρτη ...
                </Button>
              )}
            </div>
            {/* )} */}
          </div>
          &nbsp; &nbsp;
        </div>
      </header>
      <header className="jumbotron" style={{ padding: "1.0rem" }}>
        <div className="p-2 text-center bg-light">
          <h5 className="text-center">Εντοπισμός με ID ... Καρτέλα</h5>
        </div>
        <div className="p-2 text-left bg-light">
          {/* {searchId && ( */}
          <TextField
            id="searchId"
            name="searchId"
            label="ID αναζήτησης"
            value={searchId}
            size="small"
            onChange={handleTextBox}
            sx={{
              width: [150],
              "& label": {
                top: "-0.50rem",
              },
              "& legend": {
                textAlign: "left",
              },
              "& .Mui-disabled": {
                color: "lightgray", // Customize the disabled color here
              },
              "& .MuiInputBase-input": {
                color: "black",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "green", // Customize the disabled color here
              },
            }}
          //disabled={!currentUser}
          ></TextField>
          {/* )} */}
          &nbsp;&nbsp;&nbsp;&nbsp;
          {existId && (
            <Link to={`/${selForm}/${searchId}`} className="btn btn-secondary">
              <FaMapMarkedAlt
                color="#AEFDCE"
                style={{ top: "-2px", position: "relative" }}
              />{" "}
              Καρτέλα
              {` (id=${searchId})`}
            </Link>
          )}
          {/* {existId && currentUser && !roleOk(
            <Link to={`/getdendro/${searchId}`} className="btn btn-info">
              <FaMapMarkedAlt
                color="#AEFDCE"
                style={{ top: "-2px", position: "relative" }}
              />{" "}
              Form
              {` (id=${searchId})`}
            </Link>
          )} */}
        </div>
      </header>
      <div className="text-center" style={{ marginTop: -20 }}>
        <Link to="/home" className="btn btn-secondary">
          <GiFruitTree
            color="#AEFDCE"
            style={{ top: "-2px", position: "relative" }}
          />{" "}
          Επιστροφή στην Αρχική{" "}
        </Link>
        &nbsp;&nbsp;&nbsp;
        <Link to="/views" className="btn btn-secondary">
          <GiFruitTree
            color="#AEFDCE"
            style={{ top: "-2px", position: "relative" }}
          />{" "}
          Επιστροφή στις Προβολές{" "}
        </Link>
      </div>
    </div>
  );
}
