import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BoardModerator from "./board-moderator.component";
import { Link, useParams } from "react-router-dom";
import AuthService from "../services/auth.service";
import Home from "../components/home.component";

function BoardModeratorContainer() {
  let roleOk = null;
  const navigate = useNavigate();
  //-------------Athentication---------------------
  const [state, setState] = useState({
    redirect: null,
    userReady: false,
    currentUser: { username: "" },
    roles: {},
  });
  const currentUser = AuthService.getCurrentUser();
  //-----------------------------------------------
  useEffect(() => {
    let roleOk =
      currentUser?.roles.includes("ROLE_MODERATOR") ||
      currentUser?.roles.includes("ROLE_ADMIN");
    if (!roleOk) {
      navigate("/");
    }
  }, [currentUser]);

  return <BoardModerator navigate={navigate} />;
}

export default BoardModeratorContainer;
