import React, { Component, useEffect } from "react";
import axios from "axios";
import AuthService from "../services/auth.service";

import { DataGrid } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
//import { withRouter } from 'react-router-dom';

import UserService from "../services/user.service";
//import { styled } from '@mui/system';
import "./dataGrid.css";

import { AiTwotoneContainer } from "react-icons/ai";
import { FaMapMarkedAlt } from "react-icons/fa";
import { GiFruitTree } from "react-icons/gi";

import { Form, Button, Row, Col } from "react-bootstrap";

const BoldHeader = ({ value }) => (
  <div style={{ fontWeight: "bold" }}>{value}</div>
);

const style0 = { color: "black", fontSize: "0.9em" };

/*######################################*/
export default class BoardModerator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: "",
      data: [],
    };
    //let navigate = useNavigate();
    const currentUser = AuthService.getCurrentUser();
    console.log("currentUser===", currentUser);
    if (!currentUser) {
      this.goToHome();
    }
  }
  componentDidMount() {
    UserService.getModeratorBoard().then(
      (response) => {
        this.setState({
          content: response.data,
        });
      },
      (error) => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });
      }
    );
  }

  goBack = () => {
    console.log("======goBack====");
    this.props.navigate(-1);
  };
  goHome = () => {
    console.log("======goHome====");
    this.props.navigate("/home");
  };
  goToHome = () => {
    this.props.navigate("/home");
  };

  render() {
    return (
      <div className="container">
        <header className="jumbotron" style={{ padding: "1.0rem" }}>
          <h4>{this.state.content}</h4>
        </header>
        <header className="jumbotron" style={{ padding: "1.0rem" }}>
          <h5 className="text-center">Διαχειριστικά Μέτρα</h5>
          <hr style={{ margin: 0 }} />
          <div style={{ margin: "10px 0 10px 20%" }}>
            <img
              src="/img/logo-greentree.png"
              height="25px"
              alt="greenTree"
            ></img>
            <Link to="/diaxmetr_grid" className="btn btn-info">
              Κατάλογος <b>όλων</b> των Διαχειριστικών Μέτρων
            </Link>
          </div>
          <div style={{ margin: "10px 0 10px 20%" }}>
            {/* <div className="col-6" style={{ margin: "25px 0 10px 20%" }}> */}
            <img
              src="/img/logo-greentree.png"
              height="25px"
              alt="greenTree"
            ></img>
            <Link to="/diaxmetr_grid/0" className="btn btn-info">
              Μόνο τα <b>εκκρεμή</b>
            </Link>
            &nbsp;&nbsp;&nbsp;
            <Link to="/diaxmetr_grid/1" className="btn btn-info">
              Μόνο τα <b>ολοκληρωμένα</b>
            </Link>
            <img
              src="/img/logo-greentree.png"
              height="25px"
              alt="greenTree"
            ></img>
            {/* </div> */}
          </div>
          <hr style={{ margin: 0 }} />
          <div className="text-center">
            <h5 className="text-center, text-success">
              Εισαγωγή Νέου Διαχειριστικού Μέτρου
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link to="/Vmap" className="btn btn-primary">
                <FaMapMarkedAlt
                  color="#AEFDCE"
                  style={{ top: "-2px", position: "relative" }}
                />{" "}
                Χάρτης{" "}
              </Link>
            </h5>
            <i>
              Η εισαγωγή Νέων Διαχειριστικών Μέτρων ξεκινά από τον <b>Χάρτη</b>{" "}
              με την ενεργοποίηση του εργαλείου
            </i>{" "}
            [<AiTwotoneContainer style={style0} />] από την εργαλειομπάρα και
            στη συνέχεια με την επιλογή ενός ή περισσοτέρων δένδρων.
          </div>
          <hr style={{ margin: 10 }} />
          <h5 className="text-center">Αναζήτηση Δένδρων</h5>
          <hr style={{ margin: 10 }} />
          <div style={{ margin: "15px 0 10px 20%" }}>
            <img
              src="/img/logo-greentree.png"
              height="25px"
              alt="greenTree"
            ></img>
            <Link to="/search2" className="btn btn-secondary">
              Αναζήτηση Δένδρων
            </Link>
          </div>
          <hr style={{ margin: 20 }} />
          <h5 className="text-center">Διαχείριση Καταλόγων (πίνακες)</h5>
          <hr style={{ margin: 10 }} />
          <div style={{ margin: "10px 0 10px 20%" }}>
            <img
              src="/img/logo-greentree.png"
              height="25px"
              alt="greenTree"
            ></img>
            <Link to="/table_eidos" className="btn btn-warning">
              Κατάλογος "<b>Ειδών</b>" δένδρων
            </Link>
          </div>
          <div style={{ margin: "10px 0 10px 20%" }}>
            <img
              src="/img/logo-greentree.png"
              height="25px"
              alt="greenTree"
            ></img>
            <Link to="/table_ergasies" className="btn btn-warning">
              Κατάλογος "<b>Εργασιών</b>" Διαχειριστικών Μέτρων
            </Link>
          </div>
          <div style={{ margin: "10px 0 10px 20%" }}>
            <img
              src="/img/logo-greentree.png"
              height="25px"
              alt="greenTree"
            ></img>
            <Link to="/table_apograf" className="btn btn-warning">
              Κατάλογος "<b>Απογραφέων</b>"
            </Link>
          </div>
          <hr style={{ margin: 10 }} />
        </header>
        {/* --------------------------------------------------------- */}
        <div className="text-center" style={{ marginTop: -20 }}>
          <Button className="btn btn-secondary" onClick={this.goToHome}>
            <GiFruitTree
              color="#AEFDCE"
              style={{
                top: "4px",
                position: "relative",
              }}
            />{" "}
            &nbsp;Επιστροφή στην Αρχική&nbsp;
          </Button>
        </div>
        <div>&nbsp;</div>
        {/* --------------------------------------------------------- */}
      </div>
    );
  }
}
