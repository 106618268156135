import React, { Component } from "react";
import axios from "axios";
//import { DataGrid } from "@mui/x-data-grid";
//import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import UserService from "../services/user.service";
import { Button } from "react-bootstrap";
//import { Form, Row, Col } from "react-bootstrap";
import { GiFruitTree } from "react-icons/gi";
import "./dataGrid.css";

// const BoldHeader = ({ value }) => (
//   <div style={{ fontWeight: "bold" }}>{value}</div>
// );

export default class BoardUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: "",
      data: [],
    };
  }

  componentDidMount() {
    UserService.getUserBoard().then(
      (response) => {
        this.setState({
          content: response.data,
        });
      },
      (error) => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });
      }
    );
    //---------------------------------------------------
    axios
      .get(`${process.env.REACT_APP_URL}/api/diaxmetr`)
      .then((response) => {
        this.setState({ data: response.data });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    //---------------------------------------------------
  }

  render() {
    //const { data } = this.state;
    // const columns = [
    //   {
    //     field: "id",
    //     headerName: "id",
    //     width: 80,
    //     headerClassName: "header-cell",
    //     renderCell: (params) => (
    //       <a href={`/diaxeir/${params.value}`}>{params.value}</a>
    //     ),
    //   },
    //   {
    //     field: "onoma",
    //     headerName: "Ονομασία",
    //     width: 200,
    //     headerClassName: "header-cell",
    //   },
    //   {
    //     field: "parat_prin",
    //     headerName: "Σχόλια (Πριν)",
    //     width: 200,
    //     headerClassName: "header-cell",
    //     editable: true,
    //   },
    //   {
    //     field: "isok",
    //     headerName: "Ολοκλ",
    //     width: 60,
    //     headerClassName: "header-cell",
    //   },
    //   {
    //     field: "parat_meta",
    //     headerName: "Σχόλια (Μετά)",
    //     width: 200,
    //     headerClassName: "header-cell",
    //   },
    //   {
    //     field: "dateok",
    //     headerName: "Ημερ.Εκτέλ",
    //     width: 110,
    //     headerClassName: "header-cell",
    //   },
    //   {
    //     field: "created_at",
    //     headerName: "Δημιουργήθηκε",
    //     width: 110,
    //     headerClassName: "header-cell",
    //   },
    //   {
    //     field: "updated_at",
    //     headerName: "Ενημερώθηκε",
    //     width: 110,
    //     headerClassName: "header-cell",
    //   },
    // ];
    return (
      <>
        <div className="container">
          <header className="jumbotron" style={{ padding: "1.0rem" }}>
            <h4>{this.state.content}</h4>
          </header>
          <header className="jumbotron" style={{ padding: "1.0rem" }}>
            <h5 className="text-center">Διαχειριστικά Μέτρα</h5>
            <hr style={{ margin: 0 }} />
            <div style={{ margin: "10px 0 10px 20%" }}>
              <img
                src="/img/logo-greentree.png"
                height="25px"
                alt="greenTree"
              ></img>
              <Link to="/diaxmetr_grid" className="btn btn-info">
                Κατάλογος <b>όλων</b> των Διαχειριστικών Μέτρων
              </Link>
            </div>
            <div style={{ margin: "25px 0 10px 20%" }}>
              {/* <div className="col-6" style={{ margin: "25px 0 10px 20%" }}> */}
              <img
                src="/img/logo-greentree.png"
                height="25px"
                alt="greenTree"
              ></img>
              <Link to="/diaxmetr_grid/0" className="btn btn-info">
                Μόνο τα <b>εκκρεμή</b>
              </Link>
              &nbsp;&nbsp;&nbsp;
              <Link to="/diaxmetr_grid/1" className="btn btn-info">
                Μόνο τα <b>ολοκληρωμένα</b>
              </Link>
              <img
                src="/img/logo-greentree.png"
                height="25px"
                alt="greenTree"
              ></img>
              {/* </div> */}
            </div>
            <hr style={{ margin: 0 }} />
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <h5 className="text-center">Αναζήτηση Δένδρων</h5>
            <hr style={{ margin: 10 }} />
            <div style={{ margin: "15px 0 10px 20%" }}>
              <img
                src="/img/logo-greentree.png"
                height="25px"
                alt="greenTree"
              ></img>
              <Link to="/search2" className="btn btn-secondary">
                Αναζήτηση Δένδρων
              </Link>
            </div>
            <hr style={{ margin: 10 }} />
          </header>
          {/* --------------------------------------------------------- */}
          <div className="text-center" style={{ marginTop: -20 }}>
            <Button className="btn btn-secondary" onClick={this.goToHome}>
              <GiFruitTree
                color="#AEFDCE"
                style={{
                  top: "4px",
                  position: "relative",
                }}
              />{" "}
              &nbsp;Επιστροφή στην Αρχική&nbsp;
            </Button>
          </div>
          <div>&nbsp;</div>
          {/* --------------------------------------------------------- */}
        </div>
      </>
    );
  }
}
