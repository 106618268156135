import React, { useState, useEffect } from "react";
import axios from "axios";
import AuthService from "../services/auth.service";
import VimgBox from "./VimgBox.component";
import { Box } from "@mui/material";
import { Image, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FaMapMarkedAlt } from "react-icons/fa";
import { GiFruitTree } from "react-icons/gi";
import Button from "react-bootstrap/Button";
import VcheckBox from "./VcheckBox.component";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { FaInfo } from "react-icons/fa";
import { AiTwotoneContainer } from "react-icons/ai";

//##################################################
const Sidebar = ({ data, handleEidosIdUpdate, stack }) => {
  //################################################

  //----------- Check Authentication ------------
  const user = AuthService.getCurrentUser();
  const isEditor =
    user?.roles.includes("ROLE_MODERATOR") || user?.roles.includes("ROLE_ADMIN")
      ? true
      : false;
  //----------------------------------------------

  const [myData1, setMyData1] = useState({}); //---(Γενικά) ολα τα δένδρα
  const [myData2, setMyData2] = useState({}); //---(Γενικά) μόνο τα ζωντανα
  //---------------------------------------
  const [sumAll, setSumAll] = useState(0);
  const [sumEidh, setSumEidh] = useState(0);
  const [sumCount, setSumCount] = useState(0);
  const [sumCO2, setSumCo2] = useState(0);
  const [sumValu, setSumValu] = useState(0);
  //----------------------------------------
  const [sumEidos, setSumEidos] = useState(0);

  const [lastDiax, setLastDiax] = useState(0); // Τελευταίο επιλεγμένο
  const [dmAll, setDmAll] = useState({}); //όλα τα Διαχειριστικά μέτρα

  const style0 = { color: "black", fontSize: "0.9em" };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/statist/st_All`)
      .then((response) => {
        setMyData1(response.data);
      })
      .catch((error) => console.error(error));
    //-------------------------------------------
    axios
      .get(`${process.env.REACT_APP_URL}/api/statist/st_All2`)
      .then((response) => {
        setMyData2(response.data);
      })
      .catch((error) => console.error(error));
    //--------------------------------------------
    axios
      .get(`${process.env.REACT_APP_URL}/api/dia_met/all`)
      .then((response) => {
        setDmAll(response.data);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (myData1.length > 0) {
      //setSumAll(myData1.length);
      setSumAll(myData1[0].exact_count);
      console.log(myData1);
    }
    if (myData2.length > 0) {
      setSumEidh(myData2.length);
      setSumCount(myData2.reduce((sum, obj) => sum + parseInt(obj.count), 0));
      setSumCo2(myData2.reduce((sum, obj) => sum + parseInt(obj.co2), 0));
      setSumValu(myData2.reduce((sum, obj) => sum + parseInt(obj.valu), 0));
    }
  }, [myData1, myData2]);

  useEffect(() => {
    if (data.eidos_id > 0) {
      const fData = { odos_id: 0, eidos_id: data.eidos_id };
      axios
        .post(`${process.env.REACT_APP_URL}/api/search/sh_anaz_cnt`, fData)
        .then((response) => {
          const aaaa = response.data;
          setSumEidos(response.data.length);
        })
        .catch((error) => console.error(error));
    }
  }, [data.eidos_id]);

  useEffect(() => {
    if (data.id === "...") {
      setShowAll(false);
      handleEidosIdUpdate(0);
    }
  }, [data.id]);

  useEffect(() => {
    console.log("data=====", data);
  }, [data]);

  let path_detail = isEditor ? "getdendro2" : "getdendro";
  const [showAll, setShowAll] = useState(false);

  const handleCheckBoxOnChange = (event) => {
    const { name, value, checked } = event.target;
    setShowAll(checked === true ? true : false);
    if (checked === true) {
      const newEidosId = data.eidos_id ? data.eidos_id : 0;
      handleEidosIdUpdate(newEidosId);
    } else {
      const newEidosId = 0;
      handleEidosIdUpdate(0);
    }
  };

  const MyAccordionsumA = styled(AccordionSummary)({
    backgroundColor: "#79a6d2",
    minHeight: "20px",
    maxHeight: "40px",
  });

  const MyAccordionsumB = styled(AccordionSummary)({
    backgroundColor: "#9fbfdf",
    minHeight: "20px",
    maxHeight: "40px",
  });

  const [accExp, setAccExp] = useState(true);
  useEffect(() => {
    data.id === "..." ? setAccExp(true) : setAccExp(false);
  }, [data.id]);

  const handleExpand = () => {
    //setExpanded(!expanded);
    setAccExp(!accExp);
  };

  let navigate = useNavigate();

  const openGuestForm = () => {
    //navigate(`/guestform/${data.id}`);
    console.log(data);
    window.open(`https://imc.thessaloniki.gr/imc/new?catid=35&latitude=
    ${data.apol_thesi_lat}&longitude=${data.apol_thesi_lon}`);
    //https://imc.thessaloniki.gr/imc/new?catid=35&latitude=40.62349035&longitude=22.97
  };

  const getDiaxId = (id) => {
    const foundItem = dmAll.find((item) => item.den_id === id);
    return foundItem ? (
      <>
        <Link
          to={`/diaxeir/${foundItem.diax_id}`}
          className="btn btn-warning btn-sm pull-right"
          style={{
            textAlign: "right",
            position: "relative",
            padding: "0px 10px 0px 10px",
          }}
          target="_blank"
        >
          {foundItem.diax_id}
        </Link>
        -
      </>
    ) : null;
  };

  const getDetail = (id) => {
    return (
      <>
        <Link
          to={`/getdendro2/${id}`}
          className="btn btn-danger btn-sm pull-right"
          style={{
            textAlign: "right",
            position: "relative",
            padding: "0px 5px 0px 5px",
          }}
          target="_blank"
        >
          <b>{id}</b>
        </Link>
        -
      </>
    );
  };

  return (
    <>
      <div className="sidebar" style={{ backgroundColor: "#c9d9d9" }}>
        <Row className="justify-content-md-center">
          <div className="Text-center">
            <img src="/fylla/F-0.png" height="60px" alt="GreenTree" />
          </div>
        </Row>
        <div
          style={{ backgroundColor: "#ffffff", margin: "5px", padding: "5px" }}
        >
          {/* =================[Πάνω μέρος (με επιλεγμένο) ]=============== */}
          {data.old_id && data.old_id !== "..." && data.old_id != "ooo" && (
            <>
              <div>Στοιχεία Δένδρου</div>
              <h6>{data.id + " / " + data.old_id + " / " + data.eidos_id}</h6>
              <hr style={{ margin: "0px" }} />
              <div>Βοτανική ονομασία :</div>
              <h4>{data.botanic_name ? data.botanic_name : "..."}</h4>
              <div>Κοινή ονομασία :</div>
              <h4>{data.common_name ? data.common_name : "..."}</h4>
              <hr style={{ margin: "0px" }} />
              <h5 className="text-center">
                <img
                  src={
                    data.eidos_id === "0"
                      ? "/fylla/F-0.png"
                      : "/fylla/F-" + data.eidos_id + ".png"
                  }
                  height="100px"
                  alt={data.eidos_id}
                />
              </h5>
              <hr style={{ margin: "0px" }} />
              <div>Οδός / Περιοχή :</div>
              {/* <h5>{data.onoma ? data.onoma : "..."}</h5> */}
              <h5>{data.odos ? data.odos : "..."}</h5>
              <hr style={{ margin: "4px" }} />
              <div
                style={{
                  backgroundColor: "#e0e0eb",
                  margin: "3px",
                  padding: "3px",
                }}
              >
                <b>
                  Κατακράτηση CO<sub>2</sub>
                </b>{" "}
                : &nbsp;&nbsp;{" "}
                <span style={{ fontSize: 18, color: "#206040" }}>
                  <b>{data.co2}</b>
                </span>{" "}
                &nbsp; kg <br />
                Υπέργεια .........(80%) : &nbsp;&nbsp;{" "}
                <span style={{ fontSize: 18, color: "green" }}>
                  {(data.co2 * 0.8).toFixed(1)}
                </span>{" "}
                &nbsp; kg <br />
                Υπόγεια ...........(20%) : &nbsp;&nbsp;{" "}
                <span style={{ fontSize: 18, color: "green" }}>
                  {(data.co2 * 0.2).toFixed(1)}
                </span>{" "}
                &nbsp; kg
              </div>
              <div
                style={{
                  backgroundColor: "#fff0b3",
                  margin: "3px",
                  padding: "3px",
                }}
              >
                <b>Νομισματική Αξία</b> :&nbsp;&nbsp;{" "}
                <span style={{ fontSize: 18, color: "red" }}>
                  <b>
                    {Math.round(data.valu).toLocaleString().replace(/,/g, ".")}
                  </b>
                </span>{" "}
                &nbsp; €
              </div>

              <hr style={{ margin: "10px" }} />
              <Row className="justify-content-md-center">
                <Link
                  to={`/${path_detail}/${data.id}`}
                  className="btn btn-secondary"
                >
                  <FaMapMarkedAlt
                    color="#AEFDCE"
                    style={{ top: "-2px", position: "relative" }}
                  />{" "}
                  ... Περισσότερα
                </Link>{" "}
              </Row>

              <hr style={{ margin: "10px" }} />
              <div>
                <span style={{ fontSize: 17, color: "#000077" }}>
                  Υπάρχουν <b>{sumEidos}</b> καταγεγραμμένα δένδρα αυτού του
                  είδους σε όλο τον Δήμο
                </span>
              </div>
              <hr style={{ margin: "8px" }} />
              {/* ----------------------------------- */}
              <VcheckBox
                id="den_yparxei"
                name="den_yparxei"
                checked={showAll}
                label="Εμφάνιση μόνο αυτού του είδους στο Σύνολο του Δήμου"
                onChange={handleCheckBoxOnChange}
              />
              <hr style={{ margin: "10px" }} />
              {/* ------------------------------------------- */}
              <Row className="justify-content-md-center">
                <div className="Text-center">
                  <img src="/img/imc_el.svg" height="70px" alt="GreenTree" />
                </div>
              </Row>
              <Row className="justify-content-md-center">
                <button className="btn btn-info" onClick={openGuestForm}>
                  <FaMapMarkedAlt
                    color="#AEFDCE"
                    style={{ top: "-2px", position: "relative" }}
                  />{" "}
                  ... Αίτημα Αναφοράς
                </button>
              </Row>
              <hr style={{ margin: "10px" }} />
              {/* ------------------------------------------- */}
            </>
          )}
          {/* console.log("data==++++==", data) */}
          {/* ==================[ Πάνω Μέρος (Πολλά Επιλεγμένα) ]============== */}
          {data.length > 0 && (
            <>
              <div>
                {/* <hr /> */}
                <Row className="justify-content-md-center">
                  <Link
                    to="/diaxeir"
                    state={data}
                    className="btn btn-info pull-right"
                    style={{ textAlign: "right", position: "relative" }}
                  >
                    <GiFruitTree
                      color="#AEFDCE"
                      style={{
                        top: "35%",
                        left: "1px",
                        position: "absolute",
                      }}
                    />{" "}
                    Δημιουργία Νέου
                    <br />
                    &nbsp; Διαχειριστικού Μέτρου
                  </Link>
                </Row>
                <hr />
              </div>
              <div>
                <h6>- Επιλεγμένα -</h6>
              </div>
              {console.log("dmAll===", dmAll)}
              {data.map((dendro) => {
                return (
                  <div key={dendro.id}>
                    <div>
                      <span style={{ fontSize: 12 }}>
                        <b>{getDetail(dendro.id)}</b>-{getDiaxId(dendro.id)}{" "}
                        {dendro.common_name}
                      </span>
                    </div>
                  </div>
                );
              })}
              <hr />
              <div
                to="#"
                className="btn btn-danger btn-sm pull-right"
                style={{
                  textAlign: "right",
                  position: "relative",
                  padding: "0px 5px 0px 5px",
                }}
                target="_blank"
              >
                <b>xx</b>
              </div>{" "}
              <span style={{ fontSize: 12 }}>Λεπτομέρειες Δένδρου</span>
              <br />
              <div
                className="btn btn-warning btn-sm pull-right"
                style={{
                  textAlign: "right",
                  position: "relative",
                  padding: "0px 5px 0px 5px",
                }}
                target="_blank"
              >
                <b>xx</b>
              </div>{" "}
              <span style={{ fontSize: 12 }}>
                Διαχειριστικό Μέτρο στο οποίο ανήκει
              </span>
              <br />
              <br />
              <div>
                <div>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span style={{ fontSize: 12 }}>το δένδρο :</span>
                </div>

                <img
                  src="/img/diax_metra_1.png"
                  height="36px"
                  alt="GreenTree"
                />
                <span style={{ fontSize: 12 }}>
                  {" "}
                  Συμμετέχει σε Διαχειριστικό Μέτρο
                </span>
              </div>
              <div>
                <img
                  src="/img/diax_metra_2.png"
                  height="32px"
                  alt="GreenTree"
                />
                <span style={{ fontSize: 12 }}>
                  {" "}
                  Επιλέχθηκε για Νέο Διαχειριστικό Μέτρο
                </span>
              </div>
              {/* <Row className="justify-content-md-center">
                <Link
                  to="/diaxeir/5"
                  className="btn btn-warning btn-sm pull-right"
                  style={{ textAlign: "right", position: "relative" }}
                >
                  <GiFruitTree
                    color="#AEFDCE"
                    style={{
                      top: "35%",
                      left: "1px",
                      position: "absolute",
                    }}
                  />{" "}
                  Προβολή <br />
                  &nbsp; Διαχ. Μέτρου{" "}
                  {data?.length > 0 ? data[data.length - 1].id : "-"}
                </Link>
              </Row> */}
            </>
          )}

          {/* ==================[ Πάνω Μέρος (Χωρίς Επιλεγμένο) ]============== */}
          {data.old_id === "..." && (
            <>
              <div className="text-center">
                {/* <Row className="justify-content-md-center"> */}
                {/* </Row> */}
                {/* <Row className="justify-content-md-center"> */}
                <h4>
                  <b>
                    <u>Δ. Θεσσαλονίκης</u>
                  </b>
                </h4>
                <h5>"τα Δένδρα της πόλης"</h5>
                {/* </Row> */}
                {/* <div className="text-center"> */}
                <p>
                  Αυτός ο Διαδραστικός χάρτης προβάλει την εικόνα των δένδρων
                  μέσα στην πόλη
                </p>
              </div>
              <VimgBox
                thumbnailUrl="https://www.gardeningknowhow.com/wp-content/uploads/2017/07/hardwood-tree.jpg"
                fullSizeUrl="https://www.gardeningknowhow.com/wp-content/uploads/2017/07/hardwood-tree.jpg"
                alt="photo1"
              />
              <br />
              <div>
                <i>Επιλέγοντας το εργαλείο</i> [<FaInfo style={style0} />]
                &nbsp;
                <i>
                  μπορείτε να δείξετε ένα δένδρο και να πάρετε τις διαθέσιμες
                  καταγεγραμμένες πληροφορίες από τη Βάση Δεδομένων.
                </i>
              </div>
              <br />
              {isEditor && (
                <>
                  <div>
                    <i>Με το εργαλείο</i> [<AiTwotoneContainer style={style0} />
                    ]&nbsp;
                    <i>
                      επιλέγετε διαδοχικά, δένδρα και στη συνέχεια μπορείτε να
                      ορίσετε Διαχειριστικά μέτρα για τα επιλεγμένα δένδρα. Με
                      την επιλογή ενός ήδη επιλεγμένου δένδρου, αυτό αφαιρείται
                      από τη λίστα.
                    </i>
                  </div>
                  <br />
                </>
              )}
              <div>
                <i>
                  Όταν έχετε επιλεγμένο ένα δένδρο, μπορείτε μέσα από το{" "}
                  <b> Βελτιώνω την πόλη μου</b> να αναφέρετε προβλήματα,
                  προτάσεις, επισημάνσεις, που αφορούν το συγκεκριμένο δένδρο.
                </i>
              </div>
              <br />
            </>
          )}
        </div>
        <div>
          <hr />
          <Row className="justify-content-md-center">
            <Link to="/home" className="btn btn-secondary pull-right">
              <GiFruitTree
                color="#AEFDCE"
                style={{ top: "-2px", position: "relative" }}
              />{" "}
              Επιστροφή στην Αρχική{" "}
            </Link>
          </Row>
          <hr />
        </div>
        {/* ======================[ Κάτω μέρος (Γενικά) ]====================== */}
        <Accordion expanded={accExp} onChange={handleExpand}>
          <MyAccordionsumA
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={{ margin: "0px" }}>
              <b>Συνολικά στον Δήμο</b>
            </Typography>
          </MyAccordionsumA>
          <AccordionDetails sx={{ backgroundColor: "#c9d9d9" }}>
            <div
              style={{
                backgroundColor: "#f0f4f4",
                margin: "0px",
                padding: "5px",
                height: "200px",
                overflowY: "scroll",
              }}
            >
              <h5>Στατιστικά Στοιχεία</h5>
              <div>
                <span style={{ fontSize: 18 }}>
                  <b>{sumAll}</b>
                </span>
                &nbsp;
                <i>εγγρ.(δένδρων) στη Βάση Δεδομένων</i>
              </div>
              <div>
                για{" "}
                <span style={{ fontSize: 18 }}>
                  <b>{sumEidh}</b>
                </span>
                &nbsp; <i>διαφορετικά είδη δένδρων</i>{" "}
              </div>
              <div>&nbsp;</div>
              <h5>Οικολογικά Οφέλη</h5>
              <div>
                Συνολική Κατακράτηση CO<sub>2</sub> μέχρι τώρα :{" "}
                <span style={{ fontSize: 18 }}>
                  <b>{sumCO2.toLocaleString().replace(/,/g, ".")}</b>
                </span>{" "}
                &nbsp; χλγ. (kg)
              </div>
              <div>&nbsp;</div>
              <h5>Οικονομική Αξία</h5>
              <div>
                Η τρέχουσα αξία αυτή τη στιγμή (για τα καταγεγραμμένα δένδρα)
                εκτιμάται στα{" "}
                <span style={{ fontSize: 18 }}>
                  <b>{sumValu.toLocaleString().replace(/,/g, ".")}</b>
                </span>{" "}
                € (Συνολικά)
              </div>
              &nbsp;
              <br />
              &nbsp;
              <br />
              &nbsp;
              <br />
            </div>
          </AccordionDetails>
        </Accordion>
        {/* ============================================================ */}
      </div>
    </>
  );
};

export default Sidebar;
