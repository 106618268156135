import React, { useRef, useState, useEffect } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import MapSel2 from "./mapSelDiaxMetr";
import AuthService from "../services/auth.service";

import VtextBox from "../components/VtextBox.component";
//import VcomboBox from "../components/VcomboBox.component";
import VcheckBox from "../components/VcheckBox.component";
import VdatePicker from "../components/VdatePicker.component";
//import VimgBox from "../components/VimgBox.component";
import VtextArea from "../components/VtextArea.component";
import GroupBox from "../components/GroupBox.component";

import { Form, Button, Row, Col } from "react-bootstrap";
import { GiFruitTree } from "react-icons/gi";
import { RiSave3Fill } from "react-icons/ri";

import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { RiDeleteBin6Line } from "react-icons/ri";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";

import Select from "react-select";
import makeAnimated from "react-select/animated";

import ConfirmBox from "../components/ConfirmBox.component";

const colourOptions = [
  { value: "ocean", label: "Ocean", color: "#00B8D9", isFixed: true },
  { value: "blue", label: "Blue", color: "#0052CC", isDisabled: true },
  { value: "purple", label: "Purple", color: "#5243AA" },
  { value: "red", label: "Red", color: "#FF5630", isFixed: true },
  { value: "orange", label: "Orange", color: "#FF8B00" },
];

/* ##################[ DiaxeirMetra ]################# */
const DiaxeirMetra = (props) => {
  //################
  const { id } = useParams();

  //----------------[ Open Page with Data ]-----------
  // Όταν καλείται μια σελίδα και στέλνουμε δεδομένα :
  //  <Link to={{ pathname: "/page", state: lista }}>
  //----------------[ page.js ]-----------------------
  // και τα παραλαμβάνουμε με :
  // const location = useLocation();
  // const lista = location.state;
  //--------------------------------------------------------
  // Εδώ καλείται (ως component mapSel2) η mapSelDiaxMetr.js
  // και συνεπώς περνάμε τα δεδομένα με props
  // <MapSel2 listaDen={formDataDen} />
  //--------------------------------------------------------
  const location = useLocation();
  const lista = location.state;

  //----------- Check Authentication ------------
  const user = AuthService.getCurrentUser();
  const isEditor =
    user?.roles.includes("ROLE_MODERATOR") || user?.roles.includes("ROLE_ADMIN")
      ? true
      : false;
  //----------------------------------------------
  console.log("@@@@@@@@", isEditor);
  //----------------------------------------------

  let today = new Date().toISOString();

  const [formData, setFormData] = useState({
    id: "",
    onoma: "Διαχ.Μέτρο ...",
    parat_prin: "",
    isok: 0,
    dateok: today,
    parat_meta: "",
  });

  const [opt_ergasies, setOpt_ergasies] = useState([]); //z_ergasies
  const [opt_ergasi_2, setOpt_ergasi_2] = useState([]); //like colourOptions
  const [selected_Ergasies, setSelected_Ergasies] = useState([]);
  const [default_Sel, setDefault_Sel] = useState({});
  const [check_Ergasies, setCheck_Ergasies] = useState(false);

  const [openSave, setOpenSave] = React.useState(false);

  const [formDataDen, setFormDataDen] = useState({});
  const [newFormDataDen, setNewFormDataDen] = useState({});
  const [formDataErg, setFormDataErg] = useState({});

  const [open, setOpen] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [roleOk, setRoleOk] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    //---------------z_ergasies--(Κατάλογος διαθέσιμων Εργασιών)-------
    axios
      .get(`${process.env.REACT_APP_URL}/api/zlookup/z_ergasies`)
      .then((response) => {
        // Assuming the response.data is an array of objects
        // like [{ label: "string", id: 9999 }]
        const respData = response.data;
        setOpt_ergasies(respData);

        // Transform and populate arrayB
        const transformedData = respData.map((item) => ({
          value: item.id,
          label: item.label,
          isFixed: false,
        }));
        setOpt_ergasi_2(transformedData);
      })
      .catch((error) => console.error(error));
    //---------[id (params: /diaxeir/52) ]---------------------
    if (id) {
      //------------[diaxeir]---εγγραφή Διαχειρ.Μέτρου---------
      axios
        .get(`${process.env.REACT_APP_URL}/api/diaxmetr/${id}`)
        .then((response) => {
          setFormData(response.data[0]);
        })
        .catch((error) => console.error(error));
      //------------[diaxeir_den]----------------------
      axios
        .get(`${process.env.REACT_APP_URL}/api/diaxmetr/den/${id}`)
        .then((response) => {
          setFormDataDen(response.data);
        })
        .catch((error) => console.error(error));
      //------------[diaxeir_erg]-----------------------
      axios
        .get(`${process.env.REACT_APP_URL}/api/diaxmetr/erg/${id}`)
        .then((response) => {
          setFormDataErg(response.data);
          console.log("response.data = ", response.data);
          if (response.data.length === 0) {
            setCheck_Ergasies(true);
          }
        })
        .catch((error) => console.error(error));
    } else {
      console.log("lista", lista);
      console.log("lista?.length", lista?.length);
      if (lista?.length > 0 && !formData.length) {
        setFormDataDen(lista);
        console.log("lista22222", lista);
        // Transform and populate arrayB
        const transformedData2 = lista.map((item) => ({
          //id: null,
          //diax_id: null,
          den_id: item.id,
          isok: 0,
          eidos: item.common_name,
          lat: item.apol_thesi_lat,
          lng: item.apol_thesi_lon,
          apol_thesi_lat: item.apol_thesi_lat,
          apol_thesi_lon: item.apol_thesi_lon,
        }));
        console.log("transformedData2===", transformedData2);
        setFormDataDen(transformedData2);
      }
    }
  }, []);

  //----------------FOR TEST---(DELETE START)-------------

  //----------------FOR TEST---(DELETE END)---------------

  //---------------[formDataErg]---------------------
  useEffect(() => {
    console.log("formDataErg===", formDataErg);
    if (formDataErg.length > 0) {
      const Array3 = [];
      // Iterate through Array1 and find matching objects in Array2
      formDataErg.forEach((item1) => {
        const matchingObject = opt_ergasi_2.find(
          (item2) => item2.value === item1.erg_id
        );
        if (matchingObject) {
          Array3.push(matchingObject);
        }
      });
      console.log("Array3===", Array3);
      setDefault_Sel(Array3);
      setSelected_Ergasies(Array3);
      setCheck_Ergasies(true);
    }
  }, [formDataErg]);

  //-----------[formDataDen]-------
  useEffect(() => {
    console.log("======================");
    console.log("formDataDen?.length===", formDataDen?.length);
    if (formDataDen?.length > 0 && newFormDataDen?.length === 0) {
      const transform = formDataDen.map((item) => ({
        //id: null,
        //diax_id: null,
        den_id: item.id,
        isok: 0,
        lat: item.apol_thesi_lat,
        lng: item.apol_thesi_lon,
        apol_thesi_lat: item.apol_thesi_lat,
        apol_thesi_lon: item.apol_thesi_lon,
      }));
      console.log("transform===", transform);
      setFormDataDen(transform);
      setNewFormDataDen(transform);
    }
  }, [formDataDen]);

  //-----------[formData dateok]-------
  useEffect(() => {
    if (formData.isok === 1) {
      formData.dateok = today;
    }
    if (formData.dateok === null) {
      formData.dateok = today;
    }
  }, [formData]);

  //----------[isok]------------------
  useEffect(() => {
    if (formData.isok === 1) {
      formData.dateok = today;
    }
  }, [formData.isok]);

  //---------- (multi) Select --------
  const handleSelect = (value) => {
    console.log("===value===", value);
    setSelected_Ergasies(value);
    const newErg = value.map((item) => ({
      diax_id: formData?.id ? formData.id : 0,
      erg_id: item.value,
    }));
    setFormDataErg(newErg);
  };

  //------------------TextBox----------------------
  const handleTextBox = (TextBox_id, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [TextBox_id]: newValue,
    }));
  };

  //-------------------TextArea---------------------
  const handleTextArea = (TextAreaId, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [TextAreaId]: newValue,
    }));
  };

  //----------------CheckBox-------------------
  const handleCheckBox = (event) => {
    const { name, value, checked } = event.target;
    setFormData({ ...formData, [name]: checked === true ? 1 : 0 });
  };

  //----------------DatePicker---------------
  const handleDateChange = (name, value) => {
    const isoDate = value ? value?.toISOString() : "";
    setFormData((prevFormData) => ({ ...prevFormData, [name]: isoDate }));
  };

  //----------------ComboBox-------------------
  const handleComboBox = (combo_id, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [combo_id]: value ? value.id : 0,
    }));
  };
  //-------------- For ComboBox ----------------------
  // const find_value = (combo_id, mValue) => {
  //   let selectedOption = null;
  //   switch (combo_id) {
  //     case "erg_id":
  //       selectedOption = opt_ergasies.find((opt) => opt.id === mValue);
  //       return selectedOption ? selectedOption.label : "";
  //
  //     default:
  //       return "";
  //   }
  // };
  //------------Final vars----------------------------------

  //--------- Open confirm for Del ---------------
  function openDelete(data) {
    setOpen(true);
    setDeleteData(data);
  }

  //----------[ Delete Diax Metro]----------------
  function deleteDiaxMetro() {
    console.log("--- Ok DELETE ---");
    console.log("deleteData===", deleteData);
    axios
      .delete(`${process.env.REACT_APP_URL}/api/diaxmetr/${deleteData?.id}`)
      .then((res) => {
        navigate(-1);
        setOpen(false);
        console.log("$$$$ feygo apo EDIT $$$$");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const animatedComponents = makeAnimated();

  //*************************************** */
  //*************************************** */
  async function handleSubmit(event) {
    //*******************************/
    event.preventDefault();
    console.log("=======SAVE====");
    console.log("formData", formData);
    console.log("formDataErg", formDataErg);
    console.log("formDataDen", formDataDen);
    console.log("opt_ergasi_2", opt_ergasi_2);
    console.log("selected_Ergasies", selected_Ergasies);
    console.log("default_Sel", default_Sel);
    console.log("check_Ergasies", check_Ergasies);

    // if (formData.isok === 1) handleDateChange("dateok", formData.dateok);
    // else setFormData((prevFormData) => ({ ...prevFormData, dateok: null }));

    console.log("@@@@@@@ SAVE @@@@@@@");
    console.log("formData = ", formData);
    console.log("formDataDen = ", formDataDen);
    console.log("formDataErg = ", formDataErg);
    console.log("selected_Ergasies", selected_Ergasies);

    const all_data = {
      formData: formData,
      formDataDen: formDataDen,
      formDataErg: formDataErg,
    };
    setOpenSave(true);

    let final_id;

    if (formData.id) {
      //---------------- UPDATE -------------------------
      final_id = formData.id;
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_URL}/api/diaxmetr/${formData.id}`,
          all_data
        );
        console.log(response);
      } catch (error) {
        console.error("Error creating record:", error);
      }
    } else {
      //---------------- INSERT -------------------------
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_URL}/api/diaxmetr/add`,
          all_data
        );
        console.log("response.data", response.data);
        setFormData((prevFormData) => ({
          ...prevFormData,
          id: response.data.id,
        }));
        final_id = response.data.id;
        formData.id = final_id;
      } catch (error) {
        console.error("Error creating record:", error);
      }
    }
    //----------------------------------------------
    setOpenSave(false);
    //----------------------------------------------
    setDefault_Sel(selected_Ergasies);
    setCheck_Ergasies(true);
  }

  function Dendra_List({ dList }) {
    const ret = dList.map((item) => (
      <Link
        key={item.id}
        to={`/getdendro${isEditor ? "2" : ""}/${item.den_id}`}
        className="btn btn-info btn-sm"
        target="_blank"
        style={{ margin: "0 5px 0 0 " }}
      >
        <span style={{ fontSize: "12px" }}>
          <strong>{`${item.den_id}`}</strong>-{item.eidos}
          &nbsp;&nbsp;&nbsp;
        </span>
      </Link>
    ));
    //console.log("$$$$$====", ret);
    return ret;
  }

  const MyAccordionsumA = styled(AccordionSummary)({
    backgroundColor: "#adc2eb",
    minHeight: "30px",
    maxHeight: "30px",
    expandedHeader: {
      minHeight: "32px", // Adjust the height as per your requirement
      maxHeight: "40px",
    },
  });

  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  return (
    <>
      <div>
        <header className="jumbotron" style={{ padding: "1.0rem" }}>
          <div className="row">
            <div className="col-1">
              <img
                src="/img/small-logo-green-tree.png"
                width="120px"
                alt="Logo"
              />
            </div>
            <div className="col-11">
              <h3 className="text-center">
                <b>GreenTree</b> - Διαχειριστικά Μέτρα
              </h3>
              <h6 className="text-center">
                (Μόνο Εξουσιοδοτημένοι χρήστες ή Διαχειριστές)
              </h6>
            </div>
          </div>
        </header>
      </div>
      {formDataDen?.length > 0 && (
        <>
          <div className="row">
            <div className="col-10">
              <h5>Επιλεγμένα Δένδρα - </h5>
            </div>
            <div className="col-2">
              {/* --------------------------------------------------------- */}
              <Button
                className="btn btn-secondary btn-sm"
                onClick={() => navigate(-1)}
              >
                <GiFruitTree
                  color="#AEFDCE"
                  style={{
                    top: "-2px",
                    position: "relative",
                  }}
                />{" "}
                Επιστροφή
              </Button>
              {/* --------------------------------------------------------- */}
            </div>
          </div>
          {/*----- Dendra_List (dList) -----*/}
          {console.log("formDataDen===", formDataDen)}
          {formDataDen.length > 0 && <Dendra_List dList={formDataDen} />}

          <hr style={{ margin: "8px" }} />
          <Accordion>
            <MyAccordionsumA
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <b>Χάρτης με τα Δένδρα που συμμετέχουν</b>&nbsp;&nbsp;
              <img src="/img/small_map2.png" width="30px" alt="greentree" />
            </MyAccordionsumA>
            <AccordionDetails>
              <MapSel2 listaDen={formDataDen} />
            </AccordionDetails>
          </Accordion>
          <hr style={{ margin: "8px" }} />
        </>
      )}
      {/* =========================================================== */}
      <form
        onSubmit={handleSubmit}
        style={{ backgroundColor: "#e0ebeb", padding: "15px" }}
      >
        <h4>
          <b>
            Βασικά Στοιχεία {formData.id ? "" : "Νέου"} Διαχειριστικού Μέτρου :
          </b>
        </h4>
        <GroupBox title={""} backgroundColor={"e0ebeb"}>
          <div className="row" style={{ padding: "20px" }}>
            {/* ---------------------id--------------------- */}
            <VtextBox
              id="id"
              name="id"
              label="ID Διαχ.Μέτρου:"
              textAlign="center"
              value={formData.id}
              width={120}
              disabled
            />
            &nbsp;&nbsp;
            {/* ---------------------onoma--------------------- */}
            <VtextBox
              id="onoma"
              name="onoma"
              label="Ονομασία Διαχ.Μέτρου:"
              textAlign="left"
              value={formData.onoma}
              width={350}
              onChange={handleTextBox}
              style={{ backgroundColor: "#FFFFFF" }}
            />
            &nbsp;&nbsp;
            {/* ---------------------dateok--------------------- */}
            <VdatePicker
              id="dateok"
              name="dateok"
              label="Ημερομ. Εκτέλεσης"
              value={formData.dateok}
              onChange={handleDateChange}
            />
          </div>
          {/* ---------------------isok--------------------- */}
          <VcheckBox
            id="isok"
            name="isok"
            checked={formData.isok === 1}
            label="Ολοκληρώθηκε"
            onChange={handleCheckBox}
            className="col-3"
          />
          <div>&nbsp;</div>

          <GroupBox title={"Εργασίες"} backgroundColor={"e0ebeb"}>
            <div>&nbsp;</div>
            {/* ----------ergasies---SELECT (react-select)--------- */}
            {(!formData.id || (opt_ergasi_2.length > 0 && check_Ergasies)) && (
              <Select
                id="ergasies"
                name="ergasies"
                closeMenuOnSelect={false}
                components={animatedComponents}
                defaultValue={default_Sel.length > 0 ? default_Sel : ""}
                onChange={handleSelect}
                isMulti
                options={opt_ergasi_2}
              />
            )}
            <div>&nbsp;</div>
          </GroupBox>
          <div>&nbsp;</div>

          <label style={{ fontSize: "14px", color: "#626262" }}>
            Σχόλια / Παρατηρήσεις <b>Πριν</b> την εφαρμογή ...
          </label>
          {/* ---------------------parat_prin--------------------- */}
          <VtextArea
            id="parat_prin"
            name="parat_prin"
            width="90%"
            height="Auto"
            value={formData.parat_prin}
            onChange={handleTextArea}
          />
          <div>&nbsp;</div>

          <label style={{ fontSize: "14px", color: "#626262" }}>
            Σχόλια / Παρατηρήσεις <b>ΜΕΤΑ</b> την εφαρμογή ...
          </label>
          {/* ---------------------parat_meta--------------------- */}
          <VtextArea
            id="parat_meta"
            name="parat_meta"
            width="90%"
            height="Auto"
            value={formData.parat_meta}
            onChange={handleTextArea}
          />
          {/* ---------------------------------------------------- */}
        </GroupBox>
        <hr />
        {/* ------------------ BUTTONs BAR --------------------------- */}
        <Form.Group as={Row}>
          <Col sm={{ span: 8 }}>
            {/* ------------ Επιστροφή ---------------- */}
            <Button className="btn btn-secondary" onClick={() => navigate(-1)}>
              <GiFruitTree
                color="#AEFDCE"
                style={{ top: "-2px", position: "relative" }}
              />{" "}
              Επιστροφή
            </Button>
            &nbsp; &nbsp; &nbsp;
            {/* ------------ Αποθήκευση ---------------- */}
            <Button
              type="submit"
              className="btn btn-primary"
              disabled={!isEditor}
            >
              <RiSave3Fill
                color="#AEFDCE"
                style={{ top: "-2px", position: "relative", fontSize: "1.4em" }}
              />{" "}
              {formData.id ? "Ενημέρωση" : "Αποθήκευση"}
            </Button>
          </Col>
          <Col sm={{ span: 4 }}>
            {/* ------------ Διαγραφή ---------------- */}
            <Button
              onClick={() => openDelete(formData)}
              //to={"#"}
              className="btn btn-danger"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginLeft: "auto",
              }}
              disabled={!(formData.id && isEditor)}
            >
              <RiDeleteBin6Line
                color="#AEFDCE"
                //style={{ top: "0px", position: "relative" }}
              />{" "}
            </Button>
            {/* ---------------Popup Confirm------------------- */}
            <ConfirmBox
              open={open}
              closeDialog={() => setOpen(false)}
              title={`Διαγραφή Διαχειριστικού Μέτρου (${formData?.id})`}
              text={`\nΕπιθυμείτε τη Διαγραφή του Διαχειριστικού Μέτρου με id = ${formData?.id}`}
              deleteFunction={deleteDiaxMetro}
            />
            {/* --------------End Διαγραφή-------------- */}
          </Col>
        </Form.Group>
        {/* -----------------End- BUTTONs BAR --------------------------- */}

        {/* ************************************************************* */}
        <Collapse in={openSave}>
          <Alert
            severity="info"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenSave(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            Διαδικασία Αποθήκευσης ...
          </Alert>
        </Collapse>
        {/* ************************************************************* */}
      </form>
    </>
  );
};

export default DiaxeirMetra;
