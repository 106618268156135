import React, { useState, useEffect, Component } from "react";
import { Navigate } from "react-router-dom";
import AuthService from "../services/auth.service";
import Modal_Pass from "./Modals/Modal_pass";

/********************** */
function Profile() {
  /************** */
  const [redirect, setRedirect] = useState(null);
  const [userReady, setUserReady] = useState(false);
  const [currentUser, setCurrentUser] = useState({ username: "" });

  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    const currentUser = AuthService.getCurrentUser();
    //console.log("currentUser===", currentUser);
    if (!currentUser) setRedirect("/home");
    setCurrentUser(currentUser);
    setUserReady(true);
    //-------------------------------------------------
  }, []);

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  //==================[ EDIT ]===================
  const Edit_Pass = (edit_data) => {
    //console.log("edit_data = ", edit_data);
    setModalOpen(true);
    setModalTitle("Αλλαγή Password");
    //setModalData(edit_data);
    setModalData({ id: currentUser.id, username: currentUser.username });
  };
  //============================================

  return (
    <div className="container">
      {modalOpen && modalData && (
        <Modal_Pass
          setModalOpen={setModalOpen}
          mTitle={modalTitle}
          mData={modalData}
          //onSaveSuccess={RefreshData}
        />
      )}
      {userReady ? (
        <div>
          <header className="jumbotron">
            <h3>
              <strong>{currentUser.username}</strong> Profile
            </h3>
          </header>
          <header className="jumbotron">
            <p>
              <strong>Token:</strong> {currentUser.accessToken.substring(0, 20)}{" "}
              ...{" "}
              {currentUser.accessToken.substr(
                currentUser.accessToken.length - 20
              )}
            </p>

            <p>
              <strong>Id:</strong> {currentUser.id}
            </p>
            <p>
              <strong>Email:</strong> {currentUser.email}
            </p>
            <p>
              <strong>FullName:</strong> {currentUser.FullName}
            </p>
            <strong>Authorities:</strong>
            <ul>
              {currentUser.roles &&
                currentUser.roles.map((role, index) => (
                  <li key={index}>{role}</li>
                ))}
            </ul>
            <hr />
            <button className="btn btn-secondary btn-sm" onClick={Edit_Pass}>
              Αλλαγή Password
            </button>
          </header>
        </div>
      ) : null}
    </div>
  );
}

export default Profile;

// export default class Profile extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       redirect: null,
//       userReady: false,
//       currentUser: { username: "" },
//     };
//   }

//   componentDidMount() {
//     const currentUser = AuthService.getCurrentUser();

//     if (!currentUser) this.setState({ redirect: "/home" });
//     this.setState({ currentUser: currentUser, userReady: true });
//   }

//   render() {
//     if (this.state.redirect) {
//       return <Navigate to={this.state.redirect} />;
//     }

//     const { currentUser } = this.state;

//     return (
//       <div className="container">
//         {this.state.userReady ? (
//           <div>
//             <header className="jumbotron">
//               <h3>
//                 <strong>{currentUser.username}</strong> Profile
//               </h3>
//             </header>
//             <header className="jumbotron">
//               <p>
//                 <strong>Token:</strong>{" "}
//                 {currentUser.accessToken.substring(0, 20)} ...{" "}
//                 {currentUser.accessToken.substr(
//                   currentUser.accessToken.length - 20
//                 )}
//               </p>

//               <p>
//                 <strong>Id:</strong> {currentUser.id}
//               </p>
//               <p>
//                 <strong>Email:</strong> {currentUser.email}
//               </p>
//               <p>
//                 <strong>FullName:</strong> {currentUser.FullName}
//               </p>
//               <strong>Authorities:</strong>
//               <ul>
//                 {currentUser.roles &&
//                   currentUser.roles.map((role, index) => (
//                     <li key={index}>{role}</li>
//                   ))}
//               </ul>
//               <hr />
//               <button className="btn btn-secondary btn-sm">
//                 Αλλαγή Password
//               </button>
//             </header>
//           </div>
//         ) : null}
//       </div>
//     );
//   }
// }
