import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import AuthService from "../services/auth.service";
import axios from "axios";
//import Modal from "../components/Modal/Modal";
import { useParams } from "react-router-dom";
//-----------------------------
import L from "leaflet";
import { MapContainer } from "react-leaflet/MapContainer";
import { TileLayer } from "react-leaflet/TileLayer";
import { WMSTileLayer } from "react-leaflet/WMSTileLayer";
import { Pane } from "react-leaflet/Pane";
import { Marker } from "react-leaflet/Marker";
import { Popup } from "react-leaflet/Popup";
import { Tooltip } from "react-leaflet/Tooltip";
import { LayersControl } from "react-leaflet/LayersControl";
import { LayerGroup } from "react-leaflet/LayerGroup";
import { Circle } from "react-leaflet/Circle";
import { Rectangle } from "react-leaflet/Rectangle";
import { useMapEvents } from "react-leaflet";
import { MapControl } from "react-leaflet";
import { useMap } from "react-leaflet";

import CoordinatesControl from "../components/CoordinatesControl.component";
import BingTileLayer from "../components/BingTileLayer.component";

//import { Control } from "react-leaflet/Control";
//import Toolbar from "../components/Toolbar.component";
//import "leaflet-toolbar/dist/leaflet.toolbar.css";
//import "leaflet-toolbar/dist/leaflet.toolbar";

//--------------------------------
import "leaflet/dist/leaflet.css";
import "../App.css";
import "../leaflet.css";
//--------------------------------
import { FaMapMarkerAlt } from "react-icons/fa";
import { IoAnalyticsOutline } from "react-icons/io5";
import { FaInfo } from "react-icons/fa";
//--------------------------------
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

export const myIcon = new L.Icon({
  iconUrl:
    "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const identifyIcon = L.icon({
  iconUrl: "img/info_icon_32.png",
  iconSize: [32, 32],
  iconAnchor: [16, 16],
});

const distanceIcon = L.icon({
  iconUrl: "img/ruler_icon_32.png",
  iconSize: [32, 32],
  iconAnchor: [16, 16],
});

const rectangle = [
  [40.62, 22.95],
  [40.63, 22.97],
];

//--------------------------------------------------------------
let readyPoint = false;
//~~~~~~~~~~~~~~~~~~~~~~~~~~~ Toolbar ~~~~~~~~~~~~~~~~~~~~~~~~~~
function Toolbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [popupContent, setPopupContent] = useState(null);
  const popupRef = useRef();

  //----------- Check Authentication ------------
  const user = AuthService.getCurrentUser();
  const isOk =
    user?.roles.includes("ROLE_MODERATOR") || user?.roles.includes("ROLE_ADMIN")
      ? true
      : false;
  //----------------------------------------------

  const map = useMapEvents({
    click(e) {
      // handle click events on the map
      //console.log("------useMapEvents---------", e.latlng.lat, e.latlng.lng);
      const lat = e.latlng.lat;
      const lng = e.latlng.lng;

      const handlePopupClose = () => {
        map.closePopup();
      };

      //------------------gdendro/:lat/:lon-------------------
      if (readyPoint) {
        axios
          .get(`${process.env.REACT_APP_URL}/api/postgis/${lat}/${lng}`)
          .then((response) => {
            const formData = response.data[0];
            //setFormData(formData);
            //console.log(formData);
            //console.log(formData.id, formData.old_id);

            const content = `
              <script>
               function handlePopupClose(e) {
                e.target.closePopup();
              };
              </script>
              <style>
              table, th, td {
                border: 2px solid white;
                border-collapse: collapse;
              }
              th, td {
                padding: 5px;
                background-color: #DFDFDF;
              }
              #cL {
                text-align: right;
                background-color: #EFEFEF;
              }
              #cC {
                text-align: center;
                background-color: #FFFFFF;
              }
              #cR {
                background-color: #DFDFDF;
              }
              a:link {
                color: white;
              }
              
              </style>
              <div>
                
                <table>
                  <tr>
                    <td id="cL">new_id</td>
                    <td id="cC">:</td>
                    <td id="cR"><strong> ${formData.id} - ( ${
              formData.old_id
            } )</strong></td>
                  </tr>
                  <tr>
                    <td id="cL">Ονομ. Βοτανική</td>
                    <td id="cC">:</td>
                    <td id="cR"><strong> ${formData.botanic_name}</strong></td>
                  </tr>
                  <tr>
                    <td id="cL">Ονομ. Κοινή</td>
                    <td id="cC">:</td>
                    <td id="cR"><strong> ${formData.common_name}</strong></td>
                  </tr>
                  <tr>
                    <td id="cL">Οδός</td>
                    <td id="cC">:</td>
                    <td id="cR"><strong> ${formData.onoma}</strong></td>
                  </tr>
                </table>
                <br>
                
                <a href="/${isOk ? "getdendro2" : "getdendro"}/${formData.id}" 
                   class="btn btn-warning btn-sm float-right" >... Περισσότερα </a>
                
                <div>&nbsp;</div>
                <div>&nbsp;</div>
              </div>
            `;
            console.log("SSSSSSSSSSSSSS", e.latlng);
            const newLatLng = {
              lat: formData.apol_thesi_lat,
              lng: formData.apol_thesi_lon,
            };
            const content_off = `
                <button class="btn btn-secondary btn-sm"
                onClick="${() => console.log("jjjjjjj")}"}>&nbsp;Close</Button>
            `;

            //map.openPopup(content, e.latlng);

            popupRef.current = L.popup()
              .setContent(content)
              .setLatLng(e.latlng)
              //.setLatLng(newLatLng ? newLatLng : e.latlng)
              .openOn(map);
          })
          .catch((error) => console.error(error));
        readyPoint = false;
        map.getContainer().style.cursor = "";
      }
      return (
        <Popup>
          {/* <div dangerouslySetInnerHTML={{ __html: popupContent }} />
          <button onClick={hideElement}>Close popup</button> */}
          <Popup ref={popupRef} closeButton={true}>
            <button>Close popup</button>
          </Popup>
        </Popup>
      );
      //------------------------------------------------------
    },
    mouseMove(e) {
      //console.log("ooooooooo");
    },
  });

  const [activeTool, setActiveTool] = useState(null);

  function handleGetAttributes() {
    // handle get attributes tool click event
    console.log("handleGetAttributes");
    setActiveTool("getAttributes");
    map.getContainer().style.cursor = "crosshair";
    readyPoint = true;
  }

  function handleAddLine() {
    // handle add line tool click event
    console.log("handleAddLine");
  }

  function handleAddMarker() {
    // handle add marker tool click event
    console.log("handleAddMarker");
  }

  function handleMapClick() {
    // reset the active tool and cursor when the map is clicked
    setActiveTool(null);
    map.getContainer().style.cursor = "";
  }

  return (
    <div
      className="leaflet-bar leaflet-control"
      style={{
        position: "relative",
        top: "50%",
        transform: "translateY(-50%)",
      }}
    >
      <a
        href="#"
        title="Επιλογή Δένδρου"
        onClick={handleGetAttributes}
        style={{ cursor: activeTool === "getAttributes" ? "crosshair" : "" }}
      >
        <FaInfo />
      </a>
      <a
        href="#"
        title="Υπό Κατασκευή --- Add line"
        onClick={handleAddLine}
        style={{ cursor: activeTool === "addLine" ? "pointer" : "" }}
      >
        <IoAnalyticsOutline />
      </a>
      <a
        href="#"
        title="Υπό Κατασκευή --- Add marker"
        onClick={handleAddMarker}
        style={{ cursor: activeTool === "addMarker" ? "inherit" : "" }}
      >
        <FaMapMarkerAlt />
      </a>
      {activeTool && (
        <div
          className="leaflet-overlay-pane"
          onClick={handleMapClick}
          style={{ cursor: "crosshair" }}
        ></div>
      )}
    </div>
  );
}

const BING_MAPS_KEY =
  "AjG59CvIm2ydzA7QDKMjSc-VtqC4epnaeu-FRYDoKnCWQKcPtScu04zhNrYegYAo";

//####################[ Map2 ]##########################
function Map2() {
  //#############
  //----------- Check Authentication ------------
  const currentUser = AuthService.getCurrentUser();
  const [state, setState] = useState({
    redirect: null,
    userReady: false,
    currentUser: { username: "" },
  });
  //----------------------------------------------

  const { lng, lat } = useParams();
  let xlng = lng ? lng : 22.953772;
  let xlat = lat ? lat : 40.629901;
  let xzoom = lat ? 20 : 15;
  const [center, setCenter] = useState([xlat, xlng]);

  const [mousePosition, setMousePosition] = useState({ lat: 0, lng: 0 });

  const handleMouseMove = (e) => {
    console.log("---handleMouseMove---");
    setMousePosition({
      lat: e.latlng.lat.toFixed(4),
      lng: e.latlng.lng.toFixed(4),
    });
    console.log(mousePosition.lat, mousePosition.lng);
  };

  const [points, setPoints] = useState([]);
  const [popupContent, setPopupContent] = useState(null);
  const mapRef = useRef(null);
  const maxZoom = 21;
  const [zoom, setZoom] = useState(xzoom);

  useEffect(() => {
    //axios
    //  .get("${process.env.REACT_APP_URL}/api/dendra")
    //  .then((response) => setPoints(response.data))
    //  .catch((error) => console.log(error));
    if (lng && lat) {
      setCenter([lat, lng]);
      setZoom(19);
    }
  }, []);

  const wms_url = process.env.REACT_APP_WMS_URL;

  return (
    <>
      <div>
        <b>Map2222</b>
      </div>
      <div className="App1111" ref={mapRef} style={{ height: "100vh" }}>
        {/* <header className="App-header"> */}
        <MapContainer
          //center={[40.6287396, 22.9556954]}
          center={center}
          zoom={zoom}
          maxZoom={maxZoom}
          //maxNativeZoom={19}
          scrollWheelZoom={true}
          style={{ height: "88vh", width: "100wh", border: "1px solid gray" }}
          onMouseMove={handleMouseMove}
        >
          {/* ----------------------------------- */}
          <LayersControl position="topright">
            <Pane>
              <LayersControl.BaseLayer checked name="Open Street Map">
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  maxZoom={maxZoom}
                  maxNativeZoom={19}
                  minZoom={0}
                />
              </LayersControl.BaseLayer>
              {/* ---------------------------------------------- */}
              <LayersControl.BaseLayer name="Bing Map Road">
                <BingTileLayer
                  bingMapsKey={BING_MAPS_KEY}
                  imagerySet="Road"
                  culture="el"
                  //minZoom="1"
                  maxZoom="21"
                />
              </LayersControl.BaseLayer>
              {/* ---------------------------------------------- */}
              <LayersControl.BaseLayer name="ESRI Topo Map">
                <TileLayer
                  url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
                  maxZoom={maxZoom}
                  maxNativeZoom={19}
                ></TileLayer>
              </LayersControl.BaseLayer>
              {/* ------------------------------------------------ */}
              <LayersControl.BaseLayer name="ESRI Street Map">
                <TileLayer
                  url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
                  maxZoom={maxZoom}
                  maxNativeZoom={19}
                ></TileLayer>
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer></LayersControl.BaseLayer>
              {/* <div className="divider-line" >-------------------------------</div> */}
              <hr />
              {/* --------------------------------------------- */}
              <LayersControl.BaseLayer name="Bing Satellite Map">
                <BingTileLayer
                  bingMapsKey={BING_MAPS_KEY}
                  imagerySet="Aerial"
                  culture="el"
                  //minZoom="1"
                  maxZoom="21"
                />
              </LayersControl.BaseLayer>
              {/* --------------------------------------------- */}
              <LayersControl.BaseLayer name="ESRI Satellite Map">
                <TileLayer
                  url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                  maxZoom={maxZoom}
                  maxNativeZoom={19}
                ></TileLayer>
              </LayersControl.BaseLayer>
              {/* ------------------------------------- */}
              <LayersControl.BaseLayer name="Κενό">
                <TileLayer
                  attribution=""
                  url=""
                  maxNativeZoom={19}
                  minZoom={0}
                  maxZoom={maxZoom}
                />
              </LayersControl.BaseLayer>
            </Pane>

            <Pane>
              {/* <WMSLayerPopup /> */}

              <LayersControl.Overlay checked name="WMS Layer ">
                <WMSTileLayer
                  //url={`http://65.109.235.213:8080/geoserver/wms`}
                  url={`${wms_url}`}
                  layers="wdendra"
                  format="image/png"
                  transparent={true}
                  maxZoom={maxZoom}
                />
              </LayersControl.Overlay>
              <LayersControl.Overlay checked name="1. Σημείο Marker με popup">
                <Circle
                  center={center}
                  pathOptions={{ fillColor: "green", color: "red", weight: 2 }}
                  radius={2}
                >
                  {/* <Popup>
                    A pretty CSS3 popup (Circle - blue). <br /> Easily
                    customizable.
                  </Popup> */}
                </Circle>
                {/* <Marker position={center} icon={DefaultIcon}>
                  <Popup>
                    A pretty CSS3 popup. <br /> Easily customizable.
                    {center}
                  </Popup>
                </Marker> */}
              </LayersControl.Overlay>

              <LayersControl.Overlay name="2. Layer with 3 shapes">
                <LayerGroup>
                  <Circle
                    center={center}
                    pathOptions={{ fillColor: "blue", border: "red" }}
                    radius={2}
                  >
                    <Popup>
                      A pretty CSS3 popup (Circle - blue). <br /> Easily
                      customizable.
                    </Popup>
                  </Circle>

                  <Circle
                    center={center}
                    pathOptions={{ fillColor: "red" }}
                    radius={5}
                    stroke={false}
                  >
                    <Popup>
                      A pretty CSS3 popup (Circle - red). <br /> Easily
                      customizable.
                    </Popup>
                  </Circle>
                  <LayerGroup>
                    <Rectangle
                      bounds={rectangle}
                      pathOptions={{ color: "green", fillColor: "green" }}
                    >
                      <Popup>
                        A pretty CSS3 popup (Rectangle). <br /> Easily
                        customizable.
                      </Popup>
                    </Rectangle>
                  </LayerGroup>
                </LayerGroup>
              </LayersControl.Overlay>

              <LayersControl.Overlay checked name="3. Layer with 2 markers">
                <LayerGroup>
                  <Marker position={[40.6287396, 22.9556954]} icon={myIcon}>
                    <Popup>
                      A pretty CSS3 popup. <br /> Easily customizable.
                    </Popup>
                    <Tooltip>Tooltip for Marker-1</Tooltip>
                  </Marker>
                  <Marker position={[40.629501, 22.955772]} icon={myIcon}>
                    <Popup>
                      A pretty CSS3 popup. <br /> Easily customizable.
                    </Popup>
                    <Tooltip>Tooltip for Marker-2</Tooltip>
                  </Marker>
                </LayerGroup>
              </LayersControl.Overlay>
            </Pane>
            {/*-------------------------*/}
            <Toolbar position="topleft" />
            {/*-------------------------*/}
            <CoordinatesControl />
            {/*----------------*/}
          </LayersControl>
        </MapContainer>
      </div>
    </>
  );
}

export default Map2;
