import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Modal.css";
import Button from "react-bootstrap/Button";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import VtextBox from "../VtextBox.component";
import VcheckBox from "../VcheckBox.component";
import GroupBox from "../GroupBox.component";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Md10K } from "react-icons/md";
import Typography from "@mui/material/Typography";

//***************************************************************** */
function Modal_user({ setModalOpen, mTitle, mData, onSaveSuccess }) {
  //************************************************************* */
  console.log("mData=", mData, Object.keys(mData).length === 0);

  //const mode = Object.keys(mData).length === 0 ? "new" : "edit";
  const [formData3, setFormData3] = useState({});
  const [selectedCatOption, setSelectedCatOption] = useState(null);

  //-------------[]------------
  useEffect(() => {
    setFormData3(mData);
    //----------------------------
    if (mData.User === 1) {
      setSelectedCatOption("1");
    }
    if (mData.Edit === 1) {
      setSelectedCatOption("2");
    }
    if (mData.Admin === 1) {
      setSelectedCatOption("3");
    }
    //----------------------------
  }, []);

  //-------------[ formData3 ]------------
  useEffect(() => {
    console.log("===formData3===", formData3);
  }, [formData3]);

  //-------------[ selectedCatOption ]------------
  useEffect(() => {
    if (selectedCatOption === "2") {
      setFormData3((prevFormData3) => ({
        ...prevFormData3,
        User: 1,
        Edit: 1,
        Admin: 0,
      }));
    } else if (selectedCatOption === "3") {
      setFormData3((prevFormData3) => ({
        ...prevFormData3,
        User: 1,
        Edit: 1,
        Admin: 1,
      }));
    } else {
      setFormData3((prevFormData3) => ({
        ...prevFormData3,
        User: 1,
        Edit: 0,
        Admin: 0,
      }));
    }
  }, [selectedCatOption]);

  //-------------------Handlers---------------------------
  const handleCatOption = (event) => {
    setSelectedCatOption(event.target.value);
  };

  const handleTextBox3 = (TextBox_id, newValue) => {
    console.log(formData3, TextBox_id, newValue);
    setFormData3((prevFormData3) => ({
      ...prevFormData3,
      [TextBox_id]: newValue,
    }));
  };

  const handleCheckBox = (event) => {
    const { name, value, checked } = event.target;
    setFormData3({
      ...formData3,
      [name]: checked === true ? 1 : 0,
    });
  };

  //*************************************** */
  async function handleSubmit3(event) {
    //*******************************/
    event.preventDefault();
    console.log("formData3", formData3);
    //console.log("mData", mData);

    //---------[ UPDATE ]-----------------------------
    await axios
      .put(
        `${process.env.REACT_APP_URL}/api/search/user/${formData3.id}`,
        formData3
      )
      .then((response) => console.log(response))
      .catch((error) => console.error(error));

    //----------------------------------------------
    onSaveSuccess();
    //----------------------------------------------
    setModalOpen(false);
    //----------------------------------------------
  }

  return (
    <div className="modalBackground">
      <div className="modalContainer modalContainerEidos">
        {/* <div className="titleCloseBtn22"> */}
        <div className="title">
          <div className="row">
            <div className="col-10">
              <h4>{mTitle}</h4>
            </div>
            <div className="col-2">
              <Button
                id="closeBtn"
                className="btn btn-secondary btn-sm"
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                &nbsp;X&nbsp;
              </Button>
            </div>
          </div>
          <hr />
        </div>
        {/* ----------------------------[ body ]-------------------------------- */}
        <div className="body">
          <form
            onSubmit={handleSubmit3}
          //</div>style={{ backgroundColor: "#e0ebeb" }}
          >
            {formData3.id && (
              <Box>
                <FormGroup>
                  <div className="row" style={{ marginLeft: "0px" }}>
                    <VtextBox
                      id="id"
                      name="id"
                      label="id"
                      textAlign="center"
                      value={formData3.id}
                      width={80}
                      //onChange={handleTextBox3}   ---ReadOnly---
                      style={{ margin: "10px" }}
                      disabled
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <VtextBox
                      id="updated_at"
                      name="updated_at"
                      label="Τελευταία Ενημέρωση"
                      textAlign="center"
                      value={formData3.updated_at}
                      width={170}
                      //onChange={handleTextBox3}   ---ReadOnly---
                      style={{ margin: "10px" }}
                      disabled={false}
                    />
                  </div>
                  <VtextBox
                    id="username"
                    name="username"
                    label="Username"
                    textAlign="left"
                    value={formData3.username}
                    width={190}
                    onChange={handleTextBox3}
                    maxLength={4}
                    style={{ margin: "10px" }}
                  />
                  <VtextBox
                    id="email"
                    name="email"
                    label="e-mail"
                    textAlign="left"
                    value={formData3.email}
                    width={470}
                    onChange={handleTextBox3}
                    style={{ margin: "10px" }}
                  />
                  <VtextBox
                    id="FullName"
                    name="FullName"
                    label="Ονοματεπώνυμο"
                    textAlign="left"
                    value={formData3.FullName}
                    width={470}
                    onChange={handleTextBox3}
                    style={{ margin: "10px" }}
                  />
                  <div>
                    <VcheckBox
                      id="Active"
                      name="Active"
                      checked={formData3.Active === 1}
                      label="Ενεργός"
                      onChange={handleCheckBox}
                    />
                  </div>
                  {/* ---------------- Radio boxes ---------------------- */}
                  <Typography component="div" style={{ padding: 10 }}>
                    <GroupBox
                      title={"Ρόλος χρήστη :"}
                      backgroundColor="#f7f7f7"
                    >
                      {/* ------------------ */}
                      <RadioGroup
                        aria-label="Options"
                        name="k_p_f"
                        value={selectedCatOption}
                        onChange={handleCatOption}
                        row // Add the 'row' attribute for horizontal alignment
                        style={{ paddingLeft: 50 }}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="User"
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="Editor"
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <FormControlLabel
                          value="3"
                          control={<Radio />}
                          label="Admin"
                        />
                      </RadioGroup>
                    </GroupBox>
                  </Typography>

                  {/* ------------------ Radio boxes ----end---------- */}
                </FormGroup>
              </Box>
            )}
          </form>
        </div>
        <div className="footer">
          <button
            onClick={() => {
              setModalOpen(false);
            }}
            id="cancelBtn"
          >
            Ακύρωση
          </button>
          <Button
            onClick={handleSubmit3}
            disabled={
              formData3.code === "" ||
              formData3.botanic_name === "" ||
              formData3.common_name === ""
            }
          >
            Αποθήκευση
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Modal_user;
