import React from "react";
import { useMap } from "react-leaflet";
import proj4 from "proj4";

const Projection_4326 = "EPSG:4326"; // latLng projection
const Projection_2100 = "EPSG:2100"; // Greek Grid projection
const Projection_3857 = "EPSG:3857"; // leaflet default projection
// Define  projections
proj4.defs(Projection_4326, "+proj=longlat +datum=WGS84 +no_defs +type=crs");
proj4.defs(
  Projection_2100,
  "+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=-199.87,74.79,246.62,0,0,0,0 +units=m +no_defs +type=crs"
);
proj4.defs(
  Projection_3857,
  "+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs +type=crs"
);

// Create a projection object
const EPSG_2100 = proj4(Projection_2100);

function round(number, precision = 0) {
  return (
    Math.round(number * Math.pow(10, precision) + Number.EPSILON) /
    Math.pow(10, precision)
  );
}

function formatLatitude(latitude) {
  const direction = latitude > 0 ? "N" : "S";
  return `${round(Math.abs(latitude), 6)}° ${direction}`;
}

function formatLongitude(longitude) {
  const direction = longitude > 0 ? "E" : "W";
  return `${round(Math.abs(longitude), 6)}° ${direction}`;
}

function formatY(latlong) {
  const direction = "Y:";
  const [x, y] = EPSG_2100.forward([latlong.lng, latlong.lat]);
  return ` ${direction} ${(Math.round(Math.abs(y) * 100) / 100).toFixed(2)} `;
  //return ` ${direction} ${round(Math.abs(y), 2)} `;
}

function formatX(latlong) {
  const [x, y] = EPSG_2100.forward([latlong.lng, latlong.lat]);
  const direction = "X:";
  return ` ${direction} ${(Math.round(Math.abs(x) * 100) / 100).toFixed(2)} `;
  //return ` ${direction} ${round(Math.abs(x), 1)} `;
}

function CoordinatesControl() {
  const map = useMap();
  const [coordinates, setCoordinates] = React.useState(null);
  const [zoomLevel, setZoomLevel] = React.useState(null);
  const [mapBounds, setMapBounds] = React.useState(null);

  const [egsaX, setEgsaX] = React.useState(null);
  const [egsaY, setEgsaY] = React.useState(null);

  const handleMouseMove = (e) => {
    setCoordinates(e.latlng);
    setZoomLevel(map.getZoom());
    //setMapBounds(map.getBounds());
    //console.log(mapBounds);
    setEgsaX(formatX(e.latlng));
    setEgsaY(formatY(e.latlng));
    //console.log(xxx);
  };

  React.useEffect(() => {
    setZoomLevel(map.getZoom());
    setMapBounds(map.getBounds());
    //console.log(mapBounds);
  }, [zoomLevel]);

  React.useEffect(() => {
    map.on("mousemove", handleMouseMove);
    return () => {
      map.off("mousemove", handleMouseMove);
    };
  }, [map]);

  return (
    <div
      className="leaflet-bottom leaflet-left"
      style={{ margin: "0px", position: "absolute", background: "#f0f0f0" }}
    >
      &nbsp;(z.<b>{zoomLevel}</b>) --- φ:{coordinates?.lat?.toFixed(6)} ,&nbsp;
      λ:
      {coordinates?.lng?.toFixed(6)} &nbsp;&nbsp;{egsaX} , {egsaY} &nbsp;
    </div>
  );
}

export default CoordinatesControl;
