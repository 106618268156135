import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import AuthService from "../services/auth.service";
import axios from "axios";
//import Button from "@mui/material/Button";
import Button from "react-bootstrap/Button";
import Box from "@mui/material/Box";
import VtextBox from "../components/VtextBox.component";
import VcomboBox from "../components/VcomboBox.component";
import VcheckBox from "../components/VcheckBox.component";
import VdatePicker from "../components/VdatePicker.component";
import VimgBox from "../components/VimgBox.component";
import VtextArea from "../components/VtextArea.component";
import VtextArea3 from "../components/VtextArea3.component";
import GroupBox from "../components/GroupBox.component";

import FormGroup from "@mui/material/FormGroup";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { el } from "date-fns/locale";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import { styled } from "@mui/material/styles";

import { useMap } from "react-leaflet";
import MapHalf from "../components/mapHalf.component";
import { useNavigate } from "react-router-dom";

import proj4 from "proj4";

import { FaMapMarkedAlt } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { GiFruitTree } from "react-icons/gi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BiAddToQueue } from "react-icons/bi";
import { RiSave3Fill } from "react-icons/ri";

import ConfirmBox from "../components/ConfirmBox.component";

import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import QRCode from "qrcode.react";

import Modal from "../components/Modals/Modal_eidos";

/* *********************************** */
export default function GetDendro2() {
  /* ************************ */
  const { id } = useParams();
  /* ************************ */

  //----------- Check Authentication ------------
  const currentUser = AuthService.getCurrentUser();
  const [state, setState] = useState({
    redirect: null,
    userReady: false,
    currentUser: { username: "" },
    roles: {},
  });
  const [roleOk, setRoleOk] = useState(false);
  //----------------------------------------------
  const [formData, setFormData] = useState({});
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  //-------------- History ---------------------------------
  const [oldData, setOldData] = useState({});
  const [changes, setChanges] = useState([]);
  //------------- ComboBox ---------------------------------
  const [kat_xorou, setKat_xorou] = useState([]);
  const [drastir, setDrastir] = useState([]);
  const [kat_drastir, setKat_drastir] = useState([]);
  const [odoi, setOdoi] = useState([]);
  const [eidos, setEidos] = useState([]);
  const [sxKomis, setSxKomis] = useState([]);
  const [pos_ksiransis_fyll, setPos_ksiransis_fyll] = useState([]);
  const [klisi_kormou, setKlisi_kormou] = useState([]);
  const [vathmos_apofloiosis, setVathmos_apofloiosis] = useState([]);
  const [apograf, setApograf] = useState([]);
  const [dim_diam, setDim_diam] = useState([]);
  const [dendrodoxos, setDendrodoxos] = useState([]);
  const [zotikotita, setZotikotita] = useState([]);
  const [systasi, setSystasi] = useState([]);

  const [opt_kat_xorou, setOpt_kat_xorou] = useState([]);
  const [opt_drastir, setOpt_drastir] = useState([]);
  const [opt_kat_drastir, setOpt_kat_drastir] = useState([]);
  const [opt_odoi, setOpt_odoi] = useState([]);
  const [opt_eidos, setOpt_eidos] = useState([]);
  const [opt_sxKomis, setOpt_sxKomis] = useState([]);
  const [opt_pos_ksiransis_fyll, setOpt_pos_ksiransis_fyll] = useState([]);
  const [opt_klisi_kormou, setOpt_klisi_kormou] = useState([]);
  const [opt_vathmos_apofloiosis, setOpt_vathmos_apofloiosis] = useState([]);
  const [opt_apograf, setOpt_apograf] = useState([]);
  const [opt_dim_diam, setOpt_dim_diam] = useState([]);
  const [opt_dendrodoxos, setOpt_Dendrodoxos] = useState([]);
  const [opt_zotikotita, setOpt_Zotikotita] = useState([]);
  const [opt_systasi, setOpt_Systasi] = useState([]);

  const [ektEtosFyt, setEktEtosFyt] = useState(null);
  const [ektD13, setEktD13] = useState(null);
  const [selectedZNDOption, setSelectedZNDOption] = useState("");

  const [file1Exists, setFile1Exists] = useState(false);
  const [file2Exists, setFile2Exists] = useState(false);

  const [anaforaData, setAnaforaData] = useState({});
  const [historyData, setHistoryData] = useState({});

  const [showUpload1, setShowUpload1] = useState(false);
  const [showUpload2, setShowUpload2] = useState(false);
  const [sizeFile1, setSizeFile1] = useState(0);
  const [sizeFile2, setSizeFile2] = useState(0);

  //------------------------------------------------
  //const [modalOpen, setModalOpen] = useState(false);

  //-------------------ConfirmBox-DELETE---------------------------------------
  //---https://www.youtube.com/watch?v=JesTjUcXh8o&ab_channel=ReactDeveloper---
  //-----------------https://github.com/Sanskarraj51/CRUD----------------------
  //---------------------------------------------------------------------------
  //const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  //----------------------------------------------------------
  const [openDelAnafora, setOpenDelAnafora] = useState(false);
  const [delAnaforaData, setDelAnaforaData] = useState({});
  const [idAnaforas, setIdAnaforas] = useState(null);
  //------------------------------------------------
  const [modalOpen, setModalOpen] = useState(false);
  //------------------------------------------------

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return new Date(dateString).toLocaleDateString("el-GR", options);
  };

  //-------------handle ANAFORA -------------
  const createEnergeiaFromAnafora = (id) => {};

  //----openDialogDelAnafora--------------
  const openDialogDelAnafora = (item) => {
    setIdAnaforas(item.id);
    setOpenDelAnafora(true);
  };

  //-------deleteAnafora----
  function deleteAnafora() {
    //console.log("---idAnaforas=", idAnaforas);
    axios
      .delete(`${process.env.REACT_APP_URL}/api/dendra/anafora/${idAnaforas}`)
      .then((res) => {
        setIdAnaforas(null);
        setOpenDelAnafora(false);
        get_anafores(formData.id);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function openDelete(data) {
    setOpen(true);
    setDeleteData(data);
  }

  function deleteDendro() {
    // console.log("--- Ok DELETE ---");
    const alat = formData.apol_thesi_lat;
    const alng = formData.apol_thesi_lon;

    axios
      .delete(`${process.env.REACT_APP_URL}/api/dendra/${deleteData?.id}`)
      .then((res) => {
        navigate(`/Vmap2/${alat}/${alng}`);
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //-------------------------------------------------------

  const handleOptionZNDChange = (event) => {
    setSelectedZNDOption(event.target.value);
  };

  //----------***** 1rst time *****----------------
  useEffect(() => {
    //-------------- Auth -------------------
    if (!currentUser) setState({ redirect: "/home" });
    setState({
      currentUser: currentUser,
      userReady: true,
      roles: currentUser.roles,
    });
    setRoleOk(
      currentUser?.roles.includes("ROLE_MODERATOR") ||
        currentUser?.roles.includes("ROLE_ADMIN")
        ? true
        : false
    );
    //--------------------
    window.scrollTo(0, 0);
    //--------------------

    //------------------dendro/:id-------------------
    axios
      .get(`${process.env.REACT_APP_URL}/api/dendra/${id}`)
      .then((response) => {
        const formData = response.data[0];
        setFormData(formData);
        //-------for History-------------
        setOldData(formData);
        //-----------------------------------
        console.log("formData = ", formData);
        //-----------------------------------
        setKat_xorou(formData.kat_xorou);
        // setZontano(formData.zontano);
        setLat(formData.apol_thesi_lat);
        setLng(formData.apol_thesi_lon);
      })
      .catch((error) => console.error(error));
    //---------------------kat_xorou-----------------------
    axios
      .get(`${process.env.REACT_APP_URL}/api/zlookup/z_kat_xorou`)
      .then((response) => {
        setOpt_kat_xorou(response.data);
      })
      .catch((error) => console.error(error));
    //---------------------drastir-----------------------
    axios
      .get(`${process.env.REACT_APP_URL}/api/zlookup/z_drastir`)
      .then((response) => {
        setOpt_drastir(response.data);
      })
      .catch((error) => console.error(error));
    //---------------------kat_drastir-----------------------
    axios
      .get(`${process.env.REACT_APP_URL}/api/zlookup/z_kat_drastir`)
      .then((response) => {
        setOpt_kat_drastir(response.data);
      })
      .catch((error) => console.error(error));
    //----------------------------odos_id-------------------------
    axios
      .get(`${process.env.REACT_APP_URL}/api/zlookup/z_odoi`)
      .then((response) => {
        setOpt_odoi(response.data);
      })
      .catch((error) => console.error(error));

    //----------------------------z_eidos-------------------------
    axios
      .get(`${process.env.REACT_APP_URL}/api/zlookup/z_eidos`)
      .then((response) => {
        setOpt_eidos(response.data);
        //console.log("---------------------------------", response.data);
      })
      .catch((error) => console.error(error));

    //----------------------------z_sx_komis-------------------------
    axios
      .get(`${process.env.REACT_APP_URL}/api/zlookup/z_sx_komis`)
      .then((response) => {
        setOpt_sxKomis(response.data);
      })
      .catch((error) => console.error(error));

    //-----------------------z_vathmos_apofloiosis-------------------------
    axios
      .get(`${process.env.REACT_APP_URL}/api/zlookup/z_vathmos_apofloiosis`)
      .then((response) => {
        setOpt_vathmos_apofloiosis(response.data);
      })
      .catch((error) => console.error(error));

    //----------------------------z_pos_ksiransis_fyll-------------------------
    axios
      .get(`${process.env.REACT_APP_URL}/api/zlookup/z_pos_ksiransis_fyll`)
      .then((response) => {
        setOpt_pos_ksiransis_fyll(response.data);
      })
      .catch((error) => console.error(error));

    //----------------------------z_klisi_kormou-------------------------
    axios
      .get(`${process.env.REACT_APP_URL}/api/zlookup/z_klisi_kormou`)
      .then((response) => {
        setOpt_klisi_kormou(response.data);
      })
      .catch((error) => console.error(error));

    //----------------------------z_apograf-------------------------
    axios
      .get(`${process.env.REACT_APP_URL}/api/zlookup/z_apograf`)
      .then((response) => {
        setOpt_apograf(response.data);
      })
      .catch((error) => console.error(error));

    //----------------------------z_dim_diam-------------------------
    axios
      .get(`${process.env.REACT_APP_URL}/api/zlookup/z_dim_diam`)
      .then((response) => {
        setOpt_dim_diam(response.data);
      })
      .catch((error) => console.error(error));

    //----------------------------z_dendrodoxos-------------------------
    axios
      .get(`${process.env.REACT_APP_URL}/api/zlookup/z_dendrodoxos`)
      .then((response) => {
        setOpt_Dendrodoxos(response.data);
      })
      .catch((error) => console.error(error));

    //----------------------------z_zotikotita-------------------------
    axios
      .get(`${process.env.REACT_APP_URL}/api/zlookup/z_zotikotita`)
      .then((response) => {
        setOpt_Zotikotita(response.data);
      })
      .catch((error) => console.error(error));

    //----------------------------z_systasi-------------------------
    axios
      .get(`${process.env.REACT_APP_URL}/api/zlookup/z_systasi`)
      .then((response) => {
        setOpt_Systasi(response.data);
      })
      .catch((error) => console.error(error));

    //------------------anafores/:id-------------------
    get_anafores(id);
    //--------------------------------------------------
    get_history(id);
  }, []);

  function get_anafores(a_id) {
    if (a_id) {
      axios
        .get(`${process.env.REACT_APP_URL}/api/dendra/anafora_get/${a_id}`)
        .then((response) => {
          setAnaforaData(response.data);
        })
        .catch((error) => console.error(error));
    }
  }

  function get_history(a_id) {
    if (a_id) {
      axios
        .get(`${process.env.REACT_APP_URL}/api/dendra/history_get/${a_id}`)
        .then((response) => {
          setHistoryData(response.data);
        })
        .catch((error) => console.error(error));
    }
  }

  useEffect(() => {
    //console.log("===anaforaData", anaforaData);
  }, [anaforaData]);

  useEffect(() => {
    Calc_ekt_etos_fyteysis();
  }, [formData.d_1_3, formData.eidos_id]);

  //------------------ekt_etos_fyteysis/:id-------------------
  function Calc_ekt_etos_fyteysis() {
    if (formData.eidos_id) {
      axios
        .get(
          `${process.env.REACT_APP_URL}/api/zlookup/z_eidos_gr/${formData.eidos_id}`
        )
        .then((response) => {
          const vData = response.data;
          const g_r = vData.g_r;
          if (g_r) {
            //-------------------------------------------------------
            const year = new Date(formData.date_apogr).getFullYear();
            const d_1_3 = formData.d_1_3;
            const newEktEtos = parseInt(year - d_1_3 / g_r);
            if (formData.eidos_id === 1) setEktEtosFyt("");
            else setEktEtosFyt(newEktEtos);
            //-------------------------------------------------------
            const today = new Date();
            const tote = new Date(formData.date_apogr);
            //let years = new Date(new Date() - new Date(formData.date_apogr)).getFullYear() - 1970;
            const Yearsdiff = +(
              (today - tote) /
              (1000 * 60 * 60 * 24) /
              365.25
            );
            const newD13 = (formData.d_1_3 + Yearsdiff * g_r).toFixed(0);
            if (formData.eidos_id === 1) setEktD13("");
            else setEktD13(newD13);
            //console.log("newD13", newD13);
          }
        })
        .catch((error) => console.error(error));
    }
  }

  useEffect(() => {
    Calc_ekt_etos_fyteysis();
  }, [formData.eidos_id, formData.d_1_3, formData.cd1, formData.cd2]);

  useEffect(() => {
    if (formData) {
      if (formData.zontano === 1) setSelectedZNDOption("1");
      else if (formData.nekro === 1) setSelectedZNDOption("2");
      else if (formData.den_yparxei === 1) setSelectedZNDOption("3");
      else setSelectedZNDOption("0");
    }
  }, [formData.zontano, formData.nekro, formData.den_yparxei]);

  useEffect(() => {
    if (selectedZNDOption) {
      if (selectedZNDOption === "1") {
        setFormData((prevFormData) => ({
          ...prevFormData,
          zontano: 1,
          nekro: 0,
          den_yparxei: 0,
        }));
      } else if (selectedZNDOption === "2") {
        setFormData((prevFormData) => ({
          ...prevFormData,
          zontano: 0,
          nekro: 1,
          den_yparxei: 0,
        }));
      } else if (selectedZNDOption === "3") {
        setFormData((prevFormData) => ({
          ...prevFormData,
          zontano: 0,
          nekro: 0,
          den_yparxei: 1,
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          zontano: 0,
          nekro: 0,
          den_yparxei: 0,
        }));
      }
    }
  }, [selectedZNDOption]);

  //-------------- For ComboBox ----------------------
  const find_value = (combo_id, mValue) => {
    let selectedOption = null;
    if (!mValue) return "";
    switch (combo_id) {
      case "kat_xorou":
        selectedOption = opt_kat_xorou.find((opt) => opt.id === mValue);
        return selectedOption ? selectedOption.label : "";
      case "drastir_id":
        selectedOption = opt_drastir.find((opt) => opt.id === mValue);
        return selectedOption ? selectedOption.label : "";
      case "kat_drastir_id":
        selectedOption = opt_kat_drastir.find((opt) => opt.id === mValue);
        return selectedOption ? selectedOption.label : "";
      case "odoi":
        selectedOption = opt_odoi.find((opt) => opt.id === mValue);
        return selectedOption ? selectedOption.label : "";
      case "eidos":
        selectedOption = opt_eidos.find((opt) => opt.id === mValue);
        return selectedOption ? selectedOption.label : "";
      case "sxKomis":
        selectedOption = opt_sxKomis.find((opt) => opt.id === mValue);
        return selectedOption ? selectedOption.label : "";
      case "pos_ksiransis_fyll":
        selectedOption = opt_pos_ksiransis_fyll.find(
          (opt) => opt.id === mValue
        );
        return selectedOption ? selectedOption.label : "";
      case "klisi_kormou":
        selectedOption = opt_klisi_kormou.find((opt) => opt.id === mValue);
        return selectedOption ? selectedOption.label : "";
      case "vathmos_apofloiosis":
        selectedOption = opt_vathmos_apofloiosis.find(
          (opt) => opt.id === mValue
        );
        return selectedOption ? selectedOption.label : "";
      case "apograf":
        selectedOption = opt_apograf.find((opt) => opt.id === mValue);
        return selectedOption ? selectedOption.label : "";
      case "dim_diam":
        selectedOption = opt_dim_diam.find(
          (opt) => opt.id === parseInt(mValue)
        );
        return selectedOption ? selectedOption.label : "";
      case "dendrodoxos":
        selectedOption = opt_dendrodoxos.find(
          (opt) => opt.id === parseInt(mValue)
        );
        return selectedOption ? selectedOption.label : "";
      case "zotikotita":
        selectedOption = opt_zotikotita.find(
          (opt) => opt.id === parseInt(mValue)
        );
        return selectedOption ? selectedOption.label : "";
      case "systasi":
        selectedOption = opt_systasi.find((opt) => opt.id === parseInt(mValue));
        return selectedOption ? selectedOption.label : "";

      default:
        return "";
    }
  };

  function get_clearFileName(fullName) {
    if (fullName.startsWith("IMG_")) {
      return fullName;
    } else if (fullName.startsWith("gT_")) {
      return fullName;
    } else if (fullName.includes("/IMG_")) {
      var parts = fullName.split("/IMG_");
      return "IMG_" + parts[1];
    } else if (fullName.includes("/gT_")) {
      var parts = fullName.split("/gT_");
      return "gT_" + parts[1];
    }
  }

  //--------- check images [A]-------------
  useEffect(() => {
    if (
      formData.foto_dendrou_path &&
      !formData.foto_dendrou_path.startsWith(process.env.REACT_APP_URL) &&
      !formData.foto_dendrou_path.startsWith(process.env.REACT_APP_FOTO_URL)
    ) {
      let init_path = formData.foto_dendrou_path;
      let filename = get_clearFileName(init_path);
      let url_local = process.env.REACT_APP_URL + "/api/img11/" + filename;
      let url_out = process.env.REACT_APP_FOTO_URL + filename;
      let isLocal = false;
      try {
        const src = require("../../../treePhotos/" + filename);
        isLocal = true;
      } catch (err) {
        isLocal = false;
      }
      let finalUrl = isLocal ? url_local : url_out;
      setFormData((prevFormData) => ({
        ...prevFormData,
        foto_dendrou_path: finalUrl,
      }));
      //--------------------------------------
    }
  }, [formData.foto_dendrou_path]);

  //--------- check images [B]-------------
  useEffect(() => {
    if (
      formData.foto_dendrodox_path &&
      !formData.foto_dendrodox_path.startsWith(process.env.REACT_APP_URL) &&
      !formData.foto_dendrodox_path.startsWith(process.env.REACT_APP_FOTO_URL)
    ) {
      let init_path = formData.foto_dendrodox_path;
      let filename = get_clearFileName(init_path);
      let url_local = process.env.REACT_APP_URL + "/api/img11/" + filename;
      let url_out = process.env.REACT_APP_FOTO_URL + filename;
      let isLocal = false;
      try {
        const src = require("../../../treePhotos/" + filename);
        isLocal = true;
      } catch (err) {
        isLocal = false;
      }
      let finalUrl = isLocal ? url_local : url_out;
      setFormData((prevFormData) => ({
        ...prevFormData,
        foto_dendrodox_path: finalUrl,
      }));
      //--------------------------------------
    }
  }, [formData.foto_dendrodox_path]);

  //*************************************** */
  //*************************************** */
  async function handleSubmit(event) {
    //*******************************/
    event.preventDefault();
    console.log(formData);
    //-------Geometry--------------------;
    //const lng = formData.apol_thesi_lon;
    //const lat = formData.apol_thesi_lat;
    //formData.geom = `POINT(${lng} ${lat})`;
    setOpenSave(true);
    //---- sql: UPDATE -----------------------------
    await axios
      .put(`${process.env.REACT_APP_URL}/api/dendra/${id}`, formData)
      .then((response) => console.log(response))

      .catch((error) => console.error(error));
    //----------------------------------------------
    Calc_ekt_etos_fyteysis();
    //----------------------------------------------
    save_history();
    //----------------------------------------------
    setOpenSave(false);
    //----------------------------------------------
    window.location.reload();
    //----------------------------------------------
  }

  const save_history = async () => {
    //---------- HISTORY ---------------------------
    //---------- search for changes ----------------
    const updatedFields = Object.entries(formData).reduce(
      (result, [key, value]) => {
        if (oldData[key] !== value) {
          result.push({ key, old_value: oldData[key], new_value: value });
        }
        return result;
      },
      []
    );
    if (updatedFields.length > 0) {
      // Store changes in text variable
      const changesText = updatedFields
        .map(
          ({ key, old_value, new_value }) =>
            `${key}, [${old_value}] --> [${new_value}]`
        )
        .join("\n");

      //console.log(changesText);
      //--------------- 1. Save in DB -------------------
      const v_id = formData.id;
      const vuser = currentUser.username;
      const vdiff = changesText;

      try {
        if (vdiff.trim() !== "") {
          await axios
            .post(`${process.env.REACT_APP_URL}/api/dendra/history_add/`, {
              oldData,
              v_id,
              vuser,
              vdiff,
            })
            .then((response) => console.log(response));
        }
      } catch (err) {
        console.log(err);
      }
      //---------- End HISTORY -----------------------
    }
  };

  const handleTextBox = (TextBox_id, newValue) => {
    if (
      TextBox_id === "h" ||
      TextBox_id === "hcb" ||
      TextBox_id === "r1" ||
      TextBox_id === "r2" ||
      TextBox_id === "r3" ||
      TextBox_id === "r4" ||
      TextBox_id === "rd1" ||
      TextBox_id === "rd2"
    ) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        //[TextBox_id]: parseFloat(newValue),
        [TextBox_id]: newValue,
      }));
    } else if (
      TextBox_id === "id" ||
      TextBox_id === "old_id" ||
      TextBox_id === "oik_tetr" ||
      TextBox_id === "ar" ||
      TextBox_id === "tk" ||
      TextBox_id === "22etos_fyteysis" ||
      TextBox_id === "d_1_3"
    ) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [TextBox_id]: parseInt(newValue),
      }));
    } else if (
      TextBox_id === "zzzfoto_dendrou_path" ||
      TextBox_id === "zzzfoto_dendrodox_path"
    ) {
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [TextBox_id]: newValue,
      }));
    }
  };

  const handleTextArea = (TextAreaId, newValue) => {
    //console.log("(TextAreaId, newValue) = ", TextAreaId, newValue);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [TextAreaId]: newValue,
    }));
    //console.log(formData);
  };

  const handleComboBox = (combo_id, value) => {
    //console.log("=========", combo_id, value);
    //console.log("=========", formData);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [combo_id]: value ? value.id : 0,
    }));
    //setFormData({ ...formData, [combo_id]: value ? value.id : 0 });
    //console.log("=========", combo_id, value);
    //console.log("=========", formData);
  };

  const handleCheckBoxOnChange = (event) => {
    const { name, value, checked } = event.target;
    setFormData({ ...formData, [name]: checked === true ? 1 : 0 });
  };

  const handleDateChange = (name, value) => {
    const isoDate = value.toISOString();
    //const dateStringWithoutTimeZone = isoDate.slice(0, 10).replace('T', ' ');
    //const dateStringWithoutTimeZone = isoDate.replace('T', ' ').replace('Z', ' ');
    setFormData((prevFormData) => ({ ...prevFormData, [name]: isoDate }));
    //setFormData((prevFormData) => ({ ...prevFormData, [name]: dateStringWithoutTimeZone }));
  };

  const MyAccordionsumA = styled(AccordionSummary)({
    backgroundColor: "#c2d6d6",
    minHeight: "20px",
    maxHeight: "40px",
  });

  const MyAccordionsumB = styled(AccordionSummary)({
    backgroundColor: "#a3c2c2",
    minHeight: "20px",
    maxHeight: "40px",
  });

  const MyAccordionsumC = styled(AccordionSummary)({
    backgroundColor: "#ffb84d",
    minHeight: "20px",
    maxHeight: "40px",
  });

  const MyAccordionsumD = styled(AccordionSummary)({
    backgroundColor: "#ffb3b3",
    minHeight: "20px",
    maxHeight: "40px",
  });

  const MyAccordionsumD2 = styled(AccordionSummary)({
    backgroundColor: "#ffcccc",
    minHeight: "20px",
    maxHeight: "40px",
  });

  const divStyleComboAllo = { padding: "13px 0 0 0", marginLeft: "1px" };

  let navigate = useNavigate();

  //===========[ geometry Edit ]==============================
  const [mapEventActive, setMapEventActive] = useState(false);
  const [neoLatLng, setNeoLatLng] = useState(null);
  const [showSaveMsg, setShowSaveMsg] = useState(null);

  const handleMapEventClick = () => {
    setNeoLatLng(null); // Clear the previously retrieved latlng value
    setShowSaveMsg(null);
    setMapEventActive(true);
  };

  const handleMapClick = (latlng) => {
    if (latlng) {
      setNeoLatLng(latlng);
    }
    setMapEventActive(false); // Deactivate the map event
  };
  //-------------- EGSA ----(need import proj4)----------------
  const Projection_2100 = "EPSG:2100"; // Greek Grid projection
  const EPSG_2100 = proj4(Projection_2100);
  //---------------------------
  function formatY(latlong) {
    const [x, y] = EPSG_2100.forward([latlong.lng, latlong.lat]);
    return Number(y.toFixed(2));
  }

  function formatX(latlong) {
    const [x, y] = EPSG_2100.forward([latlong.lng, latlong.lat]);
    return Number(x.toFixed(2));
  }
  //-----------------------------
  const saveNewPosition = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      apol_thesi_lat: neoLatLng.lat,
      apol_thesi_lon: neoLatLng.lng,
      apol_thesi_x: formatX(neoLatLng),
      apol_thesi_y: formatY(neoLatLng),
      geom: `POINT(${neoLatLng.lng} ${neoLatLng.lat})`,
    }));
    setShowSaveMsg(true);
    console.log(formData);
  };

  useEffect(() => {
    setLat(formData.apol_thesi_lat);
    setLng(formData.apol_thesi_lon);
  }, [formData.apol_thesi_lat, formData.apol_thesi_lon]);
  //===========[ end geometry Edit ]===========================

  const [openSave, setOpenSave] = React.useState(false);
  const [openUpload, setOpenUpload] = React.useState(false);
  const [files, setFiles] = useState({ file1: null, file2: null });
  const [upload1, setUpload1] = useState(false);
  const [upload2, setUpload2] = useState(false);
  const [upFile1, setUpFile1] = useState("");
  const [upFile2, setUpFile2] = useState("");

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      let curFile = e.target.files[0];
      //console.log("e.target = ", e.target)
      //--------------size-----------------
      if (e.target.id === "file1") {
        setSizeFile1(curFile.size);
      } else if (e.target.id === "file2") {
        setSizeFile2(curFile.size);
      }
      //------------------------------------
      if (curFile.size < 1048577) {
        if (e.target.id === "file1") {
          setShowUpload1(true);
        } else if (e.target.id === "file2") {
          setShowUpload2(true);
        }
        //---------------------------------
        setFiles((prevFiles) => ({
          ...prevFiles,
          [e.target.id]: e.target.files[0],
        }));
        //---------------------------------
      } else {
        if (e.target.id === "file1") {
          setShowUpload1(false);
        } else if (e.target.id === "file2") {
          setShowUpload2(false);
        }
      }
    }
  };

  // useEffect(() => {
  //   console.log("------files = ", files);
  // }, [files]);

  //--------------Upload Foto [A]--------------
  const uploadFile_A = async (e) => {
    e.preventDefault();
    const file = files.file1;
    setOpenUpload(true);
    try {
      if (file) {
        const mformData = new FormData();
        mformData.append("file", file);
        mformData.append("id", formData.id + "_A_");

        await axios
          .post(
            `${process.env.REACT_APP_URL}/api/dendra/uploadfoto/`,
            mformData
          )
          .then((response) => {
            setUpload1(true);
            setUpFile1(response.data);
          });
      }
    } catch (error) {
      console.error(error);
    }
    setOpenUpload(false);
  };

  //--------------Upload Foto [B]-------------
  const uploadFile_B = async (e) => {
    e.preventDefault();
    const file = files.file2;
    setOpenUpload(true);
    try {
      if (file) {
        const mformData = new FormData();
        mformData.append("file", file);
        mformData.append("id", formData.id + "_B_");

        await axios
          .post(
            `${process.env.REACT_APP_URL}/api/dendra/uploadfoto/`,
            mformData
          )
          .then((response) => {
            setUpload2(true);
            setUpFile2(response.data);
          });
      }
    } catch (error) {
      console.error(error);
    }
    setOpenUpload(false);
  };

  //-----formData.(foto_dendrou_path, foto_dendrodox_path)------
  useEffect(() => {
    //--------------------------------------------------------
    var init_path = `${process.env.REACT_APP_URL}/api/img11/`;
    //--------------------[A]---------------------
    if (upload1) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        foto_dendrou_path: init_path + upFile1,
      }));
    }
    //--------------------[B]---------------------
    if (upload2) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        foto_dendrodox_path: init_path + upFile2,
      }));
    }
  }, [upload1, upload2]);

  const [histFileList, setHistFileList] = useState([]);

  //======== convert History photo ===========================

  const foto_link = (str) => {
    //----------------------------Split in Lines--------------
    const lines = str.split("\n");
    //--------------------------------------------------------
    const prefix1 = "foto_dendrou_path, [";
    const prefix2 = "foto_dendrodox_path, [";

    const fotosLines = lines.filter(
      (line) =>
        line.trim().startsWith(prefix1) || line.trim().startsWith(prefix2)
    );
    fotosLines.map((fline) => {
      let newfline = fline;

      if (fline.startsWith(prefix1)) {
        newfline = newfline.slice(prefix1.length);
      } else if (fline.startsWith(prefix2)) {
        newfline = newfline.slice(prefix2.length);
      }
      const endIndex = newfline.indexOf("] -->");
      if (endIndex !== -1) {
        // Extract the content between the prefix (if any) and " -->"
        newfline = newfline.slice(0, endIndex);
      }
      let filename = get_clearFileName(newfline.trim());
      if (filename) {
        if (filename?.trim() !== "") {
          if (!histFileList.includes(filename)) {
            setHistFileList([...histFileList, filename]);
          }
        }
      }
      //histFileList.push(filename);
    });
    //console.log("===histFileList===", histFileList);
    //str = "<b>vvvvvv</b>" + str;
    return str;
  };

  const Refresh_Eidos = () => {
    console.log("----Add New Eidos-----");
    //----------------------------z_eidos-------------------------
    axios
      .get(`${process.env.REACT_APP_URL}/api/zlookup/z_eidos`)
      .then((response) => {
        setOpt_eidos(response.data);
        //console.log("---------------------------------", response.data);
      })
      .catch((error) => console.error(error));
  };

  //=================================URL για QR===============
  const currentPageURL = window.location.href;
  //====================================================

  return (
    <>
      <style>{"body { background-color: #e0ebeb; }"}</style>

      {/* ***************************************************** */}
      {/* *********************[ modalOpen ]******************* */}
      {/* *******************[ Add new Eidos ]***************** */}
      {/* ***************************************************** */}
      {modalOpen && (
        <Modal
          setModalOpen={setModalOpen}
          mTitle="Εισαγωγή Νέου Είδους Δένδρου"
          mData={{}}
          onSaveSuccess={Refresh_Eidos}
        />
      )}
      {/* *******************[ Add new Eidos ]***end********* */}
      {/* ************************************************************* */}

      <form onSubmit={handleSubmit} style={{ backgroundColor: "#e0ebeb" }}>
        <Box>
          <FormGroup>
            <div
              style={{ display: "flex", alignItems: "center", top: "10px" }}
              className="col -6"
            >
              <VdatePicker
                name="date_apogr"
                label="Ημερομ. Απογραφής"
                value={formData?.date_apogr}
                onChange={handleDateChange}
                disabled={!currentUser}
              />

              <VcomboBox
                id="id_apogr"
                options={opt_apograf}
                value={find_value("apograf", formData.id_apogr)}
                label="Απογραφέας"
                onChange={handleComboBox}
                disabled={!currentUser}
                width="250px"
                className="col-4"
                top="00px"
              />
              {/* ----- */}
              {formData.apol_thesi_lat && (
                <>
                  <Button
                    onClick={() => openDelete(formData)}
                    //to={"#"}
                    className="btn btn-danger"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginLeft: "auto",
                    }}
                    disabled={!roleOk}
                  >
                    {/* ----------- DELETE button --------------- */}
                    <RiDeleteBin6Line
                      color="#AEFDCE"
                      //style={{ top: "0px", position: "relative" }}
                    />{" "}
                  </Button>
                  {/* ---------------Confirm------------------- */}
                  <ConfirmBox
                    open={open}
                    closeDialog={() => setOpen(false)}
                    title={`Διαγραφή δένδρου (${formData?.id})`}
                    text={`Επιθυμείτε τη Διαγραφή του δένδρου με id = ${formData?.id}
                           \n(μετά την Διαγραφή και την επιστροφή στον χάρτη,
                            κάντε ανανέωση / refresh σελίδας)`}
                    deleteFunction={deleteDendro}
                  />
                  {/* -------------------------------------- */}
                </>
              )}
              <div>
                <br />
              </div>
            </div>
          </FormGroup>
          {/* ----------- Map ------------------ */}
          <Accordion defaultExpanded>
            <MyAccordionsumB
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography component="div" sx={{ margin: "0px" }}>
                <b>Χάρτης</b>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                {formData.apol_thesi_lat && (
                  <Link
                    to={`/Vmap/${formData.id}`}
                    // className="btn btn-outline-secondary"
                    className="btn btn-secondary"
                  >
                    {/* ---------------- XARTHS button ---------------- */}
                    <FaMapMarkedAlt
                      color="#AEFDCE"
                      style={{ top: "-2px", position: "relative" }}
                    />{" "}
                    Χάρτης
                    {` (id=${formData.id})`}
                  </Link>
                )}
              </Typography>
            </MyAccordionsumB>
            <AccordionDetails>
              {/* ========================== Map ======================= */}
              {/* ---------- component MapHalf ---------- */}
              {lat && lng && (
                <MapHalf
                  lat={lat}
                  lng={lng}
                  onMapClick={handleMapClick}
                  mapEventActive={mapEventActive}
                />
              )}
              <hr />
              {/* ------------------ Αλλαγή Θέσης button------------ */}
              <Link
                className={mapEventActive ? "btn btn-danger" : "btn btn-info"}
                onClick={handleMapEventClick}
              >
                {mapEventActive ? "Δείξτε Σημείο" : `Αλλαγή Θέσης`}
              </Link>
              &nbsp;&nbsp;
              {neoLatLng && (
                <>
                  &nbsp;&nbsp;
                  <input
                    type="text"
                    size="35"
                    style={{ fontSize: "12px" }}
                    value={
                      neoLatLng ? `${neoLatLng.lat}, ${neoLatLng.lng}` : ""
                    }
                    readOnly
                  />
                  &nbsp;&nbsp;
                  <Link className="btn btn-primary" onClick={saveNewPosition}>
                    Οριστικοποίηση
                  </Link>
                  {showSaveMsg && (
                    <span style={{ fontSize: "14px" }}>
                      &nbsp;&nbsp;Η τιμή Οριστικοποιήθηκε. Θα καταχωρηθεί με την{" "}
                      <b>Αποθήκευση</b>
                    </span>
                  )}
                </>
              )}
            </AccordionDetails>
          </Accordion>
          <hr />
          {/* =======================Γενικά στοιχεία===================== */}
          <Accordion defaultExpanded>
            <MyAccordionsumA
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Γενικά Στοιχεία Δέντρου</Typography>
            </MyAccordionsumA>
            <AccordionDetails>
              {formData.id && (
                <Typography component="div">
                  <div style={{ position: "inherit" }}>
                    <div style={{ fontSize: "13px" }}>&nbsp;</div>
                    {/* -----------------------------------   */}
                    <div className="row" style={divStyleComboAllo}>
                      <VcomboBox
                        id="kat_xorou"
                        options={opt_kat_xorou}
                        value={find_value("kat_xorou", formData.kat_xorou)}
                        label="Κατηγορία Χώρου"
                        onChange={handleComboBox}
                        disabled={!currentUser}
                        width="300px"
                      />
                      &nbsp;&nbsp;
                      {/*console.log(formData); */}
                      <VtextBox
                        id="kat_xor_allo"
                        name="kat_xor_allo"
                        label="Άλλο:"
                        value={
                          formData?.kat_xor_allo ? formData.kat_xor_allo : ""
                        }
                        width={380}
                        onChange={handleTextBox}
                        disabled={
                          find_value("kat_xorou", formData.kat_xorou) !== "Άλλο"
                        }
                      />
                    </div>
                    <div>&nbsp;</div>
                    <VcomboBox
                      id="odos_id"
                      options={opt_odoi}
                      value={find_value("odoi", formData.odos_id)}
                      label="Οδός"
                      onChange={handleComboBox}
                      disabled={!currentUser}
                      width="300px"
                    />
                    <div>&nbsp;</div>
                    <VcomboBox
                      id="dim_diam"
                      options={opt_dim_diam}
                      value={find_value("dim_diam", formData.dim_diam)}
                      label="Δημοτικό Διαμέρισμα"
                      onChange={handleComboBox}
                      disabled={!currentUser}
                      width="300px"
                    />
                    <div>&nbsp;</div>
                    <VtextBox
                      id="oik_tetr"
                      name="oik_tetr"
                      label="Οικοδομικό Τετράγωνο"
                      value={formData?.oik_tetr ? formData?.oik_tetr : ""}
                      width={150}
                      onChange={handleTextBox}
                      disabled={!currentUser}
                    />
                    &nbsp;&nbsp;
                    <VtextBox
                      id="ar"
                      name="ar"
                      label="Αριθμός"
                      value={formData?.ar ? formData?.ar : ""}
                      width={90}
                      onChange={handleTextBox}
                      disabled={!currentUser}
                    />
                    &nbsp;&nbsp;
                    <VtextBox
                      id="tk"
                      name="tk"
                      label="Τ.Κ."
                      value={formData?.tk ? formData?.tk : ""}
                      width={150}
                      onChange={handleTextBox}
                      disabled={!currentUser}
                    />
                  </div>
                  <hr />
                  <GroupBox title="Συντεταγμένες :">
                    {formData.apol_thesi_x && formData.apol_thesi_lat && (
                      <>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (ΕΓΣΑ87) : &nbsp; X
                        = {formData.apol_thesi_x.toFixed(2)} ,&nbsp; Y ={" "}
                        {formData.apol_thesi_y.toFixed(2)}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;. .
                        .&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (wgs84) : &nbsp; φ ={" "}
                        {formData.apol_thesi_lat.toFixed(6)} ,&nbsp; λ ={" "}
                        {formData.apol_thesi_lon.toFixed(6)}
                      </>
                    )}
                  </GroupBox>
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>
          <hr />
          {/* ========================= Ειδικά στοιχεία ========================= */}
          <Accordion defaultExpanded>
            <MyAccordionsumB
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Ειδικά Στοιχεία Δέντρου</Typography>
            </MyAccordionsumB>
            <AccordionDetails
              sx={{
                backgroundColor: "#f9f9f9",
              }}
            >
              {formData.id && (
                <Typography component="div">
                  <div>&nbsp;</div>
                  <VtextBox
                    id="id"
                    name="id"
                    label="ID Δένδρου:"
                    textAlign="center"
                    value={formData?.id ? formData?.id : ""}
                    width={120}
                    onChange={handleTextBox}
                    disabled={!currentUser}
                  />
                  &nbsp;&nbsp;
                  <VtextBox
                    id="old_id"
                    name="old_id"
                    label="(old) ID:"
                    textAlign="center"
                    value={formData?.old_id ? formData?.old_id : ""}
                    width={120}
                    onChange={handleTextBox}
                    disabled={!currentUser}
                  />
                  <div>&nbsp;</div>
                  <VtextBox
                    id="kodikos"
                    name="kodikos"
                    label="Κωδικός μητρώου:"
                    value={formData?.kodikos ? formData?.kodikos : ""}
                    width={560}
                    onChange={handleTextBox}
                    disabled={!currentUser}
                  />
                  <div style={{ fontSize: "5px" }}>&nbsp;</div>
                  {selectedZNDOption && (
                    <Typography
                      component="div"
                      style={{ padding: "20px 10px 0px 0px" }}
                    >
                      <GroupBox
                        title={"Κατάσταση στην οποία βρίσκεται :"}
                        backgroundColor="#f7f7f7"
                      >
                        {/* ---------------- Radio boxes ---------------------- */}
                        <RadioGroup
                          aria-label="Options"
                          name="z_n_d"
                          value={selectedZNDOption}
                          onChange={handleOptionZNDChange}
                          row // Add the 'row' attribute for horizontal alignment
                          style={{ paddingLeft: 100 }}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Ζωντανό"
                          />
                          &nbsp;&nbsp;&nbsp;
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Νεκρό"
                          />
                          &nbsp;&nbsp;&nbsp;
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Δεν υπάρχει"
                          />
                        </RadioGroup>
                      </GroupBox>
                    </Typography>
                  )}
                  <div style={{ fontSize: "10px" }}>&nbsp;</div>
                  {/* --------------------------------------------- */}
                  <GroupBox title={""} backgroundColor="#ffe6cc">
                    <div className="row">
                      <div className="col-11">
                        <VcomboBox
                          id="eidos_id"
                          options={opt_eidos}
                          value={find_value("eidos", formData.eidos_id)}
                          label="Είδος Δέντρου"
                          //getOptionSelected={getOptionSelected}
                          onChange={handleComboBox}
                          width={480}
                          disabled={!currentUser}
                          style={{ maxHeight: "40px" }}
                        />
                      </div>
                      <div className="col-1">
                        {/* ------------------[ Add Eidos ]------------------ */}
                        <Button
                          onClick={() => {
                            setModalOpen(true);
                          }}
                          className="btn btn-success"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginLeft: "auto",
                          }}
                        >
                          <BiAddToQueue
                            color="#AEFDCE"
                            //style={{ top: "0px", position: "relative" }}
                          />{" "}
                        </Button>
                        {/* ------------------[ Add ]------------------ */}
                      </div>
                    </div>
                  </GroupBox>
                  <div style={{ fontSize: "5px" }}>&nbsp;</div>
                  {/* --------------------------------------------- */}
                  <VtextBox
                    id="etos_fyteysis"
                    name="etos_fyteysis"
                    label="Έτος φύτευσης"
                    textAlign="right"
                    value={
                      formData?.etos_fyteysis ? formData?.etos_fyteysis : ""
                    }
                    width={150}
                    onChange={handleTextBox}
                    disabled={!currentUser}
                  />
                  &nbsp;&nbsp;
                  <VtextBox
                    id="d_1_3"
                    name="d_1_3"
                    label="d1,3(cm)"
                    textAlign="right"
                    value={formData?.d_1_3 ? formData?.d_1_3 : ""}
                    width={100}
                    onChange={handleTextBox}
                    disabled={!currentUser}
                    style={{ backgroundColor: "#ffe6cc" }}
                  />
                  &nbsp;&nbsp;
                  <VtextBox
                    id="h"
                    name="h"
                    label="h(m)"
                    textAlign="right"
                    value={formData?.h ? formData?.h : ""}
                    width={100}
                    onChange={handleTextBox}
                    disabled={!currentUser}
                    style={{ backgroundColor: "#ffe6cc" }}
                  />
                  &nbsp;&nbsp;&nbsp;
                  {/* {console.log("ektEtosFyt===", ektEtosFyt)} */}
                  <VtextBox
                    id="ektEtosFyt"
                    name="ektEtosFyt"
                    label="Εκτίμηση Έτους Φύτευσης"
                    textAlign="right"
                    //value={(ektEtosFyt === null) ? "" : `${ektEtosFyt}`}
                    value={`${ektEtosFyt}`}
                    width={190}
                    onChange={handleTextBox}
                    disabled={!currentUser}
                    style={{ backgroundColor: "#d9e6f2" }}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <VtextBox
                    id="ektD13"
                    name="ektD13"
                    label="Εκτίμηση d1.3(cm) σήμερα"
                    textAlign="right"
                    //value={(ektEtosFyt === null) ? "" : `${ektEtosFyt}`}
                    value={`${ektD13}`}
                    width={190}
                    onChange={handleTextBox}
                    disabled={!currentUser}
                    style={{ backgroundColor: "#d9e6f2" }}
                  />
                  <div style={{ fontSize: "10px" }}>&nbsp;</div>
                  {/* --------------------------------------------- */}
                  <GroupBox title={""} backgroundColor="#ffe6cc">
                    <VcomboBox
                      id="sxima_komis_id"
                      options={opt_sxKomis}
                      value={find_value("sxKomis", formData.sxima_komis_id)}
                      label="Σχήμα Κόμης"
                      //getOptionSelected={getOptionSelected}
                      onChange={handleComboBox}
                      disabled={!currentUser}
                      width="400px"
                    />
                  </GroupBox>
                  <div style={{ fontSize: "5px" }}>&nbsp;</div>
                  <VtextBox
                    id="hcb"
                    name="hcb"
                    label="hcb(m)"
                    textAlign="right"
                    value={formData?.hcb ? formData?.hcb : ""}
                    onChange={handleTextBox}
                    disabled={!currentUser}
                    style={{ backgroundColor: "#ffe6cc" }}
                    className="col-1"
                  />
                  &nbsp;&nbsp;
                  <VtextBox
                    id="rd1"
                    name="rd1"
                    label="CD1"
                    textAlign="right"
                    value={formData?.rd1 ? formData?.rd1 : ""}
                    className="col-1"
                    onChange={handleTextBox}
                    disabled={!currentUser}
                    style={{ backgroundColor: "#ffe6cc" }}
                  />
                  &nbsp;&nbsp;
                  <VtextBox
                    id="rd2"
                    name="rd2"
                    label="CD2"
                    textAlign="right"
                    value={formData?.rd2 ? formData?.rd2 : ""}
                    className="col-1"
                    onChange={handleTextBox}
                    disabled={!currentUser}
                    style={{ backgroundColor: "#ffe6cc" }}
                  />
                  <div style={{ fontSize: "15px" }}>&nbsp;</div>
                  {/* ---------------------------------------------- */}
                  <VtextBox
                    id="r1"
                    name="r1"
                    label="R1(m)"
                    textAlign="right"
                    value={formData?.r1 ? formData?.r1 : ""}
                    width={100}
                    onChange={handleTextBox}
                    disabled={!currentUser}
                  />
                  &nbsp;&nbsp;
                  <VtextBox
                    id="r2"
                    name="r2"
                    label="R2(m)"
                    textAlign="right"
                    value={formData?.r2 ? formData?.r2 : ""}
                    width={100}
                    onChange={handleTextBox}
                    disabled={!currentUser}
                  />
                  &nbsp;&nbsp;
                  <VtextBox
                    id="r3"
                    name="r3"
                    label="R3(m)"
                    textAlign="right"
                    value={formData?.r3 ? formData?.r3 : ""}
                    width={100}
                    onChange={handleTextBox}
                    disabled={!currentUser}
                  />
                  &nbsp;&nbsp;
                  <VtextBox
                    id="r4"
                    name="r4"
                    label="R4(m)"
                    textAlign="right"
                    value={formData?.r4 ? formData?.r4 : ""}
                    width={100}
                    onChange={handleTextBox}
                    disabled={!currentUser}
                  />
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>
          <hr />
          {/* ====================== Κατάσταση κόμης ==========================*/}
          <Accordion>
            <MyAccordionsumA
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Κατάσταση Κόμης</Typography>
            </MyAccordionsumA>
            <AccordionDetails>
              <Typography component="div">
                <VcheckBox
                  id="apoxrom_komis"
                  name="apoxrom_komis"
                  checked={formData?.apoxrom_komis === 1}
                  label="Αποχρωματισμός Κόμης"
                  onChange={handleCheckBoxOnChange}
                  disabled={!currentUser}
                  className="col-3"
                />
                <VcheckBox
                  id="thysanoi_komis"
                  name="thysanoi_komis"
                  checked={formData?.thysanoi_komis === 1}
                  label="Θύσανοι Κόμης"
                  onChange={handleCheckBoxOnChange}
                  disabled={!currentUser}
                  className="col-3"
                />
                <VcheckBox
                  id="fylloptosi"
                  name="fylloptosi"
                  checked={formData?.fylloptosi === 1}
                  label="Φυλλόπτωση"
                  onChange={handleCheckBoxOnChange}
                  disabled={!currentUser}
                  className="col-3"
                />
                <div>&nbsp;</div>
                <VcheckBox
                  id="sipsi_kladion"
                  name="sipsi_kladion"
                  checked={formData?.sipsi_kladion === 1}
                  label="Σήψη κλαδιών"
                  onChange={handleCheckBoxOnChange}
                  disabled={!currentUser}
                  className="col-3"
                />
                <VcheckBox
                  id="thrafsi_kladion"
                  name="thrafsi_kladion"
                  checked={formData?.thrafsi_kladion === 1}
                  label="Θραύση Κλαδιών"
                  onChange={handleCheckBoxOnChange}
                  disabled={!currentUser}
                  className="col-3"
                />
                <VcheckBox
                  id="ksiransi_kladion"
                  name="ksiransi_kladion"
                  checked={formData?.ksiransi_kladion === 1}
                  label="Ξήρανση κλαδιών"
                  onChange={handleCheckBoxOnChange}
                  disabled={!currentUser}
                  className="col-3"
                />
                <div>&nbsp;</div>
                <VcomboBox
                  id="pos_ksiransis_fyll"
                  options={opt_pos_ksiransis_fyll}
                  value={find_value(
                    "pos_ksiransis_fyll",
                    formData.pos_ksiransis_fyll_id
                  )}
                  label="Ποσοστό Ξήρανσης Φύλλων"
                  //getOptionSelected={getOptionSelected}
                  onChange={handleComboBox}
                  disabled={!currentUser}
                  width="350px"
                />
              </Typography>
            </AccordionDetails>
          </Accordion>
          <hr />
          {/* ====================== Κατάσταση κορμού ===========================*/}
          <Accordion>
            <MyAccordionsumB
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Κατάσταση Κορμού</Typography>
            </MyAccordionsumB>
            <AccordionDetails>
              {formData.id && (
                <Typography component="div">
                  <div className="row" style={divStyleComboAllo}></div>
                  <VcomboBox
                    id="klisi_kormou"
                    options={opt_klisi_kormou}
                    value={find_value("klisi_kormou", formData.klisi_kormou_id)}
                    label="Κλίση κορμού"
                    //getOptionSelected={getOptionSelected}
                    onChange={handleComboBox}
                    disabled={!currentUser}
                    width="300px"
                  />
                  <VcheckBox
                    id="sipsi_kormou"
                    name="sipsi_kormou"
                    checked={formData?.sipsi_kormou === 1}
                    label="Σήψη κορμού"
                    onChange={handleCheckBoxOnChange}
                    disabled={!currentUser}
                    className="col-3"
                  />
                  <VcheckBox
                    id="sipsi_vasis"
                    name="sipsi_vasis"
                    checked={formData?.sipsi_vasis === 1}
                    label="Σήψη Βάσης"
                    onChange={handleCheckBoxOnChange}
                    disabled={!currentUser}
                    className="col-3"
                  />
                  <VcheckBox
                    id="strevlotita"
                    name="strevlotita"
                    checked={formData?.strevlotita === 1}
                    label="Στρεβλότητα"
                    onChange={handleCheckBoxOnChange}
                    disabled={!currentUser}
                    className="col-3"
                  />
                  <VcheckBox
                    id="eksogkomata"
                    name="eksogkomata"
                    checked={formData?.eksogkomata === 1}
                    label="Εξογκώματα"
                    onChange={handleCheckBoxOnChange}
                    disabled={!currentUser}
                    className="col-2"
                  />
                  <div>&nbsp;</div>
                  <VcheckBox
                    id="kormovlastimata"
                    name="kormovlastimata"
                    checked={formData?.kormovlastimata === 1}
                    label="Κορμοβλαστήματα"
                    onChange={handleCheckBoxOnChange}
                    disabled={!currentUser}
                    className="col-3"
                  />
                  <VcheckBox
                    id="mykites"
                    name="mykites"
                    checked={formData?.mykites === 1}
                    label="Μύκητες"
                    onChange={handleCheckBoxOnChange}
                    disabled={!currentUser}
                    className="col-3"
                  />
                  <VcheckBox
                    id="koilotites"
                    name="koilotites"
                    checked={formData?.koilotites === 1}
                    label="Κοιλότητες"
                    onChange={handleCheckBoxOnChange}
                    disabled={!currentUser}
                    className="col-3"
                  />
                  <VcheckBox
                    id="epifyta"
                    name="epifyta"
                    checked={formData?.epifyta === 1}
                    label="Επίφυτα"
                    onChange={handleCheckBoxOnChange}
                    disabled={!currentUser}
                    className="col-2"
                  />
                  <div>&nbsp;</div>
                  <VcheckBox
                    id="pligoseis_vandal"
                    name="pligoseis_vandal"
                    checked={formData?.pligoseis_vandal === 1}
                    label="Πληγώσεις / Βανδαλισμοί"
                    onChange={handleCheckBoxOnChange}
                    disabled={!currentUser}
                    className="col-3"
                  />
                  <VtextBox
                    id="pligoseis_vandal_perigr"
                    name="pligoseis_vandal_perigr"
                    label="Πληγώσεις/Βανδαλισμοί Περιγραφή"
                    value={
                      formData?.pligoseis_vandal_perigr
                        ? formData?.pligoseis_vandal_perigr
                        : ""
                    }
                    className="col-5"
                    onChange={handleTextBox}
                    disabled={formData?.pligoseis_vandal === 0}
                  />
                  <div>&nbsp;</div>
                  <VcomboBox
                    id="vathmos_apofloiosis"
                    options={opt_vathmos_apofloiosis}
                    value={find_value(
                      "vathmos_apofloiosis",
                      formData.vathmos_apofloiosis_id
                    )}
                    label="Βαθμός Αποφλοίωσης"
                    //getOptionSelected={getOptionSelected}
                    onChange={handleComboBox}
                    disabled={!currentUser}
                    width="300px"
                  />
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>
          <hr />
          {/* ====================== Δενδροδόχος - Χώρος Ριζών ==================== */}
          <Accordion>
            <MyAccordionsumA
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Δενδροδόχος - Χώρος Ριζών</Typography>
            </MyAccordionsumA>
            <AccordionDetails>
              {formData.id && (
                <Typography component="div">
                  <div className="row" style={divStyleComboAllo}>
                    <VcomboBox
                      id="dendrodoxos_id"
                      options={opt_dendrodoxos}
                      value={find_value("dendrodoxos", formData.dendrodoxos_id)}
                      label="Δενδροδόχος"
                      onChange={handleComboBox}
                      disabled={!currentUser}
                      width="200px"
                    />
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <VtextBox
                      id="dendrodoxos_apostasi"
                      name="dendrodoxos_apostasi"
                      label="Δενδροδόχος Απόσταση"
                      textAlign="right"
                      value={
                        formData?.dendrodoxos_apostasi
                          ? formData?.dendrodoxos_apostasi
                          : ""
                      }
                      width={200}
                      onChange={handleTextBox}
                      disabled={!currentUser}
                      className="col-2"
                    />
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <VcheckBox
                      id="dendrodoxos_tetragoni"
                      name="dendrodoxos_tetragoni"
                      checked={formData?.dendrodoxos_tetragoni === 1}
                      label="Δενδροδόχος Τετράγωνη"
                      onChange={handleCheckBoxOnChange}
                      disabled={!currentUser}
                      className="col-3"
                    />
                    <VcheckBox
                      id="dendrodoxos_kykliki"
                      name="dendrodoxos_kykliki"
                      checked={formData?.dendrodoxos_kykliki === 1}
                      label="Δενδροδόχος Κυκλική"
                      onChange={handleCheckBoxOnChange}
                      disabled={!currentUser}
                      className="col-3"
                    />
                  </div>
                  <div>&nbsp;</div>
                  <div className="row" style={divStyleComboAllo}>
                    <VtextBox
                      id="dendrodoxos_diastasi_x"
                      name="dendrodoxos_diastasi_x"
                      label="Δενδροδ. X Διάσταση"
                      textAlign="right"
                      value={
                        formData?.dendrodoxos_diastasi_x
                          ? formData?.dendrodoxos_diastasi_x
                          : ""
                      }
                      width={200}
                      onChange={handleTextBox}
                      disabled={!currentUser}
                      className="col-2"
                    />
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <VtextBox
                      id="dendrodoxos_diastasi_y"
                      name="dendrodoxos_diastasi_y"
                      label="Δενδροδ. Y Διάσταση"
                      textAlign="right"
                      value={
                        formData?.dendrodoxos_diastasi_y
                          ? formData?.dendrodoxos_diastasi_y
                          : ""
                      }
                      width={200}
                      onChange={handleTextBox}
                      disabled={!currentUser}
                      className="col-2"
                    />
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    <VcheckBox
                      id="stiriksi"
                      name="stiriksi"
                      checked={formData?.stiriksi === 1}
                      label="Στήριξη"
                      onChange={handleCheckBoxOnChange}
                      disabled={!currentUser}
                      className="col-3"
                    />
                    <VcheckBox
                      id="ardeysi"
                      name="ardeysi"
                      checked={formData?.ardeysi === 1}
                      label="Άρδευση"
                      onChange={handleCheckBoxOnChange}
                      disabled={!currentUser}
                      className="col-3"
                    />
                  </div>
                  <VcheckBox
                    id="edafokalypsi"
                    name="edafokalypsi"
                    checked={formData?.edafokalypsi === 1}
                    label="Εδαφοκάλυψη"
                    onChange={handleCheckBoxOnChange}
                    disabled={!currentUser}
                    className="col-3"
                  />
                  <VcheckBox
                    id="sxara"
                    name="sxara"
                    checked={formData?.sxara === 1}
                    label="Σχάρα"
                    onChange={handleCheckBoxOnChange}
                    disabled={!currentUser}
                    className="col-3"
                  />
                  <VcheckBox
                    id="sympiesi_edafous"
                    name="sympiesi_edafous"
                    checked={formData?.sympiesi_edafous === 1}
                    label="Συμπίεση Εδάφους"
                    onChange={handleCheckBoxOnChange}
                    disabled={!currentUser}
                    className="col-3"
                  />
                  <VcheckBox
                    id="yperypsosi_pezodromiou"
                    name="yperypsosi_pezodromiou"
                    checked={formData?.yperypsosi_pezodromiou === 1}
                    label="Υπερύψηση Πεζοδρομίου"
                    onChange={handleCheckBoxOnChange}
                    disabled={!currentUser}
                    className="col-3"
                  />
                  <VcheckBox
                    id="rizovlastimata"
                    name="rizovlastimata"
                    checked={formData?.rizovlastimata === 1}
                    label="Ριζοβλαστήματα"
                    onChange={handleCheckBoxOnChange}
                    disabled={!currentUser}
                    className="col-3"
                  />
                  <VcheckBox
                    id="zizania"
                    name="zizania"
                    checked={formData?.zizania === 1}
                    label="Ζιζάνια"
                    onChange={handleCheckBoxOnChange}
                    disabled={!currentUser}
                    className="col-3"
                  />
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>
          <hr />
          {/* ====================== Εναέρια Εμπόδια ===================== */}
          <Accordion>
            <MyAccordionsumB
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Εναέρια Εμπόδια</Typography>
            </MyAccordionsumB>
            <AccordionDetails>
              {formData.id && (
                <Typography component="div">
                  <div className="row" style={divStyleComboAllo}></div>
                  <VcheckBox
                    id="enaer_empod_kal_ote"
                    name="enaer_empod_kal_ote"
                    checked={formData?.enaer_empod_kal_ote === 1}
                    label="Εναέρια Εμπόδια Καλώδια ΟΤΕ"
                    onChange={handleCheckBoxOnChange}
                    disabled={!currentUser}
                    className="col-5"
                  />
                  <VtextBox
                    id="enaer_empod_kal_ote_ypsos"
                    name="enaer_empod_kal_ote_ypsos"
                    label="Εναέρια Εμπόδια Καλώδια ΟΤΕ Ύψος"
                    textAlign="right"
                    value={
                      formData?.enaer_empod_kal_ote_ypsos
                        ? formData?.enaer_empod_kal_ote_ypsos
                        : ""
                    }
                    width={300}
                    onChange={handleTextBox}
                    disabled={!currentUser}
                  />
                  <VcheckBox
                    id="enaer_empod_kal_deh"
                    name="enaer_empod_kal_deh"
                    checked={formData?.enaer_empod_kal_deh === 1}
                    label="Εναέρια Εμπόδια Καλώδια ΔΕΗ"
                    onChange={handleCheckBoxOnChange}
                    disabled={!currentUser}
                    className="col-5"
                  />
                  <VtextBox
                    id="enaer_empod_kal_deh_ypsos"
                    name="enaer_empod_kal_deh_ypsos"
                    label="Εναέρια Εμπόδια Καλώδια ΔΕΗ Ύψος"
                    textAlign="right"
                    value={
                      formData?.enaer_empod_kal_deh_ypsos
                        ? formData?.enaer_empod_kal_deh_ypsos
                        : ""
                    }
                    width={300}
                    onChange={handleTextBox}
                    disabled={!currentUser}
                  />
                  <div>&nbsp;</div>
                  <VcheckBox
                    id="enaer_empod_fotism"
                    name="enaer_empod_fotism"
                    checked={formData?.enaer_empod_fotism === 1}
                    label="Eναέρια Εμπόδια Φωτισμός"
                    onChange={handleCheckBoxOnChange}
                    disabled={!currentUser}
                    className="col-3"
                  />
                  <VcheckBox
                    id="enaer_empod_simat"
                    name="enaer_empod_simat"
                    checked={formData?.enaer_empod_simat === 1}
                    label="Eναέρια Εμπόδια Σηματοδότης"
                    onChange={handleCheckBoxOnChange}
                    disabled={!currentUser}
                    className="col-3"
                  />
                  <VcheckBox
                    id="enaer_empod_sima_kok"
                    name="enaer_empod_sima_kok"
                    checked={formData?.enaer_empod_sima_kok === 1}
                    label="Eναέρια Εμπόδια Σήμα ΚΟΚ"
                    onChange={handleCheckBoxOnChange}
                    disabled={!currentUser}
                    className="col-3"
                  />
                  <VcheckBox
                    id="enaer_empod_allo"
                    name="enaer_empod_allo"
                    checked={formData?.enaer_empod_allo === 1}
                    label="Eναέρια Εμπόδια Άλλο"
                    onChange={handleCheckBoxOnChange}
                    disabled={!currentUser}
                    className="col-3"
                  />
                  <VtextBox
                    id="enaer_empod_allo_perigr"
                    name="enaer_empod_allo_perigr"
                    label="Eναέρια Εμπόδια Άλλο Περιγραφή"
                    value={
                      formData?.enaer_empod_allo_perigr
                        ? formData?.enaer_empod_allo_perigr
                        : ""
                    }
                    width={400}
                    onChange={handleTextBox}
                    disabled={formData.enaer_empod_allo === 0}
                  />
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>
          <hr />
          {/* =================== Επίγεια και Υπόγεια Εμπόδια =====================*/}
          <Accordion>
            <MyAccordionsumA
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Επίγεια και Υπόγεια Εμπόδια </Typography>
            </MyAccordionsumA>
            <AccordionDetails>
              {formData.id && (
                <Typography component="div">
                  <VcheckBox
                    id="epig_empod_kolona_ote"
                    name="epig_empod_kolona_ote"
                    checked={formData?.epig_empod_kolona_ote === 1}
                    label="Επίγεια Εμπόδια Κολώνα ΟΤΕ"
                    onChange={handleCheckBoxOnChange}
                    disabled={!currentUser}
                    className="col-4"
                  />
                  <VcheckBox
                    id="epig_empod_kolona_deh"
                    name="epig_empod_kolona_deh"
                    checked={formData?.epig_empod_kolona_deh === 1}
                    label="Επίγεια Εμπόδια Κολώνα ΔΕΗ"
                    onChange={handleCheckBoxOnChange}
                    disabled={!currentUser}
                    className="col-4"
                  />
                  <div>&nbsp;</div>
                  <VcheckBox
                    id="epig_empod_fotismos"
                    name="epig_empod_fotismos"
                    checked={formData?.epig_empod_fotismos === 1}
                    label="Επίγεια Εμπόδια Φωτισμός"
                    onChange={handleCheckBoxOnChange}
                    disabled={!currentUser}
                    className="col-3"
                  />
                  <VcheckBox
                    id="epig_empod_simatodotis"
                    name="epig_empod_simatodotis"
                    checked={formData?.epig_empod_simatodotis === 1}
                    label="Επίγεια Εμπόδια Σηματοδότης"
                    onChange={handleCheckBoxOnChange}
                    disabled={!currentUser}
                    className="col-3"
                  />
                  <VcheckBox
                    id="epig_empod_sima_kok"
                    name="epig_empod_sima_kok"
                    checked={formData?.epig_empod_sima_kok === 1}
                    label="Επίγεια Εμπόδια Σήμα ΚΟΚ"
                    onChange={handleCheckBoxOnChange}
                    disabled={!currentUser}
                    className="col-3"
                  />
                  <div>&nbsp;</div>
                  <VcheckBox
                    id="epig_empod_allo"
                    name="epig_empod_allo"
                    checked={formData?.epig_empod_allo === 1}
                    label="Επίγεια Εμπόδια Άλλο"
                    onChange={handleCheckBoxOnChange}
                    disabled={!currentUser}
                    className="col-4"
                  />
                  {formData.epig_empod_allo === 1 && (
                    <VtextBox
                      id="epig_empod_allo_perigr"
                      name="epig_empod_allo_perigr"
                      label="Επίγεια Εμπόδια Άλλο Περιγραφή"
                      value={formData?.epig_empod_allo_perigr}
                      width={400}
                      onChange={handleTextBox}
                      disabled={!currentUser}
                    />
                  )}
                  <div>&nbsp;</div>
                  <VcheckBox
                    id="ypog_diktya_pwr"
                    name="ypog_diktya_pwr"
                    checked={formData.ypog_diktya_pwr === 1}
                    label="Υπόγεια Δίκτυα Power"
                    onChange={handleCheckBoxOnChange}
                    disabled={!currentUser}
                    className="col-2"
                  />
                  <VcheckBox
                    id="ypog_diktya_tel"
                    name="ypog_diktya_tel"
                    checked={formData?.ypog_diktya_tel === 1}
                    label="Υπόγεια Δίκτυα Telephone"
                    onChange={handleCheckBoxOnChange}
                    disabled={!currentUser}
                    className="col-2"
                  />
                  <VcheckBox
                    id="ypog_diktya_wtr"
                    name="ypog_diktya_wtr"
                    checked={formData?.ypog_diktya_wtr === 1}
                    label="Υπόγεια Δίκτυα Water"
                    onChange={handleCheckBoxOnChange}
                    disabled={!currentUser}
                    className="col-2"
                  />
                  <VcheckBox
                    id="ypog_diktya_gas"
                    name="ypog_diktya_gas"
                    checked={formData?.ypog_diktya_gas === 1}
                    label="Υπόγεια Δίκτυα Gas"
                    onChange={handleCheckBoxOnChange}
                    disabled={!currentUser}
                    className="col-2"
                  />
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>
          <hr />
          {/* ======================= Κοινωνικά Δεδομένα =========================*/}
          {/* {console.log(formData)} */}
          <Accordion>
            <MyAccordionsumB
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Κοινωνικά Δεδομένα</Typography>
            </MyAccordionsumB>
            <AccordionDetails>
              {formData.id && (
                <Typography component="div">
                  {/* <div style={{ position: "inherit" }}> */}
                  <div className="row" style={divStyleComboAllo}>
                    {/* <div style={{ fontSize: "10px" }}>&nbsp;</div> */}
                    <VcomboBox
                      id="drastir_id"
                      options={opt_drastir}
                      value={find_value("drastir_id", formData.drastir_id)}
                      label="Δραστηριότητα"
                      onChange={handleComboBox}
                      disabled={!currentUser}
                      width="250px"
                      className="col-4"
                    />
                    &nbsp;&nbsp;
                    <VtextBox
                      id="drastir_allo"
                      name="drastir_allo"
                      label="Άλλο:"
                      value={
                        formData?.drastir_allo ? formData?.drastir_allo : ""
                      }
                      width={380}
                      onChange={handleTextBox}
                      disabled={
                        find_value("drastir_id", formData.drastir_id) !== "Άλλο"
                      }
                    />
                  </div>
                  <div style={{ fontSize: "10px" }}>&nbsp;</div>
                  <VcomboBox
                    id="kat_drastir_id"
                    options={opt_kat_drastir}
                    value={find_value(
                      "kat_drastir_id",
                      formData.kat_drastir_id
                    )}
                    label="Κατηγορία Δραστηριότητας"
                    onChange={handleComboBox}
                    disabled={!currentUser}
                    width="300px"
                    className="col-4"
                    top="10px"
                  />
                  <div style={{ fontSize: "25px" }}>&nbsp;</div>
                  {/* ---------------------------------------------- */}
                  <GroupBox title={"Υιοθεσία :"} backgroundColor="#f7f7f7">
                    <VcheckBox
                      id="iothetimeno"
                      name="iothetimeno"
                      checked={formData?.iothetimeno === 1}
                      label="Υιοθετημένο"
                      onChange={handleCheckBoxOnChange}
                      disabled={!currentUser}
                    />
                    &nbsp;&nbsp;
                    <VtextBox
                      id="etos_iothesias"
                      name="etos_iothesias"
                      label="Έτος Υιοθεσίας"
                      textAlign="right"
                      value={
                        formData?.etos_iothesias ? formData?.etos_iothesias : ""
                      }
                      width={150}
                      onChange={handleTextBox}
                      disabled={!currentUser}
                      style={{ margin: "10px" }}
                    />
                    &nbsp;&nbsp;
                    <VtextBox
                      id="iothet_parat"
                      name="iothet_parat"
                      label="Παρατηρήσεις/Σχόλια Υιοθεσίας"
                      textAlign="center"
                      value={
                        formData?.iothet_parat ? formData.iothet_parat : ""
                      }
                      width={500}
                      onChange={handleTextBox}
                      disabled={!currentUser}
                      style={{ margin: "10px" }}
                    />
                  </GroupBox>
                  <div style={{ fontSize: "25px" }}>&nbsp;</div>
                  {/* </div> */}
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>
          <hr />
          {/* ===================== Φωτογραφική Τεκμηρίωση ========================*/}
          <Accordion defaultExpanded>
            <MyAccordionsumA
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Φωτογραφική Τεκμηρίωση</Typography>
            </MyAccordionsumA>
            <AccordionDetails>
              {formData.id && (
                <Typography component="div">
                  <div style={{ fontSize: "10px" }}>&nbsp;</div>
                  <VtextBox
                    id="foto_dendrou_path"
                    name="foto_dendrou_path"
                    label="Φωτογραφία Δέντρου Κωδικός"
                    value={formData?.foto_dendrou_path}
                    width={800}
                    onChange={handleTextBox}
                    disabled={!currentUser}
                  />
                  &nbsp;&nbsp;
                  <VimgBox
                    thumbnailUrl={formData.foto_dendrou_path}
                    fullSizeUrl={formData.foto_dendrou_path}
                    alt="A. Δένδρο"
                  />
                  <div>&nbsp;</div>
                  {/* -------------------------------------- */}
                  <input
                    className="btn btn-outline-info btn-sm"
                    type="file"
                    id="file1"
                    name="file1"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ width: "400px" }}
                  />
                  &nbsp;
                  {showUpload1 && (
                    <Link
                      name="up1"
                      onClick={uploadFile_A}
                      className="btn btn-info btn-sm"
                    >
                      Ανέβασμα
                    </Link>
                  )}
                  <label style={{ fontSize: "12px" }}>
                    (Η μεγαλύτερη διάσταση (ύψος ή πλάτος) της φωτογραφίας θα
                    πρέπει να βρίσκεται κάτω από τα 700 pixels)
                    <br />
                    {sizeFile1 > 1048577 && (
                      <span className="text-danger">
                        <b>
                          (Μέγεθος: {parseInt(sizeFile1 / 1024)}) --- Μέγιστο
                          επιτρεπόμενο μέγεθος εικόνας : 1 MB (1024 KB)
                        </b>
                      </span>
                    )}
                  </label>
                  <div>&nbsp;</div>
                  <hr />
                  <div>&nbsp;</div>
                  <VtextBox
                    id="foto_dendrodox_path"
                    name="foto_dendrodox_path"
                    label="Φωτογραφία Δενδροδόχου Κωδικός"
                    value={formData?.foto_dendrodox_path}
                    width={800}
                    onChange={handleTextBox}
                    disabled={!currentUser}
                  />
                  &nbsp;&nbsp;
                  <VimgBox
                    thumbnailUrl={formData?.foto_dendrodox_path}
                    fullSizeUrl={formData?.foto_dendrodox_path}
                    alt="B. Δενδροδόχος"
                  />
                  <div>&nbsp;</div>
                  {/* -------------------------------------- */}
                  <input
                    className="btn btn-outline-info btn-sm"
                    type="file"
                    id="file2"
                    name="file2"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ width: "400px" }}
                  />
                  &nbsp;
                  {showUpload2 && (
                    <Link
                      name="up2"
                      onClick={uploadFile_B}
                      className="btn btn-info btn-sm"
                    >
                      Ανέβασμα
                    </Link>
                  )}
                  <label style={{ fontSize: "12px" }}>
                    (Η μεγαλύτερη διάσταση (ύψος ή πλάτος) της φωτογραφίας θα
                    πρέπει να βρίσκεται κάτω από τα 700 pixels)
                    <br />
                    {sizeFile2 > 1048577 && (
                      <span className="text-danger">
                        <b>
                          (Μέγεθος: {parseInt(sizeFile2 / 1024)}) --- Μέγιστο
                          επιτρεπόμενο μέγεθος εικόνας : 1 MB (1024 KB)
                        </b>
                      </span>
                    )}
                  </label>{" "}
                  <div>&nbsp;</div>
                  <Collapse in={openUpload}>
                    <Alert
                      severity="info"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setOpenUpload(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                      sx={{ mb: 2 }}
                    >
                      Μεταφόρτωση Αρχείου ...
                    </Alert>
                  </Collapse>
                  <div>&nbsp;</div>
                  {/* --------------------- QR Code --------------------------- */}
                  <hr />
                  <div className="row" style={divStyleComboAllo}>
                    <div className="col-5 text-center">
                      <h6>
                        Σαρώστε το παρακάτω QR Code για ν' ανοίξετε την τρέχουσα
                        σελίδα (δένδρου) από άλλη συσκευή ...
                        <br />
                        &nbsp;
                      </h6>
                      <QRCode value={currentPageURL} />
                      <div>&nbsp;</div>
                    </div>
                  </div>
                  {/* --------------------- QR Code (end)----------------------- */}
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>
          <hr />
          {/* ================ Κατάσταση, Σύσταση και Παρατηρήσεις ==================*/}
          <Accordion>
            <MyAccordionsumB
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Κατάσταση, Σύσταση και Παρατηρήσεις</Typography>
            </MyAccordionsumB>
            <AccordionDetails>
              <Typography component="div">
                <div>&nbsp;</div>
                <GroupBox title={""} backgroundColor="#ffe6cc">
                  <VcomboBox
                    id="zotikotita_id"
                    options={opt_zotikotita}
                    value={find_value("zotikotita", formData.zotikotita_id)}
                    label="Ζωτικότητα Δέντρου"
                    //getOptionSelected={getOptionSelected}
                    onChange={handleComboBox}
                    disabled={!currentUser}
                    width="300px"
                  />
                </GroupBox>
                <div>&nbsp;</div>
                <GroupBox title={""} backgroundColor="#ffe6cc">
                  <VcomboBox
                    id="systasi_id"
                    options={opt_systasi}
                    value={find_value("systasi", formData.systasi_id)}
                    label="Σύσταση"
                    //getOptionSelected={getOptionSelected}
                    onChange={handleComboBox}
                    disabled={!currentUser}
                    width="300px"
                  />
                </GroupBox>
                <VcheckBox
                  id="simantiko_dendro"
                  name="simantiko_dendro"
                  checked={formData?.simantiko_dendro === 1}
                  label="Σημαντικο Δέντρο"
                  onChange={handleCheckBoxOnChange}
                  disabled={!currentUser}
                  className="col-4"
                />
                <label style={{ fontSize: "14px", color: "#626262" }}>
                  Παρατηρήσεις - Σχόλια
                </label>
                <VtextArea
                  id="paratiriseis"
                  name="paratiriseis"
                  width="90%"
                  value={formData?.paratiriseis ? formData?.paratiriseis : ""}
                  onChange={handleTextArea}
                />
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* ========================== Αναφορές ... =======================*/}
          {anaforaData.length > 0 && (
            <>
              <hr />
              <Accordion>
                <MyAccordionsumC
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Αναφορές ...</Typography>
                </MyAccordionsumC>
                <AccordionDetails style={{ backgroundColor: "transparent" }}>
                  {anaforaData.map((item, index) => (
                    <div key={index}>
                      <div>
                        ( <b>{index + 1}</b> )
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span style={{ fontSize: "12px" }}>
                          {formatDate(item.created_at)}
                        </span>
                      </div>
                      <div>
                        id :{" "}
                        <span
                          style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            color: "#AA0000",
                          }}
                        >
                          {item.id}
                        </span>
                      </div>
                      {/* <div>de_id : {item.de_id}</div> */}
                      <div>email : {item.email}</div>
                      <div>
                        fullname : <b> {item.fullname}</b>
                      </div>

                      <label style={{ fontSize: "14px", color: "#626262" }}>
                        Σχόλια / Παρατηρήσεις ...
                      </label>
                      <VtextArea
                        id="parat"
                        name="parat"
                        width="90%"
                        height="Auto"
                        value={item.sxolia}
                      />
                      {/* <div>created_at : {item.created_at}</div> */}
                      <hr
                        style={{ backgroundColor: "#f1f1f1", margin: "5px" }}
                      />
                      {/* ===================================================== */}
                      <Link
                        onClick={createEnergeiaFromAnafora}
                        className="btn btn-secondary btn-sm"
                      >
                        Δημιουργία Ενέργειας
                      </Link>
                      {/* ------------------------------------------------------ */}
                      <Button
                        onClick={() => openDialogDelAnafora(item)}
                        className="btn btn-danger btn-sm float-right"
                      >
                        <RiDeleteBin6Line
                          color="#AEFDCE"
                          style={{ top: "-2px", position: "relative" }}
                        />{" "}
                        Διαγραφή Αναφοράς
                      </Button>
                      {/* -------------------------------------- */}
                      <ConfirmBox
                        open={openDelAnafora}
                        closeDialog={() => setOpenDelAnafora(false)}
                        title={`Διαγραφή Αναφοράς (${idAnaforas})`}
                        text={`\nΕπιθυμείτε τη Διαγραφή της Αναφοράς με id = ${idAnaforas}`}
                        deleteFunction={deleteAnafora}
                      />
                      <hr style={{ backgroundColor: "#a1a1a1" }} />
                      {/* ================================================ */}
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            </>
          )}
          {/* ======================= Ιστορικό ... ===========================*/}
          {historyData.length > 0 && (
            <>
              <hr />
              <Accordion>
                <MyAccordionsumD
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Ιστορικό ...</Typography>
                </MyAccordionsumD>
                {/* --------------------------------------- */}
                <AccordionDetails style={{ backgroundColor: "transparent" }}>
                  {historyData.map((item, index) => (
                    <div key={index}>
                      <div>
                        ( <b>{index + 1}</b> )
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span style={{ fontSize: "12px" }}>
                          {formatDate(item.history_date)}
                        </span>
                      </div>
                      <div>
                        id :{" "}
                        <span
                          style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            color: "#AA0000",
                          }}
                        >
                          {item.h_id}
                        </span>
                      </div>
                      {/* <div>de_id : {item.de_id}</div> */}
                      <div>
                        user : <b>{item.edit_user}</b>
                      </div>

                      <label style={{ fontSize: "14px", color: "#626262" }}>
                        Αλλαγές ...
                      </label>
                      <VtextArea
                        id="diff"
                        name="diff"
                        width="90%"
                        height="Auto"
                        //value={item.diff}
                        value={foto_link(item.diff)}
                      />
                      {/* ===================================================== */}
                      <hr style={{ backgroundColor: "#a1a1a1" }} />
                      {/* ================================================ */}
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            </>
          )}
          {/* ====================== Ιστορικό 2 (Φωτογραφιών...) =====================*/}
          {histFileList.length > 0 && (
            <>
              <hr />
              <Accordion>
                <MyAccordionsumD2
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Ιστορικό Φωτογραφιών ...</Typography>
                </MyAccordionsumD2>
                {/* --------------------------------------- */}
                <AccordionDetails style={{ backgroundColor: "transparent" }}>
                  {histFileList.map((item, index) => (
                    <div key={index}>
                      <div>
                        ( <b>{index + 1}</b> )
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {item}
                        &nbsp;&nbsp;
                        <VimgBox
                          thumbnailUrl={
                            process.env.REACT_APP_URL + "/api/img11/" + item
                          }
                          fullSizeUrl={
                            process.env.REACT_APP_URL + "/api/img11/" + item
                          }
                          alt="Φωτο Αρχείου"
                        />
                      </div>
                      {/* ===================================================== */}
                      <hr style={{ backgroundColor: "#a1a1a1" }} />
                      {/* ================================================ */}
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            </>
          )}
          {/****************************************************************** */}
          {/*################################################################# */}
          {/****************************************************************** */}
          <hr />
          <div>&nbsp;</div>
          {/* ------------ Επιστροφή ---------------- */}
          &nbsp;&nbsp;
          <Button className="btn btn-secondary" onClick={() => navigate(-1)}>
            <GiFruitTree
              color="#AEFDCE"
              style={{ top: "-2px", position: "relative" }}
            />{" "}
            Επιστροφή
          </Button>
          &nbsp; &nbsp; &nbsp; &nbsp;
          {/* ------------ Αποθήκευση ---------------- */}
          <Button type="submit" disabled={!roleOk} className="btn btn-primary">
            <RiSave3Fill
              color="#AEFDCE"
              style={{ top: "-2px", position: "relative", fontSize: "1.4em" }}
            />{" "}
            Αποθήκευση
          </Button>
          &nbsp; &nbsp; &nbsp; &nbsp;
          {/* ------------ Χάρτης: Μετάβαση στο id ---------------- */}
          {formData.apol_thesi_lat && (
            <>
              <Link to={`/Vmap/${formData.id}`} className="btn btn-secondary">
                <FaMapMarkedAlt
                  color="#AEFDCE"
                  style={{ top: "-2px", position: "relative" }}
                />{" "}
                Χάρτης
                {` (id=${formData.id})`}
              </Link>{" "}
            </>
          )}
          {/* ------------------------------------------------------- */}
          <div>&nbsp;</div>
          {/* ************************************************************* */}
          {/* ************************[ Alert ]**************************** */}
          {/* ************************************************************* */}
          <Collapse in={openSave}>
            <Alert
              severity="info"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenSave(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              Διαδικασία Αποθήκευσης ...
            </Alert>
          </Collapse>
          {/* ************************************************************* */}
        </Box>
      </form>
    </>
  );
}
