import React from "react";

const Footer = () => {
  return (
    <footer className="footer">
      <div>&nbsp;</div>
      <hr />
      <div className="text-center" style={{ backgroundColor: "#c3c3c3" }}>
        <hr />
        <h5><img src="/fylla/F-0.png" alt="GreenTree" width={100} /><b>GreenTree v.3.0</b></h5>
        <h6>Εφαρμογή Διαχείριση Αστικών Δένδρων</h6>
        <h5>Δήμου ΘΕΣΣΑΛΟΝΙΚΗΣ</h5>
        <hr />
        <img src="/img/EP_PKM.jpg" alt="ΕΠ ΠΚΜ" width={600} />
        <div>&nbsp;</div>
        <img src="/img/Urban_Resilience.png" alt="Urban Resilience Observatory" width={250} />
        <div>&nbsp;</div>
      </div>

    </footer>
  );
};

export default Footer;
