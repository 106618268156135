import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Modal.css";
import Button from "react-bootstrap/Button";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import VtextBox from "../VtextBox.component";
import GroupBox from "../GroupBox.component";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Md10K } from "react-icons/md";
import Typography from "@mui/material/Typography";

//***************************************************************** */
function Modal_Apograf({ setModalOpen, mTitle, mData, onSaveSuccess }) {
  //************************************************************* */
  //console.log("mData=", mData, Object.keys(mData).length === 0);

  const mode = Object.keys(mData).length === 0 ? "new" : "edit";
  const [formData3, setFormData3] = useState({});

  useEffect(() => {
    if (mode === "new") {
      setFormData3({
        id: 0,
        name: "",
        kodikos: "",
        fullname: "",
      });
      axios
        .get(`${process.env.REACT_APP_URL}/api/zlookup/z_apograf/max`)
        .then((response) => {
          console.log(response.data[0].max);
          const id = response.data[0].max + 1;
          setFormData3((prevFormData3) => ({
            ...prevFormData3,
            id: id,
          }));
        });
    } else {
      //------------------
      setFormData3(mData);
      //------------------
    }
  }, []);

  // useEffect(() => {
  //   console.log("===formData3===", formData3);
  // }, [formData3]);

  //-------------------Handlers---------------------------
  const handleTextBox_kodikos = (TextBox_id, newValue) => {
    if (/^[a-z0-9]{0,3}$/.test(newValue)) {
      setFormData3((prevFormData3) => ({
        ...prevFormData3,
        [TextBox_id]: newValue,
      }));
    }
  };
  const handleTextBox_lat_num = (TextBox_id, newValue) => {
    if (/^[A-Za-z0-9\s]*$/.test(newValue)) {
      setFormData3((prevFormData3) => ({
        ...prevFormData3,
        [TextBox_id]: newValue,
      }));
    }
  };
  const handleTextBox_common_name = (TextBox_id, newValue) => {
    if (/^[Α-Ω0-9\s]*$/.test(newValue)) {
      setFormData3((prevFormData3) => ({
        ...prevFormData3,
        [TextBox_id]: newValue,
      }));
    }
  };
  const handleTextBox_g_r = (TextBox_id, newValue) => {
    if (/^\d+(\.\d{0,2})?$/.test(newValue)) {
      setFormData3((prevFormData3) => ({
        ...prevFormData3,
        [TextBox_id]: newValue,
      }));
    }
  };

  const handleTextBox3 = (TextBox_id, newValue) => {
    console.log(formData3, TextBox_id, newValue);
    setFormData3((prevFormData3) => ({
      ...prevFormData3,
      [TextBox_id]: newValue,
    }));
  };

  //*************************************** */
  async function handleSubmit3(event) {
    //*******************************/
    event.preventDefault();
    console.log("formData3", formData3);
    console.log("mData", mData);
    if (mode === "new") {
      //-----------[ INSERT ]-----------------------------
      await axios
        .post(
          `${process.env.REACT_APP_URL}/api/zlookup/z_apograf/add`,
          formData3
        )
        .then((response) => console.log(response))
        .catch((error) => console.error(error));
    } else if (mode === "edit") {
      //---------[ UPDATE ]-----------------------------
      await axios
        .put(
          `${process.env.REACT_APP_URL}/api/zlookup/z_apograf/${formData3.id}`,
          formData3
        )
        .then((response) => console.log(response))
        .catch((error) => console.error(error));
    }
    //----------------------------------------------
    onSaveSuccess();
    //----------------------------------------------
    setModalOpen(false);
    //----------------------------------------------
  }

  return (
    <div className="modalBackground">
      <div className="modalContainer modalContainerEidos">
        {/* <div className="titleCloseBtn22"> */}
        <div className="title">
          <div className="row">
            <div className="col-10">
              <h4>{mTitle}</h4>
            </div>
            <div className="col-2">
              <Button
                id="closeBtn"
                className="btn btn-secondary btn-sm"
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                &nbsp;X&nbsp;
              </Button>
            </div>
          </div>
          <hr />
        </div>
        {/* ----------------------------[ body ]-------------------------------- */}
        <div className="body">
          <form
            onSubmit={handleSubmit3}
          //</div>style={{ backgroundColor: "#e0ebeb" }}
          >
            {formData3.id && (
              <Box>
                <FormGroup>
                  <div className="row" style={{ marginLeft: "0px" }}>
                    <VtextBox
                      id="id"
                      name="id"
                      label="id"
                      textAlign="center"
                      value={formData3.id}
                      width={80}
                      //onChange={handleTextBox3}   ---ReadOnly---
                      style={{ margin: "10px" }}
                      disabled
                    />
                  </div>
                  <VtextBox
                    id="name"
                    name="name"
                    label="Σύντ.Ονομα (Λατ)"
                    textAlign="left"
                    value={formData3.name}
                    width={200}
                    onChange={handleTextBox3}
                    maxLength={20}
                    style={{ margin: "10px" }}
                  />
                  <VtextBox
                    id="kodikos"
                    name="kodikos"
                    label="Κωδ.(Λατ.2)"
                    textAlign="left"
                    value={formData3.kodikos}
                    width={100}
                    onChange={handleTextBox_kodikos}
                    maxLength={2}
                    style={{ margin: "10px" }}
                  />
                  <VtextBox
                    id="fullname"
                    name="fullname"
                    label="Ονοματεπώνυμο"
                    textAlign="left"
                    value={formData3.fullname}
                    width={400}
                    onChange={handleTextBox3}
                    style={{ margin: "10px" }}
                  />
                </FormGroup>
              </Box>
            )}
          </form>
        </div>
        <div className="footer">
          <button
            onClick={() => {
              setModalOpen(false);
            }}
            id="cancelBtn"
          >
            Ακύρωση
          </button>
          <Button
            onClick={handleSubmit3}
            disabled={
              formData3.name === "" ||
              formData3.kodikos === "" ||
              formData3.fullname === ""
            }
          >
            {mode === "new" ? "Δημιουργία" : "Αποθήκευση"}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Modal_Apograf;
