import React, { useState, useEffect } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { json } from "react-router-dom";
import { Box } from "@mui/material";
//import { makeStyles } from "@material-ui/core/styles";

const VcomboBox = (props) => {
  const { id, name, value, onChange, className, width, top, ...rest } = props;
  //export default function VcomboBox({ id, onChange, ...props }) {
  //  const { value } = props;

  const handleChange = (event, value) => {
    //console.log("-----222------", id, value, props);
    onChange(id, value);
  };

  useEffect(() => {
    //axios
    //.get("${process.env.REACT_APP_URL}/api/zlookup/z_kat_xorou")
    //.then((response) => {
    //--------------------- all response.data ----------------------
    //setOptions(response.data);
    //console.log("response.data=" + JSON.stringify(response.data));
    //-------------------- or (μπορεί να μπει φίλτρο)---------------
    // const categoryData = response.data.map((category) => ({
    //   value: category.id,
    //   label: category.label,
    // }));
    // setOptions(categoryData);
    //-------------------------------------------------------------
    //})
    //.catch((error) => {
    //  console.log(error);
    //});
  }, []);

  const get_OptionSelected = (option) => {
    return option.label === value;
  };

  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const renderOption = (props, option) => {
    return (
      <li
        {...props}
        key={option.id}
        style={{
          backgroundColor:
            option.label === value
              ? "#b3ccff"
              : isHover
              ? "%456789"
              : "#f8f8f8",
          fontSize: 15,
          margin: 3,
          padding: 5,
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {option.label}
      </li>
    );
  };

  return (
    <Autocomplete
      style={{ size: "small" }}
      id={props.id}
      name={props.id}
      options={props.options}
      getOptionLabel={(option) => option.label ?? option}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      value={value}
      defaultValue={value}
      onChange={handleChange}
      disabled={props.disabled}
      className={className}
      size="small"
      sx={{
        top: { top },
        width: { width },
        //height: 50,
        fontSize: 14,
        "& label": {
          top: "-0.50rem",
        },
      }}
      //getOptionSelected={get_OptionSelected}
      renderOption={renderOption}
      renderInput={(params) => <TextField {...params} label={props.label} />}
    />
  );
};

export default VcomboBox;
