import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import AuthService from "../services/auth.service";
import axios from "axios";

import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import { Circle } from "react-leaflet/Circle";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { LatLngBounds } from "leaflet";
import { WMSTileLayer } from "react-leaflet/WMSTileLayer";

import CoordinatesControl from "../components/CoordinatesControl.component";

import Button from "react-bootstrap/Button";

import { GiFruitTree } from "react-icons/gi";

L.Marker.prototype.options.icon = L.icon({
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  //iconUrl: 'http://leafletjs.com/examples/custom-icons/leaf-green.png',
  //shadowUrl: 'http://leafletjs.com/examples/custom-icons/leaf-shadow.png',
  //iconSize: [30, 95],
  //iconAnchor: [20, 94],
  //shadowSize: [50, 64],
  //shadowAnchor: [4, 62],
  //popupAnchor: [0, -76]
  iconSize: [26, 46],
  iconAnchor: [13, 46],
});

const customMarker = new L.icon({
  iconUrl: "/images/icons/location-pointer.svg",
  iconSize: [30, 46],
  iconAnchor: [17, 46],
});

const position = [40.629901, 22.953772];

/******************************** */
export default function MapSel2(props) {
  const { listaDen } = props;

  //------Εναλλακτικά----[ Open Page with Data ]-----------
  // Όταν καλείται μια σελίδα και στέλνουμε δεδομένα :
  //  <Link to={{ pathname: "/page", state: lista }}>
  //----------------[ page.js ]-----------------------
  // και τα παραλαμβάνουμε με :
  // const location = useLocation();
  // const lista = location.state;
  //--------------------------------------------------------
  // Εδώ καλείται (ως component mapSel2) η mapSelDiaxMetr.js
  // και συνεπώς περνάμε τα δεδομένα με props
  // <MapSel2 listaDen={formDataDen} />
  //--------------------------------------------------------

  //------------------------------
  //const location = useLocation();
  //const lista = location.state;
  //--------------------------------

  console.log("===listaDen=>=", listaDen, props);
  //--------------------------------

  //----------- Check Authentication ------------
  const currentUser = AuthService.getCurrentUser();
  const [state, setState] = useState({
    redirect: null,
    userReady: false,
    currentUser: { username: "" },
    roles: {},
  });
  //----------------------------------------------
  const mapRef = useRef();

  const bounds = new LatLngBounds();
  //const [formData, setFormData] = useState({});
  //const [data_FL, setData_FL] = useState({});
  const [mode, setMode] = useState(""); // -->"2" for getdendro2
  //const [boundsOk, setBoundsOk] = useState(false);

  useEffect(() => {
    //-------------- Auth -------------------
    if (!currentUser) setState({ redirect: "/home" });
    if (!currentUser)
      setState({
        currentUser: currentUser,
        userReady: true,
        roles: currentUser?.roles,
      });
    //--------------------------------------------
    if (
      currentUser?.roles?.includes("ROLE_MODERATOR") ||
      currentUser?.roles?.includes("ROLE_ADMIN")
    )
      setMode("2");
    //--------------------------------------------
    // axios
    //   .post(`${process.env.REACT_APP_URL}/api/dendra/listbyids`, listaDen)
    //   .then((response) => {
    //     setFormData(response.data);
    //   })
    //   .catch((error) => console.error(error));
  }, []);

  //const initialBounds = L.latLngBounds(L.latLng(0, 0), L.latLng(0, 0)); // Initialize with empty bounds

  useEffect(() => {
    if (mapRef.current && listaDen.length > 0) {
      const Map = mapRef.current;
      listaDen.forEach((marker) => {
        bounds.extend([marker.lat, marker.lng]);
      });
      const increasedBounds = bounds.pad(0.1);
      Map.fitBounds(increasedBounds);
    }
  }, [mapRef.current, listaDen]);

  const handleMapCreated = (map) => {
    // Evaluate the 'map' variable here
    // Perform any necessary evaluations or modifications
    if (listaDen?.length > 0) {
      listaDen.forEach((marker) => {
        bounds.extend([marker.lat, marker.lng]);
      });
      const increasedBounds = bounds.pad(0.4);
      map.fitBounds(increasedBounds);
      // map.fitBounds(bounds);
    }
  };

  return (
    <MapContainer
      ref={mapRef}
      center={position}
      zoom={15}
      maxZoom={22}
      scrollWheelZoom={false}
      style={{ height: "40vh" }}
    >
      {listaDen.length > 0 &&
        listaDen.map((item) => (
          // ------ Όταν θέλουμε να βάλουμε key στο <></> βάζουμε όλο το λεκτικό
          // ------ <> ==> <React.Fragment key=...></React.Fragment>
          <React.Fragment key={item.den_id + 60000}>
            {console.log("item===", item)}
            <Marker position={[item.lat, item.lng]} key={item.den_id}>
              <Popup>
                <hr style={{ margin: "8px" }} />
                <span style={{ color: "gray" }}>.id.. :</span>
                <b>{item.den_id}</b> <hr style={{ margin: "8px" }} />
                <span style={{ color: "gray" }}>είδος:</span>&nbsp;
                <span style={{ fontSize: "12px" }}>{item.eidos}</span>{" "}
                <hr style={{ margin: "8px" }} />
                <Link
                  to={`/getdendro${mode}/${item.den_id}`}
                  className="btn btn-secondary btn-sm pull-right"
                  target="_blank"
                >
                  <GiFruitTree
                    color="#AEFDCE"
                    style={{ top: "-2px", position: "relative" }}
                  />{" "}
                  <span style={{ color: "#F9F9F9" }}>
                    &nbsp;περισσότερα ...&nbsp;
                  </span>
                </Link>
              </Popup>
            </Marker>
            <Circle
              key={item.den_id + 100000}
              center={[item.lat, item.lng]}
              pathOptions={{ fillColor: "blue", border: "red" }}
              radius={1.5}
            />
            <Circle
              key={item.den_id + 200000}
              center={[item.lat, item.lng]}
              pathOptions={{ fillColor: "blue", border: "red" }}
              radius={0.01}
            />
          </React.Fragment>
        ))}

      {/*-------------------------*/}
      <CustomMap handleMapCreated={handleMapCreated} />
      {/*-------------------------*/}
      <CoordinatesControl />
      {/*----------------*/}
    </MapContainer>
  );
}

const CustomMap = ({ handleMapCreated }) => {
  const map = useMap();
  const popupElRef = useRef(null);
  const mapRef = useRef(null);

  useEffect(() => {
    handleMapCreated(map); // Call the function with the 'map' instance
  }, [map, handleMapCreated]);

  return (
    <>
      {/* <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        maxZoom={22}
        maxNativeZoom={19}
        minZoom={0}
      /> */}
      {/* ---------------------[ ESRI Topo Map ]-------- */}
      <TileLayer
        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
        maxZoom={22}
        maxNativeZoom={19}
      />
      {/* -----------------------[ WMS Layer ]------------ */}
      <WMSTileLayer
        url={`${process.env.REACT_APP_WMS_URL}`}
        //layers="v_g_dendra"
        layers="wdendra"
        //layers="wdendra"
        format="image/png"
        transparent={true}
        maxZoom={22}
      />
    </>
  );
};
