import { useRef, useState , useEffect} from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents } from "react-leaflet";
import { Circle } from "react-leaflet/Circle";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

L.Marker.prototype.options.icon = L.icon({
  //iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  iconUrl: 'http://leafletjs.com/examples/custom-icons/leaf-green.png',
  shadowUrl: 'http://leafletjs.com/examples/custom-icons/leaf-shadow.png',
  iconSize: [30, 95],
  iconAnchor: [20, 94],
  shadowSize:   [50, 64],
  shadowAnchor: [4, 62], 
  popupAnchor:  [0, -76]
});

const position = [40.629901, 22.953772];

const customMarker = new L.icon({
  iconUrl: '/images/icons/location-pointer.svg',
  iconSize: [35, 46],
  iconAnchor: [17, 46]
});

const Map = () => {
  const [map, setMap] = useState(null);
  //console.log("~~~~~~~in~~~~~~~", map);

  const handleMapCreated = (map) => {
    // Evaluate the 'map' variable here
    //console.log("~~~~~~~in2~~~~~~~", map);
    // Perform any necessary evaluations or modifications
  };

  const handleMapClick = (e) => {
    const { latlng } = e;
    const { lat, lng } = latlng;
    console.log(`------: ${lat}, ${lng}`);
    // You can perform additional actions with the clicked coordinates here
  };

  const MapClickHandler = () => {
    useMapEvents({
      click: handleMapClick,
    });

    return null;
  };

  return (
    <MapContainer
      center={position}
      zoom={20}
      maxzoom={23}
      style={{ height: "100vh" }}
    >
      <CustomMap handleMapCreated={handleMapCreated} />
      <MapClickHandler />
    </MapContainer> 
  );
};

const CustomMap = ({ handleMapCreated }) => {
  const map = useMap();
  const popupElRef = useRef(null);
  const mapRef = useRef(null);

  const hideElement = () => {
    if (!popupElRef.current || !map) return;
    console.log("==================1================");
    popupElRef.current._close();
    popupElRef.current._closeButton.click();
    map.closePopup();
    console.log("==================2================");
  };


  useEffect(() => {
    handleMapCreated(map); // Call the function with the 'map' instance
  }, [map, handleMapCreated]);

  return (
    <>
      <div className="row">
        <div className="col-5" style={{ backgroundColor: "gray"}}>
            aa  aaaaa
        </div>
        <div className="col-7" style={{ backgroundColor: "green"}}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker position={position}>
            <Popup ref={popupElRef} closeButton={false}>
                <button onClick={hideElement}>Close222 popup</button>
            </Popup>
          </Marker>
          <Circle
              center={position}
              pathOptions={{ fillColor: "blue", border: "red" }}
              radius={5}
          />
        </div>


      </div>
      
    </>
  );
};


export default Map;
