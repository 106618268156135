import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Vchart from "../../components/Vchart.component";

import { GiFruitTree } from "react-icons/gi";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

/* ************************************ */
export default function Ζotikotita() {
  /******************************** */
  const [myData, setMyData] = useState({});
  //----------- Sumarize -----------------------
  const [ss_aprosd, setSs_aprosd] = useState(0);
  const [ss_aristh, setSs_aristh] = useState(0);
  const [ss_kalh, setSs_kalh] = useState(0);
  const [ss_metria, setSs_metria] = useState(0);
  const [ss_kakh, setSs_kakh] = useState(0);
  const [ss_xeir, setSs_xeir] = useState(0);
  //----------- Graph --------------------------
  const data_labels = [];
  const data_s_aprosd = [];
  const data_s_aristh = [];
  const data_s_kalh = [];
  const data_s_metria = [];
  const data_s_kakh = [];
  const data_s_xeir = [];
  const [titlos, setTitlos] = useState("");
  const [graphData, setGraphData] = useState(null);
  //-----------------------------------------------

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/statist/st_zotikotia`)
      .then((response) => {
        const formData = response.data;
        setMyData(response.data);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (myData.length > 0) {
      setSs_aprosd(
        myData.reduce((sum, obj) => sum + parseInt(obj.s_aprosd), 0)
      );
      setSs_aristh(
        myData.reduce((sum, obj) => sum + parseInt(obj.s_aristh), 0)
      );
      setSs_kalh(myData.reduce((sum, obj) => sum + parseInt(obj.s_kalh), 0));
      setSs_metria(
        myData.reduce((sum, obj) => sum + parseInt(obj.s_metria), 0)
      );
      setSs_kakh(myData.reduce((sum, obj) => sum + parseInt(obj.s_kakh), 0));
      setSs_xeir(myData.reduce((sum, obj) => sum + parseInt(obj.s_xeir), 0));

      //-----------------Graph-----------------------
      if (myData.length > 0) {
        myData.forEach((row) => {
          data_labels.push(row.dim_diam + "ο Δ.Δ.");
          data_s_aprosd.push(parseInt(row.s_aprosd));
          data_s_aristh.push(parseInt(row.s_aristh));
          data_s_kalh.push(parseInt(row.s_kalh));
          data_s_metria.push(parseInt(row.s_metria));
          data_s_kakh.push(parseInt(row.s_kakh));
          data_s_xeir.push(parseInt(row.s_xeir));
        });
        //-------------titlos-----------------------
        setTitlos("Ζωτικότητα Δένδρων ανά Δημοτικό Διαμέρισμα");
        //--------------graphData-----------------------
        setGraphData({
          labels: data_labels,
          datasets: [
            {
              label: "Απροσδ.",
              backgroundColor: "rgba(137, 139, 139, 0.6)",
              data: data_s_aprosd,
            },
            {
              label: "Αρίστη",
              backgroundColor: "rgba(89, 179, 0, 0.75)",
              data: data_s_aristh,
            },
            {
              label: "Καλή",
              backgroundColor: "rgba(51, 153, 102, 0.85)",
              data: data_s_kalh,
            },
            {
              label: "Μέτρια",
              backgroundColor: "rgba(255, 153, 0, 0.85)",
              data: data_s_metria,
            },
            {
              label: "Κακή",
              backgroundColor: "rgba(255, 51, 0, 0.85)",
              data: data_s_kakh,
            },
            {
              label: "Χείριστη",
              backgroundColor: "rgba(255, 0, 255, 0.85)",
              data: data_s_xeir,
            },
          ],
        });
      }
      //--------------------------------------------------
    }
  }, [myData]);

  return (
    <div className="container2">
      <header className="jumbotron" style={{ padding: "1.0rem" }}>
        <h3 className="text-center">
          <b>GreenTree</b> - Στατιστικά
        </h3>
        <h6 className="text-center">(Επισκέπτες - Εγγεγραμμένοι χρήστες)</h6>
      </header>
      <header className="jumbotron" style={{ padding: "1.0rem" }}>
        <h5 className="text-justify">
          <img src="/img/small-logo-green-tree.png" width="120px" alt="Logo" />
          Ζωτικότητα Δένδρων, ... ανά Δημοτικό Διαμέρισμα
        </h5>
      </header>
      {/* ------------------------------------------------------------ */}
      <Link to="/statist" className="btn btn-secondary pull-right">
        <GiFruitTree
          color="#AEFDCE"
          style={{ top: "-2px", position: "relative" }}
        />{" "}
        Επιστροφή στα Στατιστικά{" "}
      </Link>
      <div>&nbsp;</div>
      {/*-------------------Graph------------------------------*/}
      <div className="row">
        <div className="col-1"></div>
        <div className="col-10">
          {/*----------------------------------------------------*/}
          {graphData && <Vchart titlos={titlos} data={graphData} />}
          {/*----------------------------------------------------*/}
        </div>
        <div className="col-1"></div>
      </div>
      {/* ---------------------------------------------------- */}
      <div>&nbsp;</div>
      {/* ---------------------------------------------------- */}
      {/* -------------------------------------------- */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <b>Δημοτικό Διαμέρισμα</b>
              </StyledTableCell>
              <StyledTableCell align="right">
                <b>Απροσδ.</b>
              </StyledTableCell>
              <StyledTableCell align="right">
                <b>Αρίστη</b>
              </StyledTableCell>
              <StyledTableCell align="right">
                <b>Καλή</b>
              </StyledTableCell>
              <StyledTableCell align="right">
                <b>Μέτρια</b>
              </StyledTableCell>
              <StyledTableCell align="right">
                <b>Κακή</b>
              </StyledTableCell>
              <StyledTableCell align="right">
                <b>Χείριστη</b>
              </StyledTableCell>
              <StyledTableCell align="right">
                <b>ΣΥΝΟΛΟ (Δ.Δ.)</b>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {myData.length > 0 && (
            <TableBody>
              {myData.map((row) => (
                <StyledTableRow key={row.dim_diam}>
                  <StyledTableCell component="th" scope="row">
                    {row.dim_diam}o
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {parseInt(row.s_aprosd).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {parseInt(row.s_aristh).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {parseInt(row.s_kalh).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {parseInt(row.s_metria).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {parseInt(row.s_kakh).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {parseInt(row.s_xeir).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <b>{parseInt(row.synolo).toLocaleString("el-GR")}</b>
                  </StyledTableCell>
                </StyledTableRow>
              ))}

              <StyledTableRow
                key={"SYNOLA"}
                sx={{ backgroundColor: "#c2c2c2" }}
              >
                <StyledTableCell align="left">
                  <b>{"Γενικό Σύνολο (Δένδρων)"}</b>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <b>{parseInt(ss_aprosd).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <b>{parseInt(ss_aristh).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <b>{parseInt(ss_kalh).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <b>{parseInt(ss_metria).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <b>{parseInt(ss_kakh).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <b>{parseInt(ss_xeir).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <b>
                    {parseInt(
                      ss_aprosd +
                        ss_aristh +
                        ss_kalh +
                        ss_metria +
                        ss_kakh +
                        ss_xeir
                    ).toLocaleString("el-GR", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    })}
                  </b>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {/* --------------------[ waiting ]--------------------------- */}
      {!myData?.length && (
        <h6 className="text-center text-info">
          <b>Υπολογισμός . . .</b>{" "}
          <img src="/img/wait-1.gif" height={200} alt="Wait" />
        </h6>
      )}
      {/* ---------------------------------------------------------- */}
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <Link to="/statist" className="btn btn-secondary pull-right">
        <GiFruitTree
          color="#AEFDCE"
          style={{ top: "-2px", position: "relative" }}
        />{" "}
        Επιστροφή στα Στατιστικά{" "}
      </Link>
      {/* -------------------------------------------- */}
    </div>
  );
}
