import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

/**************************** */
const VchartPie = (props) => {
  /********************* */
  const titlos = props.titlos;
  const data = props.data;

  const options = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: true,
        text: titlos,
      },
    },
  };

  // console.log("---data1---", data1);
  // console.log("===data===", data);

  return <Pie options={options} data={data} />;
};

export default VchartPie;
