import React, { Component } from "react";
import { Link } from "react-router-dom";
//import Button from "react-bootstrap/Button";
import UserService from "../services/user.service";
//import GetOne from "../pages/getdendro";

//import Box, { BoxProps } from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
//import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";

import { FaMapMarkedAlt } from "react-icons/fa";
//import { FaChartPie } from "react-icons/fa";
import { GiFruitTree } from "react-icons/gi";
//import { AiOutlineForm } from "react-icons/ai";

//----------------------
//const searcId = "39925";
//----------------------

// const openMapPage = () => {
//   //console.log("sssss");
//   GetOne();
//   //console.log("zzzzz");
// };

const urlPrasino =
  "https://thessaloniki.gr/%CE%B8%CE%AD%CE%BB%CF%89-%CE%B1%" +
  "CF%80%CF%8C-%CF%84%CE%BF%CE%BD-%CE%B4%CE%AE%CE%BC%CE%BF/%CE%BF-%CE%B4%CE%AE%" +
  "CE%BC%CE%BF%CF%82/%CE%B3%CE%B5%CE%BD%CE%B9%CE%BA%CE%AE-%CE%B4%CE%B9%CE%B5%CF%" +
  "8D%CE%B8%CF%85%CE%BD%CF%83%CE%B7/%CE%B4%CE%B9%CE%B5%CF%8D%CE%B8%CF%85%CE%BD%" +
  "CF%83%CE%B7-%CE%B4%CE%B9%CE%B1%CF%87%CE%B5%CE%AF%CF%81%CE%B9%CF%83%CE%B7%CF%" +
  "82-%CF%80%CF%81%CE%B1%CF%83%CE%AF%CE%BD%CE%BF%CF%85-%CE%BA%CE%B1%CE%B9-%CF%80/";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: "",
    };
  }

  componentDidMount() {
    UserService.getPublicContent().then(
      (response) => {
        this.setState({
          content: response.data,
        });
      },
      (error) => {
        this.setState({
          content:
            (error.response && error.response.data) ||
            error.message ||
            error.toString(),
        });
      }
    );
  }

  render() {
    return (
      <div className="container2">
        <header className="jumbotron" style={{ padding: "1.0rem" }}>
          {/* <h3>{this.state.content}</h3> */}
          <h3 className="text-center">
            <img src="/img/logo-greentree.png" height="55px" alt="greenTree" />
            &nbsp;
            <b>GreenTree</b> - Αρχική
          </h3>
          <h6 className="text-center">(Επισκέπτες - Εγγεγραμμένοι χρήστες)</h6>
        </header>
        <hr style={{ margin: 20 }} />
        {/* ************************************************************ */}
        <Grid container spacing={3}>
          {/* -----------------------(1)------------------------- */}
          <Grid
            item
            xs={12}
            sm={4}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Card sx={{ maxWidth: 300, backgroundColor: "#e1eaea" }}>
              <CardMedia
                component="img"
                sx={{
                  //height: "25%",
                  width: "50%",
                  margin: "15px 25% 10px 25%",
                  padding: "0.2em 0.4em 0em 0.7em",
                }}
                image="/img/small-logo-green-tree.png"
                title="green Tree"
                style={{ justifyContent: "center", display: "flex" }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  <b>Τα Δένδρα της πόλης</b>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Προβολή, των Δένδρων της πόλης με <b>χαρτογραφική</b>{" "}
                  απεικόνιση, για εύκολο εντοπισμό, Εργαλεία <b>Αναζήτησης</b>{" "}
                  και <b>Στατιστικά</b> στοιχεία.
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "center" }}>
                <Link to="/views" className="btn btn-secondary">
                  <GiFruitTree
                    color="#AEFDCE"
                    style={{ top: "-2px", position: "relative" }}
                  />{" "}
                  Προβολές{" "}
                </Link>
              </CardActions>
            </Card>{" "}
          </Grid>
          {/* ------------------------------(2)---------------------------- */}
          <Grid
            item
            xs={12}
            sm={4}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Card
              sx={{
                maxWidth: 400,
                backgroundColor: "#eaeaea",
                gridColumn: "span 2",
              }}
            >
              <Link to="https://thessaloniki.gr/" target="blank">
                <CardMedia
                  component="img"
                  sx={{
                    //height: "29%",
                    width: "85%",
                    margin: "15px 0px 0px 22px",
                    padding: "0.2em 0.4em 0em 0.7em",
                  }}
                  image="/img/dimos-thessalonikis.png"
                  title="green Tree"
                  style={{ justifyContent: "center", display: "flex" }}
                />
              </Link>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  <b></b>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Η <b>Διεύθυνση Διαχείρισης Πρασίνου και Περιβάλλοντος</b> του
                  Δήμου Θεσσαλονίκης είναι ο <b>Διαχειριστής </b>
                  του συστήματος παρακολούθησης των δένδρων της πόλης.
                </Typography>
              </CardContent>
              <CardActions
                sx={{ justifyContent: "center", margin: "10px auto 5px auto" }}
              >
                <Link to={urlPrasino} className="btn btn-info" target="blank">
                  <GiFruitTree
                    color="#AEFDCE"
                    style={{ top: "-2px", position: "relative" }}
                  />{" "}
                  Διεύθυνση Πρασίνου{" "}
                </Link>
              </CardActions>
            </Card>
          </Grid>
          {/* ---------------------------(3)--------------------------- */}
          <Grid
            item
            xs={12}
            sm={4}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Card sx={{ maxWidth: 300, backgroundColor: "#e1eaea" }}>
              <Link to="https://imc.thessaloniki.gr/" target="blank">
                <CardMedia
                  sx={{
                    height: "25%",
                    width: "75%",
                    margin: "30px auto auto auto",
                  }}
                  image="/img/imc_el.svg"
                  title="green Tree"
                  style={{ justifyContent: "center", display: "flex" }}
                />
              </Link>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  <b>Αίτημα Προβλήματος</b>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Μέσα από το "<b>Βελτιώνω την πόλη μου</b>" υποβάλετε αίτημα
                  στην κατηγορία:{" "}
                  <b>Δενδροφυτεύσεις - Κλαδεύσεις - Φυτώρια - Κοπή δένδρων</b>.
                </Typography>
              </CardContent>
              <CardActions
                sx={{ justifyContent: "center", marginBottom: "auto" }}
              >
                <Link
                  to="https://imc.thessaloniki.gr/imc/new?catid=35"
                  className="btn btn-warning"
                  target="blank"
                >
                  <GiFruitTree
                    color="#AEFDCE"
                    style={{ top: "-2px", position: "relative" }}
                  />{" "}
                  Νέο Αίτημα{" "}
                </Link>
              </CardActions>
            </Card>
          </Grid>
          {/* ---------------------------------------------------------------------- */}
        </Grid>
        {/* ************************************************************ */}
        <div>&nbsp;</div>
        <div className="text-center">
          <header
            className="jumbotron mx-auto"
            style={{ padding: "0.2rem", width: "300px" }}
          >
            <Link to="https://potizo.thessaloniki.gr/" target="_blank">
              <div className="text-center">
                <img
                  src="/img/potizo_logo.svg"
                  //height={30}
                  width="30%"
                  className="img-fluid"
                  alt="potizo.thessaloniki.gr"
                />
                <img
                  src="/img/potizo_moto.png"
                  width="50%"
                  className="img-fluid"
                  alt="potizo_moto.png"
                />
              </div>
            </Link>
          </header>
        </div>
        {/* ************************************************************ */}

        <hr />
        {/* <div>&nbsp;</div> */}
        <div
          style={{
            backgroundColor: "#a5ff79",
            backgroundImage: 'url("img/bar1c.png")',
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "top",
            padding: "10px",
          }}
        >
          <h6 className="text-center">
            Μπορείτε να επιλέξετε τον Χάρτη ... &nbsp;&nbsp;&nbsp;
            <Link to="/Vmap" className="btn btn-primary">
              <FaMapMarkedAlt
                color="#AEFDCE"
                style={{ top: "-2px", position: "relative" }}
              />{" "}
              Χάρτης{" "}
            </Link>
            &nbsp;&nbsp;&nbsp; ... ή κάποια από τις παραπάνω επιλογές
          </h6>
        </div>

        {/* ----------------------------------------------------------------*/}
        {/* <h4>
          for Test ... &nbsp; &nbsp;
          <Link to={`/getdendro/${searcId}`} className="btn btn-secondary">
            <FaMapMarkedAlt
              color="#AEFDCE"
              style={{ top: "-2px", position: "relative" }}
            />{" "}
            Form
            {` (id=${searcId})`}
          </Link>{" "}
          <Link to={`/getdendro2/${searcId}`} className="btn btn-secondary">
            <FaMapMarkedAlt
              color="#AEFDCE"
              style={{ top: "-2px", position: "relative" }}
            />{" "}
            Form
            {` (id=${searcId})`}
          </Link>{" "}
          <Link to={`/Vmap/${searcId}`} className="btn btn-secondary">
            <FaMapMarkedAlt
              color="#AEFDCE"
              style={{ top: "-2px", position: "relative" }}
            />{" "}
            Χάρτης
            {` (id=${searcId})`}
          </Link>{" "}
          <Link to="/map" className="btn btn-secondary">
            <FaMapMarkedAlt
              color="#AEFDCE"
              style={{ top: "-2px", position: "relative" }}
            />{" "}
            Χάρτης2{" "}
          </Link>{" "}
        </h4> */}
      </div>
    );
  }
}
