import React from "react";
import { TextField } from "@mui/material";

const VtextBox = (props) => {
  const { id, name, value, width, onChange, className, textAlign, ...rest } =
    props;

  const handleChange = (event) => {
    const newValue = event.target.value;
    if (onChange) {
      onChange(id || name, newValue);
    }
  };
  //console.log(id, value);

  return (
    <TextField
      id={id}
      name={name}
      value={value}
      onChange={handleChange}
      {...rest}
      size="small"
      inputProps={{ style: { textAlign: textAlign ? textAlign : "left" } }}
      //style={{ width: { width } }}
      InputLabelProps={{ shrink: value || value === 0 ? true : false }}
      //InputLabelProps={{ shrink: true }}
      sx={{
        width: [width],
        "& label": {
          top: "-0.50rem",
        },
        "& legend": {
          textAlign: "left",
        },
        '& .Mui-disabled': {
          color: 'lightgray', // Customize the disabled color here
        },
        '& .MuiInputBase-input': {
          color: 'black',
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: 'green', // Customize the disabled color here
        },
      }}
      className={className}
    />
  );
};

export default VtextBox;
