import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Modal.css";
import Button from "react-bootstrap/Button";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import VtextBox from "../VtextBox.component";
import GroupBox from "../GroupBox.component";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Md10K } from "react-icons/md";
import Typography from "@mui/material/Typography";

//*************************************************************** */
function Modal_erg({ setModalOpen, mTitle, mData, onSaveSuccess }) {
  //*********************************************************** */
  //console.log("mData=", mData, Object.keys(mData).length === 0);

  const mode = Object.keys(mData).length === 0 ? "new" : "edit";
  const [formData3, setFormData3] = useState({});

  useEffect(() => {
    if (mode === "new") {
      setFormData3({
        id: 0,
        old_id: 0,
        perigrafi: "",
        monada_metrisis_id: "2",
        gia_dendra: 1,
        gia_parka: 0,
      });
      axios
        .get(`${process.env.REACT_APP_URL}/api/zlookup/z_ergasies/max`)
        .then((response) => {
          console.log(response.data[0].max);
          const old_id = response.data[0].max + 1;
          const id = response.data[0].max + 1;
          console.log(old_id);
          setFormData3((prevFormData3) => ({
            ...prevFormData3,
            old_id: old_id,
            id: id,
          }));
        });
    } else {
      setFormData3(mData);
    }
  }, []);

  // useEffect(() => {
  //   console.log("formData3===", formData3);
  // }, [formData3])

  //-------------------Handlers---------------------------
  const handle_MM_Option = (event) => {
    setFormData3((prevFormData3) => ({
      ...prevFormData3, monada_metrisis_id: event.target.value,
    }));
  };

  const handleTextBox = (TextBox_id, newValue) => {
    setFormData3((prevFormData3) => ({
      ...prevFormData3,
      [TextBox_id]: newValue,
    }));
  };

  //*************************************** */
  async function handleSubmit3(event) {
    //*******************************/
    event.preventDefault();
    console.log("formData3", formData3);
    console.log("mData", mData);
    //-------------------
    if (mode === "new") {
      //-----------[ INSERT ]-----------------------------
      await axios
        .post(`${process.env.REACT_APP_URL}/api/zlookup/z_ergasies/add`, formData3)
        .then((response) => console.log(response))
        .catch((error) => console.error(error));
    } else if (mode === "edit") {
      //---------[ UPDATE ]-----------------------------
      console.log("formData3===", formData3);
      await axios
        .put(
          `${process.env.REACT_APP_URL}/api/zlookup/z_ergasies/${formData3.id}`,
          formData3
        )
        .then((response) => console.log(response))
        .catch((error) => console.error(error));
    }
    //----------------------------------------------
    onSaveSuccess();
    //----------------------------------------------
    setModalOpen(false);
    //----------------------------------------------
  }

  return (
    <div className="modalBackground">
      <div className="modalContainer modalContainerErgasia">
        <div className="title">
          <div className="row">
            {/* ------------- Title ------------- */}
            <div className="col-10">
              <h4>{mTitle}</h4>
            </div>
            {/* ---------------- Close Button ----------- */}
            <div className="col-2">
              <Button
                id="closeBtn"
                className="btn btn-secondary btn-sm"
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                &nbsp;X&nbsp;
              </Button>
            </div>
            {/* ------------------------------------------- */}
          </div>
          <hr />
        </div>
        {/* ----------------------------[ body ]-------------------------------- */}
        <div className="body">
          <form
            onSubmit={handleSubmit3}
          //</div>style={{ backgroundColor: "#e0ebeb" }}
          >
            {formData3.id && (
              <Box>
                <FormGroup>
                  <div className="row" style={{ marginLeft: "0px" }}>
                    <div className="col-6 text-left">
                      <VtextBox
                        id="id"
                        name="id"
                        label="id"
                        textAlign="center"
                        value={formData3.id}
                        width={80}
                        //onChange={handleTextBox3}   ---ReadOnly---
                        style={{ margin: "10px" }}
                        disabled
                      />
                    </div>
                    <div className="col-6 text-right">
                      <VtextBox
                        id="old_id"
                        name="old_id"
                        label="old_id"
                        textAlign="right"
                        value={formData3.old_id}
                        width={60}
                        //onChange={handleTextBox3}
                        style={{ margin: "10px" }}
                        disabled
                      />
                    </div>
                  </div>
                  <VtextBox
                    id="perigrafi"
                    name="perigrafi"
                    label="Περιγραφή"
                    textAlign="left"
                    value={formData3.perigrafi}
                    width={600}
                    onChange={handleTextBox}
                    maxLength={4}
                    style={{ margin: "10px", }}
                  />
                  {/* ---------------- Radio boxes ---------------------- */}
                  <Typography component="div" style={{ padding: "20px 10px 0px 10px" }}>
                    <GroupBox title={"Μονάδα Μέτρησης :"} backgroundColor="#f7f7f7">
                      {/* ------------------ */}
                      <RadioGroup
                        aria-label="Options"
                        name="k_p_f"
                        value={formData3.monada_metrisis_id}
                        onChange={handle_MM_Option}
                        row // Add the 'row' attribute for horizontal alignment
                        style={{ paddingLeft: 150 }}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="μέτρο"
                        />
                        &nbsp;&nbsp;&nbsp;
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="στρ."
                        />
                        &nbsp;&nbsp;&nbsp;
                        <FormControlLabel
                          value="3"
                          control={<Radio />}
                          label="Τεμάχ."
                        />
                      </RadioGroup>
                      {/* ---------------- */}
                    </GroupBox>
                  </Typography>
                  {/* ------------------ Radio boxes ----end---------- */}
                </FormGroup>
              </Box>
            )}
            <hr style={{ marginTop: "20px" }} />
          </form>
        </div>
        <div className="footer">
          <button
            onClick={() => {
              setModalOpen(false);
            }}
            id="cancelBtn"
          >
            Ακύρωση
          </button>
          <Button
            onClick={handleSubmit3}
            disabled={
              formData3.perigrafi === "" ||
              formData3.monada_metrisis_id === ""
            }
          >
            {mode === "new" ? "Δημιουργία" : "Αποθήκευση"}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Modal_erg;
