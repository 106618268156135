import { useRef, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import Navbar from "react-bootstrap/Navbar";
import Sidebar from "../components/Sidebar.component";
import CoordinatesControl from "../components/CoordinatesControl.component";
import MapToolbar from "../components/mapToolBar.component";
import { Style } from "@mui/icons-material";

import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { LayerGroup, useMap, useMapEvents } from "react-leaflet";
import { Circle } from "react-leaflet/Circle";
import { LayersControl } from "react-leaflet/LayersControl";
import { Pane } from "react-leaflet/Pane";
import BingTileLayer from "../components/BingTileLayer.component";
import { WMSTileLayer } from "react-leaflet/WMSTileLayer";
import { ScaleControl } from "react-leaflet";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";

import "leaflet/dist/leaflet.css";
import "./Vmap.css";

L.Marker.prototype.options.icon = L.icon({
  //iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  iconUrl: "http://leafletjs.com/examples/custom-icons/leaf-green.png",
  shadowUrl: "http://leafletjs.com/examples/custom-icons/leaf-shadow.png",
  iconSize: [30, 95],
  iconAnchor: [20, 94],
  shadowSize: [50, 64],
  shadowAnchor: [4, 62],
  popupAnchor: [0, -76],
});

const { BaseLayer, Overlay } = LayersControl;
const position0 = [40.629901, 22.953772];

const customMarker = new L.icon({
  iconUrl: "/images/icons/location-pointer.svg",
  iconSize: [35, 46],
  iconAnchor: [17, 46],
});

//-----------------------------------------------------------------
/* δυο πανελ (div) Στο δεξιο (χάρτης) και συγκεκριμένα στο MapToolbar
   δημιουργούνται δεδομένα (axios) και πρέπει να μεταφερθούν 
   στο αριστερό (sidebar).  
   1. Parent Page: Vmap.js
      α) Δημιουργούμε μια state var (sidebarData), β) μια function
      Διαχείρισης (handleDataChange) και
      γ) <MapToolbar onDataChange={handleDataChange} ... (μέσω της 
      onDataChange θα γίνει η μεταφορά).

    2. Right panel (MapToolbar component)
      α) Δημιουργούμε prop {onDataChange} για να κάνει την μεταφορά
         MapToolbar = ({ onDataChange }) => {     και
      ///// β) μια function (handleDataChange ) που κάνει την προετοιμασία 
      /////   και στέλνει μέσω της prop onDataChange(newData)
    3. Left Panel (sideBar) παρόμοια με το 2.α)
      α) Δημιουργούμε prop { data } για να παραλάβει την μεταφορά
         Sidebar = ({ data }) => {    και μέσα από το object data
         παίρνουμε τις τιμές.
   */

/* ##################[ Vmap ]################# */
const Vmap = () => {
  //################
  const { id, alat, alng } = useParams();
  console.log("===id,alat,alng===", id, alat, alng);

  //if (id && id === "--")
  //console.log("**********id=0******");
  //else
  //console.log("**********id= '''0******");

  const [position, setPosition] = useState(position0);
  const [formData2, setFormData2] = useState();

  useEffect(() => {
    if (alat && alng) {
      //console.log("===id,alat,alng=22==", id, alat, alng);
      const position1 = [alat, alng];
      setPosition(position1);
    }
  }, [alat, alng]);

  //const [map, setMap] = useState(null);
  const maxZoom = 21;
  const BING_MAPS_KEY =
    "AjG59CvIm2ydzA7QDKMjSc-VtqC4epnaeu-FRYDoKnCWQKcPtScu04zhNrYegYAo";

  //-------------- from Toolbar to sidebar -----------
  const [sidebarData, setSidebarData] = useState("");
  const [is_Dia_Metr, setIs_Dia_Metr] = useState(false);
  const [diax_Metra, setDiax_Metra] = useState();

  const handleDataChange = (newData) => {
    console.log("newData:", newData);
    setSidebarData(newData);
  };

  //------------ from sitebar to Vmap -------
  const [eidosId, setEidosId] = useState("");
  const [filterEidosId, setFilterEidosId] = useState(null);
  const [chWMS1, setChWMS1] = useState(true);
  const [chWMS2, setChWMS2] = useState(false);
  const [layers, setLayers] = useState([]);

  const handleEidosIdUpdate = (newEidosId) => {
    setEidosId(newEidosId);
    console.log("newEidosId=", newEidosId);
    if (newEidosId === 0) {
      setFilterEidosId("");
      setChWMS1(true);
      setChWMS2(false);
    } else {
      const filter = "eidos_id=" + `${newEidosId}`;
      setFilterEidosId(filter);
      setChWMS1(false);
      setChWMS2(true);
    }
  };

  //-------------- first execute ------------------
  useEffect(() => {
    //--------------------
    window.scrollTo(0, 0);
    //--------------------
    setChWMS1(true);
    setChWMS2(false);
    //------------------dendro/:id-------------------
    if (id && !formData2) {
      axios
        .get(`${process.env.REACT_APP_URL}/api/postgis/${id}`)
        .then((response) => {
          const formData = response.data[0];
          console.log("===formData===", formData);
          console.log("===response.data[0]===", response.data[0]);
          setSidebarData(formData);
        })
        .catch((error) => console.error(error));
    }
  }, []);

  useEffect(() => {
    if (sidebarData.st_y) {
      console.log("===sidebarData===", sidebarData);
      const newPoint = [sidebarData.st_y, sidebarData.st_x];
      setPosition(newPoint);
    }
    if (sidebarData.length > 0 && sidebarData[0].old_id === "ooo") {
      const newPoint2 = [
        sidebarData[sidebarData.length - 1].apol_thesi_lat,
        sidebarData[sidebarData.length - 1].apol_thesi_lon,
      ];
      setPosition(newPoint2);
      setIs_Dia_Metr(true);
      //---------------------------------------------
      if (!diax_Metra) {
        axios
          .get(`${process.env.REACT_APP_URL}/api/dia_met/all`)
          .then((response) => {
            setDiax_Metra(response.data);
          })
          .catch((error) => console.error(error));
      }
      //---------------------------------------------
    } else {
      setIs_Dia_Metr(false);
      setDiax_Metra(null);
    }

    console.log("sidebarData===", sidebarData);
  }, [sidebarData]);

  useEffect(() => {
    if (!eidosId || eidosId === 0) {
      setFilterEidosId("");
      setChWMS1(true);
      setChWMS2(false);
    } else {
      const filter = "eidos_id=" + `${eidosId}`;
      setFilterEidosId(filter);
      setChWMS1(false);
      setChWMS2(true);
    }
  }, [eidosId]);
  //-------------------------------------------
  function ShowDiaxMetra() {
    const make_1_Circle = (item) => (
      <Circle
        key={item.den_id}
        center={[item.lat, item.lng]}
        pathOptions={{
          //fillColor: "blue",
          fillOpacity: "0%",
          weight: 4,
          color: "#4dff4d",
        }}
        radius={5.5}
      />
    );

    console.log("diax_Metra=++=", diax_Metra);
    if (diax_Metra) {
      const allCircles = diax_Metra.map(make_1_Circle);
      return <>{allCircles}</>;
    }
  }

  //-------------------------------------------

  function ChangeView({ center, zoom }) {
    const map = useMap();
    map.setView(center, zoom);
    return null;
  }

  const mapRef = useRef();
  const zoom = 19;
  const wms_url = process.env.REACT_APP_WMS_URL;

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3 leftPart">
            <Sidebar
              data={sidebarData}
              handleEidosIdUpdate={handleEidosIdUpdate}
            />
          </div>
          <div
            className="col-lg-9 rightPart"
            style={{ backgroundColor: "#e1e1e1" }}
          >
            {/* ######################################################### */}
            <MapContainer
              ref={mapRef}
              center={position}
              zoom={19}
              maxzoom={23}
              scrollWheelZoom={false}
              style={{ height: "90vh", border: "1px solid #afafaf" }}
            >
              <ChangeView center={position} zoom={zoom} />
              <LayersControl position="topright">
                <Pane>
                  {/* -------------------------[ Open Street Map ]--- */}
                  <LayersControl.BaseLayer name="Open Street Map">
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      maxZoom={maxZoom}
                      maxNativeZoom={19}
                      minZoom={0}
                    />
                  </LayersControl.BaseLayer>
                  {/* -------------------------[ Bing Map Road ]---- */}
                  <LayersControl.BaseLayer name="Bing Map Road">
                    <BingTileLayer
                      bingMapsKey={BING_MAPS_KEY}
                      imagerySet="Road"
                      culture="el"
                      //minZoom="1"
                      maxZoom="21"
                    />
                  </LayersControl.BaseLayer>
                  {/* ---------------------[ ESRI Topo Map ]-------- */}
                  <LayersControl.BaseLayer name="ESRI Topo Map" checked>
                    <TileLayer
                      url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
                      maxZoom={maxZoom}
                      maxNativeZoom={19}
                    ></TileLayer>
                  </LayersControl.BaseLayer>
                  {/* -------------------------[ ESRI Street Map ]------ */}
                  <LayersControl.BaseLayer name="ESRI Street Map">
                    <TileLayer
                      url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
                      maxZoom={maxZoom}
                      maxNativeZoom={19}
                    ></TileLayer>
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer></LayersControl.BaseLayer>
                  {/* -------------------------[ Bing Satellite Map ]--- */}
                  <LayersControl.BaseLayer name="Bing Satellite Map">
                    <BingTileLayer
                      bingMapsKey={BING_MAPS_KEY}
                      imagerySet="Aerial"
                      culture="el"
                      //minZoom="1"
                      maxZoom="21"
                    />
                  </LayersControl.BaseLayer>
                  {/* -------------------------[ ESRI Satellite Map ]--- */}
                  <LayersControl.BaseLayer name="ESRI Satellite Map">
                    <TileLayer
                      url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                      maxZoom={maxZoom}
                      maxNativeZoom={19}
                    ></TileLayer>
                  </LayersControl.BaseLayer>
                  {/* -------------------------[ Κενό ]------------ */}
                  <LayersControl.BaseLayer name="Κενό">
                    <TileLayer
                      attribution=""
                      url=""
                      maxNativeZoom={19}
                      minZoom={0}
                      maxZoom={maxZoom}
                    />
                  </LayersControl.BaseLayer>
                  {/* ----------------------------------------- */}
                </Pane>
                {/* +++++++++++++++++++++++++++++++++++++++++++++++++++ */}
                <Pane>
                  {/* -----------------------[ WMS Layer ]------------ */}
                  <LayersControl.Overlay name="WMS Layer " checked={chWMS1}>
                    <WMSTileLayer
                      url={`${wms_url}`}
                      layers="v_g_dendra"
                      format="image/png"
                      transparent={true}
                      maxZoom={maxZoom}
                    />
                  </LayersControl.Overlay>
                  {/* -----------------------[ my Layers ]------------ */}

                  {/* ---------------------------------------------------- */}
                  <LayersControl.Overlay name="Selected" checked>
                    <LayerGroup>
                      {sidebarData.st_x > 0 && sidebarData.st_y > 0 && (
                        <>
                          <Circle
                            center={[sidebarData.st_y, sidebarData.st_x]}
                            pathOptions={{
                              fillColor: "green",
                              fillOpacity: "20%",
                              weight: 8,
                              color: "red",
                            }}
                            radius={4}
                          />
                          <Circle
                            center={[sidebarData.st_y, sidebarData.st_x]}
                            pathOptions={{
                              weight: 1,
                              color: "red",
                            }}
                            radius={0.05}
                          />
                        </>
                        // ---zoom=radius 21=1  20=2  19=4  18=8  17=14 ---
                      )}
                    </LayerGroup>
                  </LayersControl.Overlay>
                  {/* -----------------------[ my Layers2 ]------------ */}
                  <LayersControl.Overlay name="MultiSelected" checked>
                    <LayerGroup>
                      {sidebarData.length > 0 &&
                        sidebarData[0].old_id === "ooo" && (
                          <>
                            {sidebarData.map((dendro) => {
                              return (
                                <Circle
                                  key={dendro.id}
                                  center={[
                                    dendro.apol_thesi_lat,
                                    dendro.apol_thesi_lon,
                                  ]}
                                  pathOptions={{
                                    fillColor: "green",
                                    fillOpacity: "20%",
                                    weight: 8,
                                    color: "#ffcc00",
                                  }}
                                  radius={4}
                                />
                              );
                            })}
                          </>
                          // ---zoom=radius 21=1  20=2  19=4  18=8  17=14 ---
                        )}
                    </LayerGroup>
                  </LayersControl.Overlay>

                  <LayersControl.Overlay name="Diax_Metra" checked>
                    <LayerGroup>
                      {diax_Metra?.length > 0 && <ShowDiaxMetra />}
                    </LayerGroup>
                  </LayersControl.Overlay>

                  {/* -----------------------[ WMS Layer (filter)]------------ */}
                  {filterEidosId && (
                    <LayersControl.Overlay
                      name="WMS Layer (filter)"
                      checked={chWMS2}
                    >
                      <WMSTileLayer
                        //ref={layRef} id="aaa"
                        url={`${wms_url}`}
                        layers="v_g_dendra"
                        format="image/png"
                        CQL_FILTER={`${filterEidosId}`}
                        transparent={true}
                        maxZoom={maxZoom}
                      />
                      {console.log("filterEidosId--->", filterEidosId)}
                    </LayersControl.Overlay>
                  )}
                </Pane>
              </LayersControl>

              {/*-------------------------*/}
              <MapToolbar onDataChange={handleDataChange} />
              {/*----------------*/}
              <ScaleControl position="topleft" />
              {/*----------------*/}
              <CoordinatesControl />
              {/*-------------------------*/}
            </MapContainer>
            {/* ######################################################### */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Vmap;
