import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Vchart from "../../components/Vchart.component";

import { GiFruitTree } from "react-icons/gi";
import { da } from "date-fns/locale";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

/* ************************************ */
export default function Nekra() {
  /*************************** */
  const [myData, setMyData] = useState({});
  //----------- Sumarize -----------------------
  const [ss_zontano, setSs_zontano] = useState(0);
  const [ss_nekro, setSs_nekro] = useState(0);
  const [ss_den_yparxei, setSs_den_yparxei] = useState(0);
  //----------- Graph --------------------------
  const data_labels = [];
  const data_zontana = [];
  const data_nekra = [];
  const data_den_yparx = [];
  const [titlos, setTitlos] = useState("");
  const [graphData, setGraphData] = useState(null);
  //-----------------------------------------------

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/statist/st_nekra`)
      .then((response) => {
        const formData = response.data;
        setMyData(response.data);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (myData.length > 0) {
      setSs_zontano(
        myData.reduce((sum, obj) => sum + parseInt(obj.s_zontano), 0)
      );
      setSs_nekro(myData.reduce((sum, obj) => sum + parseInt(obj.s_nekro), 0));
      setSs_den_yparxei(
        myData.reduce((sum, obj) => sum + parseInt(obj.s_den_yparxei), 0)
      );

      //-----------------Graph-----------------------
      if (myData.length > 0) {
        myData.forEach((row) => {
          data_labels.push(row.dim_diam + "ο Δ.Δ.");
          data_zontana.push(parseInt(row.s_zontano));
          data_nekra.push(parseInt(row.s_nekro));
          data_den_yparx.push(parseInt(row.s_den_yparxei));
        });
        //-------------titlos-----------------------
        setTitlos("Δένδρα ανά Δημοτικό Διαμέρισμα");
        //--------------graphData-----------------------
        setGraphData({
          labels: data_labels,
          datasets: [
            {
              label: "Ζωντανά",
              backgroundColor: "rgba(0, 153, 51, 0.6)",
              data: data_zontana,
            },
            {
              label: "Νεκρά",
              backgroundColor: "rgba(255, 204, 0, 0.75)",
              data: data_nekra,
            },
            {
              label: "Δέν υπάρχουν",
              backgroundColor: "rgba(255, 77, 77, 0.85)",
              data: data_den_yparx,
            },
          ],
        });
      }
      //--------------------------------------------------
    }
  }, [myData]);

  return (
    <div className="container2">
      <header className="jumbotron" style={{ padding: "1.0rem" }}>
        <h3 className="text-center">
          <b>GreenTree</b> - Στατιστικά
        </h3>
        <h6 className="text-center">(Επισκέπτες - Εγγεγραμμένοι χρήστες)</h6>
      </header>
      <header className="jumbotron" style={{ padding: "1.0rem" }}>
        <h5 className="text-justify">
          <img src="/img/small-logo-green-tree.png" width="120px" alt="Logo" />
          Ζωντανά, Νεκρά, ... ανά Δημοτικό Διαμέρισμα
        </h5>
      </header>
      {/* ------------------------------------------------------------- */}
      <Link to="/statist" className="btn btn-secondary pull-right">
        <GiFruitTree
          color="#AEFDCE"
          style={{ top: "-2px", position: "relative" }}
        />{" "}
        Επιστροφή στα Στατιστικά{" "}
      </Link>
      <div>&nbsp;</div>
      {/*-------------------Graph------------------------------*/}
      <div className="row">
        <div className="col-1"></div>
        <div className="col-10">
          {/*----------------------------------------------------*/}
          {graphData && <Vchart titlos={titlos} data={graphData} />}
          {/*----------------------------------------------------*/}
        </div>
        <div className="col-1"></div>
      </div>
      {/* ---------------------------------------------------- */}
      <div>&nbsp;</div>
      {/* ---------------------------------------------------- */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <b>Δημοτικό Διαμέρισμα</b>
              </StyledTableCell>
              <StyledTableCell align="right">
                <b>Ζωντανά</b>
              </StyledTableCell>
              <StyledTableCell align="right">
                <b>Νεκρά</b>
              </StyledTableCell>
              <StyledTableCell align="right">
                <b>Δέν υπάρχουν</b>
              </StyledTableCell>
              <StyledTableCell align="right">
                <b>ΣΥΝΟΛΟ (Δ.Δ.)</b>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {myData.length > 0 && (
            <TableBody>
              {myData.map((row) => (
                <StyledTableRow key={row.dim_diam}>
                  <StyledTableCell component="th" scope="row">
                    {row.dim_diam}o
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {parseInt(row.s_zontano).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {parseInt(row.s_nekro).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {parseInt(row.s_den_yparxei).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <b>{parseInt(row.syn).toLocaleString("el-GR")}</b>
                  </StyledTableCell>
                </StyledTableRow>
              ))}

              <StyledTableRow
                key={"SYNOLA"}
                sx={{ backgroundColor: "#c2c2c2" }}
              >
                <StyledTableCell align="left">
                  <b>{"Γενικό Σύνολο (Δένδρων)"}</b>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <b>{parseInt(ss_zontano).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <b>{parseInt(ss_nekro).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <b>{parseInt(ss_den_yparxei).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <b>
                    {parseInt(
                      ss_zontano + ss_nekro + ss_den_yparxei
                    ).toLocaleString("el-GR", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    })}
                  </b>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {/* --------------------[ waiting ]--------------------------- */}
      {!myData?.length && (
        <h6 className="text-center text-info">
          <b>Υπολογισμός . . .</b>{" "}
          <img src="/img/wait-1.gif" height={200} alt="Wait" />
        </h6>
      )}
      {/* ---------------------------------------------------------- */}
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <Link to="/statist" className="btn btn-secondary pull-right">
        <GiFruitTree
          color="#AEFDCE"
          style={{ top: "-2px", position: "relative" }}
        />{" "}
        Επιστροφή στα Στατιστικά{" "}
      </Link>
      {/* -------------------------------------------- */}
    </div>
  );
}
