import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import VchartPie from "../../components/VchartPie.component";

import { GiFruitTree } from "react-icons/gi";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

/* ************************************ */
export default function Eidos_h_d_e() {
  const [myData, setMyData] = useState({});
  //----------- Sumarize -----------------------
  const [sszontano, setSszontano] = useState(0);
  const [ssh_min, setSsh_min] = useState(0);
  const [ssh_mid, setSsh_mid] = useState(0);
  const [ssh_max, setSsh_max] = useState(0);
  const [ssd_min, setSsd_min] = useState(0);
  const [ssd_mid, setSsd_mid] = useState(0);
  const [ssd_max, setSsd_max] = useState(0);
  const [sse_min, setSse_min] = useState(0);
  const [sse_mid, setSse_mid] = useState(0);
  const [sse_max, setSse_max] = useState(0);
  //----------- Graph --------------------------
  const [titlos1, setTitlos1] = useState(null);
  const [titlos2, setTitlos2] = useState(null);
  const [titlos3, setTitlos3] = useState(null);
  const [graphData1, setGraphData1] = useState(null);
  const [graphData2, setGraphData2] = useState(null);
  const [graphData3, setGraphData3] = useState(null);
  //-----------------------------------------------
  const [loadData, setLoadData] = useState(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/statist/st_eidos_h_d13_e`)
      .then((response) => {
        const formData = response.data;
        setMyData(response.data);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (myData.length > 0) {
      setSszontano(
        myData.reduce((sum, obj) => sum + parseInt(obj.szontano), 0)
      );
      setSsh_min(myData.reduce((sum, obj) => sum + parseInt(obj.sh_min), 0));
      setSsh_mid(myData.reduce((sum, obj) => sum + parseInt(obj.sh_mid), 0));
      setSsh_max(myData.reduce((sum, obj) => sum + parseInt(obj.sh_max), 0));
      setSsd_min(myData.reduce((sum, obj) => sum + parseInt(obj.sd_min), 0));
      setSsd_mid(myData.reduce((sum, obj) => sum + parseInt(obj.sd_mid), 0));
      setSsd_max(myData.reduce((sum, obj) => sum + parseInt(obj.sd_max), 0));
      setSse_min(myData.reduce((sum, obj) => sum + parseInt(obj.se_min), 0));
      setSse_mid(myData.reduce((sum, obj) => sum + parseInt(obj.se_mid), 0));
      setSse_max(myData.reduce((sum, obj) => sum + parseInt(obj.se_max), 0));
      setLoadData(true);
    }
  }, [myData]);

  //-----------------Graph-----------------------
  useEffect(() => {
    if (loadData) {
      //-------------titlos-----------------------
      setTitlos1("Κατανομή Υψών");
      //--------------graphData-----------------------
      setGraphData1({
        labels: ["< 5 m", "5-10 m", "> 10 m"],
        datasets: [
          {
            label: "Αρ.Δένδρων",
            data: [ssh_min, ssh_mid, ssh_max],
            backgroundColor: [
              "rgba(255, 184, 77, 0.5)",
              "rgba(255, 153, 0, 0.5)",
              "rgba(179, 107, 0, 0.5)",
            ],
            borderColor: [
              "rgba(255, 184, 77, 1)",
              "rgba(255, 153, 0, 1)",
              "rgba(179, 107, 0, 1)",
            ],
            borderWidth: 1,
          },
        ],
      });
      //-------------titlos-----------------------
      setTitlos2("Στηθιαία Διάμετρος");
      //--------------graphData-----------------------
      setGraphData2({
        labels: ["< 10 cm", "10-20 cm", "> 20 cm"],
        datasets: [
          {
            label: "Αρ.Δένδρων",
            data: [ssd_min, ssd_mid, ssd_max],
            backgroundColor: [
              "rgba(83, 198, 140, 0.5)",
              "rgba(51, 153, 102, 0.5)",
              "rgba(31, 96, 64, 0.5)",
            ],
            borderColor: [
              "rgba(83, 198, 140, 1)",
              "rgba(51, 153, 102, 1)",
              "rgba(31, 96, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      });
      //-------------titlos-----------------------
      setTitlos3("Προβολή Κόμης");
      //--------------graphData-----------------------
      setGraphData3({
        labels: ["< 4 m2", "4-16 m2", "> 16 m2"],
        datasets: [
          {
            label: "Αρ.Δένδρων",
            data: [sse_min, sse_mid, sse_max],
            backgroundColor: [
              "rgba(112, 148, 219, 0.5)",
              "rgba(51, 102, 204, 0.5)",
              "rgba(36, 71, 143, 0.5)",
            ],
            borderColor: [
              "rgba(112, 148, 219, 1)",
              "rgba(51, 102, 204, 1)",
              "rgba(36, 71, 143, 1)",
            ],
            borderWidth: 1,
          },
        ],
      });
      //----------------------------------------------
    }
  }, [loadData]);

  return (
    <div className="container2">
      <header className="jumbotron" style={{ padding: "1.0rem" }}>
        <h3 className="text-center">
          <b>GreenTree</b> - Στατιστικά
        </h3>
        <h6 className="text-center">(Επισκέπτες - Εγγεγραμμένοι χρήστες)</h6>
      </header>
      <header className="jumbotron" style={{ padding: "1.0rem" }}>
        <h5 className="text-justify">
          <img src="/img/small-logo-green-tree.png" width="120px" alt="Logo" />
          Ύψος, Διάμετρος, Προβολή ... ανά Είδος
        </h5>
      </header>
      <Link to="/statist" className="btn btn-secondary pull-right">
        <GiFruitTree
          color="#AEFDCE"
          style={{ top: "-2px", position: "relative" }}
        />{" "}
        Επιστροφή στα Στατιστικά{" "}
      </Link>
      <div>&nbsp;</div>
      {/*-------------------Graph------------------------------*/}
      <div className="row">
        <div className="col-4">
          {/*----------------------------------------------------*/}
          {titlos1 && <VchartPie titlos={titlos1} data={graphData1} />}
          {/*----------------------------------------------------*/}
        </div>
        <div className="col-4">
          {/*----------------------------------------------------*/}
          {titlos2 && <VchartPie titlos={titlos2} data={graphData2} />}
          {/*----------------------------------------------------*/}
        </div>
        <div className="col-4">
          {/*----------------------------------------------------*/}
          {titlos3 && <VchartPie titlos={titlos3} data={graphData3} />}
          {/*----------------------------------------------------*/}
        </div>
      </div>
      {/* ---------------------------------------------------- */}
      <div>&nbsp;</div>
      {/* -------------------------------------------- */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell
                colSpan={3}
                align="right"
                style={{ color: "black", backgroundColor: "#ffffff" }}
              >
                <b>Αριθμός Δένδρων με ...</b>
              </StyledTableCell>
              <StyledTableCell
                colSpan={3}
                align="center"
                style={{
                  backgroundColor: "red",
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                <b>Ύψος</b>
              </StyledTableCell>
              <StyledTableCell
                colSpan={3}
                align="center"
                style={{
                  backgroundColor: "green",
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                <b>Στηθιαία Διάμετρος</b>
              </StyledTableCell>
              <StyledTableCell
                colSpan={3}
                align="center"
                style={{
                  backgroundColor: "blue",
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                <b>Προβολή Κόμης</b>
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell
                style={{ border: "1px solid rgba(224, 224, 224, 1)" }}
              >
                <b>κωδ.</b>
              </StyledTableCell>
              <StyledTableCell
                align="right"
                style={{ border: "1px solid rgba(224, 224, 224, 1)" }}
              >
                <b>Κοινή Ονομασία</b>
              </StyledTableCell>
              <StyledTableCell
                align="right"
                style={{ border: "1px solid rgba(224, 224, 224, 1)" }}
              >
                <b>
                  Σύνολο
                  <br />
                  Ζωντανών
                </b>
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{
                  backgroundColor: "red",
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                &lt; 5<br />
                (m)
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{
                  backgroundColor: "red",
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                5 - 10
                <br />
                (m)
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{
                  backgroundColor: "red",
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                &gt; 10
                <br />
                (m)
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{
                  backgroundColor: "green",
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                &lt; 10
                <br />
                (cm)
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{
                  backgroundColor: "green",
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                10-20
                <br />
                (cm)
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{
                  backgroundColor: "green",
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                &gt; 20
                <br />
                (cm)
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{
                  backgroundColor: "blue",
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                &lt; 4<br />
                (m²)
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{
                  backgroundColor: "blue",
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                4-16
                <br />
                (m²)
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{
                  backgroundColor: "blue",
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                &gt; 16
                <br />
                (m²)
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {myData.length > 0 && (
            <TableBody>
              {myData.map((row) => (
                <StyledTableRow key={row.eidos_id}>
                  <StyledTableCell component="th" scope="row">
                    {row.eidos_id}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.common_name}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {parseInt(row.szontano).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right" style={{ color: "red" }}>
                    {parseInt(row.sh_min).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right" style={{ color: "red" }}>
                    {parseInt(row.sh_mid).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right" style={{ color: "red" }}>
                    {parseInt(row.sh_max).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right" style={{ color: "green" }}>
                    {parseInt(row.sd_min).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right" style={{ color: "green" }}>
                    {parseInt(row.sd_mid).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right" style={{ color: "green" }}>
                    {parseInt(row.sd_max).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right" style={{ color: "blue" }}>
                    {parseInt(row.se_min).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right" style={{ color: "blue" }}>
                    {parseInt(row.se_mid).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right" style={{ color: "blue" }}>
                    {parseInt(row.se_max).toLocaleString("el-GR")}
                  </StyledTableCell>{" "}
                </StyledTableRow>
              ))}

              <StyledTableRow
                key={"SYNOLA"}
                sx={{ backgroundColor: "#c2c2c2" }}
              >
                <StyledTableCell align="left">
                  <b>{""}</b>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <b>{"Γενικό Σύνολο (Δένδρων)"}</b>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <b>{parseInt(sszontano).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell align="right" style={{ color: "red" }}>
                  <b>{parseInt(ssh_min).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell align="right" style={{ color: "red" }}>
                  <b>{parseInt(ssh_mid).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell align="right" style={{ color: "red" }}>
                  <b>{parseInt(ssh_max).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell align="right" style={{ color: "green" }}>
                  <b>{parseInt(ssd_min).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell align="right" style={{ color: "green" }}>
                  <b>{parseInt(ssd_mid).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell align="right" style={{ color: "green" }}>
                  <b>{parseInt(ssd_max).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell align="right" style={{ color: "blue" }}>
                  <b>{parseInt(sse_min).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell align="right" style={{ color: "blue" }}>
                  <b>{parseInt(sse_mid).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell align="right" style={{ color: "blue" }}>
                  <b>{parseInt(sse_max).toLocaleString("el-GR")}</b>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {/* --------------------[ waiting ]--------------------------- */}
      {!myData?.length && (
        <h6 className="text-center text-info">
          <b>Υπολογισμός . . .</b>{" "}
          <img src="/img/wait-1.gif" height={200} alt="Wait" />
        </h6>
      )}
      {/* ---------------------------------------------------------- */}
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <Link to="/statist" className="btn btn-secondary pull-right">
        <GiFruitTree
          color="#AEFDCE"
          style={{ top: "-2px", position: "relative" }}
        />{" "}
        Επιστροφή στα Στατιστικά{" "}
      </Link>
      {/* -------------------------------------------- */}
      <div>&nbsp;</div>
      <div>&nbsp;</div>
    </div>
  );
}
