import React, { useRef, useState, useEffect } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

import { DataGrid } from "@mui/x-data-grid";
import { Form, Button, Row, Col } from "react-bootstrap";
import { GiFruitTree } from "react-icons/gi";
import VcheckBox from "../components/VcheckBox.component";

/*##################[ DiaxeirMetra ]################# */
const DiaxMetra_Grid = (props) => {
  //################
  const { mode } = useParams();
  console.log("===mode===", mode);

  const [grid_Data, setGrid_Data] = useState({});

  let navigate = useNavigate();

  useEffect(() => {
    //-----------------------------------------------
    if (mode === "1") {
      axios
        .get(`${process.env.REACT_APP_URL}/api/diaxmetr/filter/1`)
        .then((response) => {
          //this.setState({ data: response.data });
          setGrid_Data(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else if (mode === "0") {
      axios
        .get(`${process.env.REACT_APP_URL}/api/diaxmetr/filter/0`)
        .then((response) => {
          //this.setState({ data: response.data });
          setGrid_Data(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      axios
        .get(`${process.env.REACT_APP_URL}/api/diaxmetr`)
        .then((response) => {
          //this.setState({ data: response.data });
          setGrid_Data(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
    //-----------------------------------------------
  }, []);

  const handleCheckBoxOnChange = (event) => {
    //const { name, value, checked } = event.target;
    //setFormData({ ...formData, [name]: checked === true ? 1 : 0 });
  };

  const columns = [
    {
      field: "id",
      headerName: "id",
      width: 80,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <a href={`/diaxeir/${params.value}`} className="btn btn-info btn-sm">
          &nbsp;{params.value}&nbsp;
        </a>
      ),
    },
    {
      field: "onoma",
      headerName: "Ονομασία",
      width: 200,
      headerClassName: "header-cell",
    },
    {
      field: "parat_prin",
      headerName: "Σχόλια Υλοπ. (Πριν)",
      width: 200,
      headerClassName: "header-cell",
      editable: true,
    },
    {
      field: "isok",
      headerName: "Ολοκλ",
      width: 80,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <VcheckBox
          value={params.row.isok === 1}
          checked={params.row.isok === 1}
          onChange={handleCheckBoxOnChange}
        />
      ),
    },
    {
      field: "parat_meta",
      headerName: "Σχόλια (Μετά)",
      width: 200,
      headerClassName: "header-cell",
    },
    {
      field: "dateok",
      headerName: "Ημερ.Εκτέλ",
      width: 110,
      headerClassName: "header-cell",
    },
    {
      field: "created_at",
      headerName: "Δημιουργήθηκε",
      width: 110,
      headerClassName: "header-cell",
    },
    {
      field: "updated_at",
      headerName: "Ενημερώθηκε",
      width: 110,
      headerClassName: "header-cell",
    },
  ];

  return (
    <div className="container">
      <div>
        <header className="jumbotron" style={{ padding: "1.0rem" }}>
          <h4 className="text-center">{grid_Data.length} Διαχειριστικά Μέτρα (Διαχείριση)</h4>
          <div style={{ height: 500, width: "100%" }}>
            {console.log("grid_Data = ", grid_Data.length)}
            {grid_Data.length && (
              <DataGrid
                rows={grid_Data}
                columns={columns}
                pageSize={10} // Adjust the page size as needed
              //checkboxSelection
              //disableRowSelectionOnClick
              />
            )}
          </div>
        </header>
        <div className="text-center">
          {/* ------------ Επιστροφή ---------------- */}
          <Button className="btn btn-secondary" onClick={() => navigate(-1)}>
            <GiFruitTree
              color="#AEFDCE"
              style={{ top: "-2px", position: "relative" }}
            />{" "}
            Επιστροφή
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DiaxMetra_Grid;
