import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

/**************************** */
const VchartHor = (props) => {
  /********************* */
  const titlos = props.titlos;
  const data = props.data;

  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: true,
        text: titlos,
      },
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          barPercentage: 0.2,
        },
      ],
      yAxes: [
        {
          stacked: true,
          barPercentage: 0.2,
        },
      ],
    },
  };

  // console.log("---data1---", data1);
  // console.log("===data===", data);

  return <Bar options={options} data={data} style={{
    //width: 750,
    height: 2000,
  }} />
};

export default VchartHor;
