import React from "react";
import "./GroupBox.css"; // Import CSS file for styling

const GroupBox = ({ title, children, backgroundColor }) => {
  const groupBoxStyle = {
    header: {
      backgroundColor: backgroundColor || "#f9f9f9", // Set the background color or default to white
    },
    body: {
      backgroundColor: backgroundColor || "#f9f9f9",
    },

  };
  return (
    <div className="group-box" style={groupBoxStyle}>
      <div className="group-box-header" style={groupBoxStyle}>
        {title}{" "}
      </div>
      <div
        className="group-box-body"
        style={{ backgroundColor: backgroundColor }}
      >
        {children}
      </div>
    </div>
  );
};

export default GroupBox;
