import React, { useState } from "react";

import Modal from "../components/Modals/Modal_eidos";
import Button from "react-bootstrap/Button";
import { BiAddToQueue } from "react-icons/bi";

function Temp() {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="App">
      <h1>Hey, click on the button to open the modal.</h1>
      <button
        className="openModalBtn"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        Open
      </button>

      <Button
        onClick={() => {
          setModalOpen(true);
        }}
        className="btn btn-success"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginLeft: "auto",
        }}
      >
        <BiAddToQueue
          color="#AEFDCE"
        //style={{ top: "0px", position: "relative" }}
        />{" "}
      </Button>
      {modalOpen && <Modal setOpenModal={setModalOpen} />}
    </div>
  );
}

export default Temp;
