import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/auth.service";
import axios from "axios";

import { GiFruitTree } from "react-icons/gi";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

//###################################
export default function GuestForm() {
  //#################################
  const { id } = useParams();

  //----------- Check Authentication ------------
  const user = AuthService.getCurrentUser();
  const isEditor =
    user?.roles.includes("ROLE_MODERATOR") || user?.roles.includes("ROLE_ADMIN")
      ? true
      : false;
  //----------------------------------------------
  const [outData, setOutData] = useState({
    id: id,
    email: !user ? "" : user.email,
    fullname: !user ? "" : user.FullName,
    comments: "",
    file: null,
  });
  //-----------------------------------------------
  const [file, setFile] = useState(null);
  const [formData, setFormData] = useState({});

  const [emailSent, setEmailSent] = useState(false);
  const [open, setOpen] = React.useState(true);
  const [emailNotSent, setEmailNotSent] = useState(false);
  const [open2, setOpen2] = React.useState(true);
  const [wait, setWait] = React.useState(false);
  //------------------------------------------
  useEffect(() => {
    //--------------------
    window.scrollTo(0, 0);
    //--------------------
    if (id) {
      axios
        .get(`${process.env.REACT_APP_URL}/api/search/byid/${id}`)
        .then((response) => {
          const results = response.data;
          setFormData(results);
        });
    }
  }, []);

  let navigate = useNavigate();

  const handleReadOnly = (e) => {
    //---do nothing---
  };

  const handleTextBox = (e) => {
    setOutData((prevFormData) => ({
      ...prevFormData,
      [e.target.id]: e.target.value,
    }));
  };

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setOutData((prevFormData) => ({
        ...prevFormData,
        [e.target.id]: e.target.files[0].name,
      }));
      setFile(e.target.files[0]);
    }
  };

  /* ======================================================= */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setWait(true);
    //-----------------------------------------------
    const txtFrom =
      "Μήνυμα / Αναφορά\n         " +
      "από: \n===================================\n " +
      "Ονοματεπ. :  " +
      outData.fullname +
      "\n          email :  " +
      outData.email +
      "\n===================================\n" +
      "id δένδρου : " +
      outData.id +
      "\n===================================\n\n";
    //-----------------------------------------------
    const emailData = {
      from: outData.email,
      to: "dimitriadis@mdat.gr",
      subject: "--- GreenTree --- Μήνυμα - Αναφορά μέσω Συστήματος",
      text: txtFrom + outData.comments,
      file: file,
    };
    //--------------------------------------------------
    try {
      //--------------- 1. Save in DB -------------------
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/dendra/anafora_add/`,
        outData
      );
      //--------------- 2. Send email -------------------
      if (file) {
        const response = await axios.post(
          `${process.env.REACT_APP_URL}/api/email/send2/`,
          emailData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response.data);
      } else {
        //console.log("44444444444444444444");
        const response = await axios.post(
          `${process.env.REACT_APP_URL}/api/email/send/`,
          emailData
        );
        console.log(response.data);
      }
      //------------Ενημέρωση χρήστη οτι στάλθηκε----------
      setWait(false);
      setEmailSent(true);
      setOpen(true);
      setEmailNotSent(false);
      setOpen2(false);
      //---------------------------------------------------
    } catch (error) {
      console.error(error);
      //----------Ενημέρωση χρήστη οτι ΔΕΝ στάλθηκε---------
      setWait(false);
      setEmailSent(false);
      setOpen(false);
      setEmailNotSent(true);
      setOpen2(true);
      //----------------------------------------------------
    }
  };

  //--------------------------------------------------------------
  const [isActiveEmail, setIsActiveEmail] = useState(false);
  const [isActiveFullName, setIsActiveFullName] = useState(false);
  const [isActiveComments, setIsActiveComments] = useState(false);

  const handleFocus = (e) => {
    if (e.target.id === "email") setIsActiveEmail(true);
    else if (e.target.id === "FullName") setIsActiveFullName(true);
    else if (e.target.id === "comments") setIsActiveComments(true);
  };

  const handleBlur = (e) => {
    if (e.target.id === "email") setIsActiveEmail(false);
    else if (e.target.id === "FullName") setIsActiveFullName(false);
    else if (e.target.id === "comments") setIsActiveComments(false);
  };
  //-------------------------------------------------------------

  return (
    <>
      {wait && (
        <>
          <Alert
            variant="outlined"
            severity="info"
            sx={{ mb: 2, backgroundColor: "#e6f2ff" }}
          >
            Περιμένετε ... Διαδικασία Αποστολής email ... &nbsp;&nbsp;&nbsp;
            <img src="/img/wait-4.gif" height={50} alt="Wait" />
          </Alert>
          <div>&nbsp;</div>
        </>
      )}
      {emailSent && (
        <>
          <Collapse in={open}>
            <Alert
              variant="outlined"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2, backgroundColor: "#d9f2e6" }}
            >
              <AlertTitle>
                <b>Επιτυχής Αποστολή Email</b>
              </AlertTitle>
              Η αποστολή του email ολοκληρώθηκε με επιτυχία ...!!!
            </Alert>
            <div>&nbsp;</div>
          </Collapse>
        </>
      )}
      {emailNotSent && (
        <>
          <Collapse in={open2}>
            <Alert
              variant="outlined"
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen2(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2, backgroundColor: "#ffcccc" }}
            >
              <AlertTitle>
                <b>Αποτυχημμένη προσπάθεια Αποστολής Email</b>
              </AlertTitle>
              Η αποστολή του email ΔΕΝ μπόρεσε να ολοκληρωθεί ...!!!
            </Alert>
            <div>&nbsp;</div>
          </Collapse>
        </>
      )}
      {user ? (
        <div className="container">
          <header className="jumbotron" style={{ padding: "1.0rem" }}>
            {/* <h3>{this.state.content}</h3> */}
            <h3 className="text-center">
              <b>GreenTree</b> - Φόρμα Υποβολής
            </h3>
            <h6 className="text-center">
              (Επισκέπτες - Εγγεγραμμένοι χρήστες)
            </h6>
          </header>
          <header className="jumbotron" style={{ padding: "1.0rem" }}>
            <div className="row">
              <div className="col-2">
                <img src="/img/edit-icon-15.png" width="70px" alt="Logo" />
              </div>
              <div className="col-10">
                <div>id = {id}</div>
                {formData[0] && (
                  <>
                    <div>
                      {/* {console.log("formData", formData)} */}
                      Είδος : <b>{formData[0].common_name}</b> ... (
                      {formData[0].botanic_name})
                    </div>
                    <div>Θέση : {formData[0].odos}</div>
                  </>
                )}
              </div>
            </div>
          </header>

          {/* {console.log(user)} */}
          {/* <h6>user:  </h6> */}

          {/* ##################[ Form ]#################### */}
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email">email:</label>
              <input
                type="email"
                id="email"
                value={outData.email}
                onChange={handleReadOnly}
                required
                style={{
                  color: "#724131",
                  border: `1px solid ${isActiveEmail ? "green" : "gray"}`,
                  outline: "none",
                  boxShadow: isActiveEmail ? "0 0 5px blue" : "none",
                  width: "300px",
                  WebkitBorderRadius: "5px 5px 5px 5px",
                  MozBorderRadius: "5px 5px 5px 5px",
                  borderRadius: "5px 5px 5px 5px",
                }}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </div>
            {/* --------------------FullName------------- */}
            <div>
              <label htmlFor="FullName">Ονοματεπώνυμο:</label>
              <input
                type="text"
                id="FullName"
                value={outData.fullname}
                onChange={handleReadOnly}
                required
                style={{
                  color: "#724131",
                  border: `1px solid ${isActiveFullName ? "green" : "gray"}`,
                  outline: "none",
                  boxShadow: isActiveFullName ? "0 0 5px blue" : "none",
                  width: "300px",
                  WebkitBorderRadius: "5px 5px 5px 5px",
                  MozBorderRadius: "5px 5px 5px 5px",
                  borderRadius: "5px 5px 5px 5px",
                }}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </div>
            {/* -----------------comments---------------- */}
            <div style={{ position: "relative" }}>
              <label htmlFor="comments">Σχόλια - Παρατηρήσεις :</label>
              <textarea
                id="comments"
                name="comments"
                onChange={handleTextBox}
                style={{
                  border: `1px solid ${isActiveComments ? "green" : "gray"}`,
                  outline: "none",
                  boxShadow: isActiveComments ? "0 0 5px blue" : "none",
                  width: "400px",
                  height: "134px",
                  resize: "both",
                  WebkitBorderRadius: "5px 5px 0px 5px",
                  MozBorderRadius: "5px 5px 0px 5px",
                  borderRadius: "5px 5px 0px 5px",
                }}
                required
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder="Πληκτρολογήστε τα σχόλιά σας ..."
              />
            </div>
            {/* ------------------file----------------- */}
            <div>
              <label htmlFor="file">
                Μεταφόρτωση -ανέβασμα εικόνας (Προαιρετικό):
              </label>
              <input
                className="btn btn-outline-info btn-sm"
                type="file"
                id="file"
                name="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ width: "400px" }}
              />
            </div>
            <div>&nbsp;</div>

            <button type="submit" className="btn btn-primary">
              Υποβολή
            </button>
            {/* ------------ Επιστροφή ---------------- */}
            <button
              className="btn btn-secondary float-right"
              onClick={() => navigate(-1)}
            >
              <GiFruitTree
                color="#AEFDCE"
                style={{ top: "-2px", position: "relative" }}
              />{" "}
              Επιστροφή-1
            </button>
          </form>
        </div>
      ) : (
        <div className="container">
          <header className="jumbotron" style={{ padding: "1.0rem" }}>
            <h5 className="text-center">
              <b>Προσοχή !!!</b>
              <br />
              <br />
              Υποβολή Αναφοράς - Σχολίων, έχουν δικαίωμα μόνο οι εγγεγραμμένοι
              χρήστες.
            </h5>
            <div>&nbsp;</div>
            <h6 className="text-left">
              Αν είστε ήδη εγγεγραμμένος χρήστης θα πρέπει να κάνετε εισαγωγή
              ... στο σύστημα (Login)
              <br />
              <br />
              Σε αντίθετη περίπτωση θα πρέπει να κάνετε :<br />
              (1) εγγραφή νέου χρήστη (Sign Up) και στη συνέχεια,
              <br />
              (2) να κάνετε εισαγωγή ... στο σύστημα (Login)
            </h6>
          </header>
          {/* ------------ Επιστροφή ---------------- */}
          <button
            className="btn btn-secondary float-center"
            onClick={() => navigate(-1)}
          >
            <GiFruitTree
              color="#AEFDCE"
              style={{ top: "-2px", position: "relative" }}
            />{" "}
            Επιστροφή-2
          </button>
        </div>
      )}
    </>
  );
}
