import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useMapEvents } from "react-leaflet";
import { useNavigate } from "react-router-dom";
//--------------------------------
import { FaMapMarkerAlt } from "react-icons/fa";
import { IoAnalyticsOutline } from "react-icons/io5";
import { FaInfo } from "react-icons/fa";
import { GrAddCircle } from "react-icons/gr";
import { GiMultipleTargets } from "react-icons/gi";
import { AiFillDatabase } from "react-icons/ai";
import { AiTwotoneContainer } from "react-icons/ai";
import { CiRuler } from "react-icons/ci";
import { BiRuler } from "react-icons/bi";
import AuthService from "../services/auth.service";
//------------------------
import proj4 from "proj4";
//------------------------
import L from "leaflet";
import { Popup } from "react-leaflet/Popup";
//import customImage from "../../public/img/flag_32.png";
//--------------------------------------------------------------
let readyPoint = false;

//******************* Toolbar ****************************
const MapToolbar = ({ onDataChange }) => {
  //**************************************

  //----------- Check Authentication ------------
  const currentUser = AuthService.getCurrentUser();
  //console.log("currentUser.id", currentUser.id);

  const [state, setState] = useState({
    redirect: null,
    userReady: false,
    currentUser: { username: "" },
    roles: {},
  });
  const [roleOk, setRoleOk] = useState(false);
  //----------------------------------------------

  const styleActiveTool = { color: "#ecffb3", fontSize: "1.65em" };
  const styleNoActiveTool = { color: "black", fontSize: "1.2em" };

  const [resetDist, setResetDist] = useState(false);
  const [style1, setStyle1] = useState(styleNoActiveTool);
  const [style2, setStyle2] = useState(styleNoActiveTool);
  const [style3, setStyle3] = useState(styleNoActiveTool);
  const [style4, setStyle4] = useState(styleNoActiveTool);
  const navigate = useNavigate();
  const [insID, setInsID] = useState(null);
  const [dendra, setDendra] = useState({});
  const [stack, setStack] = useState([]);
  const arr = [];
  //---------------------------------------------
  //const [egsaX, setEgsaX] = useState(null);
  //const [egsaY, setEgsaY] = useState(null);
  //---------------------------------------------
  const toolbarRef = useRef(null);
  //---------------------------------------------
  const [formData, setFormData] = useState(null);
  //---------------------------------------------
  const [activeTool, setActiveTool] = useState(null);
  //------------------------------------------------
  let _pointA,
    _pointB,
    _polyline,
    markerA = null,
    markerB = null,
    distance = null;

  _polyline = L.polyline([
    [40.0, 24.0],
    [40.0, 24.1],
  ]); //.addTo(map);
  markerA = L.marker([40.1, 24.0]);
  markerB = L.marker([40.1, 24.1]);

  const [distance2, setDistance2] = useState(null);

  //------------- Convert to EGSA87-----------------
  const Projection_4326 = "EPSG:4326"; // latLng projection
  const Projection_2100 = "EPSG:2100"; // Greek Grid projection
  const Projection_3857 = "EPSG:3857"; // leaflet default projection
  //------------ Define Projection
  proj4.defs(
    Projection_2100,
    "+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=-199.87,74.79,246.62,0,0,0,0 +units=m +no_defs +type=crs"
  );
  //------------ Create a projection object
  const EPSG_2100 = proj4(Projection_2100);
  //- - - - - - - - - - - - - 
  function formatX(latlong) {
    const [x, y] = EPSG_2100.forward([latlong.lng, latlong.lat]);
    return (Math.round(Math.abs(x) * 100) / 100).toFixed(2);
  }
  function formatY(latlong) {
    const [x, y] = EPSG_2100.forward([latlong.lng, latlong.lat]);
    return (Math.round(Math.abs(y) * 100) / 100).toFixed(2);
  }
  //------------------------------------------------
  // Create a custom icon using your image
  const customIconA = L.icon({
    iconUrl: "/img/flagA_32.png",
    iconSize: [30, 30], // Adjust the size as needed
    iconAnchor: [6, 28.5], // Adjust the anchor point if necessary
  });
  const customIconB = L.icon({
    iconUrl: "/img/flagB_32.png",
    iconSize: [30, 30], // Adjust the size as needed
    iconAnchor: [6, 28.5], // Adjust the anchor point if necessary
  });





  /***************************************************** */
  const map = useMapEvents({
    click(e) {
      if (
        toolbarRef.current &&
        toolbarRef.current.contains(e.originalEvent.target)
      ) {
        //-------Clicked on the toolbar--------------
        setFormData(null);
        if (activeTool !== null && activeTool !== "getMultiPoints") {
          setStack([]);
          setFormData(null);
        }

        map.removeLayer(_polyline);
        map.removeLayer(markerA);
        map.removeLayer(markerB);
        markerA = null;
        markerB = null;
        distance = null;
      } else {
        //-------Click events on the map-------------
        const lat = e.latlng.lat;
        const lng = e.latlng.lng;
        const egsaX = formatX(e.latlng);
        const egsaY = formatY(e.latlng);
        console.log(lat, lng);
        console.log(egsaX, egsaY);
        //----------------------[ getAttributes ]--------------------------
        if (lat && lng && activeTool === "getAttributes") {
          //setStack([]);
          axios
            .get(`${process.env.REACT_APP_URL}/api/postgis/${lat}/${lng}`)
            .then((response) => {
              const results = response.data[0];
              setFormData(results);
            });
        } else if (lat && lng && activeTool === "AddLine") {
          //----------------------[ AddLine ]------------------------------
          //console.log(e.latlng);
          if (!_pointA) {
            _pointA = e.latlng;
            markerA = L.marker(_pointA, { icon: customIconA }).addTo(map);
          } else if (!_pointB) {
            _pointB = e.latlng;
            markerB = L.marker(_pointB, { icon: customIconB }).addTo(map);
            _polyline = L.polyline([_pointA, _pointB], {
              color: "#ff0000",
              dashArray: "1, 5",
            }).addTo(map);

            distance = map.distance(_pointA, _pointB).toFixed(1);
            //const distanceInMeters = _pointA.distanceTo(_pointB);
            //setDistance2(distanceInMeters.toFixed(2) + " meters");
            //console.log("===distance===", distance);
            //console.log("=distance2=",distanceInMeters.toFixed(2));
          } else {
            if (_polyline) {
              map.removeLayer(_polyline);
              _polyline = null;
              distance = null;
            }
            _pointA = e.latlng;
            map.removeLayer(markerA);
            map.removeLayer(markerB);
            _pointB = null;
            markerA = L.marker(_pointA, { icon: customIconA }).addTo(map);
          }
          if (distance) {
            const middleLeft = map.containerPointToLatLng([
              //0,
              //map.getSize().y / 2,
              20 + map.getSize().x / 2,
              110,
            ]);
            L.popup({ closeButton: true })
              .setLatLng(middleLeft)
              .setContent(
                `<span style="color: #005500; font-size:14px">Απόσταση : 
                 <span style="color: #CC0000; font-size:18px"><b> ${distance} 
                 </b></span>μέτρα</span>`
              )
              .openOn(map);
          }

          //
        } else if (lat && lng && activeTool === "AddMarker") {
          //----------------------[ AddMarker ]----------------------------
          //setStack(null);
          setActiveTool(null);
          map.getContainer().style.cursor = "";
          //setStyle3(styleNoActiveTool);
          //---------------------------------------------------------------
          const fData = {
            lat: lat, lng: lng, egsaX: egsaX, egsaY: egsaY,
            user_id: currentUser.id
          };
          //---------------------------------------------------------------
          axios
            .post(`${process.env.REACT_APP_URL}/api/dendra/add`, fData)
            .then((response) => {
              const results = response.data;
              setInsID(results.id);
            });
        } else if (lat && lng && activeTool === "getMultiPoints") {
          axios
            .get(`${process.env.REACT_APP_URL}/api/postgis/${lat}/${lng}`)
            .then((response) => {
              const results = response.data[0];
              const p_Id = results.id;
              const p_eidos_id = results.eidos_id;
              const p_common_name = results.common_name;
              const p_botanic_name = results.botanic_name;
              const p_lat = results.apol_thesi_lat;
              const p_lng = results.apol_thesi_lon;
              //----------------------------------------------------
              const curDendro = {
                id: p_Id,
                eidos_id: p_eidos_id,
                botanic_name: p_botanic_name,
                common_name: p_common_name,
                lat: p_lat,
                lng: p_lng,
              };
              //----------------------------------------------------
              // Check if the curDendro already exists in the stack
              const existingPoint = stack.find((point) => point.id === p_Id);
              if (existingPoint) {
                // Remove the curDendro from the stack
                const updatedStack = stack.filter((point) => point.id !== p_Id);
                setStack(updatedStack);
              } else {
                // Add the curDendro to the stack
                setStack([...stack, curDendro]);
              }
            });
        }
      }
      //-------------------------------------------------------------------
    },
    mouseMove(e) {
      //console.log("ooooooooo");
    },
  });

  //-------------[]----------------
  useEffect(() => {
    //-------------- Auth -------------------
    if (!currentUser) {
      setState({ redirect: "/home" });
      setRoleOk(false);
    } else {
      setState({
        currentUser: currentUser,
        userReady: true,
        roles: currentUser.roles,
      });
      setRoleOk(
        currentUser?.roles.includes("ROLE_MODERATOR") ||
          currentUser?.roles.includes("ROLE_ADMIN")
          ? true
          : false
      );
    }
  }, []);

  //-------------[insID]---------------
  useEffect(() => {
    if (insID) {
      navigate(`/getdendro2/${insID}`);
    }
  }, [insID]);

  const refreshMap = () => { };

  //---------[formData]--------
  useEffect(() => {
    if (formData) {
      onDataChange(formData);
    } else {
      const empty = {
        id: "...",
        old_id: "...",
        eidos_id: "0",
        botanic_name: "...",
        common_name: "...",
        st_x: 0,
        st_y: 0,
        apol_thesi_lat: 0,
        apol_thesi_lon: 0,
      };
      onDataChange(empty);
    }
  }, [formData]);

  //--------[stack]-----------
  useEffect(() => {
    console.log("stack.length ==", stack.length);
    if (stack.length > 0) {
      const lista = [];
      stack.forEach((item, index) => {
        lista.push({
          id: item.id,
          old_id: "ooo",
          eidos_id: item.eidos_id,
          botanic_name: item.botanic_name,
          common_name: item.common_name,
          st_x: item.lng,
          st_y: item.lat,
          apol_thesi_lat: item.lat,
          apol_thesi_lon: item.lng,
        });
      });
      console.log("lista = ", lista);
      onDataChange(lista);
    }
    console.log("stack ==== ", stack);
  }, [stack]);

  function handleGetAttributes() {
    if (activeTool === "getAttributes") {
      setActiveTool(null);
      map.getContainer().style.cursor = "";
      setFormData(null);
      setStyle1(styleNoActiveTool);
    } else {
      setActiveTool("getAttributes");
      map.getContainer().style.cursor = "crosshair";
      setStyle1(styleActiveTool);
      setStyle2(styleNoActiveTool);
      setStyle3(styleNoActiveTool);
      setStyle4(styleNoActiveTool);
    }
  }

  function handleAddLine() {
    if (activeTool === "AddLine") {
      setActiveTool(null);
      map.getContainer().style.cursor = "";
      setStyle2(styleNoActiveTool);
    } else {
      setActiveTool("AddLine");
      map.getContainer().style.cursor = "crosshair";
      setStyle1(styleNoActiveTool);
      setStyle2(styleActiveTool);
      setStyle3(styleNoActiveTool);
      setStyle4(styleNoActiveTool);
    }
  }

  function handleAddMarker() {
    if (activeTool === "AddMarker") {
      setActiveTool(null);
      map.getContainer().style.cursor = "";
      setFormData(null);
      setStyle3(styleNoActiveTool);
    } else {
      setActiveTool("AddMarker");
      map.getContainer().style.cursor = "crosshair";
      setStyle1(styleNoActiveTool);
      setStyle2(styleNoActiveTool);
      setStyle3(styleActiveTool);
      setStyle4(styleNoActiveTool);
    }
  }

  function handleGetMultiPoints() {
    if (activeTool === "getMultiPoints") {
      setActiveTool(null);
      map.getContainer().style.cursor = "";
      setFormData(null);
      setStyle4(styleNoActiveTool);
    } else {
      setActiveTool("getMultiPoints");
      map.getContainer().style.cursor = "crosshair";
      setStyle1(styleNoActiveTool);
      setStyle2(styleNoActiveTool);
      setStyle3(styleNoActiveTool);
      setStyle4(styleActiveTool);
    }
  }

  function handleMapClick() {
    // reset the active tool and cursor when the map is clicked
    setActiveTool(null);
    map.getContainer().style.cursor = "";
    setFormData(null);
  }

  const bg_On = "#5e6e5e";
  const bgOff = "#f1f4f1";

  return (
    <>
      <div
        ref={toolbarRef}
        className="leaflet-bar leaflet-control"
        style={{
          position: "relative",
          transform: "translateY(-50%)",
          //top: "50%",
          top: "2.1%",
          left: "46%",
          display: "flex",
          backgroundColor: "#bbbbbb",
          borderColor: "#bbbbbb",
        }}
      >
        &nbsp;
        <a
          //   href="#"
          title="Επιλογή και Προβολή Δένδρου"
          onClick={handleGetAttributes}
          style={{
            cursor: activeTool === "getAttributes" ? "crosshair" : "",
            backgroundColor:
              activeTool === "getAttributes" ? `${bg_On}` : `${bgOff}`,
          }}
        >
          <FaInfo style={style1} />
        </a>
        &nbsp;
        <a
          //   href="#"
          title="Μέτρηση Απόστασης"
          onClick={handleAddLine}
          style={{
            cursor: activeTool === "AddLine" ? "pointer" : "",
            backgroundColor: activeTool === "AddLine" ? `${bg_On}` : `${bgOff}`,
          }}
        >
          <BiRuler style={style2} />
        </a>
        &nbsp;
        {roleOk && (
          <a
            //   href="#"
            title="Προσθήκη Νέου Δένδρου"
            onClick={handleAddMarker}
            style={{
              cursor: activeTool === "AddMarker" ? "inherit" : "",
              backgroundColor:
                activeTool === "AddMarker" ? `${bg_On}` : `${bgOff}`,
            }}
          >
            <GrAddCircle style={style3} />
          </a>
        )}
        &nbsp;
        {roleOk && (
          <>
            <a
              //   href="#"
              title="Επιλογή πολλών δενδρων για Διαχειριστικά Μέτρα"
              onClick={handleGetMultiPoints}
              style={{
                cursor: activeTool === "getMultiPoints" ? "crosshair" : "",
                backgroundColor:
                  activeTool === "getMultiPoints" ? `${bg_On}` : `${bgOff}`,
              }}
            >
              <AiTwotoneContainer style={style4} />
            </a>
            &nbsp;
          </>
        )}
        {activeTool && (
          <div
            className="leaflet-overlay-pane"
            onClick={handleMapClick}
            style={{ cursor: "crosshair" }}
          ></div>
        )}
      </div>
    </>
  );
};

export default MapToolbar;
