import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Modal.css";
import Button from "react-bootstrap/Button";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import VtextBox from "../VtextBox.component";
import GroupBox from "../GroupBox.component";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Md10K } from "react-icons/md";
import Typography from "@mui/material/Typography";

//***************************************************************** */
function Modal_eidos({ setModalOpen, mTitle, mData, onSaveSuccess }) {
  //************************************************************* */
  //console.log("mData=", mData, Object.keys(mData).length === 0);

  const mode = Object.keys(mData).length === 0 ? "new" : "edit";
  const [formData3, setFormData3] = useState({});
  const [selectedCatOption, setSelectedCatOption] = useState(null);

  useEffect(() => {
    if (mode === "new") {
      setFormData3({
        id: 0,
        old_id: 0,
        code: "",
        botanic_name: "",
        common_name: "",
        g_r: 0.5,
        kon: 1,
        plat: 0,
        foin: 0,
      });
      setSelectedCatOption(1);
      axios
        .get(`${process.env.REACT_APP_URL}/api/zlookup/z_eidos/max`)
        .then((response) => {
          console.log(response.data[0].max);
          const old_id = response.data[0].max + 1;
          const id = response.data[0].max + 2;
          console.log(old_id);
          setFormData3((prevFormData3) => ({
            ...prevFormData3,
            old_id: old_id,
            id: id,
          }));
          setSelectedCatOption("1");
        });
    } else {
      setFormData3(mData);
      //----------------------------
      if (mData.plat === 1) {
        setSelectedCatOption("2");
      } else if (mData.foin === 1) {
        setSelectedCatOption("3");
      } else {
        setSelectedCatOption("1");
      }
      //----------------------------
    }
  }, []);

  // useEffect(() => {
  //   console.log("===formData3===", formData3);
  // }, [formData3]);

  useEffect(() => {
    if (selectedCatOption === "2") {
      setFormData3((prevFormData3) => ({
        ...prevFormData3,
        kon: 0,
        plat: 1,
        foin: 0,
      }));
    } else if (selectedCatOption === "3") {
      setFormData3((prevFormData3) => ({
        ...prevFormData3,
        kon: 0,
        plat: 0,
        foin: 1,
      }));
    } else {
      setFormData3((prevFormData3) => ({
        ...prevFormData3,
        kon: 1,
        plat: 0,
        foin: 0,
      }));
    }
  }, [selectedCatOption]);

  //-------------------Handlers---------------------------
  const handleCatOption = (event) => {
    setSelectedCatOption(event.target.value);
  };

  const handleTextBox_code = (TextBox_id, newValue) => {
    if (/^[A-Z0-9]{0,4}$/.test(newValue)) {
      setFormData3((prevFormData3) => ({
        ...prevFormData3,
        [TextBox_id]: newValue,
      }));
    }
  };
  const handleTextBox_botanic_name = (TextBox_id, newValue) => {
    if (/^[A-Za-z0-9\s]*$/.test(newValue)) {
      setFormData3((prevFormData3) => ({
        ...prevFormData3,
        [TextBox_id]: newValue,
      }));
    }
  };
  const handleTextBox_common_name = (TextBox_id, newValue) => {
    if (/^[Α-Ω0-9\s]*$/.test(newValue)) {
      setFormData3((prevFormData3) => ({
        ...prevFormData3,
        [TextBox_id]: newValue,
      }));
    }
  };
  const handleTextBox_g_r = (TextBox_id, newValue) => {
    if (/^\d+(\.\d{0,2})?$/.test(newValue)) {
      setFormData3((prevFormData3) => ({
        ...prevFormData3,
        [TextBox_id]: newValue,
      }));
    }
  };

  const handleTextBox3 = (TextBox_id, newValue) => {
    console.log(formData3, TextBox_id, newValue);
    setFormData3((prevFormData3) => ({
      ...prevFormData3,
      [TextBox_id]: newValue,
    }));
  };

  //*************************************** */
  async function handleSubmit3(event) {
    //*******************************/
    event.preventDefault();
    console.log("formData3", formData3);
    console.log("mData", mData);
    if (mode === "new") {
      //-----------[ INSERT ]-----------------------------
      await axios
        .post(`${process.env.REACT_APP_URL}/api/zlookup/z_eidos/add`, formData3)
        .then((response) => console.log(response))
        .catch((error) => console.error(error));
    } else if (mode === "edit") {
      //---------[ UPDATE ]-----------------------------
      await axios
        .put(
          `${process.env.REACT_APP_URL}/api/zlookup/z_eidos/${formData3.id}`,
          formData3
        )
        .then((response) => console.log(response))
        .catch((error) => console.error(error));
    }
    //----------------------------------------------
    onSaveSuccess();
    //----------------------------------------------
    setModalOpen(false);
    //----------------------------------------------
  }

  return (
    <div className="modalBackground">
      <div className="modalContainer modalContainerEidos">
        {/* <div className="titleCloseBtn22"> */}
        <div className="title">
          <div className="row">
            <div className="col-10">
              <h4>{mTitle}</h4>
            </div>
            <div className="col-2">
              <Button
                id="closeBtn"
                className="btn btn-secondary btn-sm"
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                &nbsp;X&nbsp;
              </Button>
            </div>
          </div>
          <hr />
        </div>
        {/* ----------------------------[ body ]-------------------------------- */}
        <div className="body">
          <form
            onSubmit={handleSubmit3}
          //</div>style={{ backgroundColor: "#e0ebeb" }}
          >
            {formData3.id && (
              <Box>
                <FormGroup>
                  <div className="row" style={{ marginLeft: "0px" }}>
                    <VtextBox
                      id="id"
                      name="id"
                      label="id"
                      textAlign="center"
                      value={formData3.id}
                      width={80}
                      //onChange={handleTextBox3}   ---ReadOnly---
                      style={{ margin: "10px" }}
                      disabled
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <VtextBox
                      id="old_id"
                      name="old_id"
                      label="old_id"
                      textAlign="right"
                      value={formData3.old_id}
                      width={60}
                      //onChange={handleTextBox3}
                      style={{ margin: "10px" }}
                      disabled
                    />
                  </div>
                  <VtextBox
                    id="code"
                    name="code"
                    label="Κωδ.(4 Λατ.κεφαλαία)"
                    textAlign="left"
                    value={formData3.code}
                    width={190}
                    onChange={handleTextBox_code}
                    maxLength={4}
                    style={{ margin: "10px" }}
                  />
                  <VtextBox
                    id="botanic_name"
                    name="botanic_name"
                    label="Βοτανική Ονομασία (Λατ.πεζοκεφαλαία)"
                    textAlign="left"
                    value={formData3.botanic_name}
                    width={400}
                    onChange={handleTextBox_botanic_name}
                    style={{ margin: "10px" }}
                  />
                  <VtextBox
                    id="common_name"
                    name="common_name"
                    label="Κοινή Ονομασία (Ελλ.κεφαλαία)"
                    textAlign="left"
                    value={formData3.common_name}
                    width={400}
                    onChange={handleTextBox_common_name}
                    style={{ margin: "10px" }}
                  />
                  <VtextBox
                    id="g_r"
                    name="g_r"
                    label="Μέση ετήσ.σύξηση d1,3(cm)"
                    textAlign="center"
                    value={formData3.g_r}
                    width={250}
                    onChange={handleTextBox_g_r}
                    style={{ margin: "10px" }}
                  />
                  {/* ---------------- Radio boxes ---------------------- */}
                  <Typography component="div" style={{ padding: 10 }}>
                    <GroupBox title={"Κατηγορία Είδους :"} backgroundColor="#f7f7f7">
                      {/* ------------------ */}
                      <RadioGroup
                        aria-label="Options"
                        name="k_p_f"
                        value={selectedCatOption}
                        onChange={handleCatOption}
                        row // Add the 'row' attribute for horizontal alignment
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Κωνοφόρα"
                        />
                        &nbsp;&nbsp;&nbsp;
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="Πλατύφυλλα"
                        />
                        &nbsp;&nbsp;&nbsp;
                        <FormControlLabel
                          value="3"
                          control={<Radio />}
                          label="Φοινικοειδή"
                        />
                      </RadioGroup>

                    </GroupBox>

                  </Typography>

                  {/* ------------------ Radio boxes ----end---------- */}
                </FormGroup>
              </Box>
            )}
          </form>
        </div>
        <div className="footer">
          <button
            onClick={() => {
              setModalOpen(false);
            }}
            id="cancelBtn"
          >
            Ακύρωση
          </button>
          <Button
            onClick={handleSubmit3}
            disabled={
              formData3.code === "" ||
              formData3.botanic_name === "" ||
              formData3.common_name === ""
            }
          >
            {mode === "new" ? "Δημιουργία" : "Αποθήκευση"}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Modal_eidos;
