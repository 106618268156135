import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

/**************************** */
const Vchart = (props) => {
  /********************* */
  const titlos = props.titlos;
  const data = props.data;

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: titlos,
      },
    },
  };
  // console.log("---data1---", data1);
  // console.log("===data===", data);

  return <Bar options={options} data={data} />;
};

export default Vchart;
