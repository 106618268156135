import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Image } from "@mui/icons-material";

const ThumbnailWithDialog = ({ thumbnailUrl, fullSizeUrl, alt }) => {
  const [open, setOpen] = useState(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleOpenDialog}>
        <img
          src={thumbnailUrl}
          alt={alt}
          style={{ maxWidth: "40%", maxHeight: "40%" }}
        />
      </IconButton>
      <Dialog open={open} onClose={handleCloseDialog} maxWidth="md">
        <DialogTitle>
          {alt}
          <button
            className="btn btn-secondary btn-sm float-right"
            onClick={handleCloseDialog}
          >
            X
          </button>
        </DialogTitle>
        <DialogContent>
          <img
            src={fullSizeUrl}
            alt={alt}
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ThumbnailWithDialog;
