import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const VcheckBox = (props) => {
  const { id, name, checked, label, onChange, className, ...rest } = props;

  const handleChange = (event) => {
    //if (onChange) {
    onChange(event);
    //}
  };

  return (
    <FormControlLabel
      id={id}
      name={name}
      checked={checked}
      control={<Checkbox />}
      className={className}
      label={label}
      onChange={handleChange}
      disabled={props.disabled}
    />
  );
};

export default VcheckBox;
