import React, { useRef, useState, useEffect } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

import { DataGrid } from "@mui/x-data-grid";
import { Form, Button, Row, Col } from "react-bootstrap";
import { GiFruitTree } from "react-icons/gi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlineClose } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";
import { BsFillTrash3Fill } from "react-icons/bs";

import ConfirmBox from "../components/ConfirmBox.component";
import Modal_eidos from "../components/Modals/Modal_eidos";
import VcheckBox from "../components/VcheckBox.component";

/*################################ */
const Table_z_eidos = (props) => {
  //############################
  const [grid_Data, setGrid_Data] = useState({});

  let navigate = useNavigate();

  useEffect(() => {
    LoadData();
  }, []);

  function LoadData() {
    //-----------------------------------------------
    axios
      .get(`${process.env.REACT_APP_URL}/api/zlookup/z_eidos/all`)
      .then((response) => {
        setGrid_Data(response.data);
        //console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    //-----------------------------------------------
  }

  const RefreshData = () => {
    // Call this function to refresh the data in your DataGrid.
    LoadData();
  };

  //==================[ DEL ]===================
  const [openDel, setOpenDel] = useState(false);
  const [idForDel, setIdForDel] = useState(0);
  //--------------------------------------------
  const Confirm_Delete = (id) => {
    setIdForDel(id);
    setOpenDel(true);
  };
  //--------------------------------------------
  const deleteEidos = () => {
    console.log("===[ idForDel ]===", idForDel);
    //---------------------------------------------------------------------
    axios
      .delete(`${process.env.REACT_APP_URL}/api/zlookup/z_eidos/${idForDel}`)
      .then((res) => {
        RefreshData();
        setOpenDel(false);
      })
      .catch((err) => {
        console.log(err);
      });
    //----------------------------------------
  };
  //============================================

  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalData, setModalData] = useState(null);
  //==================[ EDIT ]===================
  const Edit_Eidos = (edit_data) => {
    //console.log("edit_data = ", edit_data);
    setModalOpen(true);
    setModalTitle("Προβολή/Ενημέρωση Είδους");
    setModalData(edit_data);
  };
  //==================[ ADD ]===================
  const AddNewEidos = () => {
    //console.log("Add_new_Eidos");
    setModalOpen(true);
    setModalTitle("Προσθήκη Νέου Είδους");
    setModalData({});
  };
  //============================================

  const handleCheckBoxOnChange = (event) => {
    //const { name, value, checked } = event.target;
    //setFormData({ ...formData, [name]: checked === true ? 1 : 0 });
  };

  const columns = [
    {
      field: "del",
      headerName: "Del",
      width: 50,
      renderCell: (params) => (
        <div>
          <button
            className="btn btn-outline-secondary btn-sm"
            onClick={() => Confirm_Delete(params.row.id)}
            style={{ color: "red" }}
          >
            <BsFillTrash3Fill color="#FF0000" />{" "}
          </button>
        </div>
      ),
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 50,
      renderCell: (params) => (
        <div>
          <button
            className="btn btn-success btn-sm"
            onClick={() => Edit_Eidos(params.row)}
            style={{ color: "red" }}
          >
            <AiOutlineEdit color="#FFFFFF" />{" "}
          </button>
        </div>
      ),
    },
    {
      field: "id",
      headerName: "id",
      width: 80,
      headerClassName: "header-cell",
    },
    {
      field: "code",
      headerName: "Κωδικός",
      width: 80,
      headerClassName: "header-cell",
      editable: true,
    },
    {
      field: "botanic_name",
      headerName: "Βοτανική Ονομασία",
      width: 200,
      headerClassName: "header-cell",
    },
    {
      field: "common_name",
      headerName: "Κοινή Ονομασία",
      width: 200,
      headerClassName: "header-cell",
    },
    // {
    //   field: "gr",
    //   headerName: "gr",
    //   width: 80,
    //   headerClassName: "header-cell",
    // },
    {
      field: "g_r",
      headerName: "Ετ.Αύξηση",
      width: 120,
      headerClassName: "header-cell",
    },
    {
      field: "kon",
      headerName: "Κωνοφ",
      width: 80,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <VcheckBox
          value={params.row.kon === 1}
          checked={params.row.kon === 1}
          onChange={handleCheckBoxOnChange}
        />
      ),
    },
    {
      field: "plat",
      headerName: "Πλατ",
      width: 80,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <VcheckBox
          value={params.row.plat === 1}
          checked={params.row.plat === 1}
          onChange={handleCheckBoxOnChange}
        />
      ),
    },
    {
      field: "foin",
      headerName: "Φοιν",
      width: 80,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <VcheckBox
          value={params.row.foin === 1}
          checked={params.row.foin === 1}
          onChange={handleCheckBoxOnChange}
        />
      ),
    },
    // {
    //   field: "old_id",
    //   headerName: "old_id",
    //   width: 80,
    //   headerClassName: "header-cell",
    // },
  ];

  return (
    <>
      <div className="container">
        {modalOpen && modalData && (
          <Modal_eidos
            setModalOpen={setModalOpen}
            mTitle={modalTitle}
            mData={modalData}
            onSaveSuccess={RefreshData}
          />
        )}
        <div>
          <header className="jumbotron" style={{ padding: "1.0rem" }}>
            <div className="row">
              <div className="col-9">
                <h5 className="text-center">
                  <b>Πίνακας {grid_Data.length} Ειδών Δένδρων</b>
                </h5>
              </div>
              <div className="col-3 text-right">
                <Button
                  className="btn btn-success btn-sm"
                  onClick={AddNewEidos}
                >
                  <b>+</b> Νέο Είδος
                </Button>
              </div>
            </div>
            <hr style={{ margin: 7, padding: 0 }} />
            {/* -----------------DataGrid------------------------ */}
            <div style={{ height: 640, width: "100%" }}>
              {grid_Data.length && (
                <DataGrid
                  rows={grid_Data}
                  columns={columns}
                  pageSize={10} // Adjust the page size as needed
                //checkboxSelection
                //disableRowSelectionOnClick
                />
              )}
            </div>
          </header>
          <div className="text-center" style={{ marginTop: -20 }}>
            {/* ------------ Επιστροφή ---------------- */}
            <Button className="btn btn-secondary" onClick={() => navigate(-1)}>
              <GiFruitTree
                color="#AEFDCE"
                style={{ top: "-2px", position: "relative" }}
              />{" "}
              Επιστροφή
            </Button>
          </div>
        </div>
      </div>
      {/* ---------------Confirm------------------- */}
      <ConfirmBox
        open={openDel}
        closeDialog={() => setOpenDel(false)}
        title={`Διαγραφή Είδους δένδρου (${idForDel})`}
        text={`\nΕπιθυμείτε τη Διαγραφή του δένδρου με id = ${idForDel}`}
        deleteFunction={deleteEidos}
      />
    </>
  );
};

export default Table_z_eidos;
