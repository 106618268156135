import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import VchartPie from "../../components/VchartPie.component";

import { GiFruitTree } from "react-icons/gi";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

/* ************************************ */
export default function Eidos_Ogkos() {
  const [myData, setMyData] = useState({});
  //----------- Sumarize -----------------------
  const [sszontano, setSszontano] = useState(0);
  const [ss_sz, setSs_sz] = useState(0);
  const [ss_va, setSs_va] = useState(0);
  const [ss_mo_va, setSs_mo_va] = useState(0);
  const [ss_vb, setSs_vb] = useState(0);
  const [ss_mo_vb, setSs_mo_vb] = useState(0);
  const [ss_vc, setSs_vc] = useState(0);
  const [ss_mo_vc, setSs_mo_vc] = useState(0);
  const [ss_sumv, setSs_sumv] = useState(0);
  const [ss_final_trees_a, setSs_final_trees_a] = useState(0);
  const [ss_final_trees_b, setSs_final_trees_b] = useState(0);
  const [ss_final_trees_c, setSs_final_trees_c] = useState(0);
  const [ss_sumv2, setSs_sumv2] = useState(0);
  //----------- Graph --------------------------
  const [titlos1, setTitlos1] = useState(null);
  const [graphData1, setGraphData1] = useState(null);
  //-----------------------------------------------
  const [loadData, setLoadData] = useState(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/statist/st_eidos_ogkos`)
      .then((response) => {
        const formData = response.data;
        setMyData(response.data);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (myData.length > 0) {
      setSszontano(
        myData.reduce((sum, obj) => sum + parseInt(obj.szontano), 0)
      );
      setSs_sz(myData.reduce((sum, obj) => sum + parseInt(obj.sz), 0));
      setSs_va(myData.reduce((sum, obj) => sum + parseInt(obj.va), 0));
      setSs_mo_va(myData.reduce((sum, obj) => sum + parseInt(obj.mo_va), 0));
      setSs_vb(myData.reduce((sum, obj) => sum + parseInt(obj.vb), 0));
      setSs_mo_vb(myData.reduce((sum, obj) => sum + parseInt(obj.mo_vb), 0));
      setSs_vc(myData.reduce((sum, obj) => sum + parseInt(obj.vc), 0));
      setSs_mo_vc(myData.reduce((sum, obj) => sum + parseInt(obj.mo_vc), 0));
      setSs_sumv(myData.reduce((sum, obj) => sum + parseInt(obj.sumv), 0));
      setSs_final_trees_a(
        myData.reduce((sum, obj) => sum + parseInt(obj.final_trees_a), 0)
      );
      setSs_final_trees_b(
        myData.reduce((sum, obj) => sum + parseInt(obj.final_trees_b), 0)
      );
      setSs_final_trees_c(
        myData.reduce((sum, obj) => sum + parseInt(obj.final_trees_c), 0)
      );
      setSs_sumv2(
        myData.reduce(
          (sum, obj) =>
            sum +
            parseInt(
              obj.mo_va * obj.final_trees_a +
                obj.mo_vb * obj.final_trees_b +
                obj.mo_vc * obj.final_trees_c
            ),
          0
        )
      );
      setLoadData(true);
    }
  }, [myData]);

  //-----------------Graph-----------------------
  useEffect(() => {
    if (loadData) {
      //-------------titlos-----------------------
      setTitlos1("Κατανομή Όγκων Κόμης");
      //--------------graphData-----------------------
      setGraphData1({
        labels: ["< 20 κ.μ", "20-100 κ.μ", "> 100 κ.μ"],
        datasets: [
          {
            label: "Αρ.Δένδρων",
            data: [ss_va, ss_vb, ss_vc],
            backgroundColor: [
              "rgba(83, 198, 140, 0.5)",
              "rgba(51, 153, 102, 0.5)",
              "rgba(31, 96, 64, 0.5)",
            ],
            borderColor: [
              "rgba(83, 198, 140, 1)",
              "rgba(51, 153, 102, 1)",
              "rgba(31, 96, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      });
      //----------------------------------------------
    }
  }, [loadData]);

  return (
    <div className="container2">
      <header className="jumbotron" style={{ padding: "1.0rem" }}>
        <h3 className="text-center">
          <b>GreenTree</b> - Στατιστικά
        </h3>
        <h6 className="text-center">(Επισκέπτες - Εγγεγραμμένοι χρήστες)</h6>
      </header>
      <header className="jumbotron" style={{ padding: "1.0rem" }}>
        <h5 className="text-justify">
          <img src="/img/small-logo-green-tree.png" width="120px" alt="Logo" />
          Όγκος κόμης ... ανά Είδος
        </h5>
      </header>
      <Link to="/statist" className="btn btn-secondary pull-right">
        <GiFruitTree
          color="#AEFDCE"
          style={{ top: "-2px", position: "relative" }}
        />{" "}
        Επιστροφή στα Στατιστικά{" "}
      </Link>
      <div>&nbsp;</div>
      {/*-------------------Graph------------------------------*/}
      <div className="row">
        <div className="col-4"></div>
        <div className="col-4">
          {/*----------------------------------------------------*/}
          {titlos1 && <VchartPie titlos={titlos1} data={graphData1} />}
          {/*----------------------------------------------------*/}
        </div>
        <div className="col-4"></div>
      </div>
      {/* ---------------------------------------------------- */}
      <div>&nbsp;</div>
      {/* -------------------------------------------- */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 800 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell
                colSpan={3}
                style={{ backgroundColor: "#ffffff" }}
              ></StyledTableCell>
              {/*---------------------------------- */}
              <StyledTableCell
                colSpan={7}
                align="center"
                style={{
                  backgroundColor: "green",
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                <b>Όγκος Κόμης</b>
              </StyledTableCell>
              <StyledTableCell
                colSpan={4}
                align="center"
                style={{
                  backgroundColor: "blue",
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                <b>Δυνητικός Όγκος</b>
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell
                colSpan={3}
                style={{ backgroundColor: "#ffffff" }}
              ></StyledTableCell>
              {/*--------------------------------------*/}
              <StyledTableCell
                colSpan={2}
                align="center"
                style={{
                  backgroundColor: "green",
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                &lt; 20 (m³)
              </StyledTableCell>
              <StyledTableCell
                colSpan={2}
                align="center"
                style={{
                  backgroundColor: "green",
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                20 - 100 (m³)
              </StyledTableCell>
              <StyledTableCell
                colSpan={2}
                align="center"
                style={{
                  backgroundColor: "green",
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                <b>&gt; 100 (m³)</b>
              </StyledTableCell>
              <StyledTableCell
                rowSpan={2}
                align="center"
                style={{
                  backgroundColor: "green",
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                <b>Σύνολα Όγκων</b>
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{
                  backgroundColor: "blue",
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                &lt; 20
              </StyledTableCell>
              <StyledTableCell
                colSpan
                align="center"
                style={{
                  backgroundColor: "blue",
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                20-100
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{
                  backgroundColor: "blue",
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                &gt; 100
              </StyledTableCell>
              <StyledTableCell
                rowSpan={2}
                align="center"
                style={{
                  backgroundColor: "blue",
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                <b>Σύνολα Όγκων</b>
              </StyledTableCell>
            </TableRow>
            {/* ================ */}
            <TableRow>
              <StyledTableCell
                style={{ border: "1px solid rgba(224, 224, 224, 1)" }}
              >
                <b>κωδ.</b>
              </StyledTableCell>
              <StyledTableCell
                align="right"
                style={{ border: "1px solid rgba(224, 224, 224, 1)" }}
              >
                <b>Κοινή Ονομασία</b>
              </StyledTableCell>
              <StyledTableCell
                align="right"
                style={{ border: "1px solid rgba(224, 224, 224, 1)" }}
              >
                <b>
                  Σύνολο
                  <br />
                  Ζωντανών
                </b>
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{
                  backgroundColor: "green",
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                Αριθμός Δένδρων
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{
                  backgroundColor: "green",
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                Μ.Ο. Όγκου
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{
                  backgroundColor: "green",
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                Αριθμός Δένδρων
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{
                  backgroundColor: "green",
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                Μ.Ο. Όγκου
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{
                  backgroundColor: "green",
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                Αριθμός Δένδρων
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{
                  backgroundColor: "green",
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                Μ.Ο. Όγκου
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{
                  backgroundColor: "blue",
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                Αρ. Δένδρ
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{
                  backgroundColor: "blue",
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                Αρ. Δένδρ
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{
                  backgroundColor: "blue",
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                Αρ. Δένδρ
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {myData.length > 0 && (
            <TableBody>
              {myData.map((row) => (
                <StyledTableRow key={row.eidos_id}>
                  <StyledTableCell component="th" scope="row">
                    {row.eidos_id}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.common_name}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {parseInt(row.sz).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right" style={{ color: "green" }}>
                    {parseInt(row.va).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right" style={{ color: "green" }}>
                    {parseInt(row.mo_va).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right" style={{ color: "green" }}>
                    {parseInt(row.vb).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right" style={{ color: "green" }}>
                    {parseInt(row.mo_vb).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right" style={{ color: "green" }}>
                    {parseInt(row.vc).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right" style={{ color: "green" }}>
                    {parseInt(row.mo_vc).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right" style={{ color: "red" }}>
                    {parseInt(row.sumv).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right" style={{ color: "blue" }}>
                    {parseInt(row.final_trees_a).toLocaleString("el-GR")}
                  </StyledTableCell>
                  <StyledTableCell align="right" style={{ color: "blue" }}>
                    {parseInt(row.final_trees_b).toLocaleString("el-GR")}
                  </StyledTableCell>{" "}
                  <StyledTableCell align="right" style={{ color: "blue" }}>
                    {parseInt(row.final_trees_c).toLocaleString("el-GR")}
                  </StyledTableCell>{" "}
                  <StyledTableCell align="right" style={{ color: "red" }}>
                    {parseInt(
                      row.mo_va * row.final_trees_a +
                        row.mo_vb * row.final_trees_b +
                        row.mo_vc * row.final_trees_c
                    ).toLocaleString("el-GR")}
                  </StyledTableCell>{" "}
                </StyledTableRow>
              ))}

              <StyledTableRow
                key={"SYNOLA"}
                sx={{ backgroundColor: "#c2c2c2" }}
              >
                <StyledTableCell align="left">
                  <b>{""}</b>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <b>{"Γενικό Σύνολο (Δένδρων)"}</b>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <b>{parseInt(ss_sz).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell align="right" style={{ color: "green" }}>
                  <b>{parseInt(ss_va).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell align="right" style={{ color: "green" }}>
                  {/* <b>{parseInt(ss_mo_va).toLocaleString("el-GR")}</b> */}
                </StyledTableCell>
                <StyledTableCell align="right" style={{ color: "green" }}>
                  <b>{parseInt(ss_vb).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell align="right" style={{ color: "green" }}>
                  {/* <b>{parseInt(ss_mo_vb).toLocaleString("el-GR")}</b> */}
                </StyledTableCell>
                <StyledTableCell align="right" style={{ color: "green" }}>
                  <b>{parseInt(ss_vc).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell align="right" style={{ color: "green" }}>
                  {/* <b>{parseInt(ss_mo_vc).toLocaleString("el-GR")}</b> */}
                </StyledTableCell>
                <StyledTableCell align="right" style={{ color: "red" }}>
                  <b>{parseInt(ss_sumv).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell align="right" style={{ color: "blue" }}>
                  <b>{parseInt(ss_final_trees_a).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell align="right" style={{ color: "blue" }}>
                  <b>{parseInt(ss_final_trees_b).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell align="right" style={{ color: "blue" }}>
                  <b>{parseInt(ss_final_trees_c).toLocaleString("el-GR")}</b>
                </StyledTableCell>
                <StyledTableCell align="right" style={{ color: "red" }}>
                  <b>{parseInt(ss_sumv2).toLocaleString("el-GR")}</b>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {/* --------------------[ waiting ]--------------------------- */}
      {!myData?.length && (
        <h6 className="text-center text-info">
          <b>Υπολογισμός . . .</b>{" "}
          <img src="/img/wait-1.gif" height={200} alt="Wait" />
        </h6>
      )}
      {/* ---------------------------------------------------------- */}
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <Link to="/statist" className="btn btn-secondary pull-right">
        <GiFruitTree
          color="#AEFDCE"
          style={{ top: "-2px", position: "relative" }}
        />{" "}
        Επιστροφή στα Στατιστικά{" "}
      </Link>
      {/* -------------------------------------------- */}
      <div>&nbsp;</div>
      <div>&nbsp;</div>
    </div>
  );
}
