import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import AuthService from "../services/auth.service";
import axios from "axios";
import VcomboBox from "../components/VcomboBox.component";
import VcheckBox from "../components/VcheckBox.component";
import VtextBox from "../components/VtextBox.component";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
//import { TextField } from "@mui/material";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { Box, TextField, Typography } from "@mui/material";

import { GiFruitTree } from "react-icons/gi";
import { FaMapMarkedAlt } from "react-icons/fa";

export default function Search2() {
  let roleOk = null;
  const navigate = useNavigate();

  //==============[ Athentication ]==================
  const [state, setState] = useState({
    redirect: null,
    userReady: false,
    currentUser: { username: "" },
    roles: {},
  });
  const currentUser = AuthService.getCurrentUser();
  //-----------------------------------------------
  useEffect(() => {
    let roleOk =
      currentUser?.roles.includes("ROLE_MODERATOR") ||
      currentUser?.roles.includes("ROLE_ADMIN");
    if (!roleOk) {
      navigate("/");
    }
  }, [currentUser, navigate]);
  //===============================================

  const [formData, setFormData] = useState({
    odos_id: 0,
    eidos_id: 0,
    dim_diam: 0,
    zotikotita_id: 0,
    systasi_id: 0,
    simantiko_dendro: 0,
  });
  //------------- ComboBox periexomeno --------------------
  const [opt_odos, setOpt_odos] = useState([]);
  const [opt_eidos, setOpt_eidos] = useState([]);
  const [opt_dim_diam, setOpt_dim_diam] = useState([]);
  const [opt_zotikotita, setOpt_zotikotita] = useState([]);
  const [opt_systasi, setOpt_systasi] = useState([]);
  //-------------------------------------------------------
  const [allCnt, setAllCnt] = useState(0);
  const [dataCnt, setDataCnt] = useState(0);
  const [selDendra, setSelDendra] = useState({});
  const [reset, setReset] = useState(true);
  const [lastCombo, setLastCombo] = useState("");
  const [showOnMap, setShowOnMap] = useState(false);
  const [showWait, setShowWait] = useState(false);
  //const [searchId, setSearchId] = useState(0);
  //const [existId, setExistId] = useState(false);

  //------------------[]
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/statist/st_All`)
      .then((response) => {
        console.log("999999999", response.data[0].exact_count);
        setAllCnt(response.data[0].exact_count);
      })
      .catch((error) => console.error(error));
  }, []);

  //----------------[formData]
  useEffect(() => {

    //-------------------------- Re-Calc (comboBoxes) ----------------------
    const recalc_odos = () => {
      axios
        .post(
          `${process.env.REACT_APP_URL}/api/zlookup/get_odos_filter`,
          formData
        )
        .then((response) => {
          setOpt_odos(response.data);
        })
        .catch((error) => console.error(error));
    };
    const recalc_eidos = () => {
      axios
        .post(
          `${process.env.REACT_APP_URL}/api/zlookup/get_eidos_filter`,
          formData
        )
        .then((response) => {
          setOpt_eidos(response.data);
        })
        .catch((error) => console.error(error));
    };
    const recalc_dim_diam = () => {
      axios
        .post(
          `${process.env.REACT_APP_URL}/api/zlookup/get_dim_diam_filter`,
          formData
        )
        .then((response) => {
          setOpt_dim_diam(response.data);
        })
        .catch((error) => console.error(error));
    };
    const recalc_zotikotita = () => {
      axios
        .post(
          `${process.env.REACT_APP_URL}/api/zlookup/get_zotikotita_filter`,
          formData
        )
        .then((response) => {
          setOpt_zotikotita(response.data);
        })
        .catch((error) => console.error(error));
    };
    const recalc_systasi = () => {
      axios
        .post(
          `${process.env.REACT_APP_URL}/api/zlookup/get_systasi_filter`,
          formData
        )
        .then((response) => {
          setOpt_systasi(response.data);
        })
        .catch((error) => console.error(error));
    };
    //--------------------------------------------------------------------------------
    if (lastCombo === "odos_id") {
      if (formData.eidos_id === 0) recalc_eidos();
      if (formData.dim_diam === 0) recalc_dim_diam();
      if (formData.zotikotita_id === 0) recalc_zotikotita();
      if (formData.systasi_id === 0) recalc_systasi();
    } else if (lastCombo === "eidos_id") {
      if (formData.odos_id === 0) recalc_odos();
      if (formData.dim_diam === 0) recalc_dim_diam();
      if (formData.zotikotita_id === 0) recalc_zotikotita();
      if (formData.systasi_id === 0) recalc_systasi();
    } else if (lastCombo === "dim_diam") {
      if (formData.odos_id === 0) recalc_odos();
      if (formData.eidos_id === 0) recalc_eidos();
      if (formData.zotikotita_id === 0) recalc_zotikotita();
      if (formData.systasi_id === 0) recalc_systasi();
    } else if (lastCombo === "zotikotita") {
      if (formData.odos_id === 0) recalc_odos();
      if (formData.eidos_id === 0) recalc_eidos();
      if (formData.dim_diam === 0) recalc_dim_diam();
      if (formData.systasi_id === 0) recalc_systasi();
    } else if (lastCombo === "systasi") {
      if (formData.odos_id === 0) recalc_odos();
      if (formData.eidos_id === 0) recalc_eidos();
      if (formData.dim_diam === 0) recalc_dim_diam();
      if (formData.zotikotita_id === 0) recalc_zotikotita();
    }
    console.log("formData", formData);
    //--- calculate number of Recs----
    const calcRecs = () => {
      if (
        formData.odos_id === 0 &&
        formData.eidos_id === 0 &&
        formData.dim_diam === 0 &&
        formData.zotikotita_id === 0 &&
        formData.systasi_id === 0 &&
        formData.simantiko_dendro === 0
      ) {
        setSelDendra({});
        setDataCnt(0);
      } else {
        //--------------------------------------------
        setShowWait(true);
        axios
          .post(`${process.env.REACT_APP_URL}/api/search/sh_anaz2`, formData)
          .then((response) => {
            setSelDendra(response.data);
            setDataCnt(response.data.length);
            setShowWait(false);
          })
          .catch((error) => console.error(error));
        //---------------------------------------------
      }
    };
    //---------
    calcRecs();
    //---------
  }, [formData, lastCombo]);

  useEffect(() => {
    const oid = formData.odos_id ? formData.odos_id : 0;
    const eid = formData.eidos_id ? formData.eidos_id : 0;
    const d_d = formData.dim_diam ? formData.dim_diam : 0;
    const zot = formData.zotikotita_id ? formData.zotikotita_id : 0;
    const sys = formData.systasi_id ? formData.systasi_id : 0;
    const sim = formData.simantiko_dendro ? formData.simantiko_dendro : 0;
    if (
      dataCnt > 0 &&
      oid === 0 &&
      eid === 0 &&
      d_d === 0 &&
      zot === 0 &&
      sys === 0 &&
      sim === 0
    )
      setShowOnMap(false);
    else setShowOnMap(true);
  }, [dataCnt, formData]);

  //------------------[reset]
  useEffect(() => {
    if (reset) {
      //----------------------------odos_id-------------------------
      setShowWait(true);
      axios
        .get(`${process.env.REACT_APP_URL}/api/zlookup/z_odoi`)
        .then((response) => {
          setOpt_odos(response.data);
          setShowWait(false);
        })
        .catch((error) => console.error(error));
      //----------------------------z_eidos-------------------------
      setShowWait(true);
      axios
        .get(`${process.env.REACT_APP_URL}/api/zlookup/z_eidos`)
        .then((response) => {
          setOpt_eidos(response.data);
          setShowWait(false);
          //console.log("---------------------------------", response.data);
        })
        .catch((error) => console.error(error));
      //----------------------------z_dim_diam-------------------------
      setShowWait(true);
      axios
        .get(`${process.env.REACT_APP_URL}/api/zlookup/z_dim_diam`)
        .then((response) => {
          setOpt_dim_diam(response.data);
          setShowWait(false);
        })
        .catch((error) => console.error(error));

      //----------------------------z_zotikotita-------------------------
      setShowWait(true);
      axios
        .get(`${process.env.REACT_APP_URL}/api/zlookup/z_zotikotita`)
        .then((response) => {
          setOpt_zotikotita(response.data);
          setShowWait(false);
        })
        .catch((error) => console.error(error));

      //----------------------------z_systasi-------------------------
      setShowWait(true);
      axios
        .get(`${process.env.REACT_APP_URL}/api/zlookup/z_systasi`)
        .then((response) => {
          setOpt_systasi(response.data);
          setShowWait(false);
        })
        .catch((error) => console.error(error));
      //--------------------------------------------------------------

      setReset(false);
    }
  }, [reset]);

  const handleComboBox = (combo_id, value) => {
    if (value) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [combo_id]: value ? value.id : 0,
      }));
      setLastCombo(combo_id);
    }
  };
  //-------------- find_value For ComboBox ----------------------
  const find_value = (combo_id, mValue) => {
    if (mValue) {
      let selectedOption = null;
      switch (combo_id) {
        case "odos_id":
          selectedOption = opt_odos.find((opt) => opt.id === mValue);
          return selectedOption ? selectedOption.label : "";
        case "eidos_id":
          selectedOption = opt_eidos.find((opt) => opt.id === mValue);
          return selectedOption ? selectedOption.label : "";
        case "dim_diam":
          selectedOption = opt_dim_diam.find(
            (opt) => opt.id === parseInt(mValue)
          );
          return selectedOption ? selectedOption.label : "";
        case "zotikotita":
          selectedOption = opt_zotikotita.find(
            (opt) => opt.id === parseInt(mValue)
          );
          return selectedOption ? selectedOption.label : "";
        case "systasi":
          selectedOption = opt_systasi.find(
            (opt) => opt.id === parseInt(mValue)
          );
          return selectedOption ? selectedOption.label : "";
        default:
          return "";
      }
    } else {
      return "";
    }
  };

  const handleCheckBoxOnChange = (event) => {
    const { name, value, checked } = event.target;
    setFormData({ ...formData, [name]: checked === true ? 1 : 0 });
  };





  // const handleTextBox = (e) => {
  //     //console.log("e.target====", e.target);
  //     //if (e.target.value) {
  //     let newValue = parseInt(e.target.value);
  //     console.log("newValue===", newValue);
  //     //setSearchId(newValue);
  //     if (newValue) {
  //         setSearchId(newValue);
  //     } else {
  //         setSearchId(0);
  //     }
  //     //}
  // };

  // useEffect(() => {
  //     //------------------dendro/:id-------------------
  //     if (searchId) {
  //         axios
  //             .get(`${process.env.REACT_APP_URL}/api/dendra/${searchId}`)
  //             .then((response) => {
  //                 const formData = response.data[0];
  //                 if (formData) setExistId(true);
  //                 else setExistId(false);
  //             })
  //             .catch((error) => console.error(error));
  //     }
  // }, [searchId]);

  const myReset = () => {
    setShowOnMap(false);
    setReset(true);
    //setShowOnMap(false);
    setFormData({
      odos_id: 0,
      eidos_id: 0,
      dim_diam: 0,
      zotikotita_id: 0,
      systasi_id: 0,
      simantiko_dendro: 0,
    });
    setDataCnt(0);
    //calcRecs();
  };

  //let selForm = currentUser && roleOk ? "getdendro2" : "getdendro";

  const GotoMap = () => {
    navigate("/mapsel2", { state: selDendra });
  };

  //======================================================================
  return (
    <>
      <header className="jumbotron" style={{ padding: "1.0rem" }}>
        <div className="p-2 text-center bg-light">
          <h5 className="text-center">
            Κριτήρια αναζήτησης Δένδρων στον Χάρτη ...
          </h5>
          <div>Επιλέξτε ...</div>
        </div>
        {/* ............................................. */}
        <div className="p-2 text-center bg-light">
          &nbsp; &nbsp;
          {/* ................odos_id..................... */}
          {formData && (
            <>
              <VcomboBox
                id="odos_id"
                options={opt_odos}
                value={find_value("odos_id", formData.odos_id)}
                label="Οδός"
                onChange={handleComboBox}
                //disabled={!currentUser}
                width="300px"
              />
              <div style={{ fontSize: "10px" }}>&nbsp;</div>
              {/* ----------------eidos_id------------- */}
              <VcomboBox
                id="eidos_id"
                options={opt_eidos}
                value={find_value("eidos_id", formData.eidos_id)}
                label="Είδος Δέντρου"
                onChange={handleComboBox}
                width={480}
              //disabled={!currentUser}
              />
              <div style={{ fontSize: "10px" }}>&nbsp;</div>
              {/* ---------------dim_diam-------------- */}
              <VcomboBox
                id="dim_diam"
                options={opt_dim_diam}
                value={find_value("dim_diam", formData.dim_diam)}
                label="Δημοτικό Διαμέρισμα"
                onChange={handleComboBox}
                width={300}
              //disabled={!currentUser}
              />
              <div style={{ fontSize: "10px" }}>&nbsp;</div>
              {/* ---------------dim_diam-------------- */}
              <VcomboBox
                id="zotikotita_id"
                options={opt_zotikotita}
                value={find_value("zotikotita", formData.zotikotita_id)}
                label="Ζωτικότητα"
                onChange={handleComboBox}
                width={300}
              //disabled={!currentUser}
              />
              <div style={{ fontSize: "10px" }}>&nbsp;</div>
              {/* ---------------dim_diam-------------- */}
              <VcomboBox
                id="systasi_id"
                options={opt_systasi}
                value={find_value("systasi", formData.systasi_id)}
                label="Σύσταση"
                onChange={handleComboBox}
                width={300}
              //disabled={!currentUser}
              />
              <div className="text-left">
                <VcheckBox
                  id="simantiko_dendro"
                  name="simantiko_dendro"
                  checked={formData?.simantiko_dendro === 1}
                  label="Σημαντικο Δέντρο"
                  onChange={handleCheckBoxOnChange}
                  //disabled={!currentUser}
                  className="col-4"
                />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {showWait && (
                  <>
                    <img
                      src="/img/wait-4.gif"
                      height={35}
                      alt="Wait"
                      style={{ marginTop: -5 }}
                    />
                    &nbsp;
                    <span
                      style={{
                        padding: 4,
                        color: "white",
                        backgroundColor: "#79a6d2",
                      }}
                    >
                      &nbsp;... <b>Έ λ ε γ χ ο ς</b> ... Περιμένετε &nbsp;
                    </span>
                  </>
                )}
              </div>

              <div>
                Αριθμός Επιλεγμένων Δένδρων :{" "}
                <span style={{ fontSize: "20px" }}>
                  {" "}
                  <b>{dataCnt}</b>
                </span>{" "}
                από συνολικά{" "}
                <span style={{ fontSize: "20px" }}>
                  {" "}
                  <b>{allCnt}</b>
                </span>
              </div>
            </>
          )}
        </div>
        <div>&nbsp;</div>

        <div className="row">
          {/* {currentUser && ( */}
          <div className="col-4">
            <Button onClick={myReset} className="btn btn-secondary">
              <GiFruitTree
                color="#AEFDCE"
                style={{ top: "-2px", position: "relative" }}
              />{" "}
              μηδενισμός
            </Button>
          </div>
          {!showWait && dataCnt > 0 && (
            <div className="col-8">
              <Button
                onClick={GotoMap}
                className="btn btn-success pull-right"
                disabled={!showOnMap}
              >
                <GiFruitTree
                  color="#AEFDCE"
                  style={{ top: "-2px", position: "relative" }}
                />{" "}
                Εμφάνιση στον χάρτη ...
              </Button>
            </div>
          )}
        </div>
      </header>
      {/* --------------------------------------------------------- */}
      <div className="text-center" style={{ marginTop: -20 }}>
        <Link to="/mod" className="btn btn-secondary">
          <GiFruitTree
            color="#AEFDCE"
            style={{ top: "-2px", position: "relative" }}
          />{" "}
          Επιστροφή{" "}
        </Link>
      </div>
      <div>&nbsp;</div>
      {/* --------------------------------------------------------- */}
    </>
  );
}
