import { useState, useEffect, useRef } from "react";
//-----------------------------
import L from "leaflet";
import { MapContainer } from "react-leaflet/MapContainer";
import { TileLayer } from "react-leaflet/TileLayer";
import { WMSTileLayer } from "react-leaflet/WMSTileLayer";
import { Pane } from "react-leaflet/Pane";
import { Marker } from "react-leaflet/Marker";
import { Popup } from "react-leaflet/Popup";
import { Tooltip } from "react-leaflet/Tooltip";
import { LayersControl } from "react-leaflet/LayersControl";
import { LayerGroup } from "react-leaflet/LayerGroup";
import { Circle } from "react-leaflet/Circle";
import { Rectangle } from "react-leaflet/Rectangle";
import { useMapEvents } from "react-leaflet";
import { MapControl } from "react-leaflet";
import { useMap } from "react-leaflet";

import BingTileLayer from "./BingTileLayer.component";
import CoordinatesControl from "./CoordinatesControl.component";
//--------------------------------
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

export const myIcon = new L.Icon({
  iconUrl:
    "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [0, -34],
  shadowSize: [41, 41],
});

/* ******************************** */
const MapHalf = (props) => {
  /* ********************** */
  const { lat, lng, onMapClick, mapEventActive } = props;
  /* **************************************************** */
  //let xlng = lng ? lng : 22.953772;
  //let xlat = lat ? lat : 40.629901;
  const [map, setMap] = useState(null);

  const position = [lat, lng];
  const [center, setCenter] = useState([lat, lng]);
  const BING_MAPS_KEY =
    "AjG59CvIm2ydzA7QDKMjSc-VtqC4epnaeu-FRYDoKnCWQKcPtScu04zhNrYegYAo";
  const [filterEidosId, setFilterEidosId] = useState(null);
  const maxZoom = 21;

  useEffect(() => {
    if (lng && lat) {
      setCenter([lat, lng]);
    }
  }, []);

  useEffect(() => {
    setCenter([lat, lng]);
  }, [lat, lng]);

  var LeafIcon = L.Icon.extend({
    options: {
      iconSize: [38, 95],
      shadowSize: [50, 64],
      iconAnchor: [22, 94],
      shadowAnchor: [4, 62],
      popupAnchor: [-3, -76],
    },
  });

  const [newPoint, setNewPoint] = useState(null);

  const handleMapClick = (e) => {
    if (mapEventActive) {
      onMapClick(e.latlng);
    }
  };

  function MapClickHandler({ onMapClick }) {
    useMapEvents({
      click(e) {
        onMapClick(e);
        if (mapEventActive) {
          setNewPoint(e.latlng);
        }
      },
    });
    return null;
  }

  const wms_url = process.env.REACT_APP_WMS_URL

  return (
    <MapContainer
      center={center}
      zoom={19}
      maxZoom={22}
      whenCreated={setMap}
      scrollWheelZoom={false}
      style={{
        height: "50vh",
        cursor: mapEventActive ? "crosshair" : "auto",
      }}
    >
      {/* ---------------------[ ESRI Topo Map ]-------- */}
      <LayersControl position="topright">
        <Pane>
          {/* -------------------------[ Open Street Map ]--- */}
          <LayersControl.BaseLayer name="Open Street Map">
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              maxZoom={maxZoom}
              maxNativeZoom={19}
              minZoom={0}
            />
          </LayersControl.BaseLayer>
          {/* -------------------------[ Bing Map Road ]---- */}
          <LayersControl.BaseLayer name="Bing Map Road">
            <BingTileLayer
              bingMapsKey={BING_MAPS_KEY}
              imagerySet="Road"
              culture="el"
              //minZoom="1"
              maxZoom="21"
            />
          </LayersControl.BaseLayer>
          {/* ---------------------[ ESRI Topo Map ]-------- */}
          <LayersControl.BaseLayer name="ESRI Topo Map" checked>
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
              maxZoom={maxZoom}
              maxNativeZoom={19}
            ></TileLayer>
          </LayersControl.BaseLayer>
          {/* -------------------------[ ESRI Street Map ]------ */}
          <LayersControl.BaseLayer name="ESRI Street Map">
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
              maxZoom={maxZoom}
              maxNativeZoom={19}
            ></TileLayer>
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer></LayersControl.BaseLayer>
          {/* -------------------------[ Bing Satellite Map ]--- */}
          <LayersControl.BaseLayer name="Bing Satellite Map">
            <BingTileLayer
              bingMapsKey={BING_MAPS_KEY}
              imagerySet="Aerial"
              culture="el"
              //minZoom="1"
              maxZoom="21"
            />
          </LayersControl.BaseLayer>
          {/* -------------------------[ ESRI Satellite Map ]--- */}
          <LayersControl.BaseLayer name="ESRI Satellite Map">
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              maxZoom={maxZoom}
              maxNativeZoom={19}
            ></TileLayer>
          </LayersControl.BaseLayer>
          {/* -------------------------[ Κενό ]------------ */}
          <LayersControl.BaseLayer name="Κενό">
            <TileLayer
              attribution=""
              url=""
              maxNativeZoom={19}
              minZoom={0}
              maxZoom={maxZoom}
            />
          </LayersControl.BaseLayer>
        </Pane>
        <Pane>
          {/* -----------------------Σημείο Ενδιαφέροντος----------------- */}
          <LayersControl.Overlay name="Σημείο Ενδιαφέροντος" checked={true}>
            <LayerGroup>
              {/* <Circle
                center={center}
                pathOptions={{ fillColor: "black" }}
                radius={0.7}
                stroke={true}
              /> */}
              {/* <Circle
                center={center}
                pathOptions={{ fillColor: "blue" }}
                radius={2}
                stroke={true}
              /> */}
              <Circle
                center={center}
                pathOptions={{
                  fillColor: "green",
                  fillOpacity: "20%",
                  weight: 8,
                  color: "red",
                }}
                radius={4}
                stroke={true}
              />
              <Marker position={center} icon={myIcon}>
                <Popup>
                  Τρέχον Σημείο Ενδιαφέροντος
                </Popup>
                <Tooltip>Σημείο Ενδιαφέροντος</Tooltip>
              </Marker>
              {newPoint && (
                <>
                  <Circle
                    center={newPoint}
                    radius={4}
                    stroke={true}
                    pathOptions={{ fillColor: "lightgray" }}
                  />
                  <Circle
                    center={newPoint}
                    pathOptions={{ fillColor: "blue" }}
                    radius={0.1}
                    stroke={true}
                  />
                </>
              )}
            </LayerGroup>
          </LayersControl.Overlay>
          {/* -----------------------[ WMS Layer ]------------ */}
          <LayersControl.Overlay name="WMS Layer " checked={true}>
            <WMSTileLayer
              url={`${wms_url}`}
              layers="v_g_dendra"
              format="image/png"
              transparent={true}
              maxZoom={maxZoom}
            />
          </LayersControl.Overlay>
        </Pane>

        {/*-------------------------*/}
        <MapClickHandler onMapClick={handleMapClick} />
        {/*-------------------------*/}
        <CoordinatesControl />
        {/*----------------*/}
      </LayersControl>
    </MapContainer>
  );
};

export default MapHalf;
