import React, { Component } from "react";
import { Link, useNavigate } from "react-router-dom";
import UserService from "../services/user.service";

export default class BoardAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: "",
    };
  }

  componentDidMount() {
    UserService.getAdminBoard().then(
      (response) => {
        this.setState({
          content: response.data,
        });
      },
      (error) => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });
      }
    );
  }

  render() {
    return (
      <div className="container">
        <header className="jumbotron" style={{ padding: "1.0rem" }}>
          <h4>{this.state.content}</h4>
        </header>
        <header className="jumbotron" style={{ padding: "1.0rem" }}>
          <h5 className="text-center">Διαχείριση Συστήματος</h5>
          <hr style={{ margin: 0 }} />
          <div style={{ margin: "10px 0 10px 20%" }}>
            <img
              src="/img/logo-greentree.png"
              height="25px"
              alt="greenTree"
            ></img>
            <Link to="/diax_users" className="btn btn-warning">
              Κατάλογος <b>χρηστών</b>
            </Link>
          </div>
        </header>
      </div>
    );
  }
}
