import React, { Component } from "react";
import { Routes, Route, Link } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import AuthService from "./services/auth.service";

import Login from "./components/login.component";
import Register from "./components/register.component";
import Home from "./components/home.component";
import Views from "./components/pviews.component";
import About from "./components/About.component";
import Profile from "./components/profile.component";
// import BoardUser from "./components/board-user.component";
import BoardUser from "./components/board-user";
import BoardModerator from "./components/board-mod";
import BoardAdmin from "./components/board-admin";

import Map2 from "../src/pages/map";
//import Map2 from "../src/pages/map"/:lng/:lat;
import Map3 from "../src/pages/map3";
import GetDendro from "./pages/getdendro";
import GetDendro2 from "./pages/getdendro2";
import SearchPage from "./pages/search";
import Search2 from "./pages/search2";
import GuestForm from "./pages/GuestForm";
import Statistics from "./pages/statist";
import Zotikotita from "./pages/stats/zotikotita";
import Nekra from "./pages/stats/nekra";
import Eidos_DD from "./pages/stats/eidos_DD";
import Eidos_h_d_e from "./pages/stats/eidos_h_d_e";
import Eidos_Ogkos from "./pages/stats/eidos_ogkos";
import Eidos_CO2 from "./pages/stats/eidos_co2";
import Eidos_Val from "./pages/stats/eidos_val";
import DiaxeirMetra from "./pages/diaxeir";
import DiaxMetra_Grid from "./pages/diaxmetr_grid";
import Diax_Users from "./pages/diax_users";
import Table_z_eidos from "./pages/table_eidos";
import Table_z_ergasies from "./pages/table_ergasies";
import Table_z_apograf from "./pages/table_apograf";

import MapSel from "./pages/mapSel";
import MapSel2 from "./pages/mapSel2";
import Vmap from "./pages/Vmap";

import Temp from "./pages/temp";

import { BsPersonCircle } from "react-icons/bs";
import { AiFillDatabase } from "react-icons/ai";
import { GiBullseye } from "react-icons/gi";
import { FaHome } from "react-icons/fa";
import { HiUsers } from "react-icons/hi";
import { FaUserEdit } from "react-icons/fa";
import { BiEditAlt } from "react-icons/bi";
import { SiPhpmyadmin } from "react-icons/si";
import { BsInfoLg } from "react-icons/bs";

import Footer from "./components/Footer.component";

//import HomeIcon from "@mui/icons-material/Home";

/******************************************** */
class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
      });
    }
  }

  logOut() {
    AuthService.logout();
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    });
  }

  render() {
    const { currentUser, showModeratorBoard, showAdminBoard } = this.state;

    return (
      <div
        style={{
          backgroundImage: 'url("img/back01c.png")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark2">
          <Link to={"/"} className="navbar-brand">
            <img
              src="/img/logo-greentree.png"
              height="30px"
              alt="greenTree"
            ></img>
            &nbsp; GreenTree
          </Link>
          <div className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link to={"/home"} className="nav-link">
                <FaHome
                  color="#AEFDCE"
                  size={21}
                  style={{ top: "-3px", position: "relative" }}
                />
                {/* <FontAwesomeIcon icon={faHome} color="#AEFDCE" /> */}
                {/* <HomeIcon sx={{ color: "#AEFDCE", fontSize: 25, position: 'relative',
                    top: '-3px', left: '3px', }} /> */}
                &nbsp;Αρχική
              </Link>
            </li>
            <li className="nav-item">
              <Link to={"/views"} className="nav-link">
                <GiBullseye
                  color="#AEFDCE"
                  size={21}
                  style={{ top: "-3px", position: "relative" }}
                />
                &nbsp;Προβολές
              </Link>
            </li>

            {currentUser && (
              <li className="nav-item">
                <Link to={"/user"} className="nav-link">
                  <HiUsers
                    color="#AEFDCE"
                    size={21}
                    style={{ top: "-3px", position: "relative" }}
                  />
                  Χρήστης
                </Link>
              </li>
            )}
            {showModeratorBoard && (
              <li className="nav-item">
                <Link to={"/mod"} className="nav-link">
                  <BiEditAlt
                    color="#AEFDCE"
                    size={21}
                    style={{ top: "-3px", position: "relative" }}
                  />
                  Διαχειριστής
                </Link>
              </li>
            )}

            {showAdminBoard && (
              <li className="nav-item">
                <Link to={"/admin"} className="nav-link">
                  <SiPhpmyadmin
                    color="#AEFDCE"
                    size={21}
                    style={{ top: "-3px", position: "relative" }}
                  />
                  Admin
                </Link>
              </li>
            )}

            <li className="nav-item">
              <Link to={"/about"} className="nav-link">
                <BsInfoLg
                  color="#AEFDCE"
                  size={21}
                  style={{ top: "-3px", position: "relative" }}
                />
                Σχετικά
              </Link>
            </li>

            {/* ---------------- mui Icons --------------------
            <Stack direction="row" alignItems="center" gap={1}>
              <AddCircleIcon style={{ color: '#AEFDCE' }} />
              <Typography variant="h6" style={{ color: '#AEFDCE' }}>Dokimastiko </Typography>
            </Stack> */}
          </div>

          {currentUser ? (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/profile"} className="nav-link">
                  {/* <FontAwesomeIcon icon={faUser} color="#FCFFBD" /> -{" "} */}
                  <BsPersonCircle
                    color="#FCFFBD"
                    size={25}
                    style={{ position: "relative", top: "-3px" }}
                  />
                  &nbsp;
                  {currentUser.username}
                </Link>
              </li>
              <li className="nav-item">
                <a href="/login" className="nav-link" onClick={this.logOut}>
                  Έξοδος
                </a>
              </li>
            </div>
          ) : (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/login"} className="nav-link">
                  Είσοδος
                </Link>
              </li>

              <li className="nav-item">
                <Link to={"/register"} className="nav-link">
                  Εγγραφή
                </Link>
              </li>
            </div>
          )}
        </nav>
        <div>
          <div
            className="container mt-3"
            style={{ marginTop: "30px!important" }}
          >
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/views" element={<Views />} />
              <Route path="/about" element={<About />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/user" element={<BoardUser />} />
              <Route path="/mod" element={<BoardModerator />} />
              <Route path="/admin" element={<BoardAdmin />} />
              <Route path="/map" element={<Map2 />} />
              <Route path="/map/:lng/:lat" element={<Map2 />} />
              <Route path="/map3" element={<Map3 />} />
              <Route path="/getdendro/:id" element={<GetDendro />} />
              <Route path="/getdendro2/:id" element={<GetDendro2 />} />
              <Route path="/search" element={<SearchPage />} />
              <Route path="/search2" element={<Search2 />} />
              <Route path="/guestform/:id" element={<GuestForm />} />
              <Route path="/statist" element={<Statistics />} />
              <Route path="/zotikotita" element={<Zotikotita />} />
              <Route path="/nekra" element={<Nekra />} />
              <Route path="/eidosDD" element={<Eidos_DD />} />
              <Route path="/eidos_hde" element={<Eidos_h_d_e />} />
              <Route path="/eidos_ogkos" element={<Eidos_Ogkos />} />
              <Route path="/eidos_co2" element={<Eidos_CO2 />} />
              <Route path="/eidos_val" element={<Eidos_Val />} />
              <Route path="/diaxeir" element={<DiaxeirMetra />} />
              <Route path="/diaxeir/:id" element={<DiaxeirMetra />} />
              <Route path="/diaxmetr_grid" element={<DiaxMetra_Grid />} />
              <Route path="/diax_users" element={<Diax_Users />} />
              <Route path="/diaxmetr_grid/:mode" element={<DiaxMetra_Grid />} />
              <Route path="/table_eidos" element={<Table_z_eidos />} />
              <Route path="/table_ergasies" element={<Table_z_ergasies />} />
              <Route path="/table_apograf" element={<Table_z_apograf />} />
              <Route path="/temp" element={<Temp />} />

              <Route path="/mapsel/:did/:eid/:d_d" element={<MapSel />} />
              <Route path="/mapsel2" element={<MapSel2 />} />
            </Routes>
          </div>
          <div>
            <Routes>
              <Route path="/vmap" element={<Vmap />} />
              <Route path="/vmap/:id" element={<Vmap />} />
              <Route path="/vmap2/:alat/:alng" element={<Vmap />} />
            </Routes>
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default App;
